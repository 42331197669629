import styled from 'styled-components'

const SinglePackageStyle = styled.div.attrs((props) => ({ className: props.className }))`
	background-color: var(--white);
	border-radius: var(--sp0-75x);
	border: 1px solid var(--lightGrey);

	.heading {
		padding: var(--titleFromContent) 0;
		background-color: var(--lightGrey);
		width: 100%;
		border-radius: var(--sp0-75x);
	}

	.main-block {
		padding: var(--titleFromContent) var(--sp7x);

		.prices {
			width: 100%;
			padding-bottom: var(--titleFromContent);
			border-bottom: 1px solid var(--lightGrey);

			.price {
				margin-bottom: var(--sp3x);

				h3:last-child {
					margin-left: var(--sp2x);
				}
			}
		}
		.content {
			padding-top: var(--titleFromContent);

			p {
				font-size: var(--h6);
				font-family: MonRegular, MonRegularRu;
				margin-bottom: var(--titleFromContent);
				position: relative;
				padding-left: var(--sp7x);

				&:before {
					content: url('/images/svg/check.svg');
					position: absolute;
					top: var(--spSize);
					left: 0;
				}

				strong {
					font-family: MonBold, MonBoldRu;
				}
			}
		}

		.client-text {
			position: relative;
			padding-bottom: var(--titleFromContent);

			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				left: calc(-1 * var(--sp7x));
				width: calc(100% + (2 * var(--sp7x)));
				height: 1px;
				background-color: var(--darkGrey);
			}
		}

		.payment-method-text {
			padding-top: var(--titleFromContent);
			margin-bottom: var(--titleFromContent);
		}

		.ant-form-item {
			margin: 0;
			margin-top: var(--titleFromContent);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		.main-block {
			.prices {
				.price {
					h3:last-child {
						margin-left: var(--sp1-5x);
					}
				}
			}
			.content {
				p {
					padding-left: var(--sp6-5x);
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		.main-block {
			.prices {
				.price {
					margin-bottom: var(--sp2-5x);
					h3:last-child {
						margin-left: var(--sp1-5x);
					}
				}
			}
			.content {
				p {
					padding-left: var(--sp5-5x);
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		.main-block {
			padding: var(--titleFromContent) var(--sp4x);

			.prices {
				.price {
					h3:last-child {
						margin-left: var(--sp1-5x);
					}
				}
			}
			.content {
				p {
					padding-left: var(--sp6-5x);
				}
			}

			.client-text {
				&:before {
					left: calc(-1 * var(--sp4x));
					width: calc(100% + (2 * var(--sp4x)));
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.main-block {
			padding: var(--titleFromContent) var(--sp2x);

			.prices {
				.price {
					margin-bottom: var(--sp2x);

					h3:last-child {
						margin-left: var(--sp1-5x);
					}
				}
			}
			.content {
				p {
					padding-left: var(--sp5-5x);
				}
			}

			.client-text {
				&:before {
					left: calc(-1 * var(--sp2x));
					width: calc(100% + (2 * var(--sp2x)));
				}
			}
		}
	}
`
export default SinglePackageStyle
