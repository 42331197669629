import { memo as Memo, useMemo, useState } from 'react'

//* HOC's
import { withUIContext, withLanguageContext } from 'context'
import { useRouter } from 'next/router'

//* Components
import { Text, Icon, FormItem, Translate, FormInput } from 'components/common'
import Collapse from '@material-ui/core/Collapse'

//* Helpers
import { getParamsByKey } from 'helpers'

//* Styles
import FormStyle from 'styles/pages/FormStyle'

const LocationFilter = Memo(({ queryParamKeys, countries, onCategoryChange, winWidth, translate }) => {
	//! Router
	const router = useRouter()

	//! States
	const [searchVal, setSearchVal] = useState('')
	const [opened, setOpened] = useState(true)
	const [activeProdTypesInTabletLocation, setActiveProdTypesInTabletLocation] = useState('')

	//! Get Location Filter Selected and Free Items
	const getSelectedAndFreeLocations = useMemo(() => {
		const catParam = queryParamKeys[1] //? "loc_ids"
		const activeLocations = getParamsByKey(router, catParam)
		const selectedLocationsList = []
		const freeLocationsList = []

		countries.forEach((c, k) => {
			if (!activeLocations?.includes(`${c.id}`) && c.name.toLowerCase().includes(searchVal)) {
				freeLocationsList.push(
					<Text
						key={k}
						onClick={() => onCategoryChange(catParam, c.id)}
						tag={'p'}
						className={`single-location pM MonRegular cursorPointer`}
						text={c.name}
					/>
				)
			} else if (activeLocations && activeLocations?.includes(`${c.id}`)) {
				selectedLocationsList.push(
					<div className='single-sel-location FlexBox alignMiddle spaceBetween' key={k}>
						<Text tag={'p'} className={'pM MonRegular'} text={c.name} />
						<Icon className='icon-close cursorPointer' onClick={() => onCategoryChange(catParam, c.id)} />
					</div>
				)
			}
		})

		return { selectedLocationsList, freeLocationsList, activeLocations }
	}, [countries, queryParamKeys, router, searchVal])

	//! Locations Filter
	const storeLocations = useMemo(() => {
		const { selectedLocationsList, freeLocationsList, activeLocations } = getSelectedAndFreeLocations

		return (
			<>
				{/* Selected Locations */}
				{activeLocations && <div className='selected-locations FlexBoxColumn'>{selectedLocationsList}</div>}

				{/* Free Locations */}
				<div className={`locations FlexBoxColumn ${winWidth > 1023 ? '' : 'active'}`}>{freeLocationsList}</div>
			</>
		)
	}, [getSelectedAndFreeLocations])

	return (
		<div className={`single-filter overflowHidden ${opened ? 'active' : ''}`}>
			{winWidth > 1023 ? (
				<>
					<Text tag={'h5'} onClick={() => setOpened(!opened)} className={'title h5 MonMedium black'} text={'countryToExport'} />
					<Collapse in={opened} timeout='auto'>
						<FormStyle className='search'>
							<div className='FormRow FlexBox relativeWrapp'>
								<FormItem rules={[{ type: 'text' }]}>
									<FormInput placeholder={translate('search')} onChange={(e) => setSearchVal(e.target.value.toLowerCase())} />
								</FormItem>
								<Icon className='icon-search ultraDarkGrey cursorPointer' />
							</div>
						</FormStyle>

						{storeLocations}
					</Collapse>
				</>
			) : (
				<>
					<Text tag={'h5'} onClick={() => setActiveProdTypesInTabletLocation('active')} className={'title tablet h5 MonMedium black'}>
						<Translate val={'countryToExport'} />
						<Icon className='icon-arrow-right' />
					</Text>
					<div className={`filter-in-tablet-mode ${activeProdTypesInTabletLocation}`}>
						<Text
							tag={'p'}
							onClick={() => setActiveProdTypesInTabletLocation('')}
							className={'head-title pM MonRegular darkGrey uppercase FlexBox cursorPointer'}
						>
							<Icon className='icon-arrow-left' />
							<Translate val={'countryToExport'} />
						</Text>
						<FormStyle className='search'>
							<div className='FormRow FlexBox relativeWrapp'>
								<FormItem rules={[{ type: 'text' }]}>
									<FormInput placeholder={translate('search')} onChange={(e) => setSearchVal(e.target.value.toLowerCase())} />
								</FormItem>
								<Icon className='icon-search ultraDarkGrey' />
							</div>
						</FormStyle>

						{storeLocations}
					</div>
				</>
			)}
		</div>
	)
})

export default withLanguageContext(withUIContext(LocationFilter, ['winWidth']), ['translate'])
