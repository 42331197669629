import { memo as Memo } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Button } from 'antd'
import { Text, FormItem, FormInput } from 'components/common'

const Contacts = Memo((props) => {
	return (
		<>
			<div className='FormColumnsTitle'>
				<Text tag={'h4'} className={'MonMedium black'} text={'Contacts'} />
			</div>

			<div className='FormColumnsWrapp FlexBoxContainer spaceBetween'>
				<div className='FormCol'>
					<FormItem label={'ContactPerson'} name={'contact_name'} required={true}>
						<FormInput />
					</FormItem>
				</div>

				<div className='FormColGroup FlexBoxContainer spaceBetween'>
					<div className='FormCol FormCol-50'>
						<FormItem label={'MobileNumber'} name={'contact_phone'} required={true}>
							<FormInput />
						</FormItem>
					</div>
					<div className='FormCol FormCol-50'>
						<FormItem label={'Email'} name={'contact_email'} required={true}>
							<FormInput />
						</FormItem>
					</div>
				</div>
				{props.type == 2 && (
					<div className='formBtnWrapp'>
						<Button htmlType={'Submit'} className={`btn btn-black btn-max-width ${!props.passBtnIsActive && 'disabled'}`}>
							{props.translate('SaveChanges')}
						</Button>
					</div>
				)}
			</div>
		</>
	)
})

export default withLanguageContext(Contacts, ['translate', 'selectedLang'])
