import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import { Image, Text, Container } from 'components/common'
import ProjectApprovedStyle from './style'

const ProjectApproved = forwardRef((props, ref) => {

	const itemRef = useRef()

	useImperativeHandle(ref, () => [itemRef], [])

	return (
		<ProjectApprovedStyle>
			<Container className='block full last' ref={itemRef}>
				<Text tag={'h3'} className={'title h3 MonRegular'} text={'investOppApprovedTitle'} />
				<Text tag={'h6'} className={'text h3 MonRegular'} text={'investOppApprovedText'} />
				<Image src='/images/singleInvest/approve.svg' alt='approve image' />
			</Container>
		</ProjectApprovedStyle>
	)
})

export default ProjectApproved
