import App from 'next/app'
import React from 'react'

//* Providers
import { UIProvider, DataProvider, LanguageProvider, AccountProvider } from 'context'
import { ThemeProvider } from 'styled-components'

//* Components
import { AppHeader, AppFooter, Popup } from 'components/common'
import dynamic from 'next/dynamic'
const Preloader = dynamic(() => import('components/common/Preloader'), { ssr: false })

//* Helpers
import { getData, getPageParams, getUser, config } from 'helpers'

//* Styles
import '../styles/icons/style.css'
import 'swiper/swiper.min.css'
import theme from '../styles/theme'
import HelperClass from '../styles/helperClass'
import Variables from 'styles/variables'
import Typography from 'styles/typography'

class MyApp extends App {
    static async getInitialProps({ Component, ctx, router, ...props }) {
        //! Checking If First Request or Not
        if (!process.browser) {
            //! Default States
            const newState = {
                pages: {},
                global: {},
                singleItem: {},
            }
            let userData = {
                isLoggedIn: false,
                privatePages: {},
            }

            //! Geeting User Data
            userData = await getUser(ctx.locale, userData, ctx)

            //! Checking is private page and not logged in
            userData.redirect && ctx.res.redirect(userData.redirectUrl || '/?c=login')

            //! Getting Page Data
            const data = await getData(ctx.locale, newState, ctx, true)

            return {
                pageProps: {
                    ...data,
                    browser: false,
                },
                userProps: userData.newState,
            }
        } else {
            return {
                pageProps: {
                    pageParams: getPageParams(ctx.locale, ctx),
                    browser: true,
                },
            }
        }
    }

    state = {
        preloader: true,
        preloaderHide: false,
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ preloaderHide: false })
            setTimeout(() => {
                this.setState({ preloader: false })
            }, 800)
        }, 200)
    }

    render() {
        const { Component, pageProps, userProps, router } = this.props

        return (
            <LanguageProvider>
                <UIProvider>
                    <DataProvider newData={pageProps}>
                        <AccountProvider newUser={userProps}>
                            <ThemeProvider theme={theme}>
                                <HelperClass />
                                <Variables />
                                <Typography />
                                {router.pathname !== '/404' && <AppHeader preloader={!this.state.preloader} />}
                                <div className='minHeightCont' style={{ opacity: this.state.preloader ? 0 : 1 }}>
                                    {(!process.browser || (process.browser && !this.state.preloader)) && <Component {...pageProps} config={config.routes} selectedLang={router.locale} preloader={this.state.preloader} />}
                                    <Popup />
                                </div>
                                {router.pathname !== '/404' && <AppFooter />}
                                {this.state.preloader && <Preloader hide={this.state.preloaderHide} className={'preloaderHome'} />}
                            </ThemeProvider>
                        </AccountProvider>
                    </DataProvider>
                </UIProvider>
            </LanguageProvider>
        )
    }
}

export default MyApp
