import React, { forwardRef, useRef, useImperativeHandle, useMemo, useCallback, useState } from 'react'

//* Components
import { Container, FormContainer } from 'components/common'
import { BusinessInArmeniaFormMid, BusinessInArmeniaFormTop, BusinessInArmeniaFormBottom } from 'components/pages'

//* Styles
import FormStyle from 'styles/pages/FormStyle'
import { withLanguageContext } from 'context'

const BusinessInArmeniaForm = forwardRef(({ currency, ...props }, ref) => {
    //! Initial Form Values
    const initialFormValues = useMemo(
        () => ({
            currency: 'USD',
            sector: '',
            product: '',
        }),
        []
    )

    //! Initial Result Values
    const initialResultValues = useMemo(
        () => ({
            VATax: 0,
            AnnualProfitTax: 0,
            SalesTax: 0,
            AnnualPersonalIncomeTax: 0,
            AnnualPaymentArmy: 0,
            AnnualSocialPayments: 0,
            DividendTax: 0,
            AccessTax: 0,
            Text: '',
        }),
        []
    )

    //! States
    const [list, setList] = useState(initialFormValues)
    const [result, setResult] = useState({ first: initialResultValues, second: null })

    //! Refs
    const form = useRef()
    const topRef = useRef()
    const midRef = useRef()
    const botRef = useRef()

    //! Imperative Handle
    useImperativeHandle(
        ref,
        () => [
            topRef.current[0].current.childNodes[0].childNodes,
            topRef.current[1].current,
            topRef.current[2].current.childNodes,
            midRef.current[0].current,
            midRef.current[1].current.childNodes,
            botRef.current[0].current,
            botRef.current[1].current.childNodes[0].childNodes,
            botRef.current[2].current,
        ],
        [props.selectedLang]
    )

    //! Form Change
    const onFormChange = useCallback(
        (val, vals) => {
            const name = Object.keys(val)[0]

            if (name === 'currency') {
                setList({ ...list, currency: val[name] })
            } else if (name === 'BusinessSector') {
                setList({ ...list, sector: val[name] })
            } else if (name === 'Product') {
                setList({ ...list, product: val[name] })
            }
        },
        [list]
    )

    //! Calculator Currency
    const calculatorCurrency = useMemo(() => (list.currency === 'AMD' ? 1 : currency), [list.currency, currency])

    //! Math Round 100
    const mathRound100 = useCallback((number) => Math.round(number * 100) / 100, [])

    //! VAT Calculator
    const calculateVAT = useCallback(
        (AnnualSalesAMD) => {
            return mathRound100((0.2 * AnnualSalesAMD) / calculatorCurrency)
        },
        [calculatorCurrency]
    )

    //! Sales Tax Calculator
    const calculateSalesTax = useCallback(
        (AnnualSalesAMD, factor) => {
            return mathRound100(((factor || 0.05) * AnnualSalesAMD) / calculatorCurrency)
        },
        [calculatorCurrency]
    )

    //! Annual Profit Tax Calculator
    const calculateAnnualProfitTax = useCallback(
        (ExpectedAverageMonthlyProfitAMD) => {
            return mathRound100((0.18 * 12 * ExpectedAverageMonthlyProfitAMD) / calculatorCurrency)
        },
        [calculatorCurrency]
    )

    //! Annual Personal Income Tax
    const calculateAnnualPersonalIncomeTax = useCallback(
        (NumberEmployees, factor) => {
            return mathRound100((12 * NumberEmployees * factor) / calculatorCurrency)
        },
        [calculatorCurrency]
    )

    //! Army Tax Calculator
    const calculateArmyTax = useCallback(
        (AverageMonthlySalaryAMD, NumberEmployees) => {
            const factor = (NumberEmployees * 12) / calculatorCurrency
            const armyTax =
                AverageMonthlySalaryAMD <= 100000
                    ? 1500
                    : AverageMonthlySalaryAMD > 100000 && AverageMonthlySalaryAMD <= 200000
                        ? 3000
                        : AverageMonthlySalaryAMD > 200000 && AverageMonthlySalaryAMD <= 500000
                            ? 5500
                            : AverageMonthlySalaryAMD > 500000 && AverageMonthlySalaryAMD <= 1000000
                                ? 8500
                                : 15000

            return mathRound100(armyTax * factor)
        },
        [currency, calculatorCurrency]
    )

    //! Social Tax Calculator
    const calculateSocialTax = useCallback(
        (AverageMonthlySalaryAMD, NumberEmployees) => {
            const factor = (12 * AverageMonthlySalaryAMD * NumberEmployees) / calculatorCurrency
            let socialTax

            if (AverageMonthlySalaryAMD <= 500000) {
                socialTax = 0.045 * factor
            } else if (AverageMonthlySalaryAMD > 500000 && AverageMonthlySalaryAMD <= 1020000) {
                socialTax = 0.1 * factor
            } else {
                socialTax = 12 * NumberEmployees * Math.min(0.1 * AverageMonthlySalaryAMD - 27500 / calculatorCurrency, 74500 / calculatorCurrency)
            }

            return mathRound100(socialTax)
        },
        [currency, calculatorCurrency]
    )

    //! Divident Tax Calculator
    const calculateDividentTax = useCallback(
        (LegalStatus, ExpectedAverageMonthlyProfitAMD) => {
            let dividentTax

            if (['LLC', 'CJSC', 'ОJSC'].includes(LegalStatus)) {
                dividentTax = mathRound100((0.05 * 12 * ExpectedAverageMonthlyProfitAMD) / calculatorCurrency)
            } else {
                dividentTax = 0
            }

            return dividentTax
        },
        [calculatorCurrency]
    )

    //! Access Tax Calculiator
    const calculateAccessTax = useCallback(
        ({ Product, SalesExpectedUnits, ValueUnitAMD, DegreeLiter, Age }) => {
            let accessTax
            if (Product === 'EthylAlcohol') {
                accessTax = 4400 * SalesExpectedUnits
            } else if (Product === 'AlcoholicBeverages') {
                accessTax = 2640 * SalesExpectedUnits
            } else if (Product === 'VodkaFruitsBerries') {
                accessTax = 800 * SalesExpectedUnits
            } else if (Product === 'CognacBrandy') {
                const factor = Age <= 3 ? 3000 : Age <= 5 ? 3500 : Age <= 10 ? 6000 : Age <= 15 ? 8500 : Age <= 20 ? 14000 : 20000
                accessTax = (factor * SalesExpectedUnits * DegreeLiter) / 100
            } else if (Product === 'Whiskey') {
                accessTax = 7000 * SalesExpectedUnits
            } else if (Product === 'Beer') {
                accessTax = 130 * SalesExpectedUnits
            } else if (Product === 'GrapeWines') {
                accessTax = 150 * SalesExpectedUnits
            } else if (Product === 'Vermouth') {
                accessTax = 1000 * SalesExpectedUnits
            } else if (Product === 'OtherFermentedBeverages') {
                accessTax = 270 * SalesExpectedUnits
            } else if (Product === 'FruitBerryWines') {
                accessTax = 150 * SalesExpectedUnits
            } else if (Product === 'Tobacco') {
                accessTax = 12.7 * SalesExpectedUnits
            } else if (Product === 'Cigars') {
                accessTax = 605 * SalesExpectedUnits
            } else if (Product === 'Cigarette') {
                accessTax = 16.5 * SalesExpectedUnits
            } else if (Product === 'IndustrialTobaccoSubstitutes') {
                accessTax = 1500 * SalesExpectedUnits
            } else if (Product === 'LubricatingOil') {
                accessTax = 500 * SalesExpectedUnits
            } else if (Product === 'Petroleum') {
                accessTax = 40000 * SalesExpectedUnits
            } else if (Product === 'DieselFuel') {
                accessTax = 13000 * SalesExpectedUnits
            } else if (Product === 'CrudeOil') {
                accessTax = 27000 * SalesExpectedUnits
            } else if (Product === 'PetroleumProducts') {
                accessTax = 1000 * SalesExpectedUnits
            } else if (Product === 'PetroleumProducts') {
                accessTax = 1000 * SalesExpectedUnits
            } else if (Product === 'Compressed') {
                accessTax = 25 * SalesExpectedUnits
            } else {
                accessTax = 0
            }

            return accessTax ? mathRound100(accessTax / calculatorCurrency) : 0
        },
        [calculatorCurrency]
    )

    //! Agricultural Produce Taxes
    const calculateAgriculturalProduceTaxes = useCallback(
        ({ AnnualSalesAMD, NumberEmployees, ExpectedAverageMonthlyProfitAMD, AverageMonthlySalaryAMD, newResult }) => {
            const pitFactor = AnnualSalesAMD < 24000000 ? 5000 : AverageMonthlySalaryAMD * 0.21
            newResult.VATax = AnnualSalesAMD > 115000000 ? calculateVAT(AnnualSalesAMD) : 0
            newResult.AnnualProfitTax = AnnualSalesAMD > 115000000 ? calculateAnnualProfitTax(ExpectedAverageMonthlyProfitAMD) : 0
            newResult.AnnualPersonalIncomeTax = calculateAnnualPersonalIncomeTax(NumberEmployees, pitFactor)
            newResult.Text = pitFactor === 5000 ? 'personalIncome1' : newResult.VATax === 0 ? 'profitTax1' : 'profitTax3'

            return [newResult]
        },
        [calculateVAT, calculateAnnualProfitTax, calculateAnnualPersonalIncomeTax]
    )

    //! Financial Service Taxes
    const calculateFinancialServicesTaxes = useCallback(
        ({ AnnualSalesAMD, ExpectedAverageMonthlyProfitAMD, NumberEmployees, AverageMonthlySalaryAMD, newResult }) => {
            const pitFactor = AverageMonthlySalaryAMD * 0.21
            newResult.VATax = calculateVAT(AnnualSalesAMD)
            newResult.AnnualProfitTax = calculateAnnualProfitTax(ExpectedAverageMonthlyProfitAMD)
            newResult.AnnualPersonalIncomeTax = calculateAnnualPersonalIncomeTax(NumberEmployees, pitFactor)
            newResult.Text = 'profitTax3'

            return [newResult]
        },
        [calculateVAT, calculateAnnualProfitTax, calculateAnnualPersonalIncomeTax]
    )

    //! IT Service & Electronic Taxes
    const calculateITServicesElectronicTaxes = useCallback(
        ({ AnnualSalesAMD, NumberEmployees, AverageMonthlySalaryAMD, ExpectedAverageMonthlyProfitAMD, newResult }) => {
            const pitFactor = AverageMonthlySalaryAMD * 0.1
            const VATax = calculateVAT(AnnualSalesAMD)
            let personalIncomeTax = calculateAnnualPersonalIncomeTax(NumberEmployees, pitFactor)
            let newResultSecond

            if (NumberEmployees < 30) {
                newResult.VATax = VATax
                newResult.AnnualPersonalIncomeTax = personalIncomeTax
                newResult.Text = 'personalIncome2'

                if (AnnualSalesAMD < 115000000) {
                    newResultSecond = { ...initialResultValues }
                    newResultSecond.SalesTax = calculateSalesTax(AnnualSalesAMD)
                    newResultSecond.AnnualPersonalIncomeTax = personalIncomeTax
                    newResultSecond.AnnualPaymentArmy = newResult.AnnualPaymentArmy
                    newResultSecond.AnnualSocialPayments = newResult.AnnualSocialPayments
                    newResultSecond.DividendTax = newResult.DividendTax
                    newResultSecond.Text = 'annualSales2'
                }
            } else {
                if (AnnualSalesAMD < 115000000) {
                    newResult.SalesTax = calculateSalesTax(AnnualSalesAMD)
                    newResult.AnnualPersonalIncomeTax = personalIncomeTax
                    newResult.Text = 'annualSales2'
                } else {
                    const pitFactor = AverageMonthlySalaryAMD * 0.21
                    personalIncomeTax = calculateAnnualPersonalIncomeTax(NumberEmployees, pitFactor)
                    newResult.VATax = VATax
                    newResult.AnnualProfitTax = calculateAnnualProfitTax(ExpectedAverageMonthlyProfitAMD)
                    newResult.AnnualPersonalIncomeTax = personalIncomeTax
                    newResult.Text = 'profitTax3'
                }
            }

            return [newResult, newResultSecond]
        },
        [calculateVAT, calculateAnnualPersonalIncomeTax, calculateSalesTax, calculateAnnualProfitTax]
    )

    //! Construction Taxes
    const calculateConstructionTaxes = useCallback(
        ({ NumberEmployees, AverageMonthlySalaryAMD, AnnualSalesAMD, ExpectedAverageMonthlyProfitAMD, newResult }) => {
            const pitFactor = AverageMonthlySalaryAMD * 0.21
            newResult.AnnualPersonalIncomeTax = calculateAnnualPersonalIncomeTax(NumberEmployees, pitFactor)

            if (AnnualSalesAMD < 115000000) {
                newResult.SalesTax = calculateSalesTax(AnnualSalesAMD)
                newResult.Text = 'personalIncome3'
            } else {
                newResult.VATax = calculateVAT(AnnualSalesAMD)
                newResult.AnnualProfitTax = calculateAnnualProfitTax(ExpectedAverageMonthlyProfitAMD)
                newResult.Text = 'profitTax3'
            }

            return [newResult]
        },
        [calculateVAT, calculateAnnualPersonalIncomeTax, calculateSalesTax, calculateAnnualProfitTax]
    )

    //! Trade Purchase Taxes
    const calculateTradePurchaseTaxes = useCallback(
        ({
            NumberEmployees,
            AnnualSalesAMD,
            AverageMonthlySalaryAMD,
            ExpectedAverageMonthlyProfitAMD,
            Location,
            Product,
            SalesExpectedUnits,
            ValueUnitAMD,
            DegreeLiter,
            Age,
            newResult,
        }) => {
            const pitFactor = AnnualSalesAMD < 24000000 && Location !== 'Yerevan' ? 5000 : AverageMonthlySalaryAMD * 0.21
            newResult.AnnualPersonalIncomeTax = calculateAnnualPersonalIncomeTax(NumberEmployees, pitFactor)
            newResult.Text = 'personalIncome1'

            if (AnnualSalesAMD >= 115000000) {
                newResult.VATax = calculateVAT(AnnualSalesAMD)
                newResult.AnnualProfitTax = calculateAnnualProfitTax(ExpectedAverageMonthlyProfitAMD)
                newResult.Text = 'profitTax3'
            } else if (
                (AnnualSalesAMD < 24000000 && Location === 'Yerevan') ||
                (AnnualSalesAMD >= 24000000 && AnnualSalesAMD < 115000000 && Location !== 'Borderline')
            ) {
                newResult.SalesTax = calculateSalesTax(AnnualSalesAMD, 0.015)
                newResult.Text = 'annualSales3'
            } else if (AnnualSalesAMD >= 24000000 && AnnualSalesAMD < 115000000 && Location === 'Borderline') {
                newResult.Text = 'profitTax2'
            }

            newResult.AccessTax = calculateAccessTax({ Product, SalesExpectedUnits, ValueUnitAMD, DegreeLiter, Age })

            return [newResult]
        },
        [calculateAnnualPersonalIncomeTax, calculateSalesTax, calculateVAT, calculateAnnualProfitTax, calculateAccessTax]
    )

    //! Manufacturing Taxes
    const calculateManufacturingTaxes = useCallback(
        ({
            NumberEmployees,
            AnnualSalesAMD,
            AverageMonthlySalaryAMD,
            ExpectedAverageMonthlyProfitAMD,
            Location,
            Product,
            SalesExpectedUnits,
            ValueUnitAMD,
            DegreeLiter,
            Age,
            newResult,
        }) => {
            const pitFactor = AnnualSalesAMD < 24000000 ? 5000 : AverageMonthlySalaryAMD * 0.21
            newResult.AnnualPersonalIncomeTax = calculateAnnualPersonalIncomeTax(NumberEmployees, pitFactor)
            newResult.Text = 'personalIncome1'

            if (AnnualSalesAMD >= 115000000) {
                newResult.Text = 'profitTax2'

                if (Location !== 'Borderline' || (Location === 'Borderline' && Product !== 'None')) {
                    newResult.VATax = calculateVAT(AnnualSalesAMD)
                    newResult.AnnualProfitTax = calculateAnnualProfitTax(ExpectedAverageMonthlyProfitAMD)
                    newResult.Text = 'profitTax3'
                }
            } else if (AnnualSalesAMD >= 24000000 && AnnualSalesAMD < 115000000) {
                const factor = Location !== 'Borderline' || (Location === 'Borderline' && Product !== 'None') ? 0.035 : false
                newResult.Text = factor ? 'annualSales4' : 'profitTax2'
                newResult.SalesTax = factor ? calculateSalesTax(AnnualSalesAMD, factor) : 0
            }

            newResult.AccessTax = calculateAccessTax({ Product, SalesExpectedUnits, ValueUnitAMD, DegreeLiter, Age })

            return [newResult]
        },
        [calculateAnnualPersonalIncomeTax, calculateSalesTax, calculateVAT, calculateAnnualProfitTax, calculateAccessTax]
    )

    //! Hotels & Hostels Taxes
    const calculateHotelsHostelsTaxes = useCallback(
        ({ NumberEmployees, AnnualSalesAMD, AverageMonthlySalaryAMD, ExpectedAverageMonthlyProfitAMD, newResult }) => {
            const pitFactor = AnnualSalesAMD < 24000000 ? 5000 : AverageMonthlySalaryAMD * 0.21
            newResult.AnnualPersonalIncomeTax = calculateAnnualPersonalIncomeTax(NumberEmployees, pitFactor)
            newResult.Text = 'personalIncome1'

            if (AnnualSalesAMD >= 24000000 && AnnualSalesAMD < 115000000) {
                newResult.SalesTax = calculateSalesTax(AnnualSalesAMD)
                newResult.Text = 'personalIncome3'
            } else if (AnnualSalesAMD >= 115000000) {
                newResult.AnnualProfitTax = calculateAnnualProfitTax(ExpectedAverageMonthlyProfitAMD)
                newResult.Text = 'profitTax3'
            }

            return [newResult]
        },
        [calculateAnnualPersonalIncomeTax, calculateSalesTax, calculateAnnualProfitTax]
    )

    //! Public Catering Taxes
    const calculatePublicCateringTaxes = useCallback(
        ({ NumberEmployees, AnnualSalesAMD, Location, AverageMonthlySalaryAMD, newResult }) => {
            const pitFactor = AnnualSalesAMD < 24000000 && Location !== 'Yerevan' ? 5000 : AverageMonthlySalaryAMD * 0.21
            newResult.AnnualPersonalIncomeTax = calculateAnnualPersonalIncomeTax(NumberEmployees, pitFactor)
            newResult.Text = pitFactor === 5000 ? 'personalIncome1' : 'profitTax2'

            if (
                (AnnualSalesAMD < 24000000 && Location === 'Yerevan') ||
                (AnnualSalesAMD >= 24000000 && AnnualSalesAMD < 115000000 && Location !== 'Borderline') ||
                AnnualSalesAMD >= 115000000
            ) {
                newResult.SalesTax = calculateSalesTax(AnnualSalesAMD, 0.06)
                newResult.Text = 'annualSales1'
            }

            return [newResult]
        },
        [calculateAnnualPersonalIncomeTax, calculateSalesTax]
    )

    //! Other Service Taxes
    const calculateOtherTaxes = useCallback(
        ({ NumberEmployees, AnnualSalesAMD, AverageMonthlySalaryAMD, ExpectedAverageMonthlyProfitAMD, newResult }) => {
            const pitFactor = AverageMonthlySalaryAMD * 0.21
            newResult.AnnualPersonalIncomeTax = calculateAnnualPersonalIncomeTax(NumberEmployees, pitFactor)

            if (AnnualSalesAMD < 115000000) {
                newResult.SalesTax = calculateSalesTax(AnnualSalesAMD)
                newResult.Text = 'personalIncome3'
            } else if (AnnualSalesAMD >= 115000000) {
                newResult.VATax = calculateVAT(AnnualSalesAMD)
                newResult.Text = 'profitTax3'
                newResult.AnnualProfitTax = calculateAnnualProfitTax(ExpectedAverageMonthlyProfitAMD)
            }

            return [newResult]
        },
        [calculateAnnualPersonalIncomeTax, calculateSalesTax, calculateVAT, calculateAnnualProfitTax]
    )

    //! Form Submit
    const onCalculate = useCallback(
        (vals) => {
            let newResult = { ...initialResultValues }

            //? Form Values
            const Age = vals['Age']
            const Product = vals['Product']
            const Location = vals['Location']
            const DegreeLiter = vals['DegreeLiter']
            const BusinessSector = vals['BusinessSector']
            const NumberEmployees = vals['NumberEmployees']
            const SalesExpectedUnits = vals['SalesExpectedUnits']
            const ExpectedAverageMonthlyProfit = vals['ExpectedAverageMonthlyProfit']
            const ValueUnitAMD = list.currency === 'AMD' ? vals['ValueUnit'] : vals['ValueUnit'] * currency
            const AnnualSalesAMD = list.currency === 'AMD' ? vals['AnnualSales'] : vals['AnnualSales'] * currency
            const AverageMonthlySalaryAMD = list.currency === 'AMD' ? vals['AverageMonthlySalary'] : vals['AverageMonthlySalary'] * currency
            const ExpectedAverageMonthlyProfitAMD = list.currency === 'AMD' ? ExpectedAverageMonthlyProfit : ExpectedAverageMonthlyProfit * currency

            //? Global For All Categories
            newResult.DividendTax = calculateDividentTax(vals['LegalStatus'], ExpectedAverageMonthlyProfitAMD)
            newResult.AnnualPaymentArmy = calculateArmyTax(AverageMonthlySalaryAMD, vals['NumberEmployees'])
            newResult.AnnualSocialPayments = calculateSocialTax(AverageMonthlySalaryAMD, vals['NumberEmployees'])

            //? New Calculation Result
            const newResults = eval(`calculate${BusinessSector}Taxes`)({
                NumberEmployees,
                AnnualSalesAMD,
                AverageMonthlySalaryAMD,
                ExpectedAverageMonthlyProfitAMD,
                Location,
                Product,
                SalesExpectedUnits,
                ValueUnitAMD,
                DegreeLiter,
                Age,
                newResult,
            })

            setResult({ first: newResults[0], second: newResults[1] })

            return Promise.resolve() //? For Form Async Function
        },
        [currency, calculatorCurrency]
    )

    //! Reset Fields
    const resetFields = useCallback(() => {
        setList(initialFormValues)
        setResult({ first: initialResultValues, second: '' })
        form.current.resetFields()
    }, [])

    return (
        <div className='overflowHidden'>
            <Container className='largePadding'>
                <FormStyle className='columnFormSection FlexBoxContainer spaceBetween'>
                    <div className='formCont '>
                        <FormContainer
                            ref={form}
                            initialValues={initialFormValues}
                            layout={'vertical'}
                            className={'FormRow FlexBoxContainer spaceBetween'}
                            onChange={onFormChange}
                            onFinish={onCalculate}
                        >
                            <BusinessInArmeniaFormTop
                                ref={topRef}
                                currency={list.currency}
                                sector={list.sector}
                                product={list.product}
                                resetFields={resetFields}
                            />

                            <BusinessInArmeniaFormMid ref={midRef} currency={list.currency} result={result} />

                            <BusinessInArmeniaFormBottom ref={botRef} leftText={props.leftText} rightText={props.rightText} />
                        </FormContainer>
                    </div>
                </FormStyle>
            </Container>
        </div>
    )
})

export default withLanguageContext(BusinessInArmeniaForm, ['selectedLang'])
