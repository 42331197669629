import { forwardRef, useRef, useImperativeHandle } from 'react'

//* HOC's
import { withUIContext } from 'context'

//* Components
import { Container, Text } from 'components/common'
import { CalculatorForm } from 'components/pages'

//* Styles
import CalculatorStyle from './style'

const Calculator = forwardRef(({ calcData, winWidth, ...props }, ref) => {
	//! Ref's
	const TitleRef = useRef()
	const itemRef1 = useRef()
	const itemRef2 = useRef()
	const calculatorFormCont = useRef()

	useImperativeHandle(ref, () => [TitleRef, itemRef1, itemRef2, calculatorFormCont], [])

	return (
		<CalculatorStyle>
			<Container className='CalculatorTopInfoWrap largePadding '>
				<Text tag={'stringToLetters'} className={'h1 title MonBold black'} text={props.title} ref={TitleRef} />
				<div className='CalculatorTopInfo FlexBoxContainer spaceBetween'>
					<Text tag={'div'} className={' MonRegular black CalculatorTopInfoLeft'}>
						<div
							ref={itemRef1}
							dangerouslySetInnerHTML={{
								__html: props.leftText,
							}}
						/>
					</Text>
					<Text tag={'div'} className={'pM  MonRegular black CalculatorTopInfoRight'}>
						<div
							ref={itemRef2}
							dangerouslySetInnerHTML={{
								__html: props.rightText,
							}}
						/>
					</Text>
				</div>
			</Container>

			<Container className='CalculatorWrap '>
				<CalculatorForm calcData={calcData} ref={calculatorFormCont} />
			</Container>
		</CalculatorStyle>
	)
})

export default withUIContext(Calculator, ['winWidth'])
