import styled from 'styled-components'

const ContactSectionStyle = styled.div`
	.contact-section {
		padding-top: var(--sp10x);
		padding-bottom: var(--sp10x);
		background-color: var(--ultraLightGrey);

		&-block {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
			padding: 0;

			.content {
				max-width: var(--sp98x);
				margin-right: var(--sp4x);
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				.description {
					margin: var(--sp4-5x) 0 var(--sp7x);
				}
			}

			.LottieWrapp {
				width: 100%;
				padding-top: 19%;
				max-width: var(--sp85-5x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		.contact-section {
			padding-top: var(--sp7-5x);
			padding-bottom: var(--sp7-5x);

			&-block {
				.content {
					max-width: var(--sp83x);

					.description {
						margin: var(--sp4x) 0 var(--sp6x);
					}
				}

				.LottieWrapp {
					max-width: var(--sp72-5x);
					padding-top: 23.8%;
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		.contact-section {
			padding-top: var(--sp5x);
			padding-bottom: var(--sp5x);

			&-block {
				.content {
					max-width: var(--sp68-5x);

					.description {
						margin: var(--sp3-5x) 0 var(--sp4-5x);
					}
				}

				.LottieWrapp {
					width: 48.6%;
					padding-top: 27.1%;
					max-width: var(--sp68-5x);
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		.contact-section {
			padding-top: var(--sp4-5x);
			padding-bottom: var(--sp4-5x);

			&-block {
				.content {
					max-width: var(--sp57-5x);
					margin-right: var(--sp3x);

					.description {
						margin: var(--sp2-5x) 0 var(--sp4x);
					}
				}

				.LottieWrapp {
					max-width: var(--sp57-5x);
					width: 48.7%;
					padding-top: 27.1%;
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		.contact-section {
			padding-top: var(--sp4-5x);
			padding-bottom: var(--sp5x);

			&-block {
				padding: 0;
				flex-direction: column;
				.content {
					max-width: var(--sp75x);
					margin-right: 0;
					margin-bottom: var(--sp3x);
					align-self: flex-start;

					.description {
						margin: var(--sp2-5x) 0 var(--sp4x);
					}
				}

				.LottieWrapp {
					align-self: flex-end;
					max-width: var(--sp58x);
					width: 100%;
					padding-top: 39.1%;
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.contact-section {
			padding-top: var(--sp4-5x);
			padding-bottom: var(--sp5x);

			&-block {
				padding: 0;
				flex-direction: column;

				.content {
					max-width: unset;
					align-items: stretch;
					margin-right: 0;

					.description {
						margin: var(--sp2x) 0 var(--sp3-5x);
					}
				}

				.LottieWrapp {
					padding-top: 57.1%;
					margin-top: var(--sp8x);
				}
			}
		}
	}
`
export default ContactSectionStyle
