import { useState, useCallback, forwardRef, useRef, useImperativeHandle } from 'react'

//* HOC's
import { withUIContext } from 'context'

//* Components
import { Text, Icon, Button } from 'components/common'
import { useRouter } from 'next/router'

import ProfitFilter from './ProfitFilter'
import ShareInCompanyFilter from './ShareInCompanyFilter'
import LocationFilter from './LocationFilter'
import InvestmentFilter from './InvestmentFilter'
import CategoriesFilter from './CategoriesFilter'

//* Styles
import 'antd/dist/antd.css'
import FilterInCatalogueStyle from './style'

const FilterInCatalogue = forwardRef(({ queryParamKeys, onCategoryChange, categories, winWidth, ...props }, ref) => {
	//! Router
	const router = useRouter()

	//! States
	const [activeClassFilter, setActiveClassFilter] = useState('')

	//! Refs
	const titleRef = useRef()
	const itemsRef = useRef()
	const titleRef2 = useRef()

	//! Imperative Handler
	useImperativeHandle(ref, () => [titleRef, itemsRef, titleRef2], [])

	//! Filter Button Click Handler
	const filterBtnClickHandler = (open, reset = false) => {
		if (open) {
			setActiveClassFilter('active')
			document.body.classList.add('hidden')
		} else {
			setActiveClassFilter('')
			document.body.classList.remove('hidden')
			reset && resetFilter()
		}
	}

	//! Reset Filters
	const resetFilter = useCallback(() => {
		router.push('/investment-projects/')
	}, [])

	return (
		<FilterInCatalogueStyle>
			<div>
				{winWidth > 1023 ? (
					<Text tag={'p'} className={'pM black MonRegular darkGrey capitalize'} text={'filterName'} ref={titleRef} />
				) : (
					<Button className='filter-btn' onClick={() => filterBtnClickHandler(true)} ariaLabel={'filterName'}>
						<Text tag={'p'} className={'pM black MonMedium capitalize'} text={'filterName'} />
						<Icon className='icon-filter' />
					</Button>
				)}

				<div className={`filters ${activeClassFilter}`} ref={itemsRef}>
					{winWidth <= 1023 && <Text tag={'p'} className={'head-title pS MonRegular uppercase darkGrey'} text={'filterName'} />}

					{/* CategoriesFilter Filter */}
					<CategoriesFilter queryParamKeys={queryParamKeys} onCategoryChange={onCategoryChange} categories={categories} />

					{/* Investment Filter */}
					<InvestmentFilter queryParamKeys={queryParamKeys} onCategoryChange={onCategoryChange} ranges={props.ranges} />

					{/* Location Filter */}
					<LocationFilter queryParamKeys={queryParamKeys} onCategoryChange={onCategoryChange} states={props.states} />

					{/* Profit Filter */}
					<ProfitFilter queryParamKeys={queryParamKeys} onCategoryChange={onCategoryChange} />

					{/* Share in CompanyFilter */}
					<ShareInCompanyFilter queryParamKeys={queryParamKeys} onCategoryChange={onCategoryChange} />

					{winWidth <= 1023 && (
						<div className='btns'>
							<Button onClick={() => filterBtnClickHandler(false, true)} className='btn btn-white capitalize' text={'reset'} />
							<Button onClick={() => filterBtnClickHandler(false)} className='btn btn-black capitalize' text={'confirm'} />
						</div>
					)}
				</div>

				{winWidth > 1023 && (
					<div onClick={resetFilter} className={'clear-filters underline capitalize pL black'} ref={titleRef2}>
						<Text tag={'span'} className={'cursorPointer pL black MonMedium'} text={'resetFilters'} />
					</div>
				)}
			</div>
		</FilterInCatalogueStyle>
	)
})

export default withUIContext(FilterInCatalogue, ['winWidth'])
