import React, { memo as Memo, useMemo } from 'react'
import { useRouter } from "next/router"
import { Icon } from 'components/common'


const ShareLink = Memo((props) => {

    const router = useRouter()

    const url = useMemo(() => process.env.host + router.asPath, [router])
    const facebookUrl = useMemo(() => `https://www.facebook.com/sharer/sharer.php?u=${url}`, [router])
    const twitterUrl = useMemo(() => `https://twitter.com/intent/tweet?url=${url}`, [router])
    const linkedinUrl = useMemo(() => `https://www.linkedin.com/shareArticle?mini=true&url=${url}`, [router])

    return (
        <>
            <a target='_blank' rel='noopener noreferrer' href={facebookUrl}>
                <Icon className='icon-facebook' />
            </a>
            <a target='_blank' rel='noopener noreferrer' href={twitterUrl}>
                <Icon className='icon-twitter' />
            </a>
            <a target='_blank' rel='noopener noreferrer' href={linkedinUrl}>
                <Icon className='icon-linkedin' />
            </a>
        </>
    )
})


export default ShareLink