import { useCallback, useMemo, forwardRef, useRef, useImperativeHandle } from 'react'
import { withLanguageContext } from 'context'
//* Components
import { Container, Icon, Text, Image } from 'components/common'
import { SingleNews } from 'components/pages'
import Pagination from 'react-js-pagination'
import { useRouter } from 'next/router'

//* Styles
import NewsBlockStyle from './style'

const NewsBlock = forwardRef((props, ref) => {
	//! Router
	const router = useRouter()

	//! Active Page
	const activePage = useMemo(() => router.query?.[props.queryParamKeys[2]] || 1, [router.query])

	//! Pagination Change
	const onPaginationChange = useCallback(
		(p) => {
			const paginationParam = props.queryParamKeys[2] //? "page"
			props.onPageChange(paginationParam, p, false)
		},
		[props.queryParamKeys, props.onPageChange]
	)

	//! News
	const storeNews = useMemo(() => {
		if (props.newsData.posts.length > 0) {
			return props.newsData.posts.map((i, k) => {
				return (
					<SingleNews
						photo={i.feature_image}
						categoryName={i.category.name}
						categorySlug={i.category.slug}
						newsName={i.title}
						url={`/news/${i.slug}`}
						state={i.location.name}
						date={i.date}
						key={k}
						bgColor={i.category.color}
					/>
				)
			})
		} else {
			return (
				<div className='no-result'>
					<Image src='/images/noResult.svg' alt={props.translate('noResult')} />
					<Text tag={'h5'} className={'title h5 MonRegular black'} text={'noResult'} />
					<Text tag={'p'} className={'pM MonRegular ultraDarkGrey'} text={'noResultText'} />
				</div>
			)
		}
	}, [props.newsData])

	const itemRef = useRef()

	useImperativeHandle(ref, () => [itemRef.current.childNodes], [storeNews])

	return (
		<NewsBlockStyle className={'news-block'}>
			<Container className={'news-block-section'}>
				<div className='news-container' ref={itemRef}>
					{storeNews}
				</div>
				{props.newsData?.total > props.newsData.perPageCount && (
					<Pagination
						activePage={parseInt(activePage)}
						totalItemsCount={props.newsData.total}
						pageRangeDisplayed={4}
						itemsCountPerPage={props.newsData.perPageCount}
						linkClassLast={null}
						linkClassFirst={null}
						nextPageText={<Icon className='icon-arrow-right' />}
						prevPageText={<Icon className='icon-arrow-left' />}
						linkClassPrev={'prev'}
						linkClassNext={'next'}
						onChange={onPaginationChange}
					/>
				)}
			</Container>
		</NewsBlockStyle>
	)
})

export default withLanguageContext(NewsBlock, ['translate'])
