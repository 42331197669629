import styled from 'styled-components'

const TermsStyle = styled.div`
	.terms {
		.terms-cont,.have-questions {
			max-width: var(--sp128x);
			width: 100%;

			&>*{
				transform: translateY(30vh);
			}
			
			.single-term {
				display: flex;
				flex-direction: column;
				border-top: 1px solid var(--darkGrey);
				cursor: pointer;

				&.active {
					.title {
						&:after {
							transform: translate(50%, -50%) rotate(-270deg);
						}
					}
				}

				&:last-child {
					border-bottom: 1px solid var(--darkGrey);
				}

				.title {
					padding: var(--sp4x) var(--sp8x) var(--sp4x) var(--sp2x);
					position: relative;

					&:before {
						content: '';
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: 0;
						width: var(--sp2-5x);
						height: calc(var(--spSize) / 2);
						background-color: var(--black);
						transition: var(--trTime2) ease-out;
						border-radius: 4px;
					}

					&:after {
						content: '';
						position: absolute;
						top: 50%;
						transform: translate(50%, -50%);
						right: calc((var(--sp2-5x) / 2));
						height: var(--sp2-5x);
						width: calc(var(--spSize) / 2);
						background-color: var(--black);
						transition: var(--trTime2) ease-out;
						border-radius: 4px;
					}
				}

				.text {
					padding: 0 var(--sp2x) var(--sp4x);

					* {
						font-family: MonRegular, MonRegularRu;
						font-size: var(--pL);
						margin: var(--sp1-5x) 0;
					}

					ul {
						li {
							position: relative;
							padding-left: 20px;

							&:before {
								content: '';
								position: absolute;
								top: 50%;
								left: 0;
								transform: translate(0, -50%);
								background-color: var(--black);
								border-radius: 50%;
								width: 6px;
								height: 6px;
							}
						}
					}
				}
			}
		}

		.have-questions {
			margin-top: var(--pageFromHeaderMargin);
			text-align: center;
			&>*{
				transform: translateY(30vh);
			}
			.title {
				margin-bottom: var(--titleFromContent);
			}

			.links {
				a {
					display: inline-block;
				}
			}
		}
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		.terms {
			.terms-cont,.have-questions {
				max-width: var(--sp112x);
				.single-term {
					.title {
						padding: var(--sp3x) var(--sp8x) var(--sp3x) var(--sp2x);
					}

					.text {
						padding: 0 var(--sp2x) var(--sp3x);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		.terms {
			.terms-cont,.have-questions {
				max-width: var(--sp88x);
				.single-term {
					.title {
						padding: var(--sp3x) var(--sp8x) var(--sp3x) var(--sp2x);
					}

					.text {
						padding: 0 var(--sp2x) var(--sp3x);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		.terms {
			.terms-cont,.have-questions {
				max-width: unset;
				.single-term {
					.title {
						padding: var(--sp3x) var(--sp8x) var(--sp3x) var(--sp2x);

						&:before {
							width: var(--sp1-5x);
							height: calc(var(--spSize) / 4);
						}

						&:after {
							right: calc((var(--sp1-5x) / 2));
							height: var(--sp1-5x);
							width: calc(var(--spSize) / 4);
						}
					}

					.text {
						padding: 0 var(--sp2x) var(--sp3x);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.terms {
			.terms-cont,.have-questions {
				max-width: unset;
				.single-term {
					.title {
						padding: var(--sp2x) var(--sp6x) var(--sp2x) var(--sp2x);
						&:before {
							width: var(--sp1-5x);
							height: calc(var(--spSize) / 4);
						}

						&:after {
							right: calc((var(--sp1-5x) / 2));
							height: var(--sp1-5x);
							width: calc(var(--spSize) / 4);
						}
					}

					.text {
						padding: 0 var(--sp2x) var(--sp2x);
					}
				}
			}
		}
	}
`
export default TermsStyle
