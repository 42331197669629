import React, { memo as Memo, useState, useCallback } from 'react'

//* HOCs
import { withLanguageContext, withUIContext, withAccountContext } from 'context'

//* Components
import { Button as ButtonForm } from 'antd'
import { Text, Translate, FormItem, FormContainer, FormInput } from 'components/common'
import SuccessMessage from './SuccessMessage'

//* Styles
import LoginRegisterStyle from 'styles/pages/LoginRegister'
import FormStyle from 'styles/pages/FormStyle'

const ForgotPassword = Memo(({ forgotPassword, ...props }) => {

    //! States
    const [mail, setMail] = useState(false)

    //! On Submit Form
    const onFinish = useCallback((values) => {
        return forgotPassword(values).then(() => {
            setMail(values.email)
            Promise.resolve()
        })
    }, [])

    return (
        <LoginRegisterStyle>
            {!mail ? (
                <>
                    <Text tag={'div'} className={' Title h4 MonMedium black'} text={'ForgotPassword'} />

                    <Text tag={'div'} className={'subTitle pL MonRegular black'} text={'EnterEmailAddress'} />

                    <div className='SingInFormWrapp'>
                        <FormStyle>
                            <FormContainer layout={'vertical'} onFinish={onFinish} className={'FormRow FlexBoxContainer spaceBetween'}>
                                <div className='FormCol'>
                                    <FormItem name={'email'} validationType='email'>
                                        <FormInput placeholder={props.translate('Email') + '*'} />
                                    </FormItem>
                                </div>

                                <div className='formBtnWrapp'>
                                    <ButtonForm htmlType={'Submit'} className={'btn btn-black btn-max-width'}>
                                        <Translate val={'RequestPasswordReset'} />
                                    </ButtonForm>
                                </div>

                                <Text
                                    tag={'div'}
                                    className={'FormCol bottomText pM black MonRegular underline cursorPointer'}
                                    onClick={props.openLoginPopup}
                                >
                                    <Translate val='ReturnToSignIn' />
                                </Text>
                            </FormContainer>
                        </FormStyle>
                    </div>
                </>
            ) : (
                <SuccessMessage mail={mail} />
            )}
        </LoginRegisterStyle>
    )
})

export default withLanguageContext(withUIContext(withAccountContext(ForgotPassword, ['forgotPassword']), ['openLoginPopup']), ['translate'])
