import styled from 'styled-components'

const AccountStyle = styled.div.attrs()`
	--sideBarItemPaddLeft: var(--sp3x);
	--sideBarItemPaddTop: var(--sp3x);
	--colLeftRightWidthFull: calc(100% - var(--colLeftRightWidth));
	--colCenterWidth: calc(100% - (var(--colLeftRightWidth) * 2));
	--colLeftRightWidth: calc(376px + (var(--colPaddLeftRight) * 2));
	--colPaddLeftRight: var(--sp2x);

	--tableItemWidth1: 21%;
	--tableItemWidth2: 36%;
	--tableItemWidth3: 21.5%;
	--tableItemWidth4: 21.5%;

    --tableProductsTopItemWidth: 16%;
    --tableProductsTopItemWidth3: 22%;
    --tableProductsTopItemWidth4: 24%;
    --SubmittedProductsBottomItemWidth: 13%;

	--tableItemMargTop: var(--sp2x);
	--tableItemMargLeft: var(--spSize);
	--tableItemWrapMargTop: var(--sp4x);
	--warningMargTop: var(--sp2x);

	--noDataSize: var(--sp18x);
	--noDataMargTop: var(--sp7x);

	.accountCont {
		padding-left: calc(var(--containerPadding) - var(--colPaddLeftRight));
		padding-right: var(--colPaddLeftRight);

		&.AcauntContWidthFull {
			&.accountCont {
				padding-right: var(--containerPadding);
				.accountPageCont {
					padding-right: 0;
				}
			}
			.accountPageCont {
				width: var(--colLeftRightWidthFull);
			}
		}
		.accountMenuCont {
			width: var(--colLeftRightWidth);
			padding-left: var(--colPaddLeftRight);
			padding-right: var(--colPaddLeftRight);
			.sideBar {
				background-color: var(--lightGrey);
				border-radius: var(--borderSm);
				display: inline-block;
				width: 100%;
				.sideBarItem {
					margin: var(--sideBarItemPaddTop) 0;
					padding: 0 var(--sideBarItemPaddLeft);
					line-height: var(--lineHeightXS);
					.duplicate {
						opacity: 0;
						visibility: hidden;
					}
					span {
						display: inline-block;
					}
					a {
						position: absolute;
						top: 0;
						left: 0;
					}
				}
				> li {
					&[class*='bordBot'] {
						.sideBarSubItem {
							border-bottom: 1px solid var(--darkGrey);
						}
					}
				}
				.sideBarItemWrapp {
					background-color: var(--ultraLightGrey);
					display: inline-block;
					width: 100%;
					.sideBarSubItem {
						padding: 0 var(--sideBarItemPaddLeft);
						.sideBarItem{
							padding: 0 calc(var(--sideBarItemPaddLeft)/1.3);
						}
					}
					> .sideBarItem {
						display: inline-block;
						margin-bottom: 0;
						width: 100%;
					}
				}
				> .sideBarItem {
					&:last-child {
						a {
							position: static;
						}
					}
				}
			}
		}

		.accountPageCont {
			width: var(--colCenterWidth);
			padding-left: var(--colPaddLeftRight);
			padding-right: var(--colPaddLeftRight);
			overflow: hidden;
			.noDataWrap {
				margin-top: var(--noDataMargTop);
				.imageCont {
					margin: var(--noDataMargTop) 0;
					width: var(--noDataSize);
					height: var(--noDataSize);
				}
				.btn-cont{
					margin-top:calc(var(--noDataMargTop)/1.5);
				}
			}

			.tableTitlesWrap {
				.tableItemTitles {
					padding: 0 var(--tableItemMargLeft) var(--tableItemMargTop);
				}
			}
			.tableItemWrapp {
				margin-bottom: var(--tableItemWrapMargTop);
				border-radius: var(--borderSm);
				background-color: var(--ultraLightGrey);
				padding: 0 var(--tableItemMargLeft);

				.tableItem {
					padding: var(--tableItemMargTop) var(--tableItemMargLeft);
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
			.warningWrap {
				margin-top: var(--warningMargTop);
			}
			
			.SubmittedProductsTopItem{
				margin-bottom: var(--tableItemWrapMargTop);
			}

			.SubmittedProductsBottom{
				margin-bottom: var(--tableItemWrapMargTop);
				padding: 0 var(--tableItemMargLeft);
				position: relative;
				z-index:1;
				&:after{
					content:"";
					position: absolute;
					border-radius: var(--borderSm);
					background-color: var(--ultraLightGrey);
					left:0;
					top:0;
					bottom:0;
					right:var(--SubmittedProductsBottomItemWidth);
					z-index:-1;
					
				}
				
				.tableItem{
					padding: var(--tableItemMargTop) var(--tableItemMargLeft);
				}
			}

			.HelperDesc{
				margin-right:var(--tableItemMargLeft);
				display: flex;
    			align-items: center;
			}

			.addBtnWrapper{
				margin-top: var(--tableItemWrapMargTop);
				margin-bottom: calc(var(--tableItemWrapMargTop) + var(--tableItemMargTop));
			}
		}

		.accountCooperationCont {
			width: var(--colLeftRightWidth);
			padding-left: var(--colPaddLeftRight);
			padding-right: var(--colPaddLeftRight);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		--colLeftRightWidth: calc(316px + (var(--colPaddLeftRight) * 2));
		--sideBarItemPaddLeft: var(--sp2x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		--colLeftRightWidth: calc(316px + (var(--colPaddLeftRight) * 2));

		.accountCont {
			&.AcauntContWidth,
			&.AcauntContWidthFull {
				padding-right: calc(var(--containerPadding) - var(--colPaddLeftRight));
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		--colLeftRightWidth: 37%;
		--colCenterWidth: 63%;
		--sideBarItemPaddTop: var(--sp3x);
		--colPaddLeftRight: var(--containerPadding);
		--sideBarItemPaddLeft: var(--containerPadding);

		--noDataSize: var(--sp15x);
		--noDataMargTop: var(--sp4x);

		.account {
			margin-top: 0;
		}

		.accountCont {
			padding-right: 0;

			.accountCooperationCont {
				padding-left: 0;
			}
			.accountMenuCont {
				width: 100%;
				padding-left: 0;
				padding-right: 0;
				margin-bottom: var(--pageFromHeaderMargin);
				position: sticky;
				z-index: 11;
				top: 0;
				overflow: auto;
				.popupMenu {
					background-color: var(--lightGrey);
					padding: var(--sideBarItemPaddTop) var(--sideBarItemPaddLeft);
					.icon-arrow-down {
						transition: var(--trTime2) ease-out;
						&.active {
							transform: rotate(180deg);
						}
					}
				}
				.sideBar {
					background-color: var(--ultraLightGrey);
					border-radius: 0;
					> .sideBarItem {
						margin: 0;
						padding: var(--sideBarItemPaddTop) var(--sideBarItemPaddLeft);
					}
					> li {
						border-bottom: 1px solid var(--lightGrey);
						&:last-child {
							border-bottom: 0;
						}
					}
					.sideBarItemWrapp {
						&.active {
							> .sideBarItem {
								&:after {
									transform: translate(50%, -50%) rotate(270deg);
								}
							}
						}
						> .sideBarItem {
							position: relative;
							margin-bottom: var(--sideBarItemPaddTop);
							padding-right: calc(var(--sideBarItemPaddLeft) * 2);
							&:before {
								content: '';
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								right: var(--sideBarItemPaddLeft);
								width: var(--sp2x);
								height: 2px;
								background-color: var(--ultraDarkGrey);
								transition: var(--trTime2) ease-out;
								border-radius: var(--borderSm);
							}
							&:after {
								content: '';
								position: absolute;
								top: 50%;
								transform: translate(50%, -50%);
								right: calc(var(--sideBarItemPaddLeft) + var(--spSize));
								width: 2px;
								height: var(--sp2x);
								background-color: var(--ultraDarkGrey);
								transition: var(--trTime2) ease-out;
								border-radius: var(--borderSm);
							}
						}
					}
				}
				.sideBarSubItem {
					display: block;
					width: 100%;
					border-top: 1px solid var(--lightGrey);
					.sideBarItem {
						padding: 0 calc(var(--sideBarItemPaddLeft) / 2);
					}
				}
			}

			&.AcauntContWidth,
			&.AcauntContWidthFull {
				--colLeftRightWidth: 100%;
				--colCenterWidth: 100%;
				--colLeftRightWidthFull: 100%;
				padding-right: 0;
			}

			&.AcauntContWidthFull {
				&.accountCont {
					padding-right: 0;
					.accountPageCont {
						padding-right: var(--colPaddLeftRight);
					}
				}
			}

			.FormRow {
				.FormCol-50 {
					width: 100%;
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.MobileSize}) {
		--colLeftRightWidth: 47.5%;
		--colCenterWidth: 52.5%;

		.accountCont {
			.accountPageCont {
				.tableCont {
					overflow: auto;
					padding-left: var(--colPaddLeftRight);
					padding-right: var(--colPaddLeftRight);
					.tableWrap {
						min-width: var(--sp123x);
						padding-right: var(--colPaddLeftRight);
					}
				}
				.warningWrap {
					padding-left: var(--colPaddLeftRight);
					padding-right: var(--colPaddLeftRight);
				}
			}
		}
	}

	@media screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		.accountCont {
			.accountPageCont {
				.tableWrap{
					.tableTitlesWrap {
						.tableItemTitles {
							&:nth-child(1) {
								width: var(--tableItemWidth1);
							}
							&:nth-child(2) {
								width: var(--tableItemWidth2);
							}
							&:nth-child(3) {
								width: var(--tableItemWidth3);
							}
							&:nth-child(4) {
								width: var(--tableItemWidth4);
								text-align: right;
							}
						}
					}
					.tableItemWrapp {
						.tableItem {
							display: flex;
    						align-items: center;
							&:nth-child(1) {
								width: var(--tableItemWidth1);
							}
							&:nth-child(2) {
								width: var(--tableItemWidth2);
							}
							&:nth-child(3) {
								width: var(--tableItemWidth3);
							}
							&:nth-child(4) {
								width: var(--tableItemWidth4);
								justify-content: flex-end;
							}
						}
					}
				}
				.SubmittedProductsTopItem{
					.tableItem {
						width: var(--tableProductsTopItemWidth);
						.black,.green {
							margin-top:var(--tableItemMargTop)
						}
						&:nth-child(4) {
							padding-right:4%;
							width: var(--tableProductsTopItemWidth3);
						}
						&:nth-child(5) {
							padding-left:4%;
							width: var(--tableProductsTopItemWidth4);
							position:relative;
							&:after{
								content:'';
								position:absolute;
								width:1px;
								background-color:var(--darkGrey);
								top:var(--tableItemMargTop);
								bottom:var(--tableItemMargTop);
								left:0;
							}
							>div{
								&:first-child{
									max-width:30%;
									padding-right:var(--tableItemMargLeft)
								}
								&:last-child{
									max-width:70%;
									padding-left:var(--tableItemMargLeft)
								}
							}
						}
					}
				}
				.SubmittedProductsBottomItem{
					.tableItemTitles{
						&:nth-child(1) {
							width: calc(var(--tableProductsTopItemWidth)*3);
						}
						&:nth-child(2) {
							width: var(--tableProductsTopItemWidth3);
						}
						&:nth-child(3) {
							padding-left:4%;
							width: var(--tableProductsTopItemWidth4);
						}
					}
					.tableItem{
						&:nth-child(1) {
							width: calc(var(--tableProductsTopItemWidth)*3);
						}
						&:nth-child(2) {
							width: var(--tableProductsTopItemWidth3);
						}
						&:nth-child(3) {
							padding-left:4%;
							width: var(--tableProductsTopItemWidth4);
						}
					}
					
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
			

		/* --tableProductsTopItemWidth: 25%;
		--tableProductsTopItemWidth3: 25%;
		--tableProductsTopItemWidth4: 30%; */

		.accountCont {
			.accountPageCont {
				.SubmittedProductsTopItem{
					.tableItem {
						width: var(--tableProductsTopItemWidth);
						.black,.green {
							margin-top:var(--sp1-3x)
						}
						&:nth-child(4) {
							padding-right:4%;
							width: var(--tableProductsTopItemWidth3);
						}
						&:nth-child(5) {
							width: var(--tableProductsTopItemWidth4);
							>div{
								&:first-child{
									max-width:100%;
									padding-right:0;
									margin-bottom: var(--tableItemMargTop);
								}
								&:last-child{
									max-width:100%;
									width:100%;
									padding-left:0;
								}
							}
						}
					}
				}
				.SubmittedProductsBottom{
					&:after{
						right:0;	
					}
				}
			}
		}
	}

	@media screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--tableProductsTopItemWidth: 15%;
		--tableProductsTopItemWidth3: 22.3%;
		--tableProductsTopItemWidth4: 32.7%;
	}


	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--colLeftRightWidth: 100%;
		--colCenterWidth: 100%;

		--tableItemWidth1: 50%;

		--tableItemWrapMargTop: var(--sp3x);
		--warningMargTop: var(--sp2x);
		--tableItemMargTop: var(--sp1-5x);

		--noDataSize: var(--sp14x);
		--noDataMargTop: var(--sp3x);



		--tableProductsTopItemWidth: 33.33%;
		--tableProductsTopItemWidth3: 100%;
		--tableProductsTopItemWidth4: 100%;
		--SubmittedProductsBottomItemWidth: 0;

		.accountCont {
			.accountPageCont {
				.tableWrap{
					.tableCont {
						.tableWrap {
							padding-right: var(--colPaddLeftRight);
						}
					}
					.tableItemWrapp {
						padding: var(--tableItemMargTop) 0;
						.tableItem {
							width: var(--tableItemWidth1);
							&:nth-child(odd) {
								padding-right: var(--spSize);
							}
							&:nth-child(even) {
								padding-left: var(--spSize);
							}
						}
					}
				}

				.SubmittedProductsTopItem{
					.tableItem {
						width: var(--tableProductsTopItemWidth);
						.black,.green {
							margin-top:var(--tableItemMargTop)
						}
						&:nth-child(4) {
							width:var(--tableProductsTopItemWidth4);
						}
						&:nth-child(5) {
							width: var(--tableProductsTopItemWidth4);
							>div{
								width:45%;
								&:first-child{
									padding-right:var(--tableItemMargLeft)
								}
								&:last-child{
									padding-left:var(--tableItemMargLeft)
								}
							}
						}
					}
				}
				.SubmittedProductsBottomItem{
					.tableItemTitles{
						&:nth-child(1) {
							width:var(--tableProductsTopItemWidth4);
						}
						&:nth-child(2) {
							width: var(--tableProductsTopItemWidth4);
						}
						&:nth-child(3) {
							width: var(--tableProductsTopItemWidth4);
						}
					}
					.tableItem{
						.black,.HelperDesc {
							margin-top:var(--tableItemMargTop)
						}
						&:nth-child(1) {
							width:var(--tableProductsTopItemWidth4);
						}
						&:nth-child(2) {
							width: var(--tableProductsTopItemWidth4);
						}
						&:nth-child(3) {
							width: var(--tableProductsTopItemWidth4);
						}
					}
					
				}
			}
		}
	}
`
export default AccountStyle
