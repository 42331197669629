import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle, useCallback } from 'react'
import { withUIContext } from 'context'

//* Config
import { config } from 'helpers'

import { Text, Container, Button, LottieAnimation } from 'components/common'
import BottomBlockStyle from './style'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
gsap.core.globals('ScrollTrigger', ScrollTrigger)

import animationJSON1 from 'lottieAnimation/home/lastSection/anime1.json'
import animationJSON2 from 'lottieAnimation/home/lastSection/anime2.json'

const BottomBlock = forwardRef((props, ref) => {
    const [playAnime, setPlayAnime] = useState({
        sec1: true,
        sec2: true,
    })

    const titleRef1 = useRef()
    const titleRef2 = useRef()
    const item1Ref = useRef()
    const item2Ref = useRef()

    useImperativeHandle(ref, () => [titleRef1.current.childNodes, titleRef2.current.childNodes, item1Ref, item2Ref], [])

    const sectionAnime = useCallback(() => {

        let newObj = playAnime
        const elTop = item1Ref?.current?.getBoundingClientRect()
        const pointStart = window.innerHeight

        if (elTop?.top < pointStart) {
            Object.values(playAnime).forEach((element, i) => {
                newObj = { ...newObj, [`sec${i + 1}`]: false }
            })
            setPlayAnime(newObj)
        }

    }, [playAnime])

    useEffect(() => {
        window.addEventListener('scroll', sectionAnime)

        return () => {
            window.removeEventListener('scroll', sectionAnime)
        }
    }, [sectionAnime])

    return (
        <BottomBlockStyle className='bottom'>
            <Container className={`FlexBox spaceBetween last`}>
                <div className='block import' ref={item1Ref}>
                    <Text tag={'stringToLetters'} className={'title h3 MonMedium black'} text={'registerImport'} ref={titleRef1} />

                    <div className='btnLottieWrapper overflowHidden'>
                        <div className='btnWrapperClass'>
                            <Button link={config.routes.investmentProjects.path} className='btn btn-white' text={'startInvest'} />
                            <Button link={config.routes.investmentsForBusiness.path} className='btn btn-black' text={'SubmitInvestmentProject'} />
                        </div>

                        <LottieAnimation animationJSON={animationJSON1} loop={true} isStopped={playAnime.sec1} />
                    </div>
                </div>
                <div className='block export' ref={item2Ref}>
                    <Text tag={'stringToLetters'} className={'title h3 MonMedium black'} text={'registerExport'} ref={titleRef2} />

                    <div className='btnLottieWrapper overflowHidden'>
                        <div className='btnWrapperClass'>
                            <Button link={config.routes.exportFromArmenia.path} className='btn btn-white' text={'importArmenian'} />
                            <Button link={config.routes.whatIsaProductSubmission.path} className='btn btn-black' text={'submitExportProduct'} />
                        </div>

                        <LottieAnimation animationJSON={animationJSON2} loop={true} isStopped={playAnime.sec1} />
                    </div>
                </div>
            </Container>
        </BottomBlockStyle>
    )
})

export default withUIContext(BottomBlock, ['winWidth'])
