import React, { memo as Memo, useMemo } from 'react'
import { Text, Icon, Button } from 'components/common'

//* Config
import { config } from 'helpers'

//* HOCs
import { withDataContext, withLanguageContext } from 'context'

//* Styles
import CooperationStyle from 'styles/pages/CooperationStyle'

const Cooperation = Memo(({ global, selectedLang, ...props }) => {
	const data = useMemo(() => global?.[selectedLang], [global, selectedLang])

	const CooperationItems = (data) => {
		let res = data.main_plan_benefits.split('<p>')

		return res.map((item, ind) => {
			if (item !== '') {
				return (
					<div className='CooperationItem FlexBox' key={ind}>
						<span className='iconCont FlexBox alignCenter alignMiddle'>
							<Icon className='icon-okIcon white' />
						</span>
						<div className={'pM MonRegular ultraDarkGrey'} dangerouslySetInnerHTML={{ __html: item }} />
					</div>
				)
			}
		})
	}

	return data ? (
		<CooperationStyle className='accountCooperationCont'>
			<div className='accountCooperation'>
				<Text tag={'h5'} className={' Title MonRegular black'} text={'CooperationTitle'} />

				<div className='CooperationItemWrap'>{CooperationItems(data)}</div>

				<Button className={'btn-black btn-max-width'} text={'BecomeOurPartner'} link={config.routes.becomePartner.path} />
			</div>
		</CooperationStyle>
	) : (
		''
	)
})

export default withLanguageContext(withDataContext(Cooperation, ['global']), ['selectedLang'])
