import styled from 'styled-components'

const FormStyle = styled.div.attrs({})`
	--inputColPadding: var(--sp2x);
	--largeFormItemRightWidth: var(--sp49x);
	--largeFormItemLeftWidth: calc(100% - var(--largeFormItemRightWidth));
	--largeFormItemLeftItemWidth: var(--sp47x);

	--groupMarginBottom: var(--sp13x);
	--titleMarginBottom: var(--sp1-5x);
	--inputPaddingIn: var(--sp1-75x);
	--inputCollMarginBottom: var(--sp7x);
	--inputMarginBottom: var(--sp4x);
	--btnMarginTop: var(--sp3x);
	--bottomTextMarginTop: var(--sp3x);
	--FormColWidth: calc(50% - var(--inputColPadding));
	--FormColWidth90: 90%;
	--FormColWidth43: calc(43% - var(--inputColPadding));
	--FormColWidth57: calc(57% - var(--inputColPadding));
	--ForgotPasswordMargBottom: var(--sp2x);
	--FooterSubscribeSuccessTopMarg: var(--sp1-3x);
	--SuccessTopPadd: var(--inputPaddingIn);

	--rowWrapMargBot: var(--sp7x);
	--textareaMinHeight: var(--sp36x);

	--lablePaddingBottom: var(--sp1-5x);
	--extraPaadingTop: var(--sp2x);
	--radioButtonMarg: var(--sp1-5x);
	--radioButtonInnerSize: var(--sp3x);
	--antUploadSizeTop: var(--sp30x);
	--antUploadSizeLeft: var(--sp25x);

	--deliteIconSize: var(--sp5x);
	--attentionIconSize: var(--sp5x);
	--globalErrorInfoTitle: var(--sp2x);

	--columnFormSectionWidth: 83%;
	--columnFormSectionLeftWidth: calc(40% - var(--inputColPadding));
	--columnFormSectionRightWidth: calc(60% - var(--inputColPadding));
	--formInfoBlockLeftWidth: calc(41.5% - var(--inputColPadding));
	--formInfoBlockRightWidth: calc(58.5% - var(--inputColPadding));

	--calculatorFormPaddTop: var(--sp6x);
	--calculatorFormPaddLeft: var(--sp3x);
	--calculatorFormSectionBottom: var(--sp7x);

	--iconAddBlack: var(--sp6x);
	--closeSize: var(--sp6x);

	--productAddWidth: var(--sp35x);
	--ProductItemWidth: 409px;

	--UpBtnSize: var(--sp10x);

    .ant-input-suffix {
        display: none!important;
    }

	.animationItem {
		transform: translateY(100vh);
	}

	&.largeFormSection {
		.formCont {
			max-width: 100%;
			width: calc(var(--largeFormItemLeftWidth) - var(--inputColPadding));
		}

		.accountCooperationCont {
			width: calc(var(--largeFormItemRightWidth) - var(--inputColPadding));
		}

		.FormColumnsTitle {
			width: calc(var(--largeFormItemLeftItemWidth) - var(--inputColPadding));
		}

		.FormColumnsWrapp {
			width: calc(100% - var(--largeFormItemLeftItemWidth) - var(--inputColPadding));
		}

		.FormRow .FormCol {
			margin-bottom: var(--inputCollMarginBottom);
		}

		.mutableFormItem {
			margin-top: var(--radioButtonMarg);
		}

		.globalErrorWrap {
			margin-bottom: var(--inputCollMarginBottom);
		}
	}

	&.UpdateProducts {
		.formCont {
			width: 100%;
			max-width: var(--sp97x);

			.btnInline {
				width: 50%;
				max-width: var(--sp20x);
				margin-right: calc(var(--inputColPadding) * 2);
			}
			.ant-upload-picture-card-wrapper {
				overflow: auto;
				&::-webkit-scrollbar {
					height: 6px;
				}

				&::-webkit-scrollbar-track {
					background: var(--lightGrey);
					border-radius: var(--spSize);
				}

				&::-webkit-scrollbar-thumb {
					background: var(--ultraDarkGrey);
					border-radius: var(--spSize);
				}

				&::-webkit-scrollbar-thumb:hover {
					background: var(--black);
				}
				.ant-upload-list {
					display: flex;
					flex-flow: row-reverse;
					justify-content: flex-end;
					width: max-content;
					min-width: 100%;
				}

				.ant-upload.ant-upload-select-picture-card {
					width: var(--sp12x);
					height: var(--sp12x);
					margin: 0 var(--inputColPadding) var(--inputColPadding) 0;
					i {
						margin-bottom: 0;
					}
				}
				.ant-upload-list-picture-card-container {
					width: var(--sp12x);
					height: var(--sp12x);
					margin: 0 var(--inputColPadding) var(--inputColPadding) 0;
					.ant-upload-list-item-actions {
						top: var(--spSize);
						right: var(--spSize);
					}
				}
			}
		}

		.FormColumnsWrapp {
			.formBtnWrapp {
				margin-top: 0;
			}
		}

		.FormRow {
			.FormColumnsWrapp > div {
				margin-bottom: var(--inputMarginBottom);
			}
		}

		.globalErrorWrap {
			margin-bottom: var(--inputCollMarginBottom);
		}
	}

	&.columnFormSection {
		max-width: var(--columnFormSectionWidth);
		margin-left: auto;
		margin-right: auto;

		.formCont {
			max-width: 100%;
		}

		.textAlignCenter {
			width: 100%;
			margin-bottom: var(--groupMarginBottom);
			h1 {
				margin-bottom: var(--sp2x);
			}
		}
		.ItemsWrapp {
			width: 100%;
		}

		.BusinessInArmeniaFormTop {
			max-width: 100%;
			width: 100%;
			padding-bottom: var(--groupMarginBottom);
			padding-top: var(--groupMarginBottom);
			position: relative;
			z-index: 1;
			&:after {
				content: '';
				background-color: var(--ultraLightGrey);
				position: absolute;
				top: 0;
				bottom: 0;
				left: -40vw;
				right: -40vw;
				z-index: -1;
			}
		}

		.totlaSection {
			width: 100%;
			> div {
				width: 100%;
			}
			&:after {
				width: 150vw;
				left: -34vw;
				right: -34vw;
			}
		}

		.FormRow {
			.ant-row {
				flex-direction: row !important;
				justify-content: space-between;

				.ant-form-item-label {
					width: 100%;
					max-width: var(--columnFormSectionLeftWidth);
				}
				.ant-form-item-control {
					width: 100%;
					max-width: var(--columnFormSectionRightWidth);
				}
			}
			.warningWrapp {
				margin-left: calc(var(--columnFormSectionLeftWidth) + (var(--inputColPadding) * 2));
			}
		}

		.columnFormCol-50 {
			.ant-row {
				.ant-form-item-control {
					max-width: calc(var(--columnFormSectionRightWidth) / 2);
					margin-right: calc(var(--columnFormSectionRightWidth) / 2);
				}
			}
		}

		.FormColumnsTitle {
			width: 100%;
			max-width: var(--columnFormSectionLeftWidth);
		}
		.FormColumnsWrapp {
			max-width: var(--columnFormSectionRightWidth);
		}

		.formInfoBlock {
			margin-top: var(--groupMarginBottom);
			margin-left: -10%;
			margin-right: -10%;

			.formInfoBlockLeft {
				width: 100%;
				max-width: var(--formInfoBlockLeftWidth);
				.comp-title {
					transform: translateY(100vh);
				}
			}
			.formInfoBlockRight {
				width: 100%;
				max-width: var(--formInfoBlockRightWidth);
				li {
					transform: translateY(100vh);
				}
			}
		}

		.formBtnWrapp {
			margin-top: 0;
			.btn {
				width: auto !important;
				&:last-child {
					margin-left: calc(var(--inputColPadding) * 2) !important;
				}
			}
		}

		.BusinessInArmeniaHTML {
			p {
				margin-bottom: var(--inputMarginBottom);
				line-height: var(--lineHeight);
			}
		}

		.BusinessInArmeniaList {
			ol {
				padding-inline-start: 0px;
				list-style-type: none;
				counter-reset: section;
				li {
					padding-bottom: var(--inputMarginBottom);
					padding-top: var(--inputMarginBottom);
					padding-right: var(--sp8x);
					border-bottom: 1px solid var(--extraLightGrey);
					display: flex;
					position: relative;
					a {
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
						svg {
							width: var(--sp3x);
						}
					}
					&:last-child {
						border-bottom: 0px;
					}
					&:first-child {
						margin-top: calc(var(--inputMarginBottom) * -1);
					}
					&:before {
						counter-increment: section;
						content: '0' counter(section) '.';
						font-family: MonBold, MonBoldRu;
						font-size: var(--h5);
						margin-right: var(--sp2x);
					}
				}
			}
		}

		.globalErrorWrap {
			margin-bottom: var(--inputCollMarginBottom);
		}
	}

	&.calculatorFormSection {
		background-color: var(--ultraLightGrey);
		padding: var(--calculatorFormPaddTop) var(--calculatorFormPaddLeft) var(--calculatorFormPaddLeft);
		margin-bottom: var(--calculatorFormSectionBottom);
		.formCont {
			max-width: 100%;
		}

		.FormRow {
			.ant-form-item-label label {
				color: var(--ultraDarkGrey);
			}
			.ant-radio-group {
				display: flex;
				flex-wrap: wrap;
				.ant-radio-button-wrapper {
					padding: var(--sp1-75x) var(--sp4x) !important;
					margin: 0 0 0 calc(var(--inputColPadding) * 2);
					.ant-radio-button-inner {
						opacity: 0;
						visibility: hidden;
					}
					&:first-child {
						margin: 0;
					}
				}
			}

			.ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
			.ant-radio-group .ant-radio-button-wrapper:hover {
				color: var(--black) !important;
				background-color: var(--darkGrey) !important;
			}
		}

		.globalErrorWrap {
			margin-bottom: var(--inputCollMarginBottom);
		}
	}

	.SuccessfullProfile {
		margin-bottom: calc(var(--inputMarginBottom) / 2);
	}

	.globalErrorWrap {
		padding: var(--sp3x) var(--sp4x);
		background-color: var(--ultraLightGrey);
		border-radius: var(--borderSm);
		border: 1px solid var(--errColor);
		margin-bottom: var(--inputMarginBottom);
		.globalErrorIconWrap {
			width: calc(var(--attentionIconSize) + var(--h4));
			.icon-attention {
				padding: var(--spSize) var(--spSize) calc(var(--spSize) * 1.5);
				height: var(--attentionIconSize);
				width: var(--attentionIconSize);
				border-radius: var(--borderLg);
				background-color: var(--errColor);
				margin-right: var(--h4);
				span {
					display: inline-block;
					&:before {
						font-size: var(--h5);
					}
				}
			}
		}
		.globalErrorInfoWrap {
			width: calc(100% - (var(--attentionIconSize) + var(--h4)));
			h6 {
				margin-bottom: var(--globalErrorInfoTitle);
			}
			a {
				text-decoration: underline;
				font-family: MonMedium, MonMediumRu;
			}
		}
	}

	.totlaSection {
		position: relative;
		z-index: 1;
		padding: var(--groupMarginBottom) 0;
		margin-bottom: 0;
		&:after {
			content: '';
			background-color: var(--extraLightGrey);
			position: absolute;
			top: 0;
			bottom: 0;
			left: calc(((100vw - (var(--maxWidth) + (var(--containerPadding) * 2))) / -2) - var(--containerPadding));
			right: 0;
			z-index: -1;
			width: 100vw;
		}

		@media screen and (max-width: 2165px) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			&:after {
				left: calc(var(--containerPadding) * -1);
			}
		}

		.priceWrapp {
			width: 100%;
			.priceItem {
				padding-top: var(--sp3x);
				padding-bottom: var(--sp3x);
				border-bottom: 1px solid var(--darkGrey);
				.priceItemRight {
					width: 40%;
					text-align: right;
					.comp-title {
						margin-top: var(--spSize);
						&:first-child {
							margin-top: 0;
						}
					}
				}
				.priceItemLeft {
					width: 60%;
					.comp-title {
						margin-top: var(--spSize);
						&:first-child {
							margin-top: 0;
						}
					}
				}
				&:first-child {
					padding-top: 0;
				}
			}
			.totalPrice {
				padding-top: var(--sp3x);
			}
		}
	}

	.UpBtnWrap {
		width: 0px;
		position: absolute;
		top: 0;
		bottom: 0;
		right: calc((var(--largeFormItemRightWidth) - var(--inputColPadding)) * -1);
		.UpBtn {
			cursor: pointer;
			border-radius: var(--borderLg);
			width: var(--UpBtnSize);
			height: var(--UpBtnSize);
			position: sticky;
			top: 100vh;
			right: 0;
			z-index: 2;
			background-color: var(--white);
			box-shadow: 0px 5px 15px rgb(0 0 0 / 25%);
			transition: all var(--trTime) ease;
			margin-bottom: var(--groupMarginBottom);
			&.active {
				top: calc(100vh - (var(--UpBtnSize) * 2));
			}
			i {
				font-size: 13px;
			}
		}
	}

	.FormColGroup {
		width: 100%;
		margin-bottom: var(--inputCollMarginBottom);
		.FormCol {
			margin-bottom: var(--sp3x);
		}
		&:last-child {
			margin-bottom: 0;
		}
		/* .FormCol-50 {
			justify-content: flex-end;
		} */
	}

	.FormRow {
		max-width: 100%;
		.FormColumnsWrapp {
			margin-bottom: var(--groupMarginBottom);
			> div {
				margin-bottom: var(--inputCollMarginBottom);
				&:last-child {
					margin-bottom: 0;
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
		}

		.FormColTitle {
			width: 100%;
			margin-bottom: var(--titleMarginBottom);
		}

		.extraMaxWidth {
			&.FormCol-50 {
				.ant-form-item-extra {
					width: calc(200% + (var(--inputColPadding) * 2));
				}
			}
		}

		.FormCol {
			width: 100%;
			margin-bottom: var(--inputMarginBottom);

			&.FormCol-50 {
				max-width: var(--FormColWidth);
				display: flex;
				flex-direction: column;
				&:nth-last-child(-n + 2) {
					margin-bottom: 0;
				}
			}

			&.FormCol-90 {
				max-width: var(--FormColWidth90);
			}

			&.FormCol-40 {
				max-width: var(--FormColWidth43);
				margin-bottom: 0;
			}
			&.FormCol-60 {
				max-width: var(--FormColWidth57);
				margin-bottom: 0;
			}

			&.marginNone {
				margin-bottom: 0;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}

		.HelperDesc {
			cursor: help;
			position: absolute;
			right: 0;
			top: 3px;
			border-radius: var(--borderLg);
			font-size: 18px;
			z-index: 11;
			width: 40px;
			margin-right: -20px;
			& + .ant-row {
				.ant-form-item-label {
					max-width: calc(100% - var(--sp4x));
				}
			}
		}

		.ant-radio-group {
			width: 100%;
			.FormCol {
				margin-bottom: 0 !important;
			}

			.ant-radio-button-wrapper {
				background: transparent;
				cursor: pointer;
				position: relative;
				display: block;
				border: 0 !important;
				border-radius: 0 !important;
				margin: var(--radioButtonMarg) 0;
				line-height: var(--lineHeight);
				padding: 0 var(--sp4x);
				height: auto;

				.h5 {
					margin-top: var(--sp1-3x);
					line-height: var(--lineHeightXS);
				}
				.ant-radio-button {
					color: var(--ultraDarkGrey);
					font-size: var(--pM);
					line-height: var(--lineHeightXS);
					font-family: MonRegular, MonRegularRu;
					z-index: 1;
					&.ant-radio-button-checked {
						color: var(--black);
						.ant-radio-button-inner {
							&:after {
								opacity: 1;
							}
						}
					}
					.ant-radio-button-inner {
						border-radius: var(--borderLg);
						border: 1px solid var(--black);
						width: var(--radioButtonInnerSize);
						height: var(--radioButtonInnerSize);
						position: absolute;
						transform: translateY(-50%);
						left: 0;
						top: var(--radioButtonMarg);

						&:after {
							opacity: 0;
							content: '';
							border-radius: var(--borderLg);
							background-color: var(--black);
							width: 14px;
							height: 14px;
							position: absolute;
							transform: translate(-50%, -50%);
							left: 50%;
							top: 50%;
						}
					}
				}

				span {
					> div {
						margin-top: var(--spSize);
					}
				}
				&.ant-radio-button-wrapper-checked,
				&:hover {
					color: var(--black);
					background: transparent;
					border: none !important;
					box-shadow: none;
				}
				&:before {
					content: none;
				}
			}
		}

		.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) {
			background-color: var(--extraLightGrey) !important;
			border-color: transparent !important;
		}

		.ant-select.ant-select-multiple {
			.ant-select-selector {
				flex: auto;
				padding: 0 !important;
				min-height: var(--sp6-5x);
				.ant-select-selection-item {
					margin: var(--spSize) var(--sp2x) var(--spSize) var(--spSize) !important;
					height: auto;
					padding: calc(var(--spSize) - 2px) var(--spSize) !important ;
					.ant-select-selection-item-content {
						margin-right: var(--sp2x);
						line-height: var(--lineHeightXS);
					}
					.ant-select-selection-item-remove {
						display: flex;
						align-items: center;
						.anticon-close {
							display: flex;
							align-items: center;
						}
					}
				}
			}
		}

		.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
			border-color: var(--black) !important;
		}
		.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
			border-color: var(--black) !important;
			box-shadow: none !important;
		}

		.ant-col {
			position: relative;
		}

		textarea {
			min-height: var(--textareaMinHeight);
			resize: none;
		}

		.ant-btn {
			height: auto;
		}

		.ant-form-item {
			margin-bottom: var(--sp3x);
			&:last-child {
				margin-bottom: 0;
			}
			& ~ .warningWrapp {
				margin-top: calc(var(--spSize) * -1);
			}
		}

		.ant-form-item-label {
			padding: 0 0 var(--lablePaddingBottom);
			label {
				color: var(--black);
				font-family: MonMedium, MonMediumRu;
				font-size: var(--pL);
				position: relative;
			}
			> label {
				align-items: flex-start;
			}
			.ant-form-item-required {
				&:before {
					content: none;
				}
			}
		}

		.formItemExtraMargMinus {
			.ant-form-item-extra {
				margin-top: calc(var(--radioButtonMarg) * -1.3);
			}
		}

		.ant-form-item-extra {
			margin-top: var(--extraPaadingTop);
			padding: var(--sp2x);
			background-color: var(--ultraLightGrey);
			border-radius: var(--borderSm);
			color: var(--ultraDarkGrey);
			font-size: var(--pM);
			font-family: MonRegular, MonRegularRu;
		}

		.ant-input-group-wrapper {
			.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
				border-color: transparent !important;
			}
			.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
				border-color: transparent !important;
			}
			.ant-select-selector {
				background-color: transparent !important;
				border-color: transparent !important;
			}
		}

		/* .ant-upload-picture-card-wrapper {
			margin-top: var(--spSize);
		} */

		.ant-upload.ant-upload-select-picture-card {
			width: var(--antUploadSizeTop);
			height: var(--antUploadSizeLeft);
			margin: 0 calc(var(--inputColPadding) * 2) var(--inputColPadding) 0;
			background-color: transparent;
			border: 0;
			background-image: repeating-linear-gradient(
					0deg,
					var(--darkGrey),
					var(--darkGrey) 7px,
					transparent 7px,
					transparent 17px,
					var(--darkGrey) 17px
				),
				repeating-linear-gradient(90deg, var(--darkGrey), var(--darkGrey) 7px, transparent 7px, transparent 17px, var(--darkGrey) 17px),
				repeating-linear-gradient(180deg, var(--darkGrey), var(--darkGrey) 7px, transparent 7px, transparent 17px, var(--darkGrey) 17px),
				repeating-linear-gradient(270deg, var(--darkGrey), var(--darkGrey) 7px, transparent 7px, transparent 17px, var(--darkGrey) 17px);
			background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
			background-position: 0 0, 0 0, 100% 0, 0 100%;
			background-repeat: no-repeat;

			i {
				font-size: var(--deliteIconSize);
				margin-bottom: var(--spSize);
				display: inline-block;
			}
		}

		.ant-upload-list-picture-card-container {
			width: var(--antUploadSizeTop);
			height: var(--antUploadSizeLeft);
			margin: 0 calc(var(--inputColPadding) * 2) var(--inputColPadding) 0;
			border-radius: var(--borderSm);
			overflow: hidden;
			.ant-upload-list-item {
				padding: 0;
				border: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}

		.ant-upload-list-picture-card {
			.ant-upload-list-item-info::before {
				content: none;
			}
			.ant-upload-list-item-thumbnail img {
				object-fit: cover;
			}
		}

		.ant-upload-list-item-actions {
			top: var(--sp2x);
			left: inherit;
			right: var(--sp2x);
			transform: none;
			opacity: 1;
			a {
				display: none;
			}
			.ant-btn {
				border-radius: var(--borderLg);
				background-color: var(--white);
				width: var(--deliteIconSize);
				height: var(--deliteIconSize);
				box-shadow: 0px 0px 12px rgb(0 0 0 / 16%);
				position: relative;
				.anticon-delete {
					font-family: icomoon !important;
					speak: never;
					font-style: normal;
					font-weight: 400;
					font-feature-settings: normal;
					font-variant: normal;
					text-transform: none;
					line-height: 1;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					&:after {
						content: '\\e903';
						font-size: calc(var(--pL) / 1.7);
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						color: var(--black);
					}
				}
			}
		}

		.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
		.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
			font-size: var(--h2);
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.ant-input-group-addon {
			padding: var(--inputPaddingIn);
			font-family: MonRegular, MonRegularRu;
			font-size: var(--pL);
			color: var(--ultraDarkGrey);
			border: 1px solid var(--darkGrey);
			border-right: 0;
			border-radius: var(--borderSm);
			background-color: var(--extraLightGrey);
			border-right: 0px;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
			& ~ .ant-input {
				border-top-left-radius: 0px;
				border-bottom-left-radius: 0px;
			}
			.ant-select-selector {
				padding-top: 0;
				padding-bottom: 0;
			}

			.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
				border-color: transparent !important;
			}
		}

		.ant-input-affix-wrapper {
			padding: 0;
			background-color: transparent;
			border: 0;
			&:focus {
				box-shadow: none !important;
			}
		}
		.ant-input-affix-wrapper-focused {
			box-shadow: none !important;
		}

		.ant-form-item-control-input {
			min-height: auto;
		}

		.ant-checkbox-wrapper {
			display: flex;
			align-items: unset;
			.ant-checkbox {
				/* margin-top: 2px; */
				.ant-checkbox-input {
					width: var(--sp3x);
					height: var(--sp3x);
				}
				& + span {
					padding-left: var(--sp2x);
				}

				&.ant-checkbox-checked {
					border-color: var(--black);
					&:after {
						content: none;
					}
					.ant-checkbox-inner {
						background-color: var(--black);
						border-color: var(--black);
					}
				}
				.ant-checkbox-inner {
					border-color: var(--darkGrey);
					width: var(--sp3x);
					height: var(--sp3x);
					border-radius: var(--borderSm);
					&:after {
						top: 45%;
						left: 27%;
						width: 7px;
						height: 12px;
					}
				}
			}
		}

		.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
			content: none;
		}

		.ant-select-clear {
			display: none;
		}

		.ant-select-selection-item {
			line-height: var(--lineHeight);
			color: var(--ultraDarkGrey);
			font-family: MonRegular, MonRegularRu;
			font-size: var(--pL);
			padding-right: var(--sp3x);
		}

		.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
			line-height: var(--lineHeight);
			color: var(--ultraDarkGrey);
			font-family: MonRegular, MonRegularRu;
			font-size: var(--pL);
			&:after {
				height: 0;
			}
		}

		.ant-select-arrow {
			right: var(--sp2x);
			width: var(--sp1-5x);
			font-size: var(--sp1-5x);
		}

		.ant-input,
		.ant-select-selector {
			width: 100%;
			height: auto;
			padding: var(--inputPaddingIn);
			font-family: MonRegular, MonRegularRu;
			font-size: var(--pL);
			line-height: var(--lineHeight);
			color: var(--ultraDarkGrey);
			border: 1px solid var(--darkGrey);
			border-radius: var(--borderSm);
			display: flex;
			align-items: center;

			&::-webkit-input-placeholder {
				color: var(--ultraDarkGrey);
				font-family: MonRegular, MonRegularRu;
				font-size: var(--pL);
			}
			&::-webkit-input-placeholder {
				color: var(--ultraDarkGrey);
				font-family: MonRegular, MonRegularRu;
				font-size: var(--pL);
			}
			&::-moz-placeholder {
				color: var(--ultraDarkGrey);
				font-family: MonRegular, MonRegularRu;
				font-size: var(--pL);
			}
			&:-ms-input-placeholder {
				color: var(--ultraDarkGrey);
				font-family: MonRegular, MonRegularRu;
				font-size: var(--pL);
			}
			&::placeholder {
				color: var(--ultraDarkGrey);
				font-family: MonRegular, MonRegularRu;
				font-size: var(--pL);
			}
		}

		.ant-input:focus,
		.ant-input-focused {
			border-color: var(--black) !important;
			box-shadow: none !important;
		}

		.ant-row {
			width: 100%;
		}

		.ForgotPassword {
			display: inline-block;
			margin-bottom: var(--ForgotPasswordMargBottom);
		}

		.ant-form-item-children-icon {
			display: none;
		}

		.suffix {
			.ant-input {
				padding-right: 30%;
			}
			&.suffixLarge {
				.ant-input-suffix {
					font-size: var(--h6);
				}
			}
		}

		.ant-input-suffix {
			position: absolute;
			top: 50%;
			right: 16px;
			transform: translateY(-50%);
			font-size: var(--pM);
			text-transform: uppercase;
			color: var(--darkGrey);
			font-family: MonMedium, MonMediumRu;
		}

		.ant-form-item-explain {
			color: var(--errColor);
			margin-top: var(--spSize);
			padding: 0 var(--inputPaddingIn);
			font-family: MonRegular, MonRegularRu;
			font-size: var(--pS);
		}

		.ant-form-item-has-error {
			.ant-input {
				border: 1px solid var(--errColor);
			}

			.ant-input-group-addon {
				.ant-select-selector {
					border-color: transparent !important;
				}
			}
		}

		.bottomText {
			margin-top: var(--bottomTextMarginTop);
		}

		// &.footerSubscribeCont {
		// 	margin-bottom: 0 !important;
		// 	.ant-col {
		// 		height: 100%;
		// 		div {
		// 			height: 100%;
		// 		}
		// 	}
		// 	.ant-form-item {
		// 		margin-bottom: 0;
		// 	}

		// 	.ant-input {
		// 		border-bottom-right-radius: 0;
		// 		border-top-right-radius: 0;
		// 		border-right: 0;
		// 		height: 100%;
		// 	}
		// 	.btn {
		// 		border-bottom-left-radius: 0;
		// 		border-top-left-radius: 0;
		// 		padding-left: var(--sp3x);
		// 		padding-right: var(--sp3x);
		// 		height: 100%;
		// 	}

		// 	.footerSubscribeSuccessWrapp {
		// 		padding: var(--SuccessTopPadd) var(--inputPaddingIn);
		// 		background-color: var(--ultraLightGrey);
		// 		position: absolute;
		// 		top: calc(100% + var(--FooterSubscribeSuccessTopMarg));
		// 		left: 0;
		// 		right: 0;

		// 		.comp-title {
		// 			line-height: var(--lineHeightS);
		// 		}

		// 		.iconCont {
		// 			min-width: var(--sp3x);
		// 			min-height: var(--sp3x);
		// 			margin-right: var(--sp1-5x);
		// 			border-radius: var(--borderLg);
		// 			background-color: var(--yellow);
		// 		}
		// 	}
		// }

		.formBtnWrapp {
			margin-top: var(--btnMarginTop);
			width: 100%;
			.btn {
				width: 100%;
			}
		}

		.btn {
			cursor: pointer;
			text-align: center;
			-webkit-appearance: none;
			color: var(--white);
			line-height: var(--lineHeightS);
			font-family: MonMedium, MonMediumRu;
			background-color: var(--black);
			transition: var(--trTime);
			border-radius: var(--borderSm);
			padding: var(--sp2x) var(--sp4x);
			border: 0;
			font-size: var(--pL);
			margin-left: -1px;

			&.btn-black {
				color: var(--white);
				background-color: var(--black);
				&:hover {
					background-color: var(--yellow);
					color: var(--black);
				}
			}

			&.disabled {
				background-color: var(--lightGrey);
				color: var(--darkGrey);
				cursor: auto;
				pointer-events: none;
			}

			&.opacity-disabled {
				opacity: 0.5;
				cursor: auto;
				pointer-events: none;
			}

			&.btn-white {
				color: var(--black);
				background-color: var(--white);
				position: relative;
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					border: 1px solid var(--black);
					border-radius: var(--borderSm);
					pointer-events: none;
				}
				&:hover {
					color: var(--white);
					background-color: var(--black);
				}
			}

			&.btn-grey {
				color: var(--ultraDarkGrey);
				background-color: var(--extraLightGrey);
				position: relative;
				overflow: hidden;
				border-radius: var(--borderSm) !important;
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					border: 1px solid var(--darkGrey);
					border-radius: var(--borderSm);
					pointer-events: none;
				}
				&:hover {
					color: var(--black);
					background-color: var(--darkGrey);
				}
			}

			&.btn-grey-black {
				color: var(--black);
				background-color: transparent;
				position: relative;
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					border: 1px solid var(--black);
					border-radius: var(--borderSm);
					pointer-events: none;
				}
				&:hover {
					color: var(--white);
					background-color: var(--black);
				}
			}

			@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSizeMin}) {
				font-size: var(--pM);
			}

			@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
				font-size: var(--pS);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		--btnMarginTop: var(--sp2x);
		--rowWrapMargBot: var(--sp6x);
		--largeFormItemRightWidth: var(--sp41x);
		--largeFormItemLeftItemWidth: var(--sp42x);
		--inputCollMarginBottom: var(--sp6x);
		--groupMarginBottom: var(--sp10x);
		--textareaMinHeight: var(--sp25x);
		--inputPaddingIn: var(--sp1-5x);

		--calculatorFormPaddTop: var(--sp4x);
		--calculatorFormPaddLeft: var(--sp2x);

		--ProductItemWidth: 349px;
		--productAddWidth: var(--sp27x);

		--UpBtnSize: var(--sp9x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		--btnMarginTop: var(--spSize);
		--inputColPadding: var(--sp1-5x);
		--SuccessTopPadd: calc(var(--inputPaddingIn) / 1.3);
		--rowWrapMargBot: var(--sp5x);

		--largeFormItemRightWidth: var(--sp41x);
		--largeFormItemLeftItemWidth: var(--sp29x);
		--inputCollMarginBottom: var(--sp6x);
		--groupMarginBottom: var(--sp8x);
		--textareaMinHeight: var(--sp25x);
		--inputPaddingIn: var(--sp1-5x);

		--UpBtnSize: var(--sp9x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--antUploadSizeTop: var(--sp25x);
		--antUploadSizeLeft: var(--sp20x);
		--deliteIconSize: var(--sp4x);

		--globalErrorInfoTitle: var(--spSize);

		--calculatorFormPaddTop: var(--sp4x);
		--calculatorFormPaddLeft: var(--sp2x);
		--calculatorFormSectionBottom: var(--sp6x);

		--closeSize: var(--sp5x);
		--iconAddBlack: var(--sp5x);

		--UpBtnSize: var(--sp7x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		.totlaSection {
			&:after {
				left: calc(-1 * var(--containerPadding));
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		&.UpdateProducts {
			.formCont {
				max-width: var(--sp88x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		&.largeFormSection {
			.totlaSection {
				&:after {
					left: calc(((100vw - (var(--maxWidth) + (var(--containerPadding) * 2))) / -2) - var(--containerPaddingLarge));
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		--btnMarginTop: var(--spSize);
		--inputColPadding: var(--sp1-5x);
		--inputMarginBottom: var(--sp3x);
		--bottomTextMarginTop: var(--sp2x);
		--SuccessTopPadd: calc(var(--inputPaddingIn) / 1.3);
		--rowWrapMargBot: var(--sp4x);
		--inputCollMarginBottom: var(--sp5x);

		--largeFormItemRightWidth: var(--sp26x);
		--largeFormItemLeftItemWidth: var(--sp26x);
		--largeFormItemLeftWidth: 89%;
		--textareaMinHeight: var(--sp25x);
		--inputPaddingIn: var(--sp1-5x);
		--groupMarginBottom: var(--sp8x);

		--radioButtonInnerSize: var(--sp2-5x);
		--extraPaadingTop: var(--sp1-3x);

		--columnFormSectionWidth: 100%;
		--columnFormSectionLeftWidth: calc(37% - var(--inputColPadding));
		--columnFormSectionRightWidth: calc(63% - var(--inputColPadding));

		&.columnFormSection {
			.formInfoBlock {
				margin-left: 0%;
				margin-right: 0%;
			}
		}

		--productAddWidth: 100%;
		--ProductItemWidth: 100%;

		.UpBtnWrap {
			right: calc(-1 * var(--sp10x));
			.UpBtn {
				i {
					font-size: 11px;
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
		--largeFormItemRightWidth: 100%;
		--largeFormItemLeftItemWidth: 100%;
		--largeFormItemLeftWidth: 100%;

		--groupMarginBottom: var(--sp8x);

		--columnFormSectionLeftWidth: 100%;
		--columnFormSectionRightWidth: 100%;

		.ant-form-vertical .ant-form-item {
			flex-direction: unset;
		}

		&.largeFormSection {
			.FormColumnsTitle {
				width: var(--largeFormItemRightWidth);
				margin-bottom: var(--sp2-5x);
			}

			.FormColumnsWrapp {
				width: var(--largeFormItemRightWidth);
			}

			.extraMaxWidth.FormCol-50 .ant-form-item-extra {
				width: 100%;
			}
		}

		&.columnFormSection {
			.FormRow {
				.warningWrapp {
					margin-left: 0;
				}
				.BusinessInArmeniaHTML {
					p {
						&:last-child {
							margin-bottom: calc(var(--inputMarginBottom) * 3);
						}
					}
				}
			}

			.formInfoBlock {
				.formInfoBlockLeft {
					max-width: var(--columnFormSectionLeftWidth);
				}
				.formInfoBlockRight {
					max-width: var(--columnFormSectionRightWidth);
				}
			}
		}

		.FormRow {
			.FormColumnsTitle {
				margin-bottom: var(--sp5x);
			}
		}

		.UpBtnWrap {
			right: var(--UpBtnSize);
			.UpBtn {
				margin-bottom: calc(var(--UpBtnSize) / -2);
				i {
					font-size: 9px;
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--FormColWidth: 100%;
		--FooterSubscribeSuccessTopMarg: var(--spSize);
		--SuccessTopPadd: var(--spSize);
		--titleMarginBottom: var(--sp1-3x);

		--largeFormItemRightWidth: 100%;
		--largeFormItemLeftItemWidth: 100%;
		--largeFormItemLeftWidth: 100%;
		--FormColWidth90: 100%;
		--FormColWidth43: 100%;
		--FormColWidth57: 100%;
		--groupMarginBottom: var(--sp5x);

		--antUploadSizeTop: calc(50% - var(--inputColPadding));
		--antUploadSizeLeft: var(--sp15x);

		--deliteIconSize: var(--sp3x);
		--attentionIconSize: var(--sp4x);

		--UpBtnSize: var(--sp7x);

        .ant-select-item-option-content {
            font-size: 16px !important;
        }

		&.largeFormSection {
			.FormColumnsTitle {
				width: var(--largeFormItemRightWidth);
				margin-bottom: var(--sp2-5x);
			}

			.FormColumnsWrapp {
				width: var(--largeFormItemRightWidth);
			}
			.formCont {
				width: var(--largeFormItemLeftWidth);
			}
		}

		&.columnFormSection {
			.columnFormCol-50 {
				.ant-row .ant-form-item-control {
					max-width: var(--columnFormSectionRightWidth);
					margin-right: 0;
				}
			}

			.BusinessInArmeniaList {
				ol {
					li {
						padding-right: var(--sp6x);
						&:first-child {
							padding-top: var(--inputMarginBottom);
						}
					}
				}
			}
		}

		&.UpdateProducts {
			.formCont {
				.ant-upload-picture-card-wrapper {
					.ant-upload-list-item-actions {
						top: 50% !important;
						left: 50% !important;
						right: inherit !important;
						transform: translate(-50%, -50%);
					}

					.ant-upload-list {
						justify-content: flex-end;
					}

					.ant-upload.ant-upload-select-picture-card,
					.ant-upload-list-picture-card-container {
						margin: 0 var(--inputColPadding) var(--inputColPadding) 0;
						width: var(--sp7x);
						height: var(--sp7x);
					}
				}
			}
		}

		.FormRow {
			.FormColumnsTitle {
				margin-bottom: var(--sp4x);
			}

			.ant-input,
            .ant-select-selection-search-input {
				font-size: 16px !important;
			}
			.ant-upload-list-picture-card-container {
				margin: 0 var(--inputColPadding) var(--inputColPadding) 0;
			}

			.ant-upload.ant-upload-select-picture-card {
				&:nth-child(even) {
					margin-right: 0;
				}
			}
			.FormCol {
				&.FormCol-50 {
					&:nth-last-child(-n + 2) {
						margin-bottom: var(--inputCollMarginBottom);
					}
				}
				&.FormCol-40 {
					margin-bottom: var(--inputMarginBottom);
				}
			}

			.extraMaxWidth {
				&.FormCol-50 {
					.ant-form-item-extra {
						width: 100%;
					}
				}
			}
		}
	}
`
export default FormStyle
