import React, { memo as Memo, useState } from 'react'
import Collapse from '@material-ui/core/Collapse'

//* HOC's
import { withUIContext, withAccountContext } from 'context'
import { useRouter } from 'next/router'

//* Config
import { config } from 'helpers'

//* Components
import { Page, ProtectRoute, CustomLink, Translate, Container, Cooperation, Preloader, Icon } from 'components/common'

//* Styles
import AccountStyle from '../../../../styles/pages/AccountStyle'

const AccountMain = Memo(({ className = '', user, logout, children, loading, ...props }) => {
	const router = useRouter()

	const [popupMenu, setPopupMenu] = useState(false)
	const [popupMenuItem, setPopupMenuItem] = useState([false, false])

	const handleClick = (ind) => {
		setPopupMenu(ind)
	}
	const handleClick2 = (ind) => {
		setPopupMenuItem(ind)
	}

	return (
		<ProtectRoute>
			<AccountStyle>
				<Page className='account'>
					<Container className={`${className ? className : ''} accountCont FlexBoxContainer`}>
						<div className='accountMenuCont'>
							{props.winWidth >= 1440 ? (
								<ul className={'sideBar'}>
									<li className={'sideBarItem'}>
										<span
											className={`relativeWrapp h6  black ${
												router.route === config.routes.account.path ? 'MonMedium' : 'MonRegular'
											} `}
										>
											<span className='duplicate MonMedium'>
												<Translate val={'MyProfile'} />
											</span>
											<CustomLink url={config.routes.account.path}>
												<Translate val={'MyProfile'} />
											</CustomLink>
										</span>
									</li>
									<li className={'sideBarItemWrapp bordBot'}>
										<span
											className={'sideBarItem MonRegular h5 darkGrey'}
											onClick={() => handleClick2([!popupMenuItem[0], false])}
										>
											<Translate val={'InvestmentPage'} />
										</span>

										<ul className='sideBarSubItem'>
											<li className={'sideBarItem'}>
												<span
													className={`relativeWrapp h6  black ${
														router.route === config.routes.submissions.path ? 'MonMedium' : 'MonRegular'
													} `}
												>
													<span className='duplicate MonMedium'>
														<Translate val={'Submissions'} />
													</span>
													<CustomLink url={config.routes.submissions.path}>
														<Translate val={'Submissions'} />
													</CustomLink>
												</span>
											</li>
											<li className={'sideBarItem'}>
												<span
													className={`relativeWrapp h6  black ${
														router.route === config.routes.projectCompany.path ? 'MonMedium' : 'MonRegular'
													} `}
												>
													<span className='duplicate MonMedium'>
														<Translate val={'AboutCompany'} />
													</span>
													<CustomLink url={config.routes.projectCompany.path}>
														<Translate val={'AboutCompany'} />
													</CustomLink>
												</span>
											</li>
										</ul>
									</li>
									<li className='sideBarItemWrapp'>
										<span
											className={'sideBarItem MonRegular h5 darkGrey'}
											onClick={() => handleClick2([false, !popupMenuItem[1]])}
										>
											<Translate val={'ExportPage'} />
										</span>
										<ul className='sideBarSubItem'>
											<li className={'sideBarItem'}>
												<span
													className={`relativeWrapp h6  black ${
														router.route === config.routes.submittedProducts.path ? 'MonMedium' : 'MonRegular'
													} `}
												>
													<span className='duplicate MonMedium'>
														<Translate val={'SubmittedProducts'} />
													</span>
													<CustomLink url={config.routes.submittedProducts.path}>
														<Translate val={'SubmittedProducts'} />
													</CustomLink>
												</span>
											</li>
											<li className={'sideBarItem'}>
												<span
													className={`relativeWrapp h6  black ${
														router.route === config.routes.productsCompany.path ? 'MonMedium' : 'MonRegular'
													} `}
												>
													<span className='duplicate MonMedium'>
														<Translate val={'AboutCompany'} />
													</span>
													<CustomLink url={config.routes.productsCompany.path}>
														<Translate val={'AboutCompany'} />
													</CustomLink>
												</span>
											</li>
										</ul>
									</li>
									<li className={'sideBarItem'}>
										<span
											className={`relativeWrapp h6  black ${
												router.route === config.routes.purchaseHistory.path ? 'MonMedium' : 'MonRegular'
											} `}
										>
											<span className='duplicate MonMedium'>
												<Translate val={'PurchaseHistory'} />
											</span>
											<CustomLink url={config.routes.purchaseHistory.path}>
												<Translate val={'PurchaseHistory'} />
											</CustomLink>
										</span>
									</li>
									<li className='sideBarItem h5  darkGrey MonRegular cursorPointer'>
										<span onClick={logout}>
											<Translate val={'LogOut'} />
										</span>
									</li>
								</ul>
							) : (
								<>
									<div
										className='popupMenu  h4 MonMedium black FlexBoxContainer spaceBetween alignMiddle cursorPointer'
										onClick={() => handleClick(!popupMenu)}
									>
										<span>
											<Translate val={'ProfileMenu'} />
										</span>
										<Icon className={`icon-arrow-down ${popupMenu ? 'active' : ''}`} />
									</div>

									<Collapse in={popupMenu} timeout='auto' unmountOnExit>
										<ul className={'sideBar'}>
											<li className={'sideBarItem'}>
												<span
													className={`relativeWrapp h6  black ${
														router.route === config.routes.account.path ? 'MonMedium' : 'MonRegular'
													} `}
												>
													<span className='duplicate MonMedium'>
														<Translate val={'MyProfile'} />
													</span>
													<CustomLink url={config.routes.account.path}>
														<Translate val={'MyProfile'} />
													</CustomLink>
												</span>
											</li>
											<li
												className={`sideBarItemWrapp cursorPointer ${popupMenuItem[0] ? 'active' : ''}`}
												onClick={() => handleClick2([!popupMenuItem[0], false])}
											>
												<span className={'sideBarItem MonRegular h5 black'}>
													<Translate val={'InvestmentPage'} />
												</span>

												<Collapse in={popupMenuItem[0]} timeout='auto' unmountOnExit>
													<ul className='sideBarSubItem'>
														<li className={'sideBarItem'}>
															<span
																className={`relativeWrapp h6  black ${
																	router.route === config.routes.submissions.path ? 'MonMedium' : 'MonRegular'
																} `}
															>
																<span className='duplicate MonMedium'>
																	<Translate val={'Submissions'} />
																</span>
																<CustomLink url={config.routes.submissions.path}>
																	<Translate val={'Submissions'} />
																</CustomLink>
															</span>
														</li>
														<li className={'sideBarItem'}>
															<span
																className={`relativeWrapp h6  black ${
																	router.route === config.routes.projectCompany.path ? 'MonMedium' : 'MonRegular'
																} `}
															>
																<span className='duplicate MonMedium'>
																	<Translate val={'AboutCompany'} />
																</span>
																<CustomLink url={config.routes.projectCompany.path}>
																	<Translate val={'AboutCompany'} />
																</CustomLink>
															</span>
														</li>
													</ul>
												</Collapse>
											</li>
											<li
												className={`sideBarItemWrapp cursorPointer ${popupMenuItem[1] ? 'active' : ''}`}
												onClick={() => handleClick2([false, !popupMenuItem[1]])}
											>
												<span className={'sideBarItem MonRegular h5 black'}>
													<Translate val={'ExportPage'} />
												</span>
												<Collapse in={popupMenuItem[1]} timeout='auto' unmountOnExit>
													<ul className='sideBarSubItem'>
														<li className={'sideBarItem'}>
															<span
																className={`relativeWrapp h6  black ${
																	router.route === config.routes.submittedProducts.path ? 'MonMedium' : 'MonRegular'
																} `}
															>
																<span className='duplicate MonMedium'>
																	<Translate val={'SubmittedProducts'} />
																</span>
																<CustomLink url={config.routes.submittedProducts.path}>
																	<Translate val={'SubmittedProducts'} />
																</CustomLink>
															</span>
														</li>
														<li className={'sideBarItem'}>
															<span
																className={`relativeWrapp h6  black ${
																	router.route === config.routes.productsCompany.path ? 'MonMedium' : 'MonRegular'
																} `}
															>
																<span className='duplicate MonMedium'>
																	<Translate val={'AboutCompany'} />
																</span>
																<CustomLink url={config.routes.productsCompany.path}>
																	<Translate val={'AboutCompany'} />
																</CustomLink>
															</span>
														</li>
													</ul>
												</Collapse>
											</li>
											<li className={'sideBarItem'}>
												<span
													className={`relativeWrapp h6  black ${
														router.route === config.routes.purchaseHistory.path ? 'MonMedium' : 'MonRegular'
													} `}
												>
													<span className='duplicate MonMedium'>
														<Translate val={'PurchaseHistory'} />
													</span>
													<CustomLink url={config.routes.purchaseHistory.path}>
														<Translate val={'PurchaseHistory'} />
													</CustomLink>
												</span>
											</li>
											<li className='sideBarItem h5  darkGrey MonRegular cursorPointer'>
												<span onClick={logout}>
													<Translate val={'LogOut'} />
												</span>
											</li>
										</ul>
									</Collapse>
								</>
							)}
						</div>

						<div className='accountPageCont relativeWrapp'>
							<Preloader hide={!loading} className={'preloaderAccount'} />
							{children}
						</div>

						{props.cooperationSizeNone <= props.winWidth ? <Cooperation /> : ''}
					</Container>
				</Page>
			</AccountStyle>
		</ProtectRoute>
	)
})

export default withUIContext(withAccountContext(AccountMain, ['user', 'logout']), ['screenSizes', 'winWidth', 'loading'])
