import styled from 'styled-components'

const SingleNewsStyle = styled.div.attrs((props) => ({ className: props.className }))`
	width: calc(50% - var(--sp2x));
	padding: var(--sp3x);
	border: 1px solid var(--lightGrey);
	border-radius: var(--sp0-75x);
	margin-bottom: var(--sp4x);
	display: flex;

	&:nth-child(even) {
		margin-left: var(--sp2x);
	}
	&:nth-child(odd) {
		margin-right: var(--sp2x);
	}

	&:hover {
		.single-news-block {
			.imageCont {
				img {
					transform: scale(1.4);
				}
			}
		}
	}

	.single-news-block {
		display: flex;
		align-items: stretch;
		width: 100%;

		.imageCont {
			width: 50.4%;
			padding-top: 32.9%;
			border-radius: 4px;
			overflow: hidden;
			img {
				transition: all var(--trTime2) ease-in-out;
				transform: scale(1);
			}
		}

		.info {
			margin-left: var(--sp3x);
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.single-cat {
				padding: var(--spSize);
				align-self: flex-end;
				border-radius: 4px;
			}

			.news-name {
				margin: var(--sp3x) 0;
			}

			.bottom {
				display: flex;
				justify-content: space-between;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		padding: var(--sp2-5x);

		.single-news-block {
			.info {
				margin-left: var(--sp2-5x);

				.news-name {
					margin: var(--sp2-5x) 0;
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		padding: var(--sp2-5x);

		.single-news-block {
			.imageCont {
				width: 51%;
				padding-top: 33.3%;
			}

			.info {
				margin-left: var(--sp2-5x);

				.news-name {
					margin: var(--sp2-5x) 0;
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		padding: var(--sp2-5x);
		width: 100%;
		margin-bottom: var(--sp3x);

		&:nth-child(even) {
			margin-left: 0;
		}
		&:nth-child(odd) {
			margin-right: 0;
		}
		.single-news-block {
			.imageCont {
				width: 100%;
				max-width: var(--sp40x);
				padding-top: 23%;

				@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
					padding-top: 32.1%;
				}
			}

			.info {
				margin-left: var(--sp2-5x);

				.news-name {
					margin: var(--sp2-5x) 0;
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		width: 100%;
		padding: var(--sp2x);
		margin-bottom: var(--sp3x);

		&:nth-child(even) {
			margin-left: 0;
		}
		&:nth-child(odd) {
			margin-right: 0;
		}
		.single-news-block {
			flex-direction: column;

			.imageCont {
				width: 100%;
				max-width: unset;
				padding-top: 68%;
				margin-bottom: var(--sp2x);
			}

			.info {
				width: 100%;
				margin-left: 0;

				.news-name {
					margin: var(--sp3x) 0;
				}
			}
		}
	}
`
export default SingleNewsStyle
