import styled from 'styled-components'

const SliderStyle = styled.div.attrs((props) => ({ className: props.className }))`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: var(--sp98x);

	.swiper-container {
		transform: translateY(30vh);

		.swiper-wrapper {
			.swiper-slide {
				cursor: pointer;
				width: 100%;

				.imageCont {
					width: 100%;
					padding-top: 66.5%;
				}
			}
		}
	}
	.sl-1 {
		width: 100%;

		.swiper-wrapper {
			.swiper-slide {
				pointer-events: none;
			}
		}
	}

	.sl-2 {
		margin: 0 var(--sp8x) 0;
	}

	.sliderBottWrapp {
		margin-top: var(--sp2x);
	}
	.swiper-btn {
		width: var(--sp6x);
		height: var(--sp6x);
		background-color: var(--white);
		border-radius: 50%;
		box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
		font-size: var(--sp1-5x);
		display: inline-flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;
		cursor: pointer;
		color: var(--black);
		transition: var(--trTime);

		&.swiper-button-disabled {
			color: var(--darkGrey);
		}
		&.prev {
			left: 4px;
		}

		&.next {
			right: 4px;
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		max-width: var(--sp83x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		max-width: unset;

		.swiper-container {
			padding-bottom: var(--sp3x);

			.swiper-wrapper {
				.swiper-slide {
					.imageCont {
						padding-top: 49.5%;
					}
				}
			}

			.swiper-pagination {
				display: flex;
				justify-content: center;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);

				.swiper-pagination-bullet {
					width: var(--spSize);
					height: var(--spSize);
					background-color: var(--darkGrey);
					border-radius: 50%;
					cursor: pointer;
					margin: 0 var(--sp0-75x);
					transition: var(--trTime);

					&-active {
						background-color: var(--black);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		max-width: unset;

		.swiper-container {
			padding-bottom: var(--sp3x);

			.swiper-wrapper {
				.swiper-slide {
					.imageCont {
						padding-top: 62.5%;
					}
				}
			}
			.swiper-pagination {
				display: flex;
				justify-content: center;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);

				.swiper-pagination-bullet {
					width: var(--spSize);
					height: var(--spSize);
					background-color: var(--darkGrey);
					border-radius: 50%;
					cursor: pointer;
					margin: 0 var(--sp0-75x);
					transition: var(--trTime);

					&-active {
						background-color: var(--black);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		max-width: unset;

		.swiper-container {
			width: calc(100% + (2 * var(--containerPadding)) + 1px);
			transform: translateX(calc(-1 * var(--containerPadding)));
			padding-bottom: var(--sp3x);

			.swiper-wrapper {
				.swiper-slide {
					.imageCont {
						padding-top: 71.5%;
					}
				}
			}

			.swiper-pagination {
				display: flex;
				justify-content: center;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);

				.swiper-pagination-bullet {
					width: var(--spSize);
					height: var(--spSize);
					background-color: var(--darkGrey);
					border-radius: 50%;
					cursor: pointer;
					margin: 0 var(--sp0-75x);
					transition: var(--trTime);

					&-active {
						background-color: var(--black);
					}
				}
			}
		}
	}
`
export default SliderStyle
