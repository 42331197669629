import { forwardRef, useRef, useImperativeHandle } from 'react'

//* Components
import { Container, Text, Button } from 'components/common'

//* Heplers
import { config } from 'helpers'

//* Styles
import SubmissionPlansStyle from './style'

const SubmissionPlans = forwardRef((props, ref) => {
	const titleRef = useRef()
	const itemRef = useRef()
	const btnRef = useRef()

	useImperativeHandle(ref, () => [titleRef, itemRef, btnRef], [])

	return (
		<SubmissionPlansStyle>
			<Container className='submit-prod full FlexBoxColumn alignMiddle overflowHidden'>
				<Text tag={'stringToLetters'} className={'title h2 MonRegular uppercase'} text={props.compTitle} ref={titleRef} />
				<div className='plans FlexBox ' ref={itemRef}>
					<div className='single-plan FlexBoxColumn alignMiddle annual'>
						<Text tag={'p'} className={'best-price pS MonRegular capitalize'} text={'bestValue'} />
						<Text tag={'h5'} className={'h5 MonMedium capitalize'} text={'annualPlan'} />
						<Text tag={'p'} className={'desc pM MonRegular ultraDarkGrey'} text={'unlimitedProdSubmissionsFor1Year'} />
						<div className='cost FlexBox'>
							<Text tag={'h3'} className={'price h3 MonBold'} text={props.annualCost} />
						</div>
						<div className='per-month FlexBox'>
							<Text tag={'p'} className={'price p MonMedium darkGrey'} text={`~ ${props.annualMonthlyFee} `} />
							<Text tag={'p'} className={'p MonMedium darkGrey'} text={'monthlyFee'} />
						</div>
					</div>
					<div className='single-plan FlexBoxColumn alignMiddle half-year'>
						<Text tag={'h5'} className={'h5 MonMedium capitalize'} text={'halfYearPlan'} />
						<Text tag={'p'} className={'desc pM MonRegular ultraDarkGrey'} text={'unlimitedProdSubmissionsForHalfYear'} />
						<div className='cost FlexBox'>
							<Text tag={'h3'} className={'price h3 MonBold'} text={props.halfYearCost} />
						</div>
						<div className='per-month FlexBox'>
							<Text tag={'p'} className={'price p MonMedium darkGrey'} text={`~ ${props.halfYearMonthlyFee} `} />
							<Text tag={'p'} className={'p MonMedium darkGrey'} text={'monthlyFee'} />
						</div>
					</div>
				</div>
				<Button ref={btnRef} className='btn btn-black' text={'startExportingProdRegister'} link={config.routes.submitExportProduct.path} />
			</Container>
		</SubmissionPlansStyle>
	)
})

export default SubmissionPlans
