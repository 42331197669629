import styled from 'styled-components'

const ProductContentStyle = styled.div.attrs((props) => ({ className: props.className }))`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: var(--sp98x);
	margin-top: var(--sp12x);

	.about-product {
		display: flex;
		flex-direction: column;

		.title {
			margin-bottom: var(--titleFromContent);
		}
		>*{
			transform: translateY(30vh);
		}

		.text.short {
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}

	.factory-location {
		display: flex;
		flex-direction: column;
		margin-top: var(--sp12x);

		.title {
			margin-bottom: var(--titleFromContent);
		}

		.certificate {
			display: flex;
			align-items: center;
			margin-top: var(--sp12x);

			.imageCont {
				width: var(--sp4x);
				padding-top: var(--sp5-5x);
				margin-right: var(--sp2x);
			}

			p {
				flex: 1;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		max-width: var(--sp83x);

		.factory-location {
			margin-top: var(--sp10x);

			.certificate {
				margin-top: var(--sp10x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		max-width: var(--sp83x);

		.factory-location {
			margin-top: var(--sp7-5x);

			.certificate {
				margin-top: var(--sp7-5x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		max-width: var(--sp85x);
		margin-top: var(--sp10x);

		.factory-location {
			margin-top: var(--sp5x);

			.certificate {
				margin-top: var(--sp7-5x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		max-width: unset;
		margin-top: var(--sp10x);

		.factory-location {
			margin-top: var(--sp5x);

			.certificate {
				margin-top: var(--sp7-5x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		max-width: unset;
		margin-top: var(--sp7-5x);

		.factory-location {
			margin-top: var(--sp5x);

			.certificate {
				margin-top: var(--sp5x);
			}
		}
	}
`
export default ProductContentStyle
