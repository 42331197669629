import styled from 'styled-components'

const OurServicesStyle = styled.div`
	.our-services {
		.heading {
			.title {
				margin-bottom: var(--titleFromContent);
			}
		}

		.services {
			flex-wrap: wrap;
			margin-top: var(--sp12x);

			.single-service {
				width: calc(50% - var(--sp2x));
				transform:translateY(20vh);

				&:nth-child(3),
				&:nth-child(4) {
					margin-top: var(--sp12x);
				}

				.LottieWrapp {
					width: var(--sp21x);
					padding-top: var(--sp21x);
					margin-right: var(--sp5x);
					svg{
						height: auto !important;
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		.our-services {
			.services {
				margin-top: var(--sp10x);

				.single-service {
					width: calc(50% - var(--sp6x));

					&:nth-child(3),
					&:nth-child(4) {
						margin-top: var(--sp10x);
					}

					.LottieWrapp {
						margin-right: var(--sp4x);
					}
				}
			}
		}
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		.our-services {
			.services {
				margin-top: var(--sp7-5x);

				.single-service {
					width: calc(50% - var(--sp6x));

					&:nth-child(3),
					&:nth-child(4) {
						margin-top: var(--sp7-5x);
					}

					.LottieWrapp {
						margin-right: var(--sp4x);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		.our-services {
			.services {
				margin-top: var(--sp5x);

				.single-service {
					width: calc(50% - var(--sp1-5x));

					&:nth-child(3),
					&:nth-child(4) {
						margin-top: var(--sp5x);
					}

					.LottieWrapp {
						margin-right: var(--sp4x);
						width: var(--sp18x);
						padding-top: var(--sp18x);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		.our-services {
			.services {
				margin-top: var(--sp5x);

				.single-service {
					width: calc(50% - var(--sp1-5x));
					flex-direction: column;
					align-items: flex-start;

					&:nth-child(3),
					&:nth-child(4) {
						margin-top: var(--sp5x);
					}

					.LottieWrapp {
						margin-right: 0;
						margin-bottom: var(--sp1-5x);
						width: var(--sp18x);
						padding-top: var(--sp18x);
						align-self: flex-start;
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		.our-services {
			.services {
				margin-top: var(--sp5x);
				flex-direction: column;

				.single-service {
					width: 100%;
					flex-direction: column;

					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4) {
						margin-top: var(--sp7-5x);
					}

					.LottieWrapp {
						margin-right: 0;
						margin-bottom: var(--sp1-5x);
						width: var(--sp18x);
						padding-top: var(--sp18x);
					}
				}
			}
		}
	}
`

export default OurServicesStyle
