import React, { memo as Memo, useMemo } from 'react'

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer'
import { useRouter } from 'next/router'

//* Components
import { Button, Menu, SocIcon, LanguageSwitcher } from 'components/common'

//* JSON's
import config from 'helpers/_config'

//style
import MenuStyle from './style'

const MenuComponent = Memo((props) => {
	//! Routes from Config
	const routes = useMemo(() => config.routes, [])

	//! Big Menu Object
	const menuObj = useMemo(
		() => ({
			contClass: 'menu-items-cont FlexBox',
			itemClass: 'menu-item MonRegular pM black uppercase',
			items: [
				{
					name: routes.investmentProjects.name,
					path: routes.investmentProjects.path,
				},
				{
					name: routes.exportFromArmenia.name,
					path: routes.exportFromArmenia.path,
				},
				{
					name: routes.businessInArmenia.name,
					path: routes.businessInArmenia.path,
				},
				{
					name: routes.news.name,
					path: routes.news.path,
				},
				{
					name: routes.becomePartner.name,
					path: routes.becomePartner.path,
				},
				{
					name: routes.ourServices.name,
					path: routes.ourServices.path,
				}
			],
		}),
		[routes]
	)

	const menuObj2 = useMemo(
		() => ({
			contClass: 'menu-items-cont FlexBox',
			itemClass: 'menu-item pM black',
			items: [
				{
					name: routes.investmentProjects.name,
					path: routes.investmentProjects.path,
				},
				{
					name: routes.exportFromArmenia.name,
					path: routes.exportFromArmenia.path,
				},
				{
					name: routes.businessInArmenia.name,
					path: routes.businessInArmenia.path,
				},
				{
					name: routes.news.name,
					path: routes.news.path,
				},
				{
					name: routes.becomePartner.name,
					path: routes.becomePartner.path,
				},
				{
					name: routes.ourServices.name,
					path: routes.ourServices.path,
				},
				{
					name: routes.investmentsForBusiness.name,
					path: routes.investmentsForBusiness.path,
				},
				{
					name: routes.contactUs.name,
					path: routes.contactUs.path,
				},
				{
					name: routes.privacyPolicy.name,
					path: routes.privacyPolicy.path,
				},
			],
		}),
		[routes]
	)

	const router = useRouter()

	return (
		<MenuStyle>
			{props.winWidth >= 1440 ? (
				<Menu checked={props.opened} type={'nav'} onClick={props.onClick} {...menuObj} />
			) : (

				<div className={`burgerOverflowCont ${props.opened ? 'active' : ''}`}>
					<div className={`burgerCont`}>
						<div className='btnWrap FlexBox'>
							<Button className={'btn-white'} text={'submitExportProduct'} link={config.routes.whatIsaProductSubmission.path} />

							<Button className={'btn-black'} text={'SubmitInvestmentProject'} link={config.routes.investmentsForBusiness.path} />
						</div>

						{props.winWidth <= 767 && router.asPath.split("/")[1] != "thank-you-message" ? <LanguageSwitcher /> : ''}

						<Menu checked={props.opened} type={'nav'} onClick={props.onClick} {...menuObj2} />

						{props.winWidth < 1440 ? (
							<div className='contactsSetion'>
								<div className='contactInfoWrapp'>
									<a href='mailto:info@investin.am' target='_blank' rel='noopener noreferrer' className='black MonMedium pS'>
										info@investin.am
								</a>
									<a
										href='https://goo.gl/maps/V8NuzY9hX9nfVyEX8'
										target='_blank'
										rel='noopener noreferrer'
										className='black MonMedium pS'
									>
										Hanrapetutyun Str. 39, 0010 Yerevan, Armenia
								</a>
								</div>
								{props.winWidth >= 768 ? <SocIcon color={'black'} /> : ''}
							</div>
						) : (
							''
						)}
					</div>
				</div>
			)}
		</MenuStyle>
	)
})
export default withUIContext(MenuComponent, ['screenSizes', 'winWidth'])
