import { forwardRef, useRef, useImperativeHandle, useState, useCallback } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Container, Text, CustomLink, Translate } from 'components/common'
import Collapse from '@material-ui/core/Collapse'

//* Styles
import TermsStyle from './style'

const Terms = forwardRef(({ translate, ...props }, ref) => {
	const itemRef = useRef()
	const itemRef2 = useRef()

	const [hgts, setHgts] = useState([])

	const storeTerms = (arr) => {
		return arr?.map((i, k) => {
			return (
				<div className={`single-term ${hgts.includes(k) && 'active'}`} key={k}>
					<Text tag={'h4'} onClick={() => handleClick(k)} className={'title h4 MonMedium black'} text={i.title} />
					<Collapse in={hgts.includes(k)} timeout='auto'>
						<div className='text pL MonRegular black' dangerouslySetInnerHTML={{ __html: i.text }}></div>
					</Collapse>
				</div>
			)
		})
	}

	//! Toggle Height on Click
	const handleClick = useCallback(
		(index) => {
			let newArr = []

			newArr = hgts.includes(index) ? newArr.filter((index) => index !== index) : [...newArr, index]
			setHgts(newArr)
		},
		[hgts]
	)

	useImperativeHandle(ref, () => [itemRef, itemRef2], [])

	return (
		<TermsStyle>
			<Container className='terms full FlexBoxColumn alignMiddle'>
				<div className='terms-cont overflowHidden' ref={itemRef}>
					{storeTerms(props.termsArr)}
				</div>
				<div className='have-questions overflowHidden FlexBoxColumn alignMiddle' ref={itemRef2}>
					<Text tag={'h1'} className={'title h1 MonRegular'} text={'stillHaveMoreQuestions'} />
					<div className='links FlexBox'>
						<Text tag={'p'} className={'pL Monregular'}>
							<Translate val={'termsText1'} />
							&nbsp;
							<CustomLink className='underline MonMedium' url={'/privacy-policy'}>
								<Text tag={'span'} className={'pL MonMedium'} text={'termsTextLink'} />
							</CustomLink>
							<Translate val={'termsText2'} />
						</Text>
					</div>
					<div className='links FlexBox'>
						<Text tag={'p'} className={'pL Monregular'}>
							<Translate val={'contactText1'} />
							&nbsp;
							<CustomLink className='underline MonMedium' url={'/contact-us'}>
								<Text tag={'span'} className={'pL MonMedium'} text={'contactUsText'} />
							</CustomLink>
							&nbsp;
							<Translate val={'contactText2'} />
						</Text>
					</div>
				</div>
			</Container>
		</TermsStyle>
	)
})

export default withLanguageContext(Terms, ['translate'])
