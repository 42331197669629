import { memo as Memo, useMemo, useState, useRef, Fragment } from 'react'

//* HOC's
import { withUIContext, withLanguageContext } from 'context'

//* Components
import { Text, Icon, FormItem, Translate } from 'components/common'
import { FormInput } from 'antd'
import { useRouter } from 'next/router'
import Collapse from '@material-ui/core/Collapse'

//* Helpers
import { getParamsByKey } from 'helpers'

const CategoryFilter = Memo(({ queryParamKeys, types, categories, onCategoryChange, winWidth }) => {
    //! Router
    const router = useRouter()

    //! Refs
    const catBlock = useRef()

    //! States
    const [opened, setOpened] = useState(true)
    const [activeProdTypesInTablet, setActiveProdTypesInTablet] = useState('')

    //! Get Active Category Id
    const activeCategoryId = useMemo(() => {
        const catParam = queryParamKeys[0] //? "cat_ids"
        const activeItem = getParamsByKey(router, catParam)?.[0]

        activeItem && catBlock.current?.scrollTo({ top: 0 })

        return activeItem
    }, [queryParamKeys, router])

    //! Get Types Filter Selected and Free Items
    const getSelectedAndFreeTypes = useMemo(() => {
        const catParam = queryParamKeys[4] //? "type_ids"
        const activeTypes = getParamsByKey(router, catParam)
        const selectedTypesList = []
        const freeTypesList = []

        types?.[activeCategoryId]?.forEach((t) => {
            if (!activeTypes?.includes(`${t.id}`)) {
                freeTypesList.push(
                    <Text
                        onClick={() => onCategoryChange(catParam, t.id)}
                        tag={'p'}
                        className={'single-prod-type cursorPointer pM MonRegular'}
                        key={t.id}
                        text={t.name}
                    />
                )
            } else if (activeTypes) {
                selectedTypesList.push(
                    <div className='single-sel-prod-type FlexBox alignMiddle spaceBetween' key={t.id}>
                        <Text tag={'p'} className={'pM MonRegular'} text={t.name} />
                        <Icon className='icon-close cursorPointer' onClick={() => onCategoryChange(catParam, t.id)} />
                    </div>
                )
            }
        })

        return { selectedTypesList, freeTypesList, activeTypes }
    }, [types, queryParamKeys, router])

    //! Types Filter
    const storeTypes = useMemo(() => {
        const catParam = queryParamKeys[0] //? "cat_ids"
        const { selectedTypesList, freeTypesList } = getSelectedAndFreeTypes

        return (
            <div className={`prod-types FlexBoxColumn active`}>
                {winWidth < 1024 && (
                    <>
                        <Text
                            tag={'h5'}
                            onClick={() => onCategoryChange(catParam, activeCategoryId, false, queryParamKeys[4])}
                            className={'head-title h5 MonRegular darkGrey uppercase FlexBox cursorPointer'}
                        >
                            <Icon className='icon-arrow-left' />
                            <Translate val={'prodType'} />
                        </Text>
                        <div className='selected-prods'>{selectedTypesList}</div>
                    </>
                )}

                {freeTypesList}
            </div>
        )
    }, [types, activeCategoryId, router, winWidth, getSelectedAndFreeTypes])

    //! Categories Filter With Types Filter
    const storeCategories = useMemo(() => {
        const catParam = queryParamKeys[0] //? "cat_ids"

        return (
            <>
                {getSelectedAndFreeTypes.selectedTypesList.length > 0 && (
                    <div className='selected-prod-types FlexBoxColumn'>{getSelectedAndFreeTypes.selectedTypesList}</div>
                )}

                <div ref={catBlock} className={`cat-block FlexBoxColumn ${activeCategoryId ? 'hidden' : ''}`}>
                    {winWidth < 1024 && (
                        <Text
                            tag={'p'}
                            onClick={() => setActiveProdTypesInTablet('')}
                            className={'head-title pM MonRegular darkGrey uppercase FlexBox cursorPointer'}
                        >
                            <Icon className='icon-arrow-left' />
                            <Translate val={'ProdCategory'} />
                        </Text>
                    )}

                    {categories.map((item) => {
                        return (
                            <Fragment key={item.id}>
                                {item.id == activeCategoryId && storeTypes}
                                <div className={`single-cat cursorPointer ${item.id == activeCategoryId ? 'active' : ''}`}>
                                    <Text
                                        onClick={() => onCategoryChange(catParam, item.id, false, queryParamKeys[4])}
                                        tag={'p'}
                                        className={`pM MonRegular`}
                                        text={item.name}
                                    />
                                </div>
                            </Fragment>
                        )
                    })}
                </div>
            </>
        )
    }, [categories, activeCategoryId, getSelectedAndFreeTypes, onCategoryChange, winWidth])

    return (
        <div className={`single-filter overflowHidden ${opened ? 'active' : ''}`}>
            {/* Selected Category Name */}
            {activeCategoryId && (
                <div className='selected-cat FlexBoxColumn cursorPointer'>
                    <div className='back-to' onClick={() => onCategoryChange(queryParamKeys[0], activeCategoryId, false, queryParamKeys[4])}>
                        <Icon className='icon-arrow-left ultraDarkGrey' />
                        <Text tag={'p'} className={'pM MonRegular ultraDarkGrey'} text={'allCats'} />
                    </div>
                    <Text tag={'p'} className={'pL MonRegular'} text={categories.find((cat) => cat.id == activeCategoryId).name} />
                </div>
            )}

            {winWidth > 1023 ? (
                <>
                    <Text
                        onClick={() => setOpened(!opened)}
                        tag={'h5'}
                        className={'title h5 MonMedium black'}
                        text={`${activeCategoryId ? 'prodType' : 'ProductCategory'}`}
                    />
                    <Collapse in={opened} timeout='auto'>
                        {storeCategories}
                    </Collapse>
                </>
            ) : (
                <>
                    <Text tag={'h5'} onClick={() => setActiveProdTypesInTablet('active')} className={'title tablet h5 MonMedium black'}>
                        <Translate val={'ProductCategory'} />
                        <Icon className='icon-arrow-right' />
                    </Text>
                    <div className={`filter-in-tablet-mode ${activeProdTypesInTablet}`}>{storeCategories}</div>
                </>
            )}
        </div>
    )
})

export default withLanguageContext(withUIContext(CategoryFilter, ['winWidth']), ['translate'])
