import styled from 'styled-components'

const NewsInHomeStyle = styled.div`
	.read-more {
		.read-more-cont {
			max-width: var(--sp82x);
			width: 100%;

			.description {
				margin: var(--titleFromContent) 0 var(--sp7x);
			}
		}

		.news {
			flex: 1;
			margin-left: var(--sp4x);

			.single-news {
				width: 100%;
				margin-left: 0;
				margin-right: 0;
				background-color: var(--ultraLightGrey);
				border: none;
				transform: translateY(15vh);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		.read-more {
			.read-more-cont {
				max-width: var(--sp68x);

				.description {
					margin-bottom: var(--sp6x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		.read-more {
			flex-direction: column;
			.read-more-cont {
				max-width: unset;
				flex-flow: row wrap;

				.title {
					max-width: var(--sp42x);
					width: 100%;
					margin-bottom: var(--sp2-5x);
				}

				.description {
					margin-top: 0;
					margin-bottom: 0;
					width: calc(100% - var(--sp42x));
					padding-left: var(--sp3x);
				}
			}

			.news {
				margin-left: 0;
				margin-top: var(--sp5x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
		.read-more {
			flex-direction: column;
			.read-more-cont {
				max-width: unset;

				.title {
					max-width: var(--sp64x);
					width: 100%;
				}

				.description {
					margin-bottom: var(--sp4x);
					width: 100%;

					@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
						margin-bottom: var(--sp3x);
					}
				}
			}

			.news {
				margin-left: 0;
				margin-top: var(--sp5x);
			}
		}
	}
`
export default NewsInHomeStyle
