import styled from 'styled-components'

const PreloaderStyle = styled.section.attrs('')`
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 12;
	background-color: var(--white);

	&.preloaderProductProject{
		position: sticky;
		top:30vh;
	}
	&.preloaderAccount {
		position: absolute;
		section {
			height: 100%;
		}
	}
	&.preloaderHome {
		position: fixed;
		z-index: 2000;
		section {
			height: 100vh;
		}
	}
	&.hide {
		transition: all var(--trTime) ease-in-out;
		pointer-events: none;
		top: 100%;
		bottom: -100%;
	}
	.LottieWrapp {
		padding: 8% 30%;
		z-index: 2;
	}
`
export default PreloaderStyle
