import React, { memo as Memo, useCallback, useMemo, useState } from 'react'

//* HOCs
import { withLanguageContext, withUIContext, withAccountContext, withDataContext } from 'context'

//* Components
import { Text, Translate, SuccessMessage, FormItem, FormContainer, FormInput, Icon } from 'components/common'
import { Button, Checkbox, Select } from 'antd'

//* Styles
import SubscribeStyle from './style'
import FormStyle from 'styles/pages/FormStyle'

const Subscribe = Memo(({ global, selectedLang, openRegisterPopup, subscribeNewsletter, ...props }) => {
    //! States
    const [identifyRole, setIdentifyRole] = useState()
    const [success, setSuccess] = useState(false)

    //! Global Data
    const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang])

    //! List Values
    const identifyRoles = useMemo(() => ['investor', 'investmentFund', 'distributor', 'wholesaleBuyer', 'general'], [])

    const listOneRoles = useMemo(() => ['investor', 'investmentFund'], [])
    const listSecondRoles = useMemo(() => ['distributor', 'wholesaleBuyer'], [])

    const [type, interestedList] = useMemo(() => {
        const resp = []

        if (listOneRoles.includes(identifyRole)) {
            resp[0] = 'firstGroup'
            resp[1] = ['IT', 'Manufacturing', 'Construction', 'Agriculture', 'Tourism', 'HORECA', 'RenewableEnergy', 'Other'];
        }
        else if (listSecondRoles.includes(identifyRole)) {
            resp[1] = [
                'alcoholCigarettes',
                'juicesAndDrinks',
                'groceryDairyProducts',
                'sweets',
                'fruitsVegetables',
                'meatFishProducts',
                'cannedFood',
                'householdProducts',
                'beautyCare',
                'healthcare',
                'clothingTextile',
                'metalProducts',
                'nonMetallicMineralProducts',
                'plasticAndRubberProducts',
                'shoesAccessories',
                'preciousMetalItems',
                'electronicEquipment',
                'Other'
            ]
        }

        return resp
    }, [identifyRole])

    const interestedToInvest = useMemo(() => ['<$100.000', '$100.000 – $700.000', '$700.000 – $3.000.000', '> $3.000.000 '], [])

    //! On Form Change
    const onChange = useCallback((val) => {
        const fieldName = Object.keys(val)[0]
        fieldName === 'identify_role' && setIdentifyRole(val[fieldName])
    }, [])

    //! On Form Submit
    const onFinish = useCallback((values) => {
        setSuccess('loading')
        return subscribeNewsletter(values).then((res) => {
            setSuccess(true)
        }).catch(() => {
            setSuccess(false)
        })
    }, [])

    return (
        <SubscribeStyle className={`subscribe-cont ${success === 'loading' ? 'loading' : ''}`}>

            {(success === 'loading' || !success) ? (
                <>
                    <Text tag={'div'} className={' Title h4 MonMedium black'} text={'subscribeBtnText'} />

                    <div className='SignInFormWrapp'>
                        <FormStyle>
                            <FormContainer layout={'vertical'} id='subscribeNewsletter' onChange={onChange} onFinish={onFinish} className={'FormRow FlexBoxContainer spaceBetween'}>

                                <div className='FormCol FlexBox AlignEnd FormCol-50'>
                                    <FormItem name={'name'} required={true} >
                                        <FormInput placeholder={props.translate('name') + '*'} />
                                    </FormItem>
                                </div>

                                <div className='FormCol FlexBox AlignEnd FormCol-50'>
                                    <FormItem name={'surname'} required={true}>
                                        <FormInput placeholder={props.translate('surname') + '*'} />
                                    </FormItem>
                                </div>

                                <div className='FormCol FlexBox AlignEnd FormCol-50'>
                                    <FormItem name={'email'} required={true} validationType='email'>
                                        <FormInput placeholder={props.translate('Email') + '*'} />
                                    </FormItem>
                                </div>

                                <div className={`FormCol FormCol-50`}>
                                    <FormItem name={'country'} required>
                                        <Select
                                            virtual={false}
                                            suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />}
                                            placeholder={props.translate('Country') + "*"}
                                            getPopupContainer={trigger => trigger.parentNode}
                                            allowClear
                                        >
                                            {globalData.countries?.map((i, k) => (
                                                <Select.Option key={k} value={i.name}>
                                                    {i.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </FormItem>
                                </div>

                                <div className='FormCol FlexBox AlignEnd FormCol-50'>
                                    <FormItem name={'affiliated_organization'} required >
                                        <FormInput placeholder={props.translate('affiliatedOrganization') + '*'} />
                                    </FormItem>
                                </div>

                                <div className='FormCol FlexBox AlignEnd FormCol-50'>
                                    <FormItem name={'webpage'} required>
                                        <FormInput placeholder={props.translate('webpage') + '*'} />
                                    </FormItem>
                                </div>

                                <div className='FormCol FlexBox AlignEnd FormCol-50'>
                                    <FormItem name={'mobile_number'} required>
                                        <FormInput type="number" placeholder={props.translate('MobileNumber') + "*"} />
                                    </FormItem>
                                </div>

                                <div className={`FormCol FormCol-50`}>
                                    <FormItem name={'identify_role'} required>
                                        <Select
                                            virtual={false}
                                            suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />}
                                            placeholder={props.translate('identifyAs') + "*"}
                                            getPopupContainer={trigger => trigger.parentNode}
                                            allowClear
                                        >
                                            {identifyRoles.map((text, k) => (
                                                <Select.Option key={k} value={text}>
                                                    {props.translate(text)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </FormItem>
                                </div>

                                {interestedList && (
                                    <div className={'FormCol FormCol'}>
                                        <FormItem label={'interestedIn'} name={'interestedIn'} required>
                                            <Checkbox.Group>
                                                {interestedList.map((text, k) => (
                                                    <Checkbox key={k} value={text}>
                                                        <Text tag={'p'} className={'pM MonRegular ultraDarkGrey '} text={text} />
                                                    </Checkbox>
                                                ))}
                                            </Checkbox.Group>
                                        </FormItem>
                                    </div>
                                )}

                                {type && (
                                    <div className={'FormCol FormCol FormRow'}>
                                        <FormItem label={'interestedToInvest'} name={'interested_to_invest'} required>
                                            <Checkbox.Group>
                                                {interestedToInvest.map((val, k) => (
                                                    <Checkbox value={val} key={k}>
                                                        <Text tag={'p'} className={'pM MonRegular ultraDarkGrey '} text={val} />
                                                    </Checkbox>
                                                ))}
                                            </Checkbox.Group>
                                        </FormItem>
                                    </div>
                                )}


                                <div className='formBtnWrapp'>
                                    <Button htmlType={'Submit'} className={'btn btn-black btn-max-width'}>
                                        <Translate val={'subscribeBtnText'} />
                                    </Button>
                                </div>

                            </FormContainer>
                        </FormStyle>
                    </div>
                </>
            ) : (
                <SuccessMessage title='subscribeThankYouTitle' text='subscribeThankYouText' />
            )}

        </SubscribeStyle>
    )
})

export default withDataContext(
    withLanguageContext(
        withUIContext(Subscribe,
            ['winWidth', 'screenSizes']
        ),
        ['translate', 'selectedLang']
    ),
    ['global', 'subscribeNewsletter']
)
