import { memo as Memo } from 'react'

//* HOC's
import { withUIContext, withLanguageContext } from 'context'

//* Config
import { config } from 'helpers'

//* Components
import { Text, Image, Button } from 'components/common'
import { ProductCatalogRow, ProductRow } from 'components/pages'

const SubmittedProducts = Memo(({ data, ...props }) => {
	return (
		<>
			{data?.length ? (
				<>
					{data.map((catalog, key) => {
						return (
							<div className='SubmittedProductsWrapp' key={key}>
								<ProductCatalogRow catalog={catalog} />

								{catalog.products && <ProductRow products={catalog.products} catId={catalog.id} />}
								<div className='addBtnWrapper'>
									<Button
										link={`/products/${catalog.id}/create`}
										icon2={'icon-plus'}
										className={'btn-white'}
										text={'AddNewProduct'}
									/>
								</div>
							</div>
						)
					})}
				</>
			) : (
				<div className='FlexBox alignCenter'>
					<div className='noDataWrap FlexBoxColumn alignMiddle'>
						<Image src={'/images/svg/noSubmissions.svg'} alt={props.translate('NOSubmissionsYET')} />
						<Text tag={'h5'} className={'MonMedium black '} text={'NOSubmissionsYET'} />
						<Button className={'btn-black'} text={'submitExportProduct'} link={config.routes.submitExportProduct.path} />
					</div>
				</div>
			)}
		</>
	)
})

export default withUIContext(withLanguageContext(SubmittedProducts, ['translate']), ['screenSizes', 'winWidth'])
