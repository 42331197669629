import styled from 'styled-components'


const LogoStyle = styled.div`

.Logo{
    --logoSize:var(--sp15x);
    --logoMargRight:var(--sp8x);
    
    display:flex;
    max-width:var(--logoSize);
    margin-right:var(--logoMargRight);
    img{
        width:100% !important;
        height: auto;
    }

    @media only screen and (max-width: ${props => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${props => props.theme.mediaQuery.DesktopSizeS}) {
        --logoMargRight:var(--sp15x);
    }
    @media only screen and (max-width: ${props => props.theme.mediaQuery.DesktopSizeMMin}) {
        --logoMargRight:var(--sp9x);
    }

    @media only screen and (max-width: ${props => props.theme.mediaQuery.DesktopSizeSMin}) {
        --logoSize:var(--sp11x);
        --logoMargRight:var(--sp5x);
    }

    @media only screen and (max-width: ${props => props.theme.mediaQuery.TabletSizeMin}) {
        --logoMargRight:0;
    }
}

`

export default LogoStyle