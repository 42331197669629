import React, { useEffect, useCallback, useState, forwardRef, useRef, useImperativeHandle } from 'react'
import { Container, Button, Text, LottieAnimation } from 'components/common'
import OurServicesStyle from './style'

import animationJSON1 from 'lottieAnimation/home/services/anime1.json'
import animationJSON2 from 'lottieAnimation/home/services/anime2.json'
import animationJSON3 from 'lottieAnimation/home/services/anime3.json'
import animationJSON4 from 'lottieAnimation/home/services/anime4.json'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
gsap.core.globals('ScrollTrigger', ScrollTrigger)

const OurServices = forwardRef((props, ref) => {
    const titleRef = useRef()
    const btnRef = useRef()
    const imgRef = useRef()

    useImperativeHandle(ref, () => [titleRef.current.childNodes, btnRef, imgRef.current.childNodes], [])

    const [playAnime, setPlayAnime] = useState({
        sec1: true,
        sec2: true,
        sec3: true,
        sec4: true,
    })

    const sectionAnime = useCallback(() => {

        let newObj = playAnime
        const elTop = imgRef?.current?.getBoundingClientRect()
        const pointStart = window.innerHeight

        if (elTop?.top < pointStart) {
            Object.values(playAnime).forEach((element, i) => {
                newObj = { ...newObj, [`sec${i + 1}`]: false }
            })
            setPlayAnime(newObj)
        }

    }, [playAnime])

    useEffect(() => {
        window.addEventListener('scroll', sectionAnime)

        return () => {
            window.removeEventListener('scroll', sectionAnime)
        }
    }, [sectionAnime])

    return (
        <OurServicesStyle>
            <Container className='our-services FlexBoxColumn'>
                <div className='heading FlexBoxColumn'>
                    <Text tag={'stringToLetters'} className={'title h1 MonBold black'} text={props.title} ref={titleRef} />
                    <Button link={props.url} wrappClassName={'otherElementAnimation'} yellowIcon={true} text={'moreAboutServices'} ref={btnRef} />
                </div>
                <div className='services FlexBox spaceBetween ' ref={imgRef}>
                    <div className='single-service FlexBox alignMiddle'>
                        <LottieAnimation animationJSON={animationJSON1} loop={false} isStopped={playAnime.sec2} />
                        <Text tag={'h3'} className={'h3 MonMedium'} text={props.service1} />
                    </div>
                    <div className='single-service FlexBox alignMiddle'>
                        <LottieAnimation animationJSON={animationJSON2} loop={false} isStopped={playAnime.sec2} />
                        <Text tag={'h3'} className={'h3 MonMedium'} text={props.service2} />
                    </div>
                    <div className='single-service FlexBox alignMiddle'>
                        <LottieAnimation animationJSON={animationJSON3} loop={false} isStopped={playAnime.sec2} />
                        <Text tag={'h3'} className={'h3 MonMedium'} text={props.service3} />
                    </div>
                    <div className='single-service FlexBox alignMiddle'>
                        <LottieAnimation animationJSON={animationJSON4} loop={false} isStopped={playAnime.sec2} />
                        <Text tag={'h3'} className={'h3 MonMedium'} text={props.service4} />
                    </div>
                </div>
            </Container>
        </OurServicesStyle>
    )
})

export default OurServices
