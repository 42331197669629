import styled from 'styled-components'

const CatalogsStyle = styled.div.attrs({ className: 'catalogs' })`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-left: var(--sp4x);

	.sort-by {
		align-self: flex-end;
		display: flex;
		align-items: center;
		margin-bottom: var(--sp3x);
		position: relative;
		z-index: 1;

		.title {
			margin-right: var(--sp2x);
			display: flex;
			align-items: center;
			transform: translateY(20vh);
		}

		.sort-block {
			position: relative;
			transform: translateY(20vh);
			.selected-option {
				display: flex;
				align-items: center;
				cursor: pointer;

				&.active {
					i {
						transform: rotate(180deg);
					}
				}

				i {
					margin-left: var(--sp3x);
					font-size: var(--sp2x);
					color: var(--darkGrey);
					transition: var(--trTime2);
				}
			}

			.sort-options {
				position: absolute;
				top: calc(100% + var(--spSize));
				right: 0;
				width: max-content;
				background-color: var(--white);
				z-index: 2;
				border-radius: 4px;
				box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.16);

				.option {
					padding: var(--sp2x);
					transition: var(--trTime);
					cursor: pointer;
					border-radius: 4px;

					&:hover {
						background-color: var(--ultraLightGrey);
					}
				}
			}
		}
	}

	.catalogs-block {
		display: flex;
		align-content: flex-start;
		align-items: stretch;
		flex-flow: row wrap;
		height: 100%;

		.single-catalog {
			width: calc(33.333% - (var(--sp8x) / 3));
			margin-bottom: var(--sp4x);
			transform: translateY(20vh);

			&:nth-child(3n) {
				margin-left: var(--sp2x);
			}

			&:nth-child(3n-1) {
				margin-left: var(--sp2x);
				margin-right: var(--sp2x);
			}

			&:nth-child(3n-2) {
				margin-right: var(--sp2x);
			}
		}
	}

	.pagination {
		margin-top: var(--sp3x);
		display: flex;
		align-self: flex-end;
		align-items: center;

		li {
			margin-left: var(--sp4x);

			&.active {
				a {
					text-decoration: underline;
				}
			}

			&.disabled {
				a {
					i {
						color: var(--darkGrey);
					}
				}
			}

			&:first-child,
			&:last-child {
				display: none;
			}

			a {
				display: flex;
				justify-content: center;
				align-items: center;
				font-family: MonRegular, MonRegularRu;
				font-size: var(--pL);

				&.prev,
				&.next {
					width: var(--sp6x);
					height: var(--sp6x);
					background-color: var(--white);
					border-radius: 50%;
					box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);

					i {
						font-size: var(--sp1-5x);
					}
				}

				&.prev {
					i {
						transform: translateX(-2px);
					}
				}

				&.next {
					i {
						transform: translateX(2px);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		.pagination {
			margin-top: var(--sp2x);

			li {
				a {
					&.prev,
					&.next {
						width: var(--sp5-5x);
						height: var(--sp5-5x);
					}

					&.prev {
						i {
							transform: translateX(0);
						}
					}

					&.next {
						i {
							transform: translateX(0);
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		margin-left: var(--sp3x);

		.sort-by {
			margin-bottom: var(--sp3x);
		}

		.catalogs-block {
			.single-catalog {
				width: calc(50% - var(--sp1-5x));
				margin-bottom: var(--sp3x);

				&:nth-child(3n) {
					margin-left: unset;
				}

				&:nth-child(3n-1) {
					margin-left: unset;
					margin-right: unset;
				}

				&:nth-child(3n-2) {
					margin-right: unset;
				}

				&:nth-child(2n) {
					margin-left: var(--sp1-5x);
				}

				&:nth-child(2n-1) {
					margin-right: var(--sp1-5x);
				}
			}
		}

		.pagination {
			margin-top: var(--spSize);

			li {
				a {
					&.prev,
					&.next {
						width: var(--sp5-5x);
						height: var(--sp5-5x);
					}

					&.prev {
						i {
							transform: translateX(0);
						}
					}

					&.next {
						i {
							transform: translateX(0);
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		margin-left: 0;

		.btn-action {
			width: calc(50% - var(--sp1-5x));
			margin-bottom: var(--sp2-5x);
			align-self: flex-end;

			.sort-btn {
				width: 100%;
				padding: var(--sp2x) var(--sp6x);
				background-color: var(--ultraLightGrey);
				border: 1px solid var(--darkGrey);
				border-radius: 4px;

				span {
					display: flex;
					justify-content: space-between;
					align-items: center;

					i {
						font-size: var(--sp2x);
						color: var(--darkGrey);
					}
				}
			}
		}

		.sort-by {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100vw;
			height: 100vh;
			overflow-x: hidden;
			overflow-y: auto;
			z-index: -1;
			display: none;
			pointer-events: none;
			background-color: var(--white);
			padding-left: var(--containerPadding);
			padding-right: var(--containerPadding);
			padding-bottom: var(--sp25x);
			margin-top: 0;
			margin-bottom: 0;

			&.active {
				pointer-events: all;
				display: block;
				z-index: 100;
			}

			.head-title {
				padding: var(--sp3x) 0;
				position: relative;
				text-align: center;
				margin-bottom: var(--sp3x);

				&:before {
					content: '';
					position: absolute;
					bottom: 0;
					left: calc(-1 * var(--containerPadding));
					width: calc(100% + (2 * var(--containerPadding)));
					height: 1px;
					background-color: var(--darkGrey);
				}
			}

			.sort-options {
				display: flex;
				flex-direction: column;

				.option {
					margin-bottom: var(--sp3x);
				}
			}

			.btns {
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: var(--sp18x);
				display: flex;
				justify-content: space-between;
				padding-left: var(--containerPadding);
				padding-right: var(--containerPadding);
				background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);

				.btn {
					width: 100%;
				}
			}
		}

		.catalogs-block {
			.single-catalog {
				width: calc(50% - var(--sp1-5x));
				margin-bottom: var(--sp3x);

				&:nth-child(3n) {
					margin-left: unset;
				}

				&:nth-child(3n-1) {
					margin-left: unset;
					margin-right: unset;
				}

				&:nth-child(3n-2) {
					margin-right: unset;
				}

				&:nth-child(2n) {
					margin-left: var(--sp1-5x);
				}

				&:nth-child(2n-1) {
					margin-right: var(--sp1-5x);
				}
			}
		}

		.pagination {
			margin-top: var(--spSize);

			li {
				a {
					&.prev,
					&.next {
						width: var(--sp5-5x);
						height: var(--sp5-5x);
					}

					&.prev {
						i {
							transform: translateX(0);
						}
					}

					&.next {
						i {
							transform: translateX(0);
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		margin-left: 0;

		.btn-action {
			width: auto;
			margin-bottom: var(--sp2x);
			align-self: flex-end;

			.sort-btn {
				width: unset;
				padding: var(--sp1-5x) var(--sp3x);
				background-color: var(--ultraLightGrey);
				border: 1px solid var(--darkGrey);
				border-radius: 4px;

				span {
					display: flex;
					justify-content: space-between;
					align-items: center;

					i {
						font-size: var(--sp2x);
						color: var(--darkGrey);
						margin-left: var(--sp1-5x);
					}
				}
			}
		}

		.sort-by {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100vw;
			height: 100vh;
			overflow-x: hidden;
			overflow-y: auto;
			z-index: -1;
			display: none;
			pointer-events: none;
			background-color: var(--white);
			padding-left: var(--containerPadding);
			padding-right: var(--containerPadding);
			padding-bottom: var(--sp25x);
			margin-top: 0;
			margin-bottom: 0;

			&.active {
				pointer-events: all;
				display: block;
				z-index: 100;
			}

			.head-title {
				padding: var(--sp3x) 0;
				position: relative;
				text-align: center;
				margin-bottom: var(--sp3x);

				&:before {
					content: '';
					position: absolute;
					bottom: 0;
					left: calc(-1 * var(--containerPadding));
					width: calc(100% + (2 * var(--containerPadding)));
					height: 1px;
					background-color: var(--darkGrey);
				}
			}

			.sort-options {
				display: flex;
				flex-direction: column;

				.option {
					margin-bottom: var(--sp3x);
				}
			}

			.btns {
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: var(--sp18x);
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
				padding-left: var(--containerPadding);
				padding-right: var(--containerPadding);
				background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);

				.btn-action {
					width: calc(50% - var(--sp0-75x));

					.btn {
						width: 100%;
					}
				}
			}
		}

		.catalogs-block {
			.single-catalog {
				width: 100%;
				margin-bottom: var(--sp2x);

				&:nth-child(3n) {
					margin-left: unset;
				}

				&:nth-child(3n-1) {
					margin-left: unset;
					margin-right: unset;
				}

				&:nth-child(3n-2) {
					margin-right: unset;
				}
			}
		}

		.pagination {
			margin-top: 0;

			li {
				a {
					&.prev,
					&.next {
						width: var(--sp5-5x);
						height: var(--sp5-5x);
					}

					&.prev {
						i {
							transform: translateX(0);
						}
					}

					&.next {
						i {
							transform: translateX(0);
						}
					}
				}
			}
		}
	}
`
export default CatalogsStyle
