import React, { memo as Memo } from 'react'
import { Text, CustomLink, Image, Button } from 'components/common'

//* Config
import { config } from 'helpers'

//* HOC's
import { withUIContext, withLanguageContext } from 'context'

const Submissions = Memo(({ data, ...props }) => {
	return (
		<>
			{data?.length > 0 ? (
				props.winWidth >= 768 ? (
					<>
						<div className='tableCont'>
							<div className='tableWrap'>
								<div className='tableTitlesWrap FlexBoxContainer spaceBetween'>
									<div className='tableItemTitles'>
										<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'ApplicationDate'} />
									</div>
									<div className='tableItemTitles'>
										<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'ApplicationName'} />
									</div>
									<div className='tableItemTitles'>
										<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'ApplicationNumber'} />
									</div>
									<div className='tableItemTitles'>
										<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'ApplicationCategory'} />
									</div>
								</div>
								{data.map((item, ind) => {
									return (
										<div className='tableItemWrapp FlexBoxContainer spaceBetween' key={ind}>
											<div className='tableItem'>
												<Text tag={'p'} className={'pL MonRegular black '} text={item.date} />
											</div>
											<div className='tableItem'>
												{item.status == 'approved' ? (
													<CustomLink
														className={'pL MonMedium black underline'}
														url={`/investment-projects/${item?.slug}`}
														content={item.name}
													/>
												) : (
													<Text tag={'p'} className={'pL MonMedium black '} text={item.name} />
												)}
											</div>
											<div className='tableItem'>
												<Text tag={'p'} className={'pL MonRegular black '} text={item.submission_number} />
											</div>
											<div className='tableItem'>
												{item.status == 'pending' ? (
													<CustomLink url={`/edit-project/${item.id}`}>
														<Text tag={'p'} className={'pL MonRegular black '} text={`${item.status}Status`} />
													</CustomLink>
												) : (
													<Text tag={'p'} className={'pL MonRegular black '} text={`${item.status}Status`} />
												)}
											</div>
										</div>
									)
								})}
							</div>
						</div>
						<div className='warningWrap'>
							<Text tag={'p'} className={'pM MonRegular darkGrey '} text={'ApplicationWarning'} />
						</div>
					</>
				) : (
					data.map((item, ind) => {
						return (
							<div className='tableItemWrapp FlexBoxContainer' key={ind}>
								<div className='tableItem'>
									<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'ApplicationDate'} />
								</div>
								<div className='tableItem'>
									<Text tag={'p'} className={'pL MonRegular black '} text={item.date} />
								</div>

								<div className='tableItem'>
									<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'ApplicationName'} />
								</div>
								<div className='tableItem'>
									<Text tag={'p'} className={'pL MonMedium black '} text={item.name} />
								</div>

								<div className='tableItem'>
									<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'ApplicationNumber'} />
								</div>
								<div className='tableItem'>
									<Text tag={'p'} className={'pL MonRegular black '} text={item.submission_number} />
								</div>

								<div className='tableItem'>
									<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'ApplicationCategory'} />
								</div>
								<div className='tableItem'>
									{item.status == 'pending' ? (
										<CustomLink
											className={'pL MonMedium black underline'}
											url={`/edit-project/${item.id}`}
											content={item.status}
										/>
									) : (
										<Text tag={'p'} className={'pL MonRegular black '} text={item.status} />
									)}
								</div>
							</div>
						)
					})
				)
			) : (
				<div className='FlexBox alignCenter'>
					<div className='noDataWrap FlexBoxColumn alignMiddle'>
						<Image src={'/images/svg/noSubmissions.svg'} alt={props.translate('NOSubmissionsYET')} />
						<Text tag={'h5'} className={'MonMedium black '} text={'NOSubmissionsYET'} />
						<Button className={'btn-black'} text={'SubmitInvestmentProject'} link={config.routes.submitInvestmentProject.path} />
					</div>
				</div>
			)}
		</>
	)
})

export default withUIContext(withLanguageContext(Submissions, ['translate']), ['screenSizes', 'winWidth'])
