import styled from 'styled-components'

const CatalogViewStyle = styled.div.attrs((props) => ({ className: props.className }))`
	section {
		display: flex;
		flex-direction: column;
		align-items: center;

		.top {
			align-items: flex-start;
			align-self: flex-start;

			&.notHome .top-title {
				max-width: var(--sp115x);
				width: 100%;
				margin-bottom: var(--titleFromContent);
			}

			& > .comp-title {
				width: calc(50% - var(--sp2x));
			}
		}

		.bottom {
			margin: var(--sp12x) 0 var(--sp7x);
			display: flex;
			justify-content: flex-start;
			width: 100%;
			.single-catalog {
				width: calc(25% - var(--sp3x));
				transform: translateY(30vw);
				
				&:not(:last-child) {
					margin-right: var(--sp4x);
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		section {
			.top {
				&.notHome .top-title {
					max-width: var(--sp97x);
				}
			}

			.bottom {
				margin: var(--sp10x) 0 var(--sp6x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		section {
			padding: 0 var(--sp5x);

			.top {
				&.notHome .top-title {
					max-width: var(--sp97x);
				}
			}

			.bottom {
				margin: var(--sp7-5x) 0 var(--sp5x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		section {
			padding: 0 var(--sp5x);

			.top {
				&.notHome .top-title {
					max-width: var(--sp88x);
				}

				& > .comp-title {
					width: calc(35% - var(--sp2x));

					&.home-text {
						width: calc(65% - var(--sp2x));
					}
				}
			}

			.bottom {
				margin: var(--sp5x) 0 var(--sp4x);

				.single-catalog {
					margin-right: var(--sp3x);
					width: calc(50% - var(--sp2x));

					&:nth-child(3) {
						margin-right: 0;
					}

					&:nth-child(4) {
						display: none;
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		section {
			padding: 0 var(--sp5x);

			.top {
				flex-direction: column;

				&.notHome .top-title {
					max-width: unset;
				}

				& > .comp-title {
					width: 100%;

					&.home-text {
						margin-top: var(--sp2-5x);
					}
				}
			}

			.bottom {
				margin: var(--sp5x) 0 var(--sp4x);

				.single-catalog {
					margin-right: var(--sp3x);
					width: calc(50% - var(--sp1-5x));

					&:nth-child(2) {
						margin-right: 0;
					}

					&:nth-child(3),
					&:nth-child(4) {
						display: none;
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		section {
			.top {
				flex-direction: column;

				&.notHome .top-title {
					max-width: unset;
				}

				& > .comp-title {
					width: 100%;

					&.home-text {
						margin-top: var(--sp2x);
					}
				}
			}

			.bottom {
				margin: var(--sp5x) 0 var(--sp3-5x);
				flex-direction: column;

				.single-catalog {
					margin-right: 0;
					width: 100%;

					&:not(:last-child) {
						margin-bottom: var(--sp2x);
					}

					&:nth-child(4) {
						display: none;
					}
				}
			}
		}
	}
`
export default CatalogViewStyle
