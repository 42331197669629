import React, { useMemo, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { Container } from 'components/common'
import { Text, Image, Button } from 'components/common';
import MainSectionStyle from './style'
import SingleServiceStyle from './styleItem';

//* Config
import { config } from 'helpers'


const MainSection = forwardRef((props, ref) => {

	const itemRef = useRef([]);

	const titleRef = useRef([]);
	const descriptionRef = useRef([]);
	const brtRef = useRef([]);
	const imgRef = useRef([]);

	let arr = []

	useEffect(() => {
		titleRef.current.map((i, k) => {
			if (titleRef.current.length - 1 !== k) {
				return (
					arr.push([i.childNodes[0].childNodes, descriptionRef.current[k], brtRef.current[k], imgRef.current[k]])
				)
			}
			else {
				return (
					arr.push([i.childNodes[0].childNodes, descriptionRef.current[k], imgRef.current[k]])
				)
			}
		})
	}, [titleRef, descriptionRef, brtRef, imgRef])

	//! Send Function To Parent Component
	useImperativeHandle(ref, () => (
		[arr]
	), [])


	let linkUrl = [
		config.routes.investmentProjects.path,
		config.routes.exportFromArmenia.path,
		config.routes.businessInArmenia.path,
		config.routes.exportFromArmenia.path,
		config.routes.businessInArmenia.path,
		config.routes.news.path
	]


	const storeServices = useMemo(() => {
		return props.servicesArr?.map((i, k) => {
			return (
				<SingleServiceStyle className='single-service' key={k} >
					<div className='content'>
						<Text tag={'stringToLetters'} className={'title h3 MonMedium black'} text={i.title} ref={ref1 => titleRef.current[k] = ref1} />
						<Text tag={'h6'} className={'description h6 MonRegular black'} text={i.description} ref={ref2 => descriptionRef.current[k] = ref2} />
						{props.servicesArr?.length - 1 !== k &&
							<Button link={linkUrl[k]} wrappClassName={'otherElementAnimation'} yellowIcon={true} text={'learnMore'} ref={ref3 => brtRef.current[k] = ref3} />
						}
					</div>
					<div className='img-block' ref={ref4 => imgRef.current[k] = ref4}>
						<div className='img'>
							<span className="backColor" />
							<Image src={i.image} alt='photo' />
						</div>
					</div>
				</SingleServiceStyle>
			)
		})
	}, [props.servicesArr, itemRef])

	return (
		<MainSectionStyle>
			<Container className='main-section largePadding'>{storeServices}</Container>
		</MainSectionStyle>
	)
})

export default MainSection
