import styled from 'styled-components'

const CategoryBtnStyle = styled.div.attrs((props) => ({ className: props.className }))`
	transition: color var(--trTime);
	border-radius: 4px;
	padding: var(--spSize);
	text-transform: capitalize;
	position: relative;
	overflow: hidden;

	.backColor {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: all var(--trTime) ease-in-out;
	}

	p {
		position: relative;
		z-index: 1;
	}

	&.inFilter {
		margin-right: var(--sp3x);
		margin-bottom: var(--sp3x);
		padding: var(--sp1-25x) var(--sp1-5x);
		background-color: var(--ultraLightGrey);
		border: 1px solid var(--lightGrey);
		cursor: pointer;

		p {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			white-space: nowrap;
		}

		span {
			opacity: 0;
			white-space: nowrap;
		}

		&.active {
			font-family: MonMedium, MonMediumRu;
			.backColor {
				transform: translateX(0%);
			}
		}

		&.state {
			margin-right: var(--sp2x);
			margin-bottom: var(--sp2x);
			padding: var(--sp1-25x) var(--sp1-5x);

			&.active {
				border-color: var(--darkGrey);
				color: var(--white);
				font-family: MonMedium, MonMediumRu;
				.backColor {
					background-color: var(--darkGrey);
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		&.inFilter {
			margin-right: var(--sp2x);
			margin-bottom: var(--sp2x);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		&.inFilter {
			margin-right: var(--sp2x);
			margin-bottom: var(--sp2x);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		&.inFilter {
			margin-right: var(--sp1-25x);
			margin-bottom: var(--sp1-25x);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		&.inFilter {
			margin-right: var(--sp1-25x);
			margin-bottom: var(--sp1-25x);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		&.inFilter {
			margin-right: var(--sp2x);
			margin-bottom: var(--sp2x);
		}
	}
`

export default CategoryBtnStyle
