import { memo as Memo, useMemo, useState } from 'react'

//* Components
import { Text, Translate } from 'components/common'
import Collapse from '@material-ui/core/Collapse'

import { useRouter } from 'next/router'

//* Helpers
import { getParamsByKey,formatNumber } from 'helpers'

const InvestmentFilter = Memo(({ queryParamKeys, onCategoryChange, ranges }) => {
	//! States
	const [opened, setOpened] = useState(true)

	//! Router
	const router = useRouter()

	//! Required Investment Filter
	const requiredInvestment = useMemo(() => {
		const catParam = queryParamKeys[3] //? "required_investment"
		const activeItems = getParamsByKey(router, catParam)

		return ranges.map((i, k) => {
			const isActive = activeItems?.includes(i.id.toString())

			if (k === 0 || k === ranges.length - 1) {
				return (
					<div
						className={`single-invest-range ${isActive ? 'active' : ''}`}
						onClick={() => onCategoryChange(catParam, i.id, false)}
						key={k}
					>
						<Text tag={'p'} className={`pM ${isActive ? 'MonMedium' : 'MonRegular'} black`}>
							<Translate val={k === 0 ? 'lessThan' : 'moreThan'} /> {`${formatNumber(k === 0 ? i.range.usd.max : i.range.usd.min,'en')}`}
						</Text>
						<Text tag={'p'} className={`pM ${isActive ? 'MonMedium extraLightGrey' : 'MonRegular ultraDarkGrey'}`}>
							<Translate val={k === 0 ? 'lessThan' : 'moreThan'} /> {`${formatNumber(k === 0 ? i.range.amd.max : i.range.amd.min,'hy')}`}
						</Text>
					</div>
				)
			} else {
				return (
					<div
						className={`single-invest-range ${isActive ? 'active' : ''}`}
						onClick={() => onCategoryChange(catParam, i.id, false)}
						key={k}
					>
						<Text
							tag={'p'}
							className={`pM ${isActive ? 'MonMedium' : 'MonRegular'} black`}
							text={`${formatNumber(i.range.usd.min,'en')} - ${formatNumber(i.range.usd.max,'en')}`}
						/>
						<Text
							tag={'p'}
							className={`pM ${isActive ? 'MonMedium extraLightGrey' : 'MonRegular ultraDarkGrey'}`}
							text={`${formatNumber(i.range.amd.min,'hy')} - ${formatNumber(i.range.amd.max,'hy')}`}
						/>
					</div>
				)
			}
		})
	}, [ranges, router.query])

	return (
		<div className={`single-filter overflowHidden ${opened ? 'active' : ''} investment-required`}>
			<Text tag={'h5'} onClick={() => setOpened(!opened)} className={'title h5 MonMedium black'} text={'filterInvestReq'} />
			<Collapse in={opened} timeout='auto'>
				<div className='invest-ranges'>{requiredInvestment}</div>
			</Collapse>
		</div>
	)
})

export default InvestmentFilter
