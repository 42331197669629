import api from './_api'
import config from './_config'
import Cookie from 'js-cookie'

export async function getUser(lang, state, ctx) {
	const accountParams = getRouteObjFromConfig(lang, ctx)
	const accessToken = ctx.req?.cookies?.access_token || Cookie.get('access_token')
	const newState = state
	let user, redirect, redirectUrl

	if (!accessToken && accountParams) {
		redirect = true
	} else if (accessToken) {
		user = await getInfo(lang, accessToken, ctx.res)
		newState.user = user
	}

	if (!user && accountParams) {
		redirect = true
		newState.isLoggedIn = false
		newState.user = {}
	} else if (user) {
		newState.isLoggedIn = true
		if (accountParams?.redirectWhen && user[accountParams.redirectWhen.param] === accountParams.redirectWhen.paramValue) {
			redirect = true
			redirectUrl = accountParams.redirectWhen.redirectUrl || '/'
		}
	}

	return { newState, redirect, redirectUrl }
}

const getInfo = (lang, access_token, res) => {
	return api
		.get('account', lang, {}, access_token)
		.then((res) => {
			return res.customer
		})
		.catch((err) => {
			if (process?.browser) {
				document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
			} else {
				res.clearCookie('access_token')
			}
		})
}

const getData = (slug, lang, access_token) => {
	return api.get(slug, lang, {}, access_token).then((res) => {
		return res.data
	})
}

const getRouteObjFromConfig = (lang, router) => {
	const privateRoutes = Object.values(config.routes).filter((r) => r?.private === true)

	let path = router.asPath.split('?')[0]

	const regex = new RegExp(`^\/${lang}`, '')
	path = path.replace(regex, '')

	const removedLastPath = path.substr(0, path.lastIndexOf('/'))

	let route = privateRoutes.filter((r) => {
		const pathArr = r.path.split(':')
		return (
			r.path === path ||
			r.path.replace(/\/$/, '') === path.replace(/\/$/, '') ||
			(pathArr[0].replace(/\/$/, '') === removedLastPath && pathArr[1] && r.autoload)
		)
	})[0]

	return router.asPath === '404' ? false : route
}

export function withPrivateRoute() {
	return async ({ req, res }) => {
		if (!req?.cookies?.access_token) {
			return {
				redirect: {
					destination: '/?c=login',
					permanent: false,
				},
			}
		}

		return {
			props: {},
		}
	}
}
