import React, { memo as Memo, useState, useEffect, useCallback } from 'react'

//* Components
import { FormInput, FormItem } from 'components/common'
import { withLanguageContext } from 'context'

import { Icon } from 'components/common'
import FormStyle from 'styles/pages/FormStyle'
import SearchFieldStyle from './style'

const SearchField = Memo(({ onSearch, initialValue, translate, ...props }) => {
    const [val, setVal] = useState()

    useEffect(() => {
        setVal(initialValue)
    }, [initialValue])

    const onKeyDown = useCallback((e) => {
        if (e.which === 13) {
            onSearch(val)
        }
    }, [onSearch, val])

    return (
        <SearchFieldStyle>
            <FormStyle className='search'>
                <div className='FormRow FlexBox relativeWrapp'>
                    <FormInput onKeyDown={onKeyDown} onChange={e => setVal(e.target.value)} value={val} placeholder={translate('search')} />
                    <Icon className='icon-search ultraDarkGrey cursorPointer' onClick={() => onSearch(val)} />
                </div>
            </FormStyle>
        </SearchFieldStyle>
    )
})

export default withLanguageContext(SearchField, ['translate'])