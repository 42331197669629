import styled from 'styled-components'

const LoginStyle = styled.div`
	--titleMarg: var(--sp2x);
	--subTitleMarg: var(--sp4x);
	--btnMargBot: var(--sp4x);
	--successIconMarg: var(--sp7x);
	--successIconSize: var(--sp20x);

	section{
		max-width: var(--sp100x);
		padding-left: 0;
		padding-right: 0;
	}

	.defoltAnimationState {
		transform: translateY(100vh);
	}

    .select-item {
        z-index: 1;
        position: relative;
    }

	.successLoader {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: var(--white);
		transition: all var(--trTime);
		z-index: -1;
		opacity: 0;
		&.active {
			z-index: 1;
			opacity: 0.5;
			transition: all 0;
		}
	}



	.TitleWrapp {
		margin-bottom: var(--subTitleMarg);
	}
	.Title {
		text-align: center;
		margin-bottom: var(--titleMarg);
		line-height: var(--lineHeightXS);
	}
	.subTitle {
		text-align: center;
		margin-bottom: var(--subTitleMarg);
		
	}
	.subitleBlock {
		width: 100%;
		margin-bottom: var(--titleMarg);
		padding: var(--sp2x);
		background-color: var(--ultraLightGrey);
		border-radius: var(--borderSm);
	}
	.otherWaySignIn {
		> div {
			margin-bottom: var(--btnMargBot);
		}
	}

	.or {
		text-align: center;
		position: relative;
		span {
			position: relative;
			z-index: 1;
			background-color: var(--white);
			padding: 0 var(--sp2x);
			line-height: var(--lineHeightXS);
		}
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			transform: translateY(-50%);
			background-color: var(--lightGrey);
			height: 1px;
			z-index: 0;
		}
	}

	.successIcon{
		margin: var(--successIconMarg) 0 ;
		.LottieWrapp,.imageCont  {
			width: var(--successIconSize);
			height: var(--successIconSize);
			svg,img {
				width: 400% !important;
				height: 400% !important;
				left: -150%;
				top: -105%;
			}
		}
	}
	.linkWrap {
		margin-top: var(--successIconMarg);
		> a {
			max-width: 45%;
		}
	}

	.btnDownload{
		-webkit-appearance: none;
		cursor: pointer;
		margin :var(--successIconMarg) 0;
		display: block;
		color: var(--white);
		line-height: var(--lineHeightS);
		position: relative;
		z-index: 1;
		padding: var(--sp2x) var(--sp4x);
		border-radius: var(--borderSm);
		border: 0;
		font-size: var(--pL);
		overflow: hidden;
		background-color: transparent;
		color: var(--white);
		width: 100%;
		text-align: center;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 205%;
			height: 100%;
			transform: translateX(0%);
			transition: var(--trTime);
			z-index: -1;
			background: linear-gradient(90deg, var(--black) 50%, var(--yellow) 50%);
		}

		&:hover {
			color: var(--black);
			&:before {
				transform: translateX(-50%);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		--successIconSize: var(--sp16x);
		--btnMargBot: var(--sp3x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		/* --titleMarg:var(--sp1-3x); */
		--subTitleMarg: var(--sp3x);
		--btnMargBot: var(--sp3x);
		--successIconMarg: var(--sp5x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		--successIconSize: var(--sp15x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		/* --titleMarg:var(--spSize); */
		--successIconMarg: var(--sp6x);
		--successIconSize: var(--sp11x);
	}
`

export default LoginStyle
