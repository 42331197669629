import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import { LottieAnimation, Text, Container } from 'components/common'
import CooperationWithInvestStyle from './style'

import animationJSON from 'lottieAnimation/partnership/partnership.json'

const CooperationWithInvest = forwardRef((props, ref) => {
	const titleRef = useRef()
	const descriptionRef = useRef()
	const imgRef = useRef()

	useImperativeHandle(ref, () => [titleRef.current.childNodes, descriptionRef, imgRef], [])

	return (
		<CooperationWithInvestStyle className='coop-with-invest'>
			<Container className='coop-with-invest-block largePadding'>
				<div className='content'>
					<Text tag={'stringToLetters'} className={'title h1 MonMedium'} ref={titleRef} text={props.title} />
					<Text tag={'h6'} className={'description h6 MonRegular'} ref={descriptionRef} text={props.text} />
				</div>

				<div className="LottieContainer" ref={imgRef} >
					<LottieAnimation loop={true} isStopped={false} animationJSON={animationJSON} />
				</div>
			</Container>
		</CooperationWithInvestStyle>
	)
})

export default CooperationWithInvest
