import styled from 'styled-components'


const Footer = styled.footer`
    position:relative;
    --footerTopPadd:var(--sp12x);
    --footerContMaxWidth:63%;
    --footerBottomPadd:var(--sp4x);
    --footerTopItemPaddLeftRight:var(--sp7x);
    --AllRightsReservedBottMarg:var(--sp2x);

    --menuItemMargBott:var(--sp4x);

    --conFooterPadd:0;


    --footerTopLeft:50%;
    --footerTopRight:calc(100% - var(--footerTopLeft));

    background-color:var(--black);

    a:hover{
        color:var(--darkGrey);
    }

    .footerTopCont{
        background-color:var(--lightGrey);
        padding:var(--footerTopPadd) 0;
        .footerTop{
            margin-bottom:0;
            .footerText{
                width:100%;
                max-width:var(--footerTopLeft);
                padding-right:var(--footerTopItemPaddLeftRight);
            }
            .footerSubscribe{
                width:100%;
                display: flex;
                justify-content: flex-end;
                max-width:var(--footerTopRight);
                padding-left:var(--footerTopItemPaddLeftRight);
                
                .btn-action {
                    display: inline-flex;
                    justify-self: flex-end;
                }

                .ant-form-item-explain ,.ant-form-item-explain-error{
                    position: absolute !important;
                    top:120%;
                    left:0;
                    right:0;
                }
            }
        }
    }

    .footerCont{
        margin-bottom:0;
        .footerMenuCont{
            width:100%;
            padding:calc(var(--footerTopPadd) + var(--spSize)) 0;

            .footerMenu{
                max-width:70%;
                width:100%;
                .menu-items-cont{
                    .menu-item{
                        width:50%;
                        margin-bottom:var(--menuItemMargBott);
                        padding-right:var(--sp3x);
                        line-height:var(--lineHeightXS);
                        position: relative;
                        &:nth-last-child(-n+2){
                            margin-bottom:0;
                        }


                        .dubleTitle{
                            position:absolute;
                            top:0;
                            left:0;
                            z-index:1;
                            opacity:0;
                            visibility:hidden;
                            white-space:nowrap;
                            pointer-events:none;
                        }

                        .Link{
                            opacity:1;
                            z-index:3;
                        }
                    }
                }
            }

            .footerContactInfo{
                max-width:30%;
                width:100%;
                text-align:right;
            }
        }
    
        .footerBottom{
            width:100%;
            padding-bottom:var(--footerBottomPadd);
            .AllRightsReserved{
                max-width:50%;
                >div{
                    &:first-child{
                        margin-bottom:var(--AllRightsReservedBottMarg);
                    }
                }
            }
        }
    }


    @media only screen and (min-width: ${props => props.theme.mediaQuery.LaptopSize}) {

        .footerCont{
            .footerMenuCont{
                .footerContactInfo{
                    flex-direction: column;
                    justify-content:space-between;
                    align-items:flex-end;
                }
            }
        }
    }

    
    @media only screen and (max-width: ${props => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${props => props.theme.mediaQuery.DesktopSizeM}) {
        --footerTopPadd:var(--sp10x);
        --footerContMaxWidth:73%;
        --footerBottomPadd:var(--sp4x);
        --footerTopItemPaddLeftRight:var(--sp6x);
        --menuItemMargBott:var(--sp4x);

        --footerTopLeft:47%;
    }

    @media only screen and (max-width: ${props => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${props => props.theme.mediaQuery.DesktopSizeS}) {
        --footerTopPadd: var(--sp8x);
        --footerBottomPadd: var(--sp5x);
        --footerTopItemPaddLeftRight: var(--sp10x);
        --menuItemMargBott: var(--sp4x);
        --footerTopLeft: 45%;
    }

    @media only screen and (max-width: ${props => props.theme.mediaQuery.DesktopSizeMMin}) {
        --footerContMaxWidth:100%;
    }

    @media only screen and (max-width: ${props => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${props => props.theme.mediaQuery.LaptopSize}) {
        --footerTopPadd:var(--sp5x);
        --footerBottomPadd:var(--sp3x);
        --footerTopItemPaddLeftRight:var(--sp2x);
        --menuItemMargBott:var(--sp3x);
        
        --footerTopLeft:36%;
    }

    @media only screen and (max-width: ${props => props.theme.mediaQuery.LaptopSizeMin}) {
        --footerTopPadd:var(--sp5x);
        --footerBottomPadd:var(--sp3x);
        --footerTopItemPaddLeftRight:0;
        --menuItemMargBott:var(--sp3x);

        --conFooterPadd:var(--sp2x);

        --footerTopLeft:100%;
        --footerTopRight: 100%;


        .footerTopCont{
            .footerTop{
                flex-direction:column;
                .footerSubscribe{
                    justify-content: flex-start;
                    padding:var(--sp4x) 0 var(--sp3x);
                }
            }
        }

        .footerCont{
            .footerMenuCont{
                .footerMenu{
                    max-width:100%;
                    margin-bottom:var(--footerTopPadd);
                    .menu-items-cont{
                        .menu-item{
                            width:50%;
                            padding-right:var(--sp4x);
                            &:nth-last-child(-n+2){
                                margin-bottom:var(--menuItemMargBott);
                            }
                        }
                    }
                }

                .footerContactInfo{
                    max-width:100%;
                    display:flex;
                    justify-content:space-between;
                    text-align: left;
                }
            }
        
            .footerBottom{
                padding-top:var(--sp2x);
            }
        }

    }

    @media only screen and (max-width: ${props => props.theme.mediaQuery.TabletSizeMin}) {

        --AllRightsReservedBottMarg:var(--sp1-5x);

        .footerTopCont{
            .footerTop{
                flex-direction:column;
                .footerSubscribe{
                    justify-content: flex-start;
                    padding:var(--sp4x) 0 var(--sp3x);
                }
            }
        }

        .footerCont{
            .footerMenuCont{
                .footerMenu{
                    margin-bottom:0;
                    .menu-items-cont{
                        .menu-item{
                            width:100%;
                            padding-right:0;
                        }
                    }
                }

                .footerContactInfo{
                    flex-direction: column;

                    .footerInfo{
                        padding-bottom:var(--sp6x);
                    }
                    .footerLanguageSwitcher{
                        justify-content:flex-end;
                        margin-bottom:0;
                    }
                }
            }
        
            .footerBottom{
                padding-top:0;
                .AllRightsReserved{
                    order:2;
                    width:100%;
                    max-width:100%;
                }
                .socIconWrapp{
                    justify-content:flex-end;
                    order:1;
                    width:100%;
                    padding-bottom:var(--sp4x);
                }
            }
        }
    }

`

export default Footer