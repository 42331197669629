import { memo as Memo } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Tooltip, Upload, Input } from 'antd'
import { Text, FormItem, Icon, FormInput } from 'components/common'

const NewsContent = Memo(({ ...props }) => {
	const dummyRequest = ({ file, onSuccess }) => {
		onSuccess('ok')
	}

	return (
		<>
			<div className='FormColumnsTitle'>
				<Text tag={'h4'} className={' MonMedium black'} text={'NewsContent'} />
			</div>

			<div className='FormColumnsWrapp FlexBoxContainer spaceBetween '>
				<div className='FormCol '>
					<FormItem label={'NewsTitle'} name={'title'} required={true}>
						<FormInput />
					</FormItem>
				</div>

				<div className='FormCol relativeWrapp'>
					<Tooltip title={<div dangerouslySetInnerHTML={{ __html: props.translate('WriteTheNewsTextWarning') }} />}>
						<div className='HelperDesc'>
							<Icon className='icon-quastion'>
								<span className='path1'></span>
								<span className='path2'></span>
							</Icon>
						</div>
					</Tooltip>
					<FormItem label={'WriteTheNewsText'} name={'text'} required={true}>
						<Input.TextArea placeholder={props.translate('WriteTheNewsTextExtra')} maxLength={2500} />
					</FormItem>
				</div>

				<div className='FormCol'>
					<FormItem label={'UploadPhotos'} name={'photos'} required={true} extra={'MinMaxPhoto'} validationType='uploader'>
						<Upload
							accept='image/png, image/jpeg'
							listType='picture-card'
							fileList={props.photos}
							maxCount={5}
							multiple={true}
							customRequest={dummyRequest}
						>
							<Text tag={'span'} className={'pS MonMedium darkGrey'}>
								<Icon className='icon-addFile'>
									<span className='path1'></span>
									<span className='path2'></span>
								</Icon>
								<br />
								{props.translate('AddFiles')}
							</Text>
						</Upload>
					</FormItem>
				</div>
			</div>
		</>
	)
})

export default withLanguageContext(NewsContent, ['translate', 'selectedLang'])
