import React, { memo as Memo, useMemo, useState, useEffect } from 'react'
import Lottie from 'react-lottie'

//* Style's
import LottieStyle from './style'

const LottieAnimation = Memo((props) => {
    const [isStopped, setIsStopped] = useState(false)
    const [isPaused, setIsPaused] = useState(true)

    useEffect(() => {
        props.isStopped !== undefined && setIsStopped(props.isStopped)

        props.isStopped !== undefined && setIsPaused(props.isStopped)
    }, [props.isStopped])

    const defaultOptions = useMemo(
        () => ({
            loop: props.loop,
            autoplay: props.startAnime || false,
            animationData: props.animationJSON,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        }),
        [props.startAnime]
    )

    const onMouseHoverEnter = () => {
        props.isStopped === undefined && setIsStopped(false)

        props.isStopped === undefined && setIsPaused(false)
    }

    const onMouseHoverLeave = () => {
        props.isStopped === undefined && setIsStopped(true)

        props.isStopped === undefined && setIsPaused(true)
    }

    return (
        <LottieStyle className='LottieWrapp'>
            <div onMouseEnter={onMouseHoverEnter} onMouseLeave={onMouseHoverLeave}>
                <Lottie
                    options={defaultOptions}
                    height={'100%'}
                    width={'100%'}
                    isClickToPauseDisabled={true}
                    isStopped={props.isStopped === undefined ? isStopped : props.isStopped}
                    isPaused={props.isStopped === undefined ? isPaused : props.isStopped}
                />
            </div>
        </LottieStyle>
    )
})

export default LottieAnimation
