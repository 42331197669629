import styled from 'styled-components'

const CalculatorFormStyle = styled.div.attrs({})`
	&.loading {
		pointer-events: none;
		opacity: 0.5;
	}
`

export default CalculatorFormStyle
