import styled from 'styled-components'

const ContainerStyle = styled.section.attrs({})`
	max-width: calc(var(--maxWidth) + (var(--containerPadding) * 2));
	padding-left: var(--containerPadding);
	padding-right: var(--containerPadding);
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	margin-bottom: var(--containerMarginBottom);

	&.last {
		margin-bottom: 0;
	}

	&.full {
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		&.largePadding {
			padding-left: var(--containerPaddingLarge);
			padding-right: var(--containerPaddingLarge);
		}
	}

	/* @media screen and (max-width:${(props) => props.theme.mediaQuery.TabletSizeMin}){ */
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		&.full {
			padding-left: var(--containerPadding);
			padding-right: var(--containerPadding);
		}
	}
`
export default ContainerStyle
