import React, { forwardRef, useRef, useImperativeHandle, useEffect, useState } from 'react'
import { MapComponent, Text } from 'components/common'
import MapInSingleStyle from './style'

const MapInSingle = forwardRef((props, ref) => {
	//! State
	const [secObj, setSecObj] = useState([{ id: 'place1', pos: { lat: 0, lng: 0 } }])

	useEffect(() => {
		setSecObj([{ id: 'place1', pos: { lat: Number(props.latitude), lng: Number(props.longitude) } }])
	}, [props.latitude, props.longitude])

	const itemRef = useRef()

	useImperativeHandle(ref, () => [itemRef], [])

	return (
		<MapInSingleStyle className='map' ref={itemRef}>
			<Text tag={'h5'} className={'title h5 MonMedium'} text={'locationMap'} />
			<MapComponent className='mapMd' pinsData={secObj} popInfo={false} markerDrag={false} minZoom={13} />
		</MapInSingleStyle>
	)
})

export default MapInSingle
