import styled from 'styled-components'

const MapInSingleStyle = styled.div.attrs((props) => ({ className: props.className }))`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: var(--sp98x);
	margin-top: var(--sp12x);

	.title {
		margin-bottom: var(--titleFromContent);
		transform: translateY(30vh);
	}

	.map-block {
		width: 100%;
		padding-top: 45%;
	}
	
	.mapMd{
		transform: translateY(30vh);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		max-width: var(--sp83x);
		margin-top: var(--sp10x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		max-width: var(--sp83x);
		margin-top: var(--sp7-5x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		max-width: var(--sp85x);
		margin-top: var(--sp5x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		max-width: unset;
		margin-top: var(--sp5x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		max-width: unset;
		margin-top: var(--sp5x);

		.map-block {
			width: calc(100% + (2 * var(--containerPadding)) + 1px);
			transform: translateX(calc(-1 * var(--containerPadding)));
		}
	}
`
export default MapInSingleStyle
