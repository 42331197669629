import { useCallback, forwardRef, useRef, useImperativeHandle } from 'react'

//* Components
import { Text } from 'components/common'

//* Styles
import CategoryBtnStyle from './style'

const CategoryBtn = forwardRef(({ active, onChange, ...props }, ref) => {
	const handleClick = useCallback(() => {
		onChange && onChange()
	}, [active, onChange])

	const RefItem = useRef()

	useImperativeHandle(ref, () => [RefItem], [])

	return (
		<CategoryBtnStyle
			ref={RefItem}
			onClick={() => handleClick()}
			className={`single-cat black MonRegular ${props?.slug?.toLowerCase().replace(' ', '_')} ${
				props.inFilter ? `inFilter ${active ? 'active' : ''} ${props.state ? 'state' : ''}` : ''
			}`}
		>
			<i className='backColor' style={{ backgroundColor: props.bgColor }} />
			<Text tag={'p'} className={`${props.inFilter ? 'pM' : 'pS'} `} text={props.text} />
			{props.inFilter ? <Text tag={'span'} className={`pM copy MonRegular`} text={props.text} /> : null}
		</CategoryBtnStyle>
	)
})

export default CategoryBtn
