import React, { memo as Memo } from 'react'
import { LottieAnimation } from 'components/common'

import preloaderAnimation from 'lottieAnimation/preloader/preloader.json'

//* Style's
import PreloaderStyle from './style'

const Preloader = Memo((props) => {
	return (
		<PreloaderStyle className={`${props.className || ''}  ${props.hide ? 'hide' : ''}`}>
			<section className={`FlexBox preloader ${props.className == 'preloaderHome' ? 'AlignEnd' : 'alignMiddle alignCenter'}`}>
				<LottieAnimation animationJSON={preloaderAnimation} loop={true} isStopped={false} />
			</section>
		</PreloaderStyle>
	)
})

export default Preloader
