import styled from 'styled-components'

const TestimonialsStyle = styled.div.attrs((props) => ({ className: props.className }))`
	--swiperWrapperPad: var(--sp4x);

	section {
		display: flex;
		flex-direction: column;

		&.full {
			background-color: var(--ultraLightGrey);
			padding-top: var(--sp15x);
			padding-bottom: var(--sp15x);
		}

		.slider {
			position: relative;
			margin-top: var(--sp12x);
			.swiper-container {
				overflow: visible;
				margin-left: calc(-1 * var(--swiperWrapperPad));
				.swiper-wrapper {
					.swiper-slide {
						padding: 0 var(--swiperWrapperPad);
						height: auto;
						.SwiperSlideWrap {
							background-color: var(--white);
							padding: var(--sp3x);
							height: 100%;
							border-radius: var(--spSize);

							.quote-mark {
								width: var(--sp6-5x);
								padding-top: var(--sp5x);
								align-self: flex-start;

								img {
									object-fit: contain;
								}
							}

							.text {
								margin: var(--sp3x) 0 var(--sp7x);
								flex: 1;
							}

							.info {
								position: relative;

								&:before {
									content: '';
									position: absolute;
									top: calc(-1 * var(--sp3x));
									left: calc(-1 * var(--sp3x));
									width: calc(100% + (2 * var(--sp3x)));
									height: 1px;
									background-color: var(--extraLightGrey);
								}

								.imageCont {
									width: var(--sp15x);
									padding-top: var(--sp15x);
									overflow: hidden;
									border-radius: 50%;
								}

								.heading {
									margin-left: var(--sp3x);

									& > :last-child {
										margin-top: var(--sp1-5x);
									}
								}
							}
						}
					}
				}
			}
			.swiper-btn {
				width: var(--sp6x);
				height: var(--sp6x);
				background-color: var(--white);
				border-radius: 50%;
				box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
				font-size: var(--sp1-5x);
				display: inline-flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				top: calc(-1 * var(--titleFromContent));
				transform: translateY(-100%);
				z-index: 10;
				cursor: pointer;
				color: var(--black);
				transition: var(--trTime);

				&.swiper-button-disabled {
					color: var(--darkGrey);
				}

				&.prev {
					right: var(--sp8-5x);
				}

				&.next {
					right: var(--sp0-5x);
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		section {
			&.full {
				padding-top: var(--sp12x);
				padding-bottom: var(--sp12x);
			}
			.slider {
				margin-top: var(--sp10x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--swiperWrapperPad: var(--sp3x);

		section {
			&.full {
				padding-top: var(--sp10x);
				padding-bottom: var(--sp10x);
			}
			.slider {
				margin-top: var(--sp7-5x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		--swiperWrapperPad: var(--sp3x);

		section {
			&.full {
				padding-top: var(--sp7-5x);
				padding-bottom: var(--sp7-5x);
				padding-left: 0;
				padding-right: 0;
			}

			.slider {
				margin-top: var(--sp5x);
			}
		}
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		overflow: hidden;

		--swiperWrapperPad: var(--sp2x);

		section {
			&.full {
				padding-top: var(--sp7-5x);
				padding-bottom: var(--sp7-5x);
				padding-left: 0;
				padding-right: 0;
			}

			.slider {
				margin-top: var(--sp12x);
				.swiper-btn {
					width: var(--sp5x);
					height: var(--sp5x);

					&.prev {
						right: var(--sp7x);
					}

					&.next {
						right: 0;
					}
				}

				.swiper-container {
					.swiper-wrapper {
						.swiper-slide {
							.SwiperSlideWrap {
								padding: var(--sp2x);

								.quote-mark {
									width: var(--sp5x);
									padding-top: var(--sp4x);
								}

								.text {
									margin: var(--sp2x) 0 var(--sp7x);
								}

								.info {
									flex-direction: column;
									width: 100%;
									flex: 1;

									&:before {
										top: calc(-1 * var(--sp2x));
										left: calc(-1 * var(--sp2x));
										width: calc(100% + (2 * var(--sp2x)));
									}

									.imageCont {
										align-self: flex-start;
									}

									.heading {
										margin-top: var(--sp1-5x);
										margin-left: 0;
										width: 100%;

										& > :last-child {
											margin-top: var(--sp1-5x);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
export default TestimonialsStyle
