import { memo as Memo } from 'react'

//* Components
import { Text, Icon, CustomLink, Image } from 'components/common'
import { CategoryBtn } from 'components/pages'

//* Styles
import SingleCatalogStyle from './style'

const SingleCatalog = Memo((props) => {
    return (
        <SingleCatalogStyle className={`single-catalog ${props.export ? 'export' : ''}`}>
            <CustomLink url={props.url} className={'single-catalog-block'}>
                {props.photo && <Image src={props.photo} alt='catPhoto' />}
                <CategoryBtn bgColor={props.bgColor} inFilter={false} slug={props.categorySlug} text={props.categoryName} />
                <div className='info'>
                    <Text tag={'h2'} className={`title pL MonBold black ${props.export ? 'inExport' : ''}`} text={props.title} />
                    {!props.export ? (
                        <div className='location'>
                            <Icon className='icon-pin'>
                                <span className='path1' />
                                <span className='path2' />
                            </Icon>
                            <Text tag={'p'} className={'pS MonMedium ultraDarkGrey'} text={props.location} />
                        </div>
                    ) : null}
                    <Text tag={'h3'} className={'desc pM MonRegular black'} text={props.description} />
                    <Text
                        tag={'p'}
                        className={'subtitle uppercase pS MonRegular ultraDarkGrey'}
                        text={props.export ? 'interestedToExport' : 'requiredInvestTitle'}
                    />
                    {props.export ? (
                        <Text tag={'p'} className={'int-countries pM MonBold black'} text={props.interestedCountries} />
                    ) : (
                        <>
                            <Text tag={'h4'} className={'h6 MonBold black'} text={props.priceInDollar} />
                            <Text tag={'p'} className={'amd-price pM MonRegular black'} text={props.priceInAMD} />
                        </>
                    )}
                    <Text
                        tag={'p'}
                        className={'subtitle uppercase pS MonRegular ultraDarkGrey'}
                        text={props.export ? 'startingPricePerUnit' : 'profitTitle'}
                    />
                    {props.export ? (
                        <>
                            <Text tag={'h4'} className={'h6 MonBold black'} text={props.priceInDollar} />
                            <Text tag={'p'} className={'amd-price pM MonRegular black'} text={props.priceInAMD} />
                        </>
                    ) : (
                        <Text tag={'h4'} className={'h6 MonBold black'} text={props.profitPercent} />
                    )}

                    <Text tag={'p'} className={'company pS MonRegular ultraDarkGrey'} text={props.companyName} />
                </div>
            </CustomLink>
        </SingleCatalogStyle>
    )
})

export default SingleCatalog
