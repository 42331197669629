import React from 'react'
import _ from 'lodash'

export function imagePathToWebP(src) {
	const pos = src.lastIndexOf('.'),
		image = src.includes('images.ctfassets') ? src + '?fm=webp' : src.substr(0, pos < 0 ? src.length : pos) + '.webp'
	return image
}

export const isMobile = () => {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export const isWebPSupport = () => {
	const elem = typeof document === 'object' ? document.createElement('canvas') : {}
	if (!!(elem.getContext && elem.getContext('2d'))) {
		return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
	}
	return false
}

export function letterify(word) {
	let arr = word.trim().split('')

	return arr.map((l, k) => (
		<span className={`letter ${l === ' ' ? 'space' : ''}`} key={k}>
			{l}
		</span>
	))
}

export function removePrefix(str) {
	return str.replace('mm_', '')
}

export const stringToLetters = (text) => {
	let wordLength = text.length
	let arr = []

	for (let i = 0; i < wordLength; i++) {
		arr.push(text.charAt(i))
	}

	return (
		<div className='word'>
			{arr.map((i, k) => {
				return (
					<span className='letter' key={k}>
						{i}
					</span>
				)
			})}
		</div>
	)
}

export const breakString = (str, hasSpan = true, symb, className = '') => {
	let arr = str.split(symb || '</br>')

	if (!hasSpan) {
		return arr
	}

	return arr.map((i, k) => (
		<span className={`${className || 'item'}-${k + 1}`} key={k}>
			{i}
		</span>
	))
}

export const queryStringToObject = (url) => {
	return _.chain(url).replace('?', '').split('&').map(_.partial(_.split, _, '=', 2)).fromPairs().value()
}

export const objectToQueryString = (obj) => {
	if (obj) {
		const results = []
		_.forOwn(obj, (value, key) => {
			if (Array.isArray(value)) {
				_.forOwn(value, (value) => {
					results.push(`${key}=${value}`)
				})
			} else {
				results.push(`${key}=${value}`)
			}
		})
		return results.join('&')
	}
	return ''
}

export const routerToPathAndQuery = (router, key, val, multiple, clearKeyItems) => {
	let newVal = val?.toString()

	if (multiple) {
		let paramsArr = getParamsByKey(router, key)
		if (paramsArr) {
			paramsArr = paramsArr.indexOf(newVal) > -1 ? paramsArr.filter((k) => k !== newVal) : [...paramsArr, newVal]
			newVal = paramsArr.join(',')
		}
	}

	const queryObj = { ...router.query, [key]: newVal }
	delete queryObj['params']

	if (clearKeyItems) {
		delete queryObj[clearKeyItems]
	}

	if (router.query[key] === newVal || !newVal) {
		delete queryObj[key]
	}

	const paramsPath = router.query.params && router.query.params.join('/')
	let pathname = router.pathname
	pathname = pathname.replace('[[...params]]', paramsPath || '')

	return { pathname, query: queryObj }
}

export const getParamsByKey = (router, key) => {
	return router.query[key]?.split(',')
}

export const GetNextDates = (daysToAdd, startDate = new Date()) => {
	let arrDates = []

	const MonthAsString = (monthIndex) => {
		let month = new Array()
		month[0] = {
			number: '01',
			full: 'january',
		}
		month[1] = {
			number: '02',
			full: 'february',
		}
		month[2] = {
			number: '03',
			full: 'march',
		}
		month[3] = {
			number: '04',
			full: 'april',
		}
		month[4] = {
			number: '05',
			full: 'may',
		}
		month[5] = {
			number: '06',
			full: 'june',
		}
		month[6] = {
			number: '07',
			full: 'july',
		}
		month[7] = {
			number: '08',
			full: 'august',
		}
		month[8] = {
			number: '09',
			full: 'september',
		}
		month[9] = {
			number: '10',
			full: 'october',
		}
		month[10] = {
			number: '11',
			full: 'november',
		}
		month[11] = {
			number: '12',
			full: 'december',
		}

		return month[monthIndex]
	}

	const DayAsString = (dayIndex) => {
		let weekdays = new Array(7)
		weekdays[0] = {
			full: 'sunday',
			short: 'sun',
		}
		weekdays[1] = {
			full: 'monday',
			short: 'mon',
		}
		weekdays[2] = {
			full: 'tuesday',
			short: 'tue',
		}
		weekdays[3] = {
			full: 'wednesday',
			short: 'wed',
		}
		weekdays[4] = {
			full: 'thursday',
			short: 'thu',
		}
		weekdays[5] = {
			full: 'friday',
			short: 'fri',
		}
		weekdays[6] = {
			full: 'saturday',
			short: 'sat',
		}

		return weekdays[dayIndex]
	}

	for (let i = 0; i <= daysToAdd - 1; i++) {
		const today = new Date()
		const currentDate = new Date()
		currentDate.setDate(startDate.getDate() + i)

		const date = currentDate.getDate(),
			month = currentDate.getMonth() + 1,
			year = currentDate.getFullYear()
		let obj = {
			day: DayAsString(currentDate.getDay()),
			date,
			month,
			year,
			fullDate: date + '/' + month + '/' + year,
		}

		obj.today = today.getDate() === currentDate.getDate() && 'today'

		today.setDate(today.getDate() + 1)

		obj.tomorrow = today.getDate() === currentDate.getDate() && 'tomorrow'

		arrDates.push(obj)
	}

	return arrDates
}

export const getPageNumbers = ({ currentPage, pageSize, total, pageNumbersToShow = 3 }) => {
	const lastPageNumber = Math.ceil(total / pageSize)
	const currentPageNumber = currentPage <= lastPageNumber ? currentPage : lastPageNumber
	const maxPagesBeforeCurrentPage = Math.floor(pageNumbersToShow / 2)
	const maxPagesAfterCurrentPage = Math.ceil(pageNumbersToShow / 2) - 1
	let startPage = 1
	let endPage = lastPageNumber

	if (lastPageNumber <= 1) {
		return [] // Don't show numbers if there's only 1 page
	}

	if (currentPageNumber <= maxPagesBeforeCurrentPage) {
		// near the start
		startPage = 1
		endPage = pageNumbersToShow
	} else if (currentPageNumber + maxPagesAfterCurrentPage >= lastPageNumber) {
		// near the end
		startPage = lastPageNumber - pageNumbersToShow + 1
	} else {
		// somewhere in the middle
		startPage = currentPageNumber - maxPagesBeforeCurrentPage
		endPage = currentPageNumber + maxPagesAfterCurrentPage
	}

	let pageNumbers = Array.from(Array(endPage + 1 - startPage).keys())
		.map((pageNumber) => startPage + pageNumber)
		.filter((pageNumber) => pageNumber <= lastPageNumber && pageNumber > 0)

	if (pageNumbers[0] > 1) {
		if (pageNumbers[0] <= 2) {
			pageNumbers = [1, ...pageNumbers]
		} else {
			const ellipsis = pageNumbers[0] > 3 ? '...' : 2
			pageNumbers = [1, ellipsis, ...pageNumbers]
		}
	}

	if (pageNumbers[pageNumbers.length - 1] !== lastPageNumber) {
		if (pageNumbers[pageNumbers.length - 1] === lastPageNumber - 1) {
			pageNumbers = [...pageNumbers, lastPageNumber]
		} else {
			const ellipsis = pageNumbers[pageNumbers.length - 1] < lastPageNumber - 2 ? '...' : lastPageNumber - 1
			pageNumbers = [...pageNumbers, ellipsis, lastPageNumber]
		}
	}

	return pageNumbers
}

export const formatNumber = (x, leng) => {
	let str = x && (typeof x === 'string' ? x : x.toString())

	if (!x) return 0

	if (leng === 'hy') {
		str = str.replace('֏', '')
		str = str.replace(',', '')
		// str = str.replace('.', '')
		str = str.replace('AMD', '')
		str = str.replace(/\s/g, '')
		str = new Intl.NumberFormat('ru-RU').format(str)
		return str + '֏'
	} else if (leng === 'en') {
		str = str.replace('$', '')
		str = str.replace(',', '')
		// str = str.replace('.', '')
		str = str.replace('USD', '')
		str = str.replace(/\s/g, '')
		str = new Intl.NumberFormat().format(str)
		return '$' + str
	} else {
		str = str.replace(',', '')
		// str = str.replace('.', '')
		str = str.replace(/\s/g, '')
		str = new Intl.NumberFormat('de-DE').format(str)
		return str
	}
}

export const arrayGenChild = (x) => {
	let arr = []
	x.forEach((el) => {
		el.childNodes.forEach((el2) => {
			arr.push(el2)
		})
	})
	return arr
}

export const arrayGen = (x) => {
	let arr = []
	x.forEach((el) => {
		el.forEach((el2) => {
			arr.push(el2)
		})
	})
	return arr
}
