import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import { Text, Container } from 'components/common'
import { SinglePackage } from 'components/pages'
import CooperationPackagesStyle from './style'

const CooperationPackages = forwardRef((props, ref) => {
	const titleRef = useRef()
	const descriptionRef = useRef()
	const itemRef = useRef()

	useImperativeHandle(ref, () => [titleRef.current.childNodes, descriptionRef, itemRef.current.childNodes], [])

	return (
		<CooperationPackagesStyle className='coop-packages'>
			<Container className='full last'>
				<Container className='block last FlexBoxColumn alignMiddle largePadding'>
					<div className='heading FlexBoxColumn alignMiddle'>
						<Text tag={'stringToLetters'} className={'title h1 MonMedium'} ref={titleRef} text={props.title} />
						<Text tag={'h6'} className={'h6 MonRegular description'} ref={descriptionRef} text={props.text} />
					</div>
					<div className='packages FlexBox spaceBetween' ref={itemRef}>
						<SinglePackage
							title={'basicPlan'}
							price={props.price1}
							price1={props.price1}
							price2={props.price2}
							content={props.content1}
							val={'main_plan_price'}
						/>
						<SinglePackage
							title={'premiumPlan'}
							price={props.price2}
							price1={props.price1}
							price2={props.price2}
							content={props.content2}
							val={'premium_plan_price'}
						/>
					</div>
				</Container>
			</Container>
		</CooperationPackagesStyle>
	)
})

export default CooperationPackages
