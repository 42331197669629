import React, { memo as Memo } from 'react'

import BurgerStyle from './style'

const Burger = Memo((props) => {

    return (
        <BurgerStyle >
            <div className={`Burger ${props.checked  ? "active" : ''}`} onClick={props.onClick}>
                <span/>
                <span/>
                <span/>
            </div>
        </BurgerStyle>
    )
})

export default Burger