import React, { useState, useEffect, useCallback, forwardRef, useRef, useImperativeHandle } from 'react'
import { Text, LottieAnimation, Container } from 'components/common';
import BusinessStepStyle from './style';

import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals('ScrollTrigger', ScrollTrigger);


const BusinessStep = forwardRef((props, ref) => {

    const [playAnime, setPlayAnime] = useState({
        sec1: true,
        sec2: true,
        sec3: true,
        sec4: true,
    })

    const titleRef = useRef();
    const descriptionRef = useRef();
    const itemsRef = useRef();

    useImperativeHandle(ref, () => (
        [titleRef.current.childNodes, descriptionRef, itemsRef.current.childNodes]
    ), [])

    const sectionAnime = useCallback(() => {
        let newObj = playAnime
        const elTop = itemsRef?.current?.getBoundingClientRect()
        const pointStart = window.innerHeight

        if (elTop?.top < pointStart) {
            Object.values(playAnime).forEach((element, i) => {
                newObj = { ...newObj, [`sec${i + 1}`]: false }
            });
            setPlayAnime(newObj)
        }

    }, [playAnime])

    useEffect(() => {
        window.addEventListener('scroll', sectionAnime)

        return () => {
            window.removeEventListener('scroll', sectionAnime)
        }
    }, [sectionAnime])

    return (
        <BusinessStepStyle className='business-cont'>
            <Container className='largePadding'>
                <div className='big-cont'>
                    <div className='top'>
                        <Text tag={'stringToLetters'} className={'title text h1 MonMedium black'} ref={titleRef} text={props.contTitle} />
                        <Text tag={'h6'} className={'description text h6 MonRegular black'} ref={descriptionRef} text={props.content} />
                    </div>
                    <div className='steps' ref={itemsRef}>
                        <div className='single-step'>
                            <Text tag={'h2'} className={'title h2 MonMedium'} text={'investTitle1'} />
                            <Text tag={'h6'} className={'subtitle h6 MonRegular'} text={'investText1'} />
                            <LottieAnimation animationJSON={props.photo1} loop={true} isStopped={playAnime.sec4} />
                        </div>
                        <div className='single-step'>
                            <Text tag={'h2'} className={'title h2 MonMedium'} text={'investTitle2'} />
                            <Text tag={'h6'} className={'subtitle h6 MonRegular'} text={'investText2'} />
                            <LottieAnimation animationJSON={props.photo2} loop={true} isStopped={playAnime.sec4} />
                        </div>
                        <div className='single-step'>
                            <Text tag={'h2'} className={'title h2 MonMedium'} text={'investTitle3'} />
                            <Text tag={'h6'} className={'subtitle h6 MonRegular'} text={'investText3'} />
                            <LottieAnimation animationJSON={props.photo3} loop={true} isStopped={playAnime.sec4} />
                        </div>
                        <div className='single-step'>
                            <Text tag={'h2'} className={'title h2 MonMedium'} text={'investTitle4'} />
                            <Text tag={'h6'} className={'subtitle h6 MonRegular'} text={'investText4'} />
                            <LottieAnimation animationJSON={props.photo4} loop={true} isStopped={playAnime.sec4} />
                        </div>
                    </div>
                </div>
            </Container>
        </BusinessStepStyle>
    )
})

export default BusinessStep;