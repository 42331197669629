import styled from 'styled-components'

const MenuStyle = styled.div`
	--menuItemMarTop: var(--sp3x);
	--menuItemMarLeft: var(--sp7x);

	background-color: var(--ultraLightGrey);
	padding-left: var(--containerPadding);
	padding-right: var(--containerPadding);
	border-bottom: 1px solid var(--lightGrey);

	.menu-items-cont {
		justify-content: space-between;
		max-width: var(--maxWidth);
		margin: 0 auto;

		.menu-item {
			margin: var(--menuItemMarTop) var(--menuItemMarLeft);
			line-height: var(--lineHeightS);
			position: relative;

			.Linkmenu-item {
				display: inline-block;
			}

			.dubleTitle {
				position: relative;
				z-index: 1;
				opacity: 0;
				visibility: hidden;
				pointer-events: none;
			}

			.Link {
				position: absolute;
				top: 0;
				left: 50%;
				z-index: 2;
				opacity: 1;
				transform: translateX(-50%);
				white-space: nowrap;
			}

			&:last-child {
				margin-right: 0;
			}
			&:first-child {
				margin-left: 0;
			}
            
			&:hover {
				.dubleTitle {
					opacity: 1;
					visibility: visible;
				}
				.Link {
					opacity: 0;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		--menuItemMarTop: var(--sp2-5x);
		--menuItemMarLeft: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.LaptopSize}) {
		--menuItemMarTop: var(--sp4x);
		--menuItemMarLeft: 0;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		--menuContMarBott: var(--sp8x);

		.burgerOverflowCont {
			overflow: hidden;
			position: fixed;
			top: var(--sp12x);
			right: 0;
			bottom: 0;
			width: 0;
			height: calc(100vh - var(--sp12x));
			pointer-events: none;
			transition: all var(--trTime2) ease-in-out;
			&.active {
				width: 100vw;
				pointer-events: all;
			}
			.burgerCont {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				height: 100%;
				width: 100vw;
				z-index: 11;
				padding: var(--sp4x) var(--headerPaddingLeftRigth) 0;
				background-color: var(--white);
				overflow: scroll;
				-webkit-overflow-scrolling: touch;

				.menu-items-cont {
					justify-content: flex-start;
					flex-wrap: wrap;
					max-width: var(--sp94x);
					margin: 0 0 var(--menuContMarBott);
					.menu-item {
						width: 50%;
						margin: 0 0 var(--menuItemMarTop);
						&:nth-child(odd) {
							padding-right: var(--sp5x);
						}
						&:nth-child(even) {
							padding-left: var(--sp5x);
							.Link {
								left: var(--sp5x);
							}
						}
						&:nth-last-child(-n + 2) {
							margin-bottom: 0;
						}
						.Link {
							left: 0;
							transform: translateX(0%);
							white-space: break-spaces;
						}
					}
				}

				.contactsSetion {
					border-top: 1px solid var(--lightGrey);
					padding: var(--sp5x) 0;
					display: flex;
					justify-content: space-between;
					.contactInfoWrapp {
						display: flex;
						a {
							margin-right: var(--sp7x);
							display: block;
							&:last-child {
								margin-right: 0;
								text-align: right;
							}
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		--menuItemMarTop: var(--sp5x);
		--menuContMarBott: var(--sp12x);
		--menuItemMarLeft: 0;

		.burgerCont {
			padding: var(--sp2-5x) var(--headerPaddingLeftRigth) 0;
			.contactsSetion {
				.contactInfoWrapp {
					flex-direction: column;
					a {
						margin-bottom: var(--sp2x);
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--menuItemMarTop: var(--sp2-5x);
		--menuItemMarLeft: 0;
		--menuContMarBott: var(--sp3x);

		.burgerOverflowCont {
			top: var(--sp10x);
			height: calc(100vh - var(--sp10x));
			.burgerCont {
				padding: var(--sp2x) var(--headerPaddingLeftRigth) 0;
				.menu-items-cont {
					.menu-item {
						width: 100%;
						.Link {
							left: 0;
							transform: translateX(0%);
						}
						&:nth-child(odd) {
							padding-right: 0;
						}
						&:nth-child(even) {
							padding-left: 0;
							.Link {
								left: 0;
							}
						}
						&:nth-last-child(-n + 2) {
							margin-bottom: var(--menuContMarBott);
						}
						&:last-child {
							margin-bottom: 0;
						}
					}
				}

				.contactsSetion {
					flex-direction: column;
					justify-content: center;
					padding: var(--sp3x) 0;
					.contactInfoWrapp {
						justify-content: space-between;
						/* margin-bottom:var(--sp3x); */
						a {
							margin-right: var(--sp5x);
						}
					}
					.socIconWrapp {
						justify-content: center;
					}
				}
			}
		}
	}
`

export default MenuStyle
