export default {
    routes: {
        home: {
            path: '/',
            slug: 'home',
            name: 'home',
            autoload: true,
        },
        investmentProjects: {
            path: '/investment-projects',
            slug: 'investment-projects',
            name: 'investmentProjects',
            autoload: true,
            postType: {
                slug: 'get-projects',
                name: 'projects',
                queryParamKeys: ['cat_ids', 'loc_ids', 'page', 'required_investment', 'margin_profitability', 'share_company', 'order_by', 's'], //* Don't change the order
            },
        },
        singleInvestmentProject: {
            path: '/investment-projects/:slug',
            slug: 'investment-projects',
            name: 'investmentProjects',
            singleType: 'singleInvestmentProject',
            autoload: true,
        },
        exportFromArmenia: {
            path: '/export-from-armenia',
            slug: 'export-from-armenia',
            name: 'exportFromArmenia',
            autoload: true,
            postType: {
                slug: 'get-products',
                name: 'products',
                queryParamKeys: ['cat_ids', 'loc_ids', 'page', 'order_by', 'type_ids', 'unit_price', 's'], //* Don't change the order
            },
        },
        singleExportFromArmenia: {
            path: '/export-from-armenia/:slug',
            slug: 'export-from-armenia',
            name: 'exportFromArmenia',
            singleType: 'singleExportFromArmenia',
            autoload: true,
        },
        news: {
            path: '/news',
            slug: 'news',
            name: 'news',
            autoload: true,
            postType: {
                slug: 'get-news',
                name: 'news',
                queryParamKeys: ['cat_ids', 'loc_ids', 'page'], //* Don't change the order
            },
        },
        singleNews: {
            path: '/news/:slug',
            slug: 'news',
            name: 'news',
            singleType: 'singleNews',
            autoload: true,
        },
        investmentsForBusiness: {
            path: '/investments-for-business',
            slug: 'investments-for-business',
            name: 'investmentsForBusiness',
            autoload: true,
        },
        ourServices: {
            path: '/our-services',
            slug: 'our-services',
            name: 'ourServices',
            autoload: true,
        },
        contactUs: {
            path: '/contact-us',
            slug: 'contact-us',
            name: 'contactUs',
            autoload: true,
        },
        businessInArmenia: {
            path: '/business-in-armenia',
            slug: 'business-in-armenia',
            name: 'businessInArmenia',
            autoload: true,
        },
        becomePartner: {
            path: '/become-partner',
            slug: 'become-partner',
            name: 'becomePartner',
            autoload: true,
        },
        whatIsaProductSubmission: {
            path: '/what-is-a-product-submission',
            slug: 'what-is-a-product-submission',
            name: 'whatIsaProductSubmission',
            name2: 'exportProducts',
            autoload: true,
        },
        privacyPolicy: {
            path: '/privacy-policy',
            slug: 'privacy-policy',
            name: 'termsConditions',
            autoload: true,
        },
        login: {
            path: '/login',
            slug: 'login',
            name: 'login',
            autoload: false,
        },
        productsCompanySingle: {
            path: '/products-company/:slug',
            slug: 'product-company',
            name: 'productsCompany',
            singleType: 'productsCompanySingle',
            autoload: true,
        },
        projectsCompanySingle: {
            path: '/projects-company/:slug',
            slug: 'project-company',
            name: 'projectsCompany',
            singleType: 'projectsCompanySingle',
            autoload: true,
        },
        //! Private Routes
        account: {
            path: '/account',
            slug: '',
            name: 'account',
            autoload: false,
            private: true,
        },
        purchaseHistory: {
            path: '/account/purchase-history',
            slug: 'purchase-history',
            name: 'purchaseHistory',
            autoload: true,
            private: true,
        },
        submissions: {
            path: '/account/submissions',
            slug: 'projects',
            name: 'submissions',
            autoload: true,
            private: true,
        },
        submitInvestmentProject: {
            path: '/submit-investment-project',
            slug: 'submit-investment-project',
            name: 'submitInvestmentProject',
            autoload: true,
            private: true,
            // redirectWhen: {
            // 	param: 'projectCompany',
            // 	paramValue: false,
            // 	redirectUrl: '/account/project-company',
            // },
        },
        editProject: {
            path: '/edit-project/:project-id',
            slug: '',
            name: 'editProject',
            autoload: false,
            private: true,
        },
        projectCompany: {
            path: '/account/project-company',
            slug: 'projects-company',
            name: 'projectCompany',
            autoload: true,
            private: true,
        },
        submitExportProduct: {
            path: '/submit-export-product',
            slug: 'submit-export-product',
            name: 'submitExportProduct',
            autoload: true,
            private: true,
        },
        editProduct: {
            path: '/products/:catId/:id',
            slug: 'products',
            name: 'editProduct',
            autoload: false,
            private: true,
        },
        submittedProducts: {
            path: '/account/submitted-products',
            slug: 'products',
            name: 'submittedProducts',
            autoload: true,
            always: true,
            private: true,
        },
        updateSubmittedProduct: {
            path: '/update-submitted-products',
            slug: 'update-submitted-products',
            name: 'updateSubmittedProduct',
            autoload: false,
            private: true,
        },
        productsCompany: {
            path: '/account/products-company',
            slug: 'products-company',
            name: 'productsCompany',
            autoload: true,
            private: true,
        },
    },
}
