import { useState, useMemo, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { Container, Text, Image, Button } from 'components/common'
import RegisterPlanStyle from './style'


import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
gsap.core.globals('ScrollTrigger', ScrollTrigger)

const RegisterPlan = forwardRef((props, ref) => {

	const titleRef = useRef()
	const descriptionRef = useRef()
	const btnRef = useRef()

	const wrappRef = useRef()

	const itemRef1 = useRef(),
		itemRef1in = useRef(),
		itemRef2 = useRef(),
		itemRef2in = useRef(),
		itemRef3 = useRef(),
		itemRef4 = useRef(),
		itemRef5 = useRef(),
		itemRef6 = useRef(),
		itemRef7 = useRef(),
		itemRef8 = useRef(),
		itemRef9 = useRef(),
		itemRef10 = useRef()


	useImperativeHandle(ref, () => [titleRef.current.childNodes, descriptionRef, btnRef,
	[itemRef1.current,
	itemRef1in.current[0],
	itemRef2.current,
	itemRef2in.current[0],
	itemRef3.current[0],
	itemRef4.current[0],
	itemRef5.current[0],
	itemRef6.current[0],
	[itemRef8.current[0], itemRef9.current[0]],
	[itemRef7.current[0], itemRef10.current[0]]]], [])


	return (
		<RegisterPlanStyle className={'register-plan'}>
			<Container className={'register-plan-block'} ref={wrappRef}>
				<div className='content'>
					<Text tag={'stringToLetters'} className={'title textAlignCenter h3 MonBold black'} text={props.title} ref={titleRef} />
					<Text tag={'h3'} className={'description textAlignCenter h3 MonRegular black'} text={props.text} ref={descriptionRef} />
					<Button className={'btn btn-black MonMedium'} text={'importSuggest'} wrappClassName={'otherElementAnimation '} link={props.url} ref={btnRef} />
				</div>
				<div className='men-cont' ref={itemRef1}>
					<Image className='men' src={props.men} alt='menPhoto' ref={itemRef1in} />
				</div>
				<div className='women-cont' ref={itemRef2}>
					<Image className='women' src={props.women} alt='womenPhoto' ref={itemRef2in} />
				</div>
				<Image alt='shape' className='filledCircle' src='/images/news/filledCircle.svg' ref={itemRef3} />
				<Image alt='shape' className='dollar' src='/images/investInBusiness/dollar.svg' ref={itemRef4} />
				<Image alt='shape' className='halfYellow' src='/images/news/yellowHalf.svg' ref={itemRef10} />
				<Image alt='shape' className='halfGreen' src='/images/news/greenHalf.svg' ref={itemRef6} />
				<Image alt='shape' className='holeCircle2' src='/images/news/holeCircle.svg' ref={itemRef7} />
				<Image alt='shape' className='holeYellowCircle' src='/images/news/holeYellowCircle.svg' ref={itemRef8} />
				<Image alt='shape' className='greenTriangle' src='/images/news/greenTriangle.svg' ref={itemRef9} />
				<Image alt='shape' className='orangeTriangle' src='/images/news/orangeTriangle.svg' ref={itemRef5} />
			</Container>
		</RegisterPlanStyle>
	)
})

export default RegisterPlan
