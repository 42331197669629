import React, { memo as Memo } from 'react'

//* Components
import { Image, Text } from 'components/common'


//* Style
import MapPopInfoStyle from './style'

const MapPopInfo = Memo((props) => {
	return (
		<MapPopInfoStyle className={'FlexBoxContainer'}>
			<Image layout='fill' src={props?.img} alt={props.title} />
			<div className='infoWrap'>
				<div className={' title pS MonRegular ultraDarkGrey'}>{props.title}</div>
				<div className={' popdupDescription pS MonMedium black'}>{props.description}</div>
				<Text tag={'div'} className={'price pS MonBold black'} text={props.price} />
			</div>
		</MapPopInfoStyle>
	)
})
export default MapPopInfo
