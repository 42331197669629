import styled from 'styled-components'

const CooperationWithInvestStyle = styled.div.attrs((props) => ({ className: props.className }))`
	.coop-with-invest-block {
		display: flex;
		align-items: flex-start;

		.content {
			display: flex;
			flex-direction: column;
			max-width: var(--sp100x);
			width: 100%;
			margin-right: var(--sp3x);

			.title {
				margin-bottom: var(--titleFromContent);
			}
		}

		.LottieContainer{
			flex: 1;
			padding-top: 28%;
			position: relative;
			transform:translateY(20vh);
			opacity:0;
			.LottieWrapp {
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		.coop-with-invest-block {
			.content {
				max-width: var(--sp83x);
				margin-right: var(--sp4x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		.coop-with-invest-block {
			.content {
				max-width: var(--sp68x);
				margin-right: var(--sp4x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		.coop-with-invest-block {
			.content {
				max-width: var(--sp57x);
				margin-right: var(--sp3x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		.coop-with-invest-block {
			flex-direction: column;

			.content {
				max-width: unset;
				margin-right: 0;
			}

			.LottieWrapp {
				margin-top: var(--sp4x);
				max-width: var(--sp64x);
				width: 100%;
				padding-top: 43%;
				align-self: center;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.coop-with-invest-block {
			flex-direction: column-reverse;

			.content {
				max-width: unset;
				margin-right: 0;
			}

			.LottieContainer {
				margin-bottom: var(--sp2x);
				width: 100%;
				padding-top: 57.5%;
				.LottieWrapp{
					position: unset;
				}
			}
		}
	}
`
export default CooperationWithInvestStyle
