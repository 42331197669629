import { memo as Memo, useCallback, useMemo } from 'react'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'

//* Components
import { Text, Button, Image } from 'components/common'
import { useRouter } from 'next/router'

//* Helpers
import { config } from 'helpers'

//* Style
import WarningPopupStyle from './style'

const WarningPopup = Memo(({ closePopup, ...props }) => {
	//! Router
	const router = useRouter()

	//! On Button Click
	const onClick = useCallback(() => {
		closePopup()
		router.push(config.routes.projectCompany.path)
	}, [])

	//! Routes from Config
	const routes = useMemo(() => config.routes, [])

	return (
		<WarningPopupStyle>
			<div className='FlexBox alignMiddle alignCenter'>
				<Image src='/images/svg/megafon.svg' alt={'WarningPopupTitleProject'} />
			</div>
			<div className='title textAlignCenter'>
				<Text tag={'p'} className={'h3 MonBold black'} text={'WarningPopupTitleProject'} />
			</div>
			<div className='description textAlignCenter'>
				<Text
					tag={'p'}
					className={'h5 MonRegular black'}
					text={'WarningPopupDescriptionProject'}
				/>
			</div>
			<Button
				onClick={onClick}
				className={`btn btn-black btn-max-width`}
				text={'AboutCompany'}
			/>
		</WarningPopupStyle>
	)
})

export default withLanguageContext(withUIContext(WarningPopup, ['closePopup']), ['translate', 'selectedLang'])
