import { memo as Memo } from 'react';
import { withLanguageContext } from "context";
import { withRouter } from 'next/router'
import _ from 'lodash';

const Translate = Memo((props) => {
    let compiled = props.languages[props.router.locale]?.[props.val] && _.template(props.languages[props.router.locale]?.[props.val]);
    return (
        (compiled && compiled(props.vars)) || props.val || null
    )
});

export default withRouter(withLanguageContext(Translate, '*'))
