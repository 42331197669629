import React, { memo as Memo, useCallback } from 'react'

//* HOCs
import { withLanguageContext, withUIContext, withAccountContext } from 'context'

//* Components
import { Text, Translate, CustomLink, FormItem, SocialLogin, FormContainer, FormInput } from 'components/common'
import { Button, Input } from 'antd'

//* Styles
import LoginRegisterStyle from 'styles/pages/LoginRegister'
import FormStyle from 'styles/pages/FormStyle'

const SignInForm = Memo(({ openRegisterPopup, login, ...props }) => {
    //! On Form Submit
    const onFinish = useCallback((values) => {
        return login(values).then((res) => {
            console.log('then')
        })
    }, [])

    return (
        <LoginRegisterStyle>
            <Text tag={'div'} className={' Title h4 MonMedium black'} text={'LoginYourAccount'} />

            <div className='subTitle'>
                <Text tag={'span'} className={' pL MonRegular ultraDarkGrey'} text={'DontHaveAccountSignUp'} />

                <Text
                    onClick={openRegisterPopup}
                    tag={'span'}
                    className={' pL MonRegular black underline cursorPointer'}
                    text={'DontHaveAccountSignUp2'}
                />
            </div>

            <SocialLogin />

            <div className='SignInFormWrapp'>
                <FormStyle>
                    <FormContainer layout={'vertical'} id='login' onFinish={onFinish} className={'FormRow FlexBoxContainer spaceBetween'}>
                        {props.winWidth >= 768 ? (
                            <>
                                <div className='FormCol FormCol-50 marginNone'></div>

                                <div className='FormCol FormCol-50 marginNone FlexBox justifyEnd'>
                                    <Text
                                        tag={'span'}
                                        onClick={props.openForgotPopup}
                                        className={'ForgotPassword ultraDarkGrey underline cursorPointer'}
                                        text={'ForgotPassword'}
                                    />
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                        <div className='FormCol FlexBox AlignEnd FormCol-50'>
                            <FormItem name={'email'} required={true} validationType='email'>
                                <FormInput placeholder={props.translate('Email') + '*'} />
                            </FormItem>
                        </div>

                        <div className='FormCol FormCol-50'>
                            <FormItem name='password' validationType='password' required={true}>
                                <Input.Password autoComplete='on' placeholder={props.translate('Password') + '*'} />
                            </FormItem>
                        </div>

                        {props.winWidth < 768 && (
                            <div className='FormCol FormCol-50 marginNone FlexBox justifyEnd'>
                                <Text
                                    tag={'span'}
                                    onClick={props.openForgotPopup}
                                    className={'ForgotPassword ultraDarkGrey underline cursorPointer'}
                                    text={'ForgotPassword'}
                                />
                            </div>
                        )}

                        <div className='formBtnWrapp'>
                            <Button htmlType={'Submit'} className={'btn btn-black btn-max-width'}>
                                <Translate val={'login'} />
                            </Button>
                        </div>

                        <Text tag={'div'} className={'FormCol bottomText pM ultraDarkGrey MonRegular'}>
                            <Translate val='ByLoggingCreatingAccount' />

                            <CustomLink url={'/privacy-policy'} className={'black underline'} options={{ target: '_blank' }}>
                                <Translate val='PrivacyPolicyTermsService' />
                            </CustomLink>
                        </Text>
                    </FormContainer>
                </FormStyle>
            </div>
        </LoginRegisterStyle>
    )
})

export default withLanguageContext(
    withAccountContext(withUIContext(SignInForm, ['openRegisterPopup', 'openForgotPopup', 'winWidth', 'screenSizes']), ['login']),
    ['translate']
)
