import styled from 'styled-components'

const FilterStyle = styled.div.attrs((props) => ({ className: props.className }))`
	.filter {
		overflow: hidden;

		&-section {
			background-color: var(--ultraLightGrey);
			padding-top: var(--sp7x);
			padding-bottom: var(--sp7x);
			margin-bottom: var(--sp12x);

			&-block {
				display: flex;
				flex-direction: column;
				margin-bottom: 0;

				.top {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.close-filter {
						display: flex;
						justify-content: space-between;
						align-items: center;
						cursor: pointer;

						&.inActive {
							i {
								transform: rotate(0);
							}
						}

						i {
							margin-left: var(--sp2x);
							font-size: var(--sp2x);
							transform: rotate(180deg);
							transition: var(--trTime2) ease-out;
						}
					}
				}

				.map {
					.MuiCollapse-wrapperInner {
						display: flex;
						padding-top: var(--sp7x);

						.left {
							display: flex;
							flex-direction: column;
							flex: 1;
							margin-right: var(--sp15x);

							.title {
								margin-bottom: var(--titleFromContent);

								&.sec-title {
									margin-top: var(--sp4x);
								}
							}

							.resetBtn {
								margin-top: var(--sp5x);

								.wordWrap{
									display: inline-block;
									position: relative;
									line-height: 1;
									&:after{
										content: "";
										position: absolute;
										bottom: 15%;
										left:0;
										right:0;
										height: 1px;
										background-color: var(--black);
									}
								}
							}

							.filters {
								display: flex;
								flex-wrap: wrap;
								> span {
									transform: translateY(150%);
								}

								&.states {
									max-width: var(--sp91x);
									width: 100%;
								}
							}
						}

						.right {
							max-width: var(--sp81x);
							padding-left: var(--sp16x);
							width: 100%;
							position: relative;

							.single-cat {
								transform: translateY(150%);
							}

							.scale {
								position: absolute;
								bottom: 0;
								left: 0;
								display: flex;
								flex-direction: column;

								.numOfNews {
									margin-top: var(--sp3x);
								}

								.single-row {
									display: flex;
									align-items: flex-end;
									justify-content: flex-start;
									transform: translateY(10vh);

									&:nth-child(1) span {
										background-color: #fff4c0;
									}

									&:nth-child(2) span {
										background-color: #ffea82;
									}

									&:nth-child(3) span {
										background-color: #ffe14a;
									}

									&:nth-child(4) span {
										background-color: #fad82c;
									}

									&:nth-child(5) span {
										background-color: #f3cb02;
									}

									span {
										width: var(--sp5x);
										height: var(--sp5x);
										display: block;
										margin-right: var(--sp1-5x);
									}
								}
							}

							.map-cont {
								path {
									cursor: pointer;
									transition: var(--trTime);

									&.active {
										fill: #e6e6e6 !important;
									}

									&.less-than-5 {
										fill: #fff4c0;
									}

									&.from-5-10 {
										fill: #ffea82;
									}

									&.from-10-20 {
										fill: #ffe14a;
									}

									&.from-20-40 {
										fill: #fad82c;
									}

									&.more-than-40 {
										fill: #f3cb02;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		.filter {
			&-section {
				padding-top: var(--sp6x);
				padding-bottom: var(--sp6x);
				margin-bottom: var(--sp10x);

				&-block {
					.map {
						.MuiCollapse-wrapperInner {
							padding-top: var(--sp6x);

							.left {
								margin-right: var(--sp12x);

								.title {
									&.sec-title {
										margin-top: var(--sp4x);
									}
								}

								.resetBtn {
									margin-top: var(--sp4x);
								}

								.filters {
									&.cats {
										.single-cat {
											margin-right: var(--sp2x);
											margin-bottom: var(--sp2x);
										}
									}

									&.states {
										max-width: var(--sp75x);
									}
								}
							}

							.right {
								max-width: var(--sp68x);
								padding-left: 0;

								.scale {
									.numOfNews {
										margin-top: var(--sp2x);
									}

									.single-row {
										span {
											width: var(--sp4x);
											height: var(--sp4x);
											margin-right: var(--spSize);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		.filter {
			&-section {
				padding-top: var(--sp5x);
				padding-bottom: var(--sp5x);
				margin-bottom: var(--sp7-5x);

				&-block {
					.map {
						.MuiCollapse-wrapperInner {
							padding-top: var(--sp5x);

							.left {
								margin-right: var(--sp12x);

								.title {
									&.sec-title {
										margin-top: var(--sp3x);
									}
								}

								.resetBtn {
									margin-top: var(--sp3x);
								}

								.filters {
									&.cats {
										.single-cat {
											margin-right: var(--sp2x);
											margin-bottom: var(--sp2x);
										}
									}

									&.states {
										max-width: var(--sp72x);
									}
								}
							}

							.right {
								max-width: var(--sp68x);
								padding-left: 0;

								.scale {
									.numOfNews {
										margin-top: var(--sp2x);
									}

									.single-row {
										span {
											width: var(--sp4x);
											height: var(--sp4x);
											margin-right: var(--spSize);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		.filter {
			&-section {
				padding-top: var(--sp4x);
				padding-bottom: var(--sp4x);
				margin-bottom: var(--sp5x);

				&-block {
					.map {
						& > .MuiCollapse-wrapperInner {
							padding-top: var(--sp4x);

							.left {
								margin-right: var(--sp11x);

								.title {
									&.sec-title {
										margin-top: var(--sp2-5x);
									}
								}

								.resetBtn {
									margin-top: var(--sp2x);
								}

								.filters {
									&.cats {
										.single-cat {
											margin-right: var(--sp1-25x);
											margin-bottom: var(--sp1-25x);
										}
									}

									&.states {
										max-width: var(--sp48x);
									}
								}
							}

							.right {
								max-width: var(--sp68x);
								padding-left: 0;

								.scale {
									.numOfNews {
										margin-top: var(--sp2x);
									}

									.single-row {
										span {
											width: var(--sp4x);
											height: var(--sp4x);
											margin-right: var(--spSize);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		.filter {
			&-section {
				padding-top: var(--sp4x);
				padding-bottom: var(--sp4x);
				margin-bottom: var(--sp5x);

				&-block {
					.map {
						.MuiCollapse-wrapperInner {
							padding-top: var(--sp4x);
							flex-direction: column;

							.left {
								margin-right: 0;

								.title {
									&.sec-title {
										margin-top: var(--sp2-5x);
									}
								}

								.resetBtn {
									margin-top: var(--sp2x);
								}

								.filters {
									&.cats {
										.single-cat {
											margin-right: var(--sp1-25x);
											margin-bottom: var(--sp1-25x);
										}
									}

									&.states {
										max-width: var(--sp48x);
									}
								}
							}

							.right {
								max-width: unset;
								width: 100%;
								padding-left: 0;
								display: flex;
								justify-content: flex-end;
								margin-top: var(--sp4x);

								.map-cont {
									max-width: var(--sp64x);
								}

								.scale {
									.numOfNews {
										margin-top: var(--sp2x);
									}

									.single-row {
										span {
											width: var(--sp4x);
											height: var(--sp4x);
											margin-right: var(--spSize);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.filter {
			&-section {
				padding-top: var(--sp3-5x);
				padding-bottom: var(--sp3-5x);
				margin-bottom: var(--sp5x);

				&-block {
					padding-left: 0;
					padding-right: 0;

					.top {
						.close-filter {
							p {
								display: none;
							}
						}
					}

					.map {
						.MuiCollapse-wrapperInner {
							padding-top: var(--sp3-5x);
							flex-direction: column;

							.left {
								margin-right: 0;

								.title {
									&.sec-title {
										margin-top: var(--sp2-5x);
									}
								}

								.resetBtn {
									margin-top: var(--sp1-5x);
								}

								.filters {
									&.cats {
										.single-cat {
											margin-right: var(--sp2x);
											margin-bottom: var(--sp2x);
										}
									}

									&.states {
										max-width: unset;
									}
								}
							}

							.right {
								max-width: unset;
								width: 100%;
								padding-left: 0;
								display: flex;
								justify-content: flex-end;
								margin-top: var(--sp2x);
								padding-top: 100%;

								.map-cont {
									max-width: unset;
									width: 100%;
									transform: translateX(10%);
									position: absolute;
									top: 0;
									left: 0;
								}

								.scale {
									.numOfNews {
										margin-top: var(--sp2x);
									}

									.single-row {
										span {
											width: var(--sp4x);
											height: var(--sp4x);
											margin-right: var(--spSize);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`
export default FilterStyle
