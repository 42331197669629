import React, { Component, createContext } from 'react'
import { withRouter } from 'next/router'
import withUIContext from '../consumerHOC/UIConsumer'
import Cookie from 'js-cookie'
import { api, getUser } from 'helpers'
import withDataContext from '../consumerHOC/DataConsumer'

const AccountContext = createContext(null)

class AccountProvider extends Component {
	//! States
	state = {
		isLoggedIn: '',
		user: '',
		updatingUser: false,
		activateAccountWarning: false,
		...this.props.newUser,
	}

	//! Methods
	methods = {
		resendVerficationMail: this.resendVerficationMail.bind(this),
		updateProfile: this.updateProfile.bind(this),
		updatePassword: this.updatePassword.bind(this),
		login: this.login.bind(this),
		loginSocial: this.loginSocial.bind(this),
		register: this.register.bind(this),
		logout: this.logout.bind(this),
		forgotPassword: this.forgotPassword.bind(this),
		resetPassword: this.resetPassword.bind(this),
		updateInvetmentProject: this.updateInvetmentProject.bind(this),
		submitInvetmentProject: this.submitInvetmentProject.bind(this),
		submitProjectCompany: this.submitProjectCompany.bind(this),
		submitExportProducts: this.submitExportProducts.bind(this),
		createExportProduct: this.createExportProduct.bind(this),
		editExportProduct: this.editExportProduct.bind(this),
		renewExportProductsGroup: this.renewExportProductsGroup.bind(this),
		submitProductsCompany: this.submitProductsCompany.bind(this),
		submitProjectContact: this.submitProjectContact.bind(this),
		submitProductContact: this.submitProductContact.bind(this),
	}

	//! Component Did Mount
	componentDidMount() {
		if (typeof window !== undefined) {
			const splitedHref = window.location.href.split('#')

			if ((window.location.hash && window.location.hash == '#_=_') || (splitedHref.length === 2 && !splitedHref[1])) {
				if (window.history && history.pushState) {
					window.history.pushState('', document.title, window.location.pathname)
				} else {
					window.location.hash = ''
				}
			}
		}
		this.props.router.events.on('routeChangeStart', this.routeChangeStart.bind(this))
	}

	//! Component Will Unmount
	componentWillUnmount() {
		this.props.router.events.off('routeChangeStart', this.routeChangeStart.bind(this))
	}

	//! Component Did Update
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.router.asPath !== this.props.router.asPath /* || this.state.isLoggedIn !== prevState.isLoggedIn */) {
			getUser(this.props.router.locale, this.state, this.props.router).then((res) => {
				this.setState({ ...res.newState, updatingUser: false }, () => {
					const routerObj = {
						pathname: res.redirectUrl || (this.state.isLoggedIn && prevProps.router.pathname) || '/',
						query: {
							c: res.redirectQuery || 'login',
						},
					}
					res.redirect && this.props.router.push(routerObj)
				})
			})
		}

		//! Disable Activate Account Warning State after Close Popup
		if (this.props.popupIsOpen && this.state.activateAccountWarning) {
			this.setState({ activateAccountWarning: false })
		}
	}

	//! Route Change Start
	routeChangeStart(url) {
		url !== this.props.router.asPath && this.setState({ updatingUser: true })
	}

	//! Resend Verification Mail
	resendVerficationMail() {
		return this.asyncFunctionDecorator(() => api.post('/email/verify/resend', {}, this.props.router.locale))
	}

	//! Update Profile
	updateProfile(data) {
		return this.validateAccountStatus(() => this.asyncFunctionDecorator(() => api.post('update-account', data, this.props.router.locale)))
	}

	//! Update Password
	updatePassword(data) {
		return this.validateAccountStatus(() => this.asyncFunctionDecorator(() => api.post('update-password', data, this.props.router.locale)))
	}

	//! Login
	login(data) {
		return this.asyncFunctionDecorator(() =>
			api.post('login', data, this.props.router.locale).then(({ access_token, user }) => {
				Cookie.set('access_token', access_token, { expires: 182 })

				this.setState(
					{
						isLoggedIn: true,
						user,
					},
					() => {
						this.props.closePopup()
					}
				)
			})
		)
	}

	//! User Social Login
	loginSocial(provider) {
		const url = this.props.router.asPath.split('?')[0]
		window.location.href = `${process.env.REACT_APP_API_SERVER}social-auth/${this.props.router.locale}/${provider}?backUrl=${url}`
	}

	//! User Registration
	register(data) {
		return this.asyncFunctionDecorator(() =>
			api.post('register', data, this.props.router.locale).then(({ access_token, user }) => {
				Cookie.set('access_token', access_token)

				this.setState({
					isLoggedIn: true,
					user,
				})
			})
		)
	}

	//! Logout
	logout() {
		return this.asyncFunctionDecorator(() =>
			api.post('logout').then(() => {
				Cookie.remove('access_token')
				this.setState({ isLoggedIn: false, actionType: 'logout' })
				this.props.router.push('/')
			})
		)
	}

	//! Forgot Password
	forgotPassword(data) {
		return this.asyncFunctionDecorator(() => api.post('forgot-password', data))
	}

	//! Reset Password
	resetPassword(data) {
		return this.asyncFunctionDecorator(() => api.post('reset-password', data))
	}

	//! Submit Investment Project
	submitInvetmentProject(data) {
		return this.validateAccountStatus(() =>
			this.asyncFunctionDecorator(() => api.post('projects', data, this.props.router.locale, 'multipart/form-data'))
		)
	}

	//! Update Investment Project
	updateInvetmentProject(data, id) {
		return this.validateAccountStatus(() =>
			this.asyncFunctionDecorator(() => api.put(`projects/${id}`, data, this.props.router.locale, 'multipart/form-data'))
		)
	}

	//! Submit Project Company
	submitProjectCompany(data, id) {
		return this.validateAccountStatus(() =>
			this.asyncFunctionDecorator(() => api.post(`projects-company${id ? `/${id}` : ''}`, data, this.props.router.locale))
		)
	}

	//! Submit Export Products (Multiple)
	submitExportProducts(data) {
		return this.validateAccountStatus(() =>
			this.asyncFunctionDecorator(() => api.post('products', data, this.props.router.locale, 'multipart/form-data'))
		)
	}

	//! Submit Export Product (Single)
	createExportProduct(data) {
		return this.validateAccountStatus(() =>
			this.asyncFunctionDecorator(() => api.post(`store-group-product`, data, this.props.router.locale, 'multipart/form-data'))
		)
	}

	//! Edit Export Product (Single)
	editExportProduct(data, prodId) {
		return this.validateAccountStatus(() =>
			this.asyncFunctionDecorator(() => api.put(`products/${prodId}`, data, this.props.router.locale, 'multipart/form-data'))
		)
	}

	//! Renew Export Products Group
	renewExportProductsGroup(data) {
		return this.validateAccountStatus(() => this.asyncFunctionDecorator(() => api.post(`renew-group`, data, this.props.router.locale)))
	}

	//! Submit Export Products Company
	submitProductsCompany(data, id) {
		return this.validateAccountStatus(() =>
			this.asyncFunctionDecorator(() => api.post(`products-company${id ? `/${id}` : ''}`, data, this.props.router.locale))
		)
	}

	//! Contact for Project Business
	submitProjectContact(data) {
		return this.asyncFunctionDecorator(() => api.post(`submit-project-contact`, data, this.props.router.locale))
	}
	//! Contact for Product Business
	submitProductContact(data) {
		return this.asyncFunctionDecorator(() => api.post(`submit-product-contact`, data, this.props.router.locale))
	}

	//! Async Function Catch Decorator
	asyncFunctionDecorator(asyncFunc) {
		return asyncFunc().catch((err) => {
			return Promise.reject(err.response)
		})
	}

	//! Account Status Decorator
	validateAccountStatus(asyncFunc) {
		return this.state.user.verified ? asyncFunc() : new Promise((res) => this.setState({ activateAccountWarning: true }, () => res()))
	}

	render() {
		return <AccountContext.Provider value={{ ...this.state, ...this.methods }}>{this.props.children}</AccountContext.Provider>
	}
}

export default withRouter(
	withUIContext(withDataContext(AccountProvider, ['prevRoute']), ['addErrorMsg', 'removeErrorMsg', 'closePopup', 'popupIsOpen'])
)
export const AccountConsumer = AccountContext.Consumer
