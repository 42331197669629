import { memo as Memo, useState, useMemo, useCallback, useEffect, forwardRef, useImperativeHandle } from 'react'

//* Components
import { Slider } from 'antd'
import { Text } from 'components/common'
import { useRouter } from 'next/router'
import Collapse from '@material-ui/core/Collapse'

//* Helpers
import { getParamsByKey } from 'helpers'

const PriceFilter = forwardRef(({ unitPrices, queryParamKeys, onCategoryChange }, ref) => {
    //! Router
    const router = useRouter()

    //! States
    const [opened, setOpened] = useState(true)
    const [unitPricesState, setUnitPricesState] = useState([unitPrices[0], unitPrices[1]])

    useImperativeHandle(ref, () => ({
        setUnitPricesState: (arr) => setUnitPricesState(arr)
    }))
    //! Marks Object
    const marks = useMemo(() => ({ 0: `${unitPrices[0]}$`, [unitPrices[1]]: `${unitPrices[1]}$` }), [])

    //! Get Category Param and Active Items
    const getParamWidthActiveItems = useMemo(() => {
        const catParam = queryParamKeys[5] //? "'unit_price'"
        const activeItems = getParamsByKey(router, catParam)

        return { catParam, activeItems }
    }, [queryParamKeys, router.query])

    //! Unit Price Filter
    const unitPriceChange = useCallback(
        (value) => {
            const { catParam, activeItems } = getParamWidthActiveItems
            const newParam = value[0] === unitPrices[0] && value[1] === unitPrices[1] ? router.query[catParam] : `${value[0]},${value[1]}`

            if (!activeItems || (activeItems && (activeItems[0] != value[0] || activeItems[1] != value[1]))) {
                onCategoryChange(catParam, newParam, false)
            }
        },
        [queryParamKeys, unitPricesState, router.query]
    )

    //! Default Value
    useEffect(() => {
        setUnitPricesState(getParamWidthActiveItems.activeItems ? getParamWidthActiveItems.activeItems : [unitPrices[0], unitPrices[1]])
    }, [router.query])

    return (
        <div className={`single-filter overflowHidden ${opened ? 'active' : ''}`}>
            <Text tag={'h5'} onClick={() => setOpened(!opened)} className={'title h5 MonMedium black'} text={'unitStartingPrice'} />
            <Collapse in={opened} timeout='auto'>
                <div className='selected'>
                    <Text tag={'p'} className={'pM MonRegular ultraDarkGrey capitalize'} text={'selected'} />
                    <Text tag={'p'} className={'pL MonMedium'} text={`${unitPricesState[0]}$ - ${unitPricesState[1]}$`} />
                </div>
                <Slider
                    range
                    marks={marks}
                    max={unitPrices[1]}
                    value={[unitPricesState[0], unitPricesState[1]]}
                    onChange={(value) => setUnitPricesState([value[0], value[1]])}
                    onAfterChange={(value) => unitPriceChange(value)}
                />
            </Collapse>
        </div>
    )
})

export default PriceFilter
