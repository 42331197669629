import { memo as Memo, useMemo, useState } from 'react'

//* Components
import { CategoryBtn } from 'components/pages'
import { Text } from 'components/common'
import Collapse from '@material-ui/core/Collapse'

import { useRouter } from 'next/router'

//* Helpers
import { getParamsByKey } from 'helpers'


const LocationFilter = Memo(({ queryParamKeys, onCategoryChange, states }) => {

	//! States
	const [opened, setOpened] = useState(true)

	//! Router
	const router = useRouter()

	//! States Filter
	const storeStates = useMemo(() => {
		const catParam = queryParamKeys[1] //? "loc_ids"
		const activeItems = getParamsByKey(router, catParam)

		return states.map((i, k) => (
			<CategoryBtn
				key={k}
				active={activeItems?.includes(i.id.toString())}
				onChange={() => onCategoryChange(catParam, i.id)}
				id={i.id}
				slug={i.slug}
				text={i.name}
				inFilter={true}
				state={true}
			/>
		))
	}, [states, router.query])

	return (
		<div className={`single-filter overflowHidden ${opened ? 'active' : ''} location`}>
			<Text tag={'h5'} onClick={() => setOpened(!opened)} className={'title h5 MonMedium black'} text={'filterLocation'} />
			<Collapse in={opened} timeout='auto'>
				<div className='block'>{storeStates}</div>
			</Collapse>
		</div>
	)
})

export default LocationFilter
