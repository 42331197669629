import styled from 'styled-components'

const ProjectInfoStyle = styled.div.attrs({})`
	padding-left: var(--sp8x);
	display: flex;
	flex-direction: column;
	position: sticky;
	top:var(--sp3x);

	.single-cat {
		align-self: flex-start;
		transform: translateY(30vh);
	}

	.project-title {
		margin: var(--sp3x) 0 var(--sp4x);
		transform: translateY(30vh);
	}

	.location-company {
		display: flex;
		justify-content: space-between;
		transform: translateY(30vh);

		.location {
			display: flex;
			align-items: center;

			i {
				margin-right: var(--sp1-5x);
			}
		}

		.company-name {
			display: inline-flex;
			position: relative;
			width: fit-content;

			p:last-child {
				margin-left: var(--sp0-75x);
			}
			&:after{
				content:"";
				position: absolute;
				bottom: 15%;
				left:0;
				right:0;
				height:1px;
				width:100%;
				background-color: currentColor;
			}
		}
	}

	.req-invest {
		display: flex;
		flex-direction: column;
		margin-top: var(--sp6x);
		transform: translateY(30vh);

		.prices {
			margin-top: var(--sp2-5x);
			display: flex;

			h4:first-child {
				margin-right: var(--sp2-5x);
			}
		}
	}

	.project-options {
		display: flex;
		justify-content: space-between;
		flex-flow: wrap;
		padding-top: var(--sp3x);
		margin-top: var(--sp3x);
		margin-bottom: var(--sp4-5x);
		border-top: 1px solid var(--lightGrey);

		.option {
			width: calc(50% - var(--sp3-5x));
			display: flex;
			flex-direction: column;
			transform: translateY(30vh);

            &.full {
                width: 100% !important;
            }

			&:not(:nth-child(1)):not(:nth-child(2)) {
				margin-top: var(--sp4x);
			}

			.option-title {
				margin-bottom: var(--sp2x);
				display: flex;
				align-items: center;

				i {
					font-size: 18px;
					margin-left: var(--sp1-5x);
				}
			}
			.uppercase{
				line-height: 1.9;
			}

			.child {
				& > *:nth-child(2) {
					margin-top: var(--sp1-5x);
				}
			}
		}
	}

	.btn-action{
		.btn{
			transform: translateY(30vh);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		.project-title {
			margin: var(--sp2x) 0 var(--sp3x);
		}

		.req-invest {
			margin-top: var(--sp4-5x);

			.prices {
				margin-top: var(--sp2x);

				h4:first-child {
					margin-right: var(--sp4-5x);
				}
			}
		}

		.project-options {
			padding-top: var(--sp2-5x);
			margin-top: var(--sp2-5x);
			margin-bottom: var(--sp4x);

			.option {
				width: calc(50% - var(--sp1-5x));

				&:not(:nth-child(1)):not(:nth-child(2)) {
					margin-top: var(--sp3-5x);
				}
			}
		}
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		.project-title {
			margin: var(--sp1-5x) 0 var(--sp2x);
		}

		.req-invest {
			margin-top: var(--sp3x);

			.prices {
				margin-top: var(--sp2x);

				h4:first-child {
					margin-right: var(--sp4-5x);
				}
			}
		}

		.project-options {
			padding-top: var(--sp2x);
			margin-top: var(--sp2x);
			margin-bottom: var(--sp3-5x);

			.option {
				width: calc(50% - var(--sp1-5x));

				&:not(:nth-child(1)):not(:nth-child(2)) {
					margin-top: var(--sp3x);
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		max-width: unset;
		padding-left: 0;
		margin-top: var(--sp5x);
		position: static;

		.project-title {
			margin: var(--sp2x) 0 var(--sp2x);
		}

		.req-invest {
			margin-top: var(--sp3x);

			.prices {
				margin-top: var(--sp1-5x);

				h4:first-child {
					margin-right: var(--sp3x);
				}
			}
		}

		.project-options {
			padding-top: var(--sp2x);
			margin-top: var(--sp2x);
			margin-bottom: var(--sp4x);

			.option {
				width: calc(50% - var(--sp3x));

				&:not(:nth-child(1)):not(:nth-child(2)) {
					margin-top: var(--sp3x);
				}
			}
		}

		div:last-child {
			align-self: flex-start;
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		max-width: unset;
		padding-left: 0;
		margin-top: var(--sp5x);
		position: static;

		.project-title {
			margin: var(--sp2-5x) 0 var(--sp1-5x);
		}

		.location-company {
			display: flex;
			flex-direction: column;

			.location {
				margin-bottom: var(--sp1-5x);
			}
		}

		.req-invest {
			margin-top: var(--sp3x);

			.prices {
				margin-top: var(--sp2x);

				h4:first-child {
					margin-right: var(--sp2-5x);
				}
			}
		}

		.project-options {
			padding-top: var(--sp2x);
			margin-top: var(--sp2x);
			margin-bottom: var(--sp4x);
			flex-direction: column;

			.option {
				width: 100%;

				.option-title {
					margin-bottom: var(--sp1-5x);
				}

				&:nth-child(2) {
					margin-top: var(--sp3x);
				}

				&:not(:nth-child(1)) {
					margin-top: var(--sp3x);
				}
			}
		}
	}
`
export default ProjectInfoStyle
