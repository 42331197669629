import styled from 'styled-components'

const GlobalErrorStyle = styled.div.attrs({})`

    --inputCollMarginBottom: var(--sp7x);
	--attentionIconSize:var(--sp5x);
	--gloalErrorInfoTitle:var(--sp2x);


	.gloalErrorWrap{
		padding: var(--sp3x) var(--sp4x);
		background-color:var(--ultraLightGrey);
		border-radius:var(--borderSm);
		border:1px solid var(--errColor);
		margin-bottom: var(--inputCollMarginBottom);
		.icon-attention{
			flex:auto;
			padding:var(--spSize) var(--spSize) calc(var(--spSize)*1.5);
			height:var(--attentionIconSize);
			width:var(--attentionIconSize);
			border-radius:var(--borderLg);
			background-color:var(--errColor);
			margin-right:var(--h4);
			span{
				display: inline-block;
				&:before{
					font-size:var(--h5);
				}
			}
		}
		.gloalErrorInfoWrap{
			flex:1 0 auto;
			h6{
				margin-bottom:var(--gloalErrorInfoTitle);
			}
		}
	}

    @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {

		--inputCollMarginBottom: var(--sp6x);

	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {

		--inputCollMarginBottom: var(--sp6x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {

		--gloalErrorInfoTitle:var(--spSize);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		--btnMarginTop: var(--spSize);
		--inputColPadding: var(--sp1-5x);
		--inputMarginBottom: var(--sp3x);
		--bottomTextMarginTop: var(--sp2x);
		--SuccessTopPadd: calc(var(--inputPaddingIn) / 1.3);
		--rowWrapMargBot: var(--sp4x);
		--inputCollMarginBottom: var(--sp5x);

		--largeFormItemRightWidth: var(--sp26x);
		--largeFormItemLeftItemWidth: var(--sp26x);
		--largeFormItemLeftWidth: 89%;
		--textareaMinHeight: var(--sp25x);
		--inputPaddingIn: var(--sp1-5x);
		--groupMarginBottom: var(--sp10x);

		--radioButtonInnerSize: var(--sp2-5x);
		--extraPaadingTop: var(--sp1-3x);
	}


	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {

		--deliteIconSize: var(--sp3x);
		--attentionIconSize:var(--sp4x);

	}
`
export default GlobalErrorStyle
