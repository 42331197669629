import { memo as Memo, useCallback, useEffect, useRef, useState } from 'react'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'

//* Components
import { Form } from 'antd'
import { Icon, Text, Button } from 'components/common'
import { SubmitExportProductItem } from 'components/pages'

const SubmitExportProduct = Memo(({ form, categories, subcategories, saveFieldsGroup, removeFieldsGroup, ...props }) => {
	//! States
	const [activeAddBtn, setActiveAddBtn] = useState(true)

	//! Refs
	const buttonRef = useRef()
	const scrollContRef = useRef()

	//! Adding First Form
	useEffect(() => {
		buttonRef.current.click()
	}, [])

	//! Add Product
	const addProduct = useCallback((prodCount) => {
		setActiveAddBtn(false)
	}, [])

	//! Scroll To New Fields Croup
	useEffect(() => {
		if (!activeAddBtn && props.winWidth >= 1440) {
			scrollContRef.current.scrollLeft = scrollContRef.current.childNodes[0].getBoundingClientRect().width
		}
	}, [activeAddBtn, props.winWidth, scrollContRef])

	//! Save Product
	const saveProduct = useCallback(() => {
		setActiveAddBtn(true)
	}, [])

	//! Remove Product
	const removeProduct = useCallback(
		(isLastGroup) => {
			isLastGroup && setActiveAddBtn(true)

			removeFieldsGroup()
		},
		[removeFieldsGroup]
	)

	return (
		<Form.List name='products'>
			{(fields, { add, remove }) => {
				return (
					<div className={`SubmitExportProductSection ${props.winWidth >= 1440 && 'FlexBoxContainer'}`}>
						<div
							ref={scrollContRef}
							className={`SubmitExportProductItemSection ${fields.length > 2 && props.winWidth >= 1440 && 'scroll'}`}
						>
							<div className={`SubmitExportProductItemWrapp ${props.winWidth >= 1440 && 'FlexBox'}`}>
								{fields.map((field, index) => {
									return (
										<SubmitExportProductItem
											key={field.key}
											form={form}
											field={field}
											categories={categories}
											subcategories={subcategories}
											length={fields.length}
											saveProduct={() => saveProduct()}
											remove={() => {
												remove(field.name)
												removeProduct(fields.length - 1 === field.name)
											}}
										/>
									)
								})}
							</div>
						</div>

						<Button
							ref={buttonRef}
							className={`SubmitExportProductAdd greyBtn ${!activeAddBtn && 'disabled-opacity'}`}
							title={!activeAddBtn && props.translate('saveUnsavedProductBeforeAddNew')}
							onClick={() => {
								if (activeAddBtn) {
									add({ unit_price_currency: 'AMD', certification: 0 })
									addProduct(fields.length + 1)
								}
							}}
						>
							<Text tag={'span'} className={'pM MonMedium black'}>
								<Icon className='icon-addFile'>
									<span className='path1' />
									<span className='path2' />
								</Icon>
								{props.translate('AddNewProduct')}
							</Text>
						</Button>
					</div>
				)
			}}
		</Form.List>
	)
})

export default withUIContext(withLanguageContext(SubmitExportProduct, ['translate', 'selectedLang']), ['screenSizes', 'winWidth'])
