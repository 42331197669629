import { forwardRef, useRef, useImperativeHandle, useMemo } from 'react'

//! Components
import { Container, Text, Button, SingleCatalog } from 'components/common'

//! Helpers
import { config } from 'helpers'

//! Styles
import CatalogViewStyle from './style'

const CatalogView = forwardRef((props, ref) => {
    //! Refs
    const titleRef = useRef()
    const desRef = useRef()
    const itemRef = useRef()
    const btnRef = useRef()

    //! Imperative Handle
    useImperativeHandle(ref, () => [titleRef, desRef, itemRef, btnRef], [])

    //! Catalog List
    const storeCatalogs = useMemo(() => {
        return props.catalogsArr?.map((i, k) => {
            //? Product
            if (props.product) {
                return (
                    <SingleCatalog
                        key={k}
                        photo={i?.feature_image}
                        title={i?.name}
                        export={true}
                        categorySlug={i.categorySlug}
                        categoryName={i.category?.name}
                        url={`${config.routes.exportFromArmenia.path}/${i?.slug}`}
                        description={i.company?.short_description}
                        priceInDollar={i.unit_price?.USD}
                        priceInAMD={i.unit_price?.AMD}
                        companyName={`${i?.company?.name} ${i?.company?.type}`}
                        companyURL={config.routes.productsCompanySingle.path.replace(':slug', i?.company?.slug)}
                        bgColor={i?.category?.color}
                        interestedCountries={i.company?.interestCountries?.map(
                            (country, key) => `${country.name}${key < i.company.interestCountries.length - 1 ? ', ' : ''}`
                        )}
                    />
                )
            }
            //? Project
            else {
                return (
                    <SingleCatalog
                        key={k}
                        photo={i?.feature_image}
                        categorySlug={i?.category?.slug}
                        categoryName={i?.category?.name}
                        title={i?.name}
                        url={`${config.routes.investmentProjects.path}/${i?.slug}`}
                        location={i?.province?.name}
                        description={i?.short_description}
                        priceInDollar={`${i?.required_investment.USD}`}
                        priceInAMD={`${i?.required_investment.AMD}`}
                        profitPercent={`${i?.margin_profitability}`}
                        companyName={`${i?.company?.name} ${i?.company?.type}`}
                        companyURL={config.routes.projectsCompanySingle.path.replace(':slug', i.company.slug)}
                        bgColor={i?.category?.color}
                    />
                )
            }
        })
    }, [props.catalogsArr])

    return (
        <CatalogViewStyle className={'catalog-view'}>
            <Container className={`catalog-view-block overflowHidden ${props.notLast ? '' : 'last'} `}>
                <div className={`top ${props.homeText ? 'FlexBox spaceBetween' : 'FlexBoxColumn notHome'}`} ref={desRef}>
                    <Text tag={'stringToLetters'} className={'title top-title h2 MonBold black'} text={props.title} ref={titleRef} />
                    {props.singleInvest ? null : <Button link={props.url} yellowIcon={true} text={'learnMore'} />}
                    {props.homeText ? <Text tag={'h3'} className={'description home-text h6 MonRegular'} text={props.homeText} /> : null}
                </div>
                <div className='bottom' ref={itemRef}>
                    {storeCatalogs}
                </div>
                {!props.inProductSubmission ? (
                    <Button
                        link={props.url}
                        ref={btnRef}
                        wrappClassName={'otherElementAnimation'}
                        className={'btn btn-white'}
                        text={`${props.homeText ? (props.product ? 'moreProducts' : 'moreInvestment') : 'viewAll'}`}
                    />
                ) : null}
            </Container>
        </CatalogViewStyle>
    )
})

export default CatalogView
