import React, { forwardRef, useRef, useImperativeHandle } from 'react'

//* HOC's
import { withUIContext, withLanguageContext } from 'context'

import { Text, Container, LottieAnimation } from 'components/common'

const BusinessInArmeniaTop = forwardRef((props, ref) => {
	const titleRef1 = useRef()
	const descriptionRef1 = useRef()
	const titleRef2 = useRef()
	const itemsRef2 = useRef()

	useImperativeHandle(
		ref,
		() =>
			props.type != 2
				? [titleRef1.current.childNodes, descriptionRef1, titleRef2.current.childNodes, itemsRef2.current.childNodes]
				: [titleRef1.current.childNodes, descriptionRef1, itemsRef2.current.childNodes],
		[props.selectedLang]
	)

	return (
		<Container className='largePadding'>
			<div className='BusinessInArmeniaTopInfo largePadding'>
				<div className='FlexBoxContainer spaceBetween'>
					<Text tag={'stringToLetters'} className={'title h1 MonBold black'} ref={titleRef1} text={props.title} />
					<Text className={'description h6 MonRegular black'} ref={descriptionRef1} text={props.text} />
				</div>
			</div>

			<div className='BusinessInArmeniaTopRegMetod largePadding'>
				{props.type == 2 ? '' : <Text tag={'stringToLetters'} className={'h3 title MonRegular black'} ref={titleRef2} text={props.title} />}
				<div
					className={`FlexBoxContainer  ${props.winWidth < 1440 && props.winWidth >= 1024 && props.type !== 2 ? '' : 'spaceBetween'}`}
					ref={itemsRef2}
				>
					{props.type == 2 && props.winWidth >= 1024 && <div className='BusinessInArmeniaRegMetodItem bordNone '></div>}
					{props.data.map((item, key) => {
						return (
							<div key={key} className='BusinessInArmeniaRegMetodItem FlexBoxContainer spaceBetween'>
								<a href={item.link} target='_blank' className=' FlexBoxColumn alignMiddle spaceBetween'>
									<LottieAnimation loop={true} animationJSON={item.animationJSON} />
									<Text className={'RegMetodItemTitle MonRegular black'} text={item.title} />
								</a>
							</div>
						)
					})}
				</div>
			</div>
		</Container>
	)
})

export default withLanguageContext(withUIContext(BusinessInArmeniaTop, ['winWidth', 'screenSizes']), ['translate', 'selectedLang'])
