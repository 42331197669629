import { memo as Memo, useCallback } from 'react'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'
import { useRouter } from 'next/router'

//* Helpers
import { config } from 'helpers'

//* Components
import { Checkbox, Tooltip, Input, Select, Button, Upload } from 'antd'
import { FormItem, Icon, Text, FormInput, CustomLink } from 'components/common'

const UpdateSubmittedProduct = Memo(({ catName, catId, subcategories, ...props }) => {
    //! Uploader Fake Request
    const fakeRequest = useCallback(({ onSuccess }) => {
        onSuccess('ok')
    }, [])

    const selectBefore = (
        <FormItem name='unit_price_currency'>
            <Select suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />} className='select-before'>
                <Select.Option value='USD'>$</Select.Option>
                <Select.Option value='AMD'>֏</Select.Option>
            </Select>
        </FormItem>
    )

    //! Router
    const router = useRouter()

    return (
        <>
            <div className='FormColumnsWrapp FlexBoxContainer spaceBetween'>
                <div className={'FormCol FormCol-50'}>
                    <FormItem label={'ProductCategory'} name={'group_id'}>
                        <Select suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />} allowClear disabled>
                            <Select.Option value={catId}>{catName}</Select.Option>
                        </Select>
                    </FormItem>
                </div>

                <div className={'FormCol FormCol-50'}>
                    <FormItem label={'ExportProductType'} name={'category_id'}>
                        <Select suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />} allowClear>
                            {subcategories.map((s, k) => (
                                <Select.Option key={k} value={s.id}>
                                    {s.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                </div>

                <div className={'FormCol FormCol-50'}>
                    <FormItem label={'ProductName'} name={'name'}>
                        <FormInput />
                    </FormItem>
                </div>

                <div className={'FormCol FormCol-50'}>
                    <FormItem label={'StartingPricePerUnit'} name={'unit_price'} validationType='number' min={0}>
                        <FormInput type='number' addonBefore={selectBefore} />
                    </FormItem>
                </div>

                <div className={'FormCol FormCol-50'}>
                    <FormItem label={'MinimumOrder'} name={'minimum_order'}>
                        <FormInput type='number' />
                    </FormItem>
                </div>

                <div className={'FormCol FormCol-50'}>
                    <FormItem label={'Certifications'} name={'certification'}>
                        <Checkbox.Group>
                            <Checkbox value='1'>
                                <Text tag={'p'} className={'pM MonRegular ultraDarkGrey '} text={'Available'} />
                            </Checkbox>
                        </Checkbox.Group>
                    </FormItem>
                </div>

                <div className='FormCol'>
                    <FormItem label={'UploadImagesProduct'} name={'photos'} required={true} validationType='uploader'>
                        <Upload
                            accept='image/png, image/jpeg'
                            listType='picture-card'
                            fileList={props.photos}
                            maxCount={5}
                            multiple={true}
                            customRequest={fakeRequest}
                        >
                            <Icon className='icon-addFile'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                            </Icon>
                        </Upload>
                    </FormItem>
                </div>

                <div className='FormCol'>
                    <div className='relativeWrapp'>
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: props.translate('submitInvestmentProjectDetailsTooltip') }} />}>
                            <div className='HelperDesc'>
                                <Icon className='icon-quastion'>
                                    <span className='path1' />
                                    <span className='path2' />
                                </Icon>
                            </div>
                        </Tooltip>
                        <FormItem label={'details'} name={'details'} required={true} >
                            <Input.TextArea placeholder={props.translate('additionalFeatures')} />
                        </FormItem>
                    </div>
                </div>

                <div className={'formBtnWrapp FlexBoxContainer'}>
                    <div className='btnInline'>
                        <Button htmlType={'Submit'} className={`btn btn-black`}>
                            {props.translate(router.query.id === 'create' ? 'add' : 'Save')}
                        </Button>
                    </div>
                    <CustomLink className='FlexBox alignMiddle' url={config.routes.submittedProducts.path}>
                        <Text className='pM MonMedium darkGrey underline cursorPointer' text={'Cancel'} />
                    </CustomLink>
                </div>
            </div>
        </>
    )
})

export default withUIContext(withLanguageContext(UpdateSubmittedProduct, ['translate', 'selectedLang']), ['screenSizes', 'winWidth'])
