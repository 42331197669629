import styled from 'styled-components'

const FilterInCatalogueStyle = styled.div.attrs({ className: 'filter' })`
	max-width: var(--sp47x);
	width: 100%;

	.filters {
		display: flex;
		flex-direction: column;
		margin-top: var(--sp3x);

		.single-filter {
			display: flex;
			flex-direction: column;
			margin-bottom: var(--sp8x);

			&.active {
				.title {
					margin-bottom: var(--sp4x);

					&:after {
						transform: translate(50%, -50%) rotate(-270deg);
					}
				}
			}

			& > .title {
				padding-right: var(--sp8x);
				position: relative;
				cursor: pointer;
				margin-bottom: 0;
				transition: margin-bottom var(--trTime) ease;

				&:before {
					content: '';
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 0;
					width: var(--sp3x);
					height: 3px;
					background-color: var(--black);
					transition: var(--trTime2) ease-out;
					border-radius: 4px;
				}

				&:after {
					content: '';
					position: absolute;
					top: 50%;
					transform: translate(50%, -50%);
					right: calc((var(--sp3x) / 2));
					height: var(--sp3x);
					width: 3px;
					background-color: var(--black);
					transition: var(--trTime2) ease-out;
					border-radius: 4px;
				}
			}

			.block {
				display: flex;
				flex-wrap: wrap;
			}

			.invest-ranges {
				width: 100%;
				display: flex;
				flex-direction: column;

				.single-invest-range {
					background-color: var(--ultraLightGrey);
					border: 1px solid var(--lightGrey);
					padding: var(--sp1-5x);
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					width: 100%;
					border-radius: 4px;
					cursor: pointer;

					&:not(:first-child) {
						margin-top: var(--sp1-25x);
					}

					&.active {
						background-color: var(--lightGrey);

						* {
							color: var(--black);
						}
					}
				}
			}

			.selected {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				p:first-child {
					margin-right: var(--sp1-5x);
				}
			}

			.ant-slider {
				margin: var(--sp10x) auto var(--sp2-5x);
				width: calc(100% - var(--sp7x));
				position: relative;
				cursor: pointer;
				touch-action: none;

				.ant-slider-rail,
				.ant-slider-step {
					opacity: 0;
				}

				.ant-slider-track {
					position: absolute;
					width: 100%;
					height: 1px;
					background-color: var(--ultraDarkGrey);
				}

				.ant-slider-handle {
					position: absolute;
					width: var(--sp5-5x);
					height: var(--sp5-5x);
					background-color: var(--white);
					box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
					border-radius: 50%;
					border: none;
					margin: 0;
					transform: translate(-50%, -50%) !important;
					cursor: pointer;
					transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
				}

				.ant-slider-mark {
					&-text {
						top: calc(-1 * var(--sp7x));
						color: var(--ultraDarkGrey) !important;
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		max-width: var(--sp40x);

		.filters {
			.single-filter {
				& > .title.active {
					margin-bottom: var(--sp3x);
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		max-width: var(--sp37x);

		.filters {
			.single-filter {
				& > .title.active {
					margin-bottom: var(--sp3x);
				}
			}
		}
	}

	@media screen and (min-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
		.clear-filters,
		.single-cat,
		.single-invest-range,
		.h5,
		.pM.darkGrey.capitalize,
		.selected,
		.ant-slider {
			transform: translateY(30vh);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		max-width: unset;
		position: absolute;
		top: 0;
		left: 0;

		.btn-action {
			width: calc(50% - var(--sp1-5x));
			margin-bottom: var(--sp2-5x);
			align-self: flex-end;

			.filter-btn {
				width: 100%;
				padding: var(--sp2x) var(--sp6x);
				background-color: var(--ultraLightGrey);
				border: 1px solid var(--darkGrey);
				border-radius: 4px;

				span {
					display: flex;
					justify-content: space-between;
					align-items: center;

					i {
						font-size: var(--sp1-5x);
						color: var(--darkGrey);
					}
				}
			}
		}

		.filters {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100vw;
			height: 100vh;
			overflow-x: hidden;
			overflow-y: auto;
			z-index: -1;
			display: none;
			pointer-events: none;
			background-color: var(--white);
			padding-left: var(--containerPadding);
			padding-right: var(--containerPadding);
			padding-bottom: var(--sp25x);
			margin-top: 0;

			&.active {
				pointer-events: all;
				display: block;
				z-index: 100;
			}

			.head-title {
				padding: var(--sp3x) 0;
				position: relative;
				text-align: center;
				margin-bottom: var(--sp3x);

				&:before {
					content: '';
					position: absolute;
					bottom: 0;
					left: calc(-1 * var(--containerPadding));
					width: calc(100% + (2 * var(--containerPadding)));
					height: 1px;
					background-color: var(--darkGrey);
				}
			}

			.single-filter {
				margin-bottom: var(--sp2x);

				& > .title {
					margin-bottom: var(--sp3x);
				}

				.invest-ranges {
					flex-flow: row wrap;

					.single-invest-range {
						width: calc(50% - var(--sp1-5x));

						&:nth-child(2n) {
							margin-left: var(--sp1-5x);
						}

						&:nth-child(2n-1) {
							margin-right: var(--sp1-5x);
						}

						&:nth-child(2) {
							margin-top: 0;
						}

						&:not(:first-child):not(:nth-child(2)) {
							margin-top: var(--sp2x);
						}
					}
				}
			}

			.btns {
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: var(--sp18x);
				display: flex;
				justify-content: space-between;
				padding-left: var(--containerPadding);
				padding-right: var(--containerPadding);
				background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);

				.btn {
					width: 100%;
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		max-width: unset;
		position: absolute;
		top: 0;
		left: 0;

		.btn-action {
			width: auto;
			margin-bottom: var(--sp2x);
			align-self: flex-end;

			.filter-btn {
				width: unset;
				padding: var(--sp1-5x) var(--sp3x);
				background-color: var(--ultraLightGrey);
				border: 1px solid var(--darkGrey);
				border-radius: 4px;

				span {
					display: flex;
					justify-content: space-between;
					align-items: center;

					i {
						font-size: var(--sp1-5x);
						color: var(--darkGrey);
						margin-left: var(--sp1-5x);
					}
				}
			}
		}

		.filters {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100vw;
			height: 100vh;
			overflow-x: hidden;
			overflow-y: auto;
			z-index: -1;
			display: none;
			pointer-events: none;
			background-color: var(--white);
			padding-left: var(--containerPadding);
			padding-right: var(--containerPadding);
			padding-bottom: var(--sp25x);
			margin-top: 0;

			&.active {
				pointer-events: all;
				display: block;
				z-index: 100;
			}

			.head-title {
				padding: var(--sp3x) 0;
				position: relative;
				text-align: center;
				margin-bottom: var(--sp3x);

				&:before {
					content: '';
					position: absolute;
					bottom: 0;
					left: calc(-1 * var(--containerPadding));
					width: calc(100% + (2 * var(--containerPadding)));
					height: 1px;
					background-color: var(--darkGrey);
				}
			}

			.single-filter {
				margin-bottom: var(--sp2x);

				& > .title {
					margin-bottom: var(--sp3x);
				}

				.invest-ranges {
					.single-invest-range {
					}
				}
			}

			.btns {
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: var(--sp18x);
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
				padding-left: var(--containerPadding);
				padding-right: var(--containerPadding);
				background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);

				.btn-action {
					width: calc(50% - var(--sp0-75x));

					.btn {
						width: 100%;
					}
				}
			}
		}
	}
`

export default FilterInCatalogueStyle
