import styled from 'styled-components'

const PlanRegisterStyle = styled.div.attrs((props) => ({ className: props.className }))`
	position: relative;
	background-color: var(--ultraLightGrey);

	.register-plan-block {
		display: flex;
		justify-content: center;
		padding: var(--sp25x) 0;

		.content {
			display: flex;
			flex-direction: column;
			align-items: center;
			max-width: var(--sp132x);
			width: 100%;
			z-index: 2;

			.description {
				margin-bottom: var(--sp10x);
			}
		}

		.imageCont {
			position: absolute;
			z-index: 1;
		}

		.men-cont {
			top: 10%;
			right: 8%;
			width: 16.3%;
			padding-top: 16.3%;
			clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
			background-color: var(--blue);
			position: absolute;
			transform: translateY(40vh);

			& > * {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transform: translateY(-40%) rotate(-9deg);
			}
		}

		.women-cont {
			top: 11%;
			left: 11.5%;
			width: 10.1%;
			padding-top: 10.1%;
			clip-path: circle(50% at 50% 50%);
			background-color: var(--yellow);
			overflow: hidden;
			position: absolute;
			transform: translateY(50%) rotate(-22deg);

			& > * {
				position: absolute;
				top: 8%;
				left: 0;
				width: 100%;
				height: 100%;
				transform: translateY(100%) rotate(22deg);
			}
		}

		.dollar {
			width: 6.1%;
			padding-top: 6.1%;
			right: 23%;
			bottom: 11%;
			transform: translateY(100%) rotate(-40deg);
		}

		.filledCircle {
			width: 6.1%;
			padding-top: 6.1%;
			left: 23%;
			bottom: 15%;
			transform: translateY(100%) rotate(-40deg);
		}

		.halfCircle {
			width: 3.6%;
			padding-top: 3.4%;
			left: 5%;
			top: 35%;
			transform: translateY(10vh);
		}

		.halfYellow {
			width: 2.6%;
			padding-top: 2.2%;
			right: 20px;
			top: 21%;
			transform: translateY(-30vh) rotate(110deg);
		}

		.halfGreen {
			width: 4%;
			padding-top: 3.4%;
			left: 52%;
			bottom: 0%;
			transform: translateY(20vh) rotate(45deg);
		}

		.holeCircle {
			width: 3.3%;
			padding-top: 3.2%;
			right: 16%;
			bottom: 44%;
			transform: translateY(10vh);
		}

		.holeCircle2 {
			width: 2%;
			padding-top: 2%;
			left: 11%;
			bottom: 4%;
			transform: translateY(30vh);
		}

		.holeYellowCircle {
			width: 1.9%;
			padding-top: 1.9%;
			left: 29%;
			top: 1%;
			transform: translateY(70vh);
		}

		.blueTriangle {
			width: 2.4%;
			padding-top: 2.3%;
			right: 18%;
			top: -35%;
			transform: translateY(20vh) rotate(-45deg);
		}

		.greenTriangle {
			width: 2.4%;
			padding-top: 2.3%;
			left: 7%;
			top: 1%;
			transform: translateY(16vh) rotate(35deg);
		}

		.orangeTriangle {
			width: 2.4%;
			padding-top: 2.3%;
			right: 30%;
			bottom: 28%;
			transform: translateY(8vh) rotate(-25deg);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		.register-plan-block {
			padding: var(--sp22x) 0;

			.content {
				max-width: var(--sp112x);

				.description {
					margin-bottom: var(--sp8x);
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		.register-plan-block {
			padding: var(--sp20x) 0;

			.content {
				max-width: var(--sp91x);

				.description {
					margin-bottom: var(--sp7x);
				}
			}

			.filledCircle {
				bottom: 23%;
			}

			.halfGreen {
				bottom: 3%;
			}
			.holeYellowCircle {
				top: 6%;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.LaptopSize}) {
		.register-plan-block {
			padding: var(--sp18x) 0;

			.content {
				max-width: var(--sp63x);

				.description {
					margin-bottom: var(--sp6x);
				}
			}

			.dollar {
				width: 7.1%;
				padding-top: 7.1%;
				right: 13%;
				bottom: 15%;
			}

			.men-cont {
				top: var(--sp10x);
				right: 20px;
				width: 19.6%;
				padding-top: 19.6%;
			}

			.women-cont {
				top: 22%;
				left: 9.5%;
				width: 12.2%;
				padding-top: 12.2%;
			}

			.filledCircle {
				width: 7.1%;
				padding-top: 7.1%;
				left: 20.3%;
				bottom: var(--sp4x);
			}

			.halfGreen {
				bottom: 3%;
			}

			.holeYellowCircle {
				top: 6%;
			}

			.orangeTriangle {
				right: 22%;
				bottom: 31%;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		.register-plan-block {
			padding: var(--sp18x) 0;

			.content {
				max-width: var(--sp63x);

				.description {
					margin-bottom: var(--sp6x);
				}
			}

			.dollar {
				width: 9.4%;
				padding-top: 9.4%;
				right: var(--sp6x);
				bottom: var(--sp7x);
			}

			.men-cont {
				width: 26.2%;
				padding-top: 26.2%;
				right: -6%;
				top: var(--sp4x);
			}

			.women-cont {
				top: var(--sp14x);
				left: 0;
				width: 16.2%;
				padding-top: 16.2%;
			}

			.filledCircle {
				width: 9.5%;
				padding-top: 9.5%;
				left: 12%;
				bottom: var(--sp4x);
			}

			.halfYellow {
				width: 3.6%;
				padding-top: 3.2%;
				right: -10px;
				top: 3%;
			}
			.halfGreen {
				bottom: 3%;
			}

			.holeCircle2 {
				width: 3.3%;
				padding-top: 3.3%;
				left: var(--sp4x);
				bottom: 18%;
			}

			.holeYellowCircle {
				top: 6%;
				padding-top: 2.9%;
				width: 2.9%;
			}

			.orangeTriangle {
				right: 22%;
				bottom: 31%;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		overflow: hidden;
		margin-bottom: var(--containerMarginBottom);

		.register-plan-block {
			padding: var(--sp12x) var(--sp2x);
			overflow: hidden;
			margin-bottom: 0;

			.content {
				max-width: unset;
				align-items: stretch;

				>.title {
					padding: 0 var(--sp4x);
				}

				.description {
					margin-bottom: var(--sp5x);
				}
			}

			.dollar {
				width: 11.4%;
				padding-top: 11.4%;
				right: var(--spSize);
				bottom: var(--spSize);
			}

			.men-cont {
				width: 28.2%;
				padding-top: 28.2%;
				right: -6%;
				top: var(--spSize);
			}

			.women-cont {
				top: var(--spSize);
				left: var(--sp2x);
				width: 17.8%;
				padding-top: 17.8%;
			}

			.filledCircle {
				width: 10.2%;
				padding-top: 10.2%;
				left: var(--sp7x);
				bottom: var(--spSize);
			}

			.halfYellow {
				width: 5%;
				padding-top: 4.4%;
				right: 19%;
				top: 3%;
			}

			.halfGreen {
				width: 6%;
				padding-top: 5.4%;
				bottom: 11%;
			}

			.holeCircle2 {
				width: 3.3%;
				padding-top: 3.3%;
				left: var(--sp2x);
				bottom: 11%;
			}

			.holeYellowCircle {
				top: 4%;
				padding-top: 3.4%;
				width: 3.4%;
				left: 34%;
			}

			.orangeTriangle {
				width: 4.4%;
				right: 22%;
				bottom: 17%;
				padding-top: 4.3%;
			}

			.greenTriangle {
				width: 4.4%;
				padding-top: 4.3%;
				left: -1.2%;
				top: 1%;
			}
		}
	}
`

export default PlanRegisterStyle
