import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import { withAccountContext } from 'context'
import { Image, Text } from 'components/common'
import ProductContentStyle from './style'

const ProductContent = forwardRef(({ certification, details, ...props }, ref) => {
    const itemRef = useRef()

    useImperativeHandle(ref, () => [itemRef], [])

    return (
        <ProductContentStyle className='product-content' ref={itemRef}>
            <div className='about-product'>
                <Text tag={'h4'} className={'title h4 MonMedium'} text={'aboutBusiness'} />
                <Text tag={'p'} className={`text pL MonRegular ${props.isLoggedIn ? '' : 'short'}`} text={props.aboutProduct} />
            </div>

            {props.isLoggedIn ?
                (<>
                    <div className='factory-location'>
                        {props.factoryLocation && (<>
                            <Text tag={'h4'} className={'title h4 MonMedium'} text={'factoryLocation'} />
                            <Text tag={'p'} className={'pL MonRegular'} text={props.factoryLocation} />
                        </>)}
                        {certification ?
                            <div className='certificate'>
                                <Image src='/images/singleInvest/businessPlan.svg' alt='business plan icon' />
                                <Text tag={'p'} className={'pL MonMedium'} text={'haveCertificate'} />
                            </div>
                            :
                            null
                        }
                    </div>
                </>)
                :
                <div />


            }
        </ProductContentStyle>
    )
})

export default withAccountContext(ProductContent, ['isLoggedIn'])
