import { memo as Memo } from 'react'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'

//* Components
import { Text, Image, Button } from 'components/common'

//* Style
import AccountActivatedPopupStyle from './style'

const AccountActivatedPopup = Memo(({ closePopup, ...props }) => {
	return (
		<AccountActivatedPopupStyle>
			<div className='title textAlignCenter'>
				<Text tag={'p'} className={'h3 MonBold black'} text={'AccountActivatedTitle'} />
			</div>
			<div className='FlexBox alignMiddle alignCenter'>
				<Image src='/images/svg/ThankYouMessageIcon.svg' alt={props.translate('AccountActivatedTitle')} />
			</div>
			<div className='title textAlignCenter'>
				<Text tag={'p'} className={'h3 MonBold black'}>
					{props.translate('Welcome')} {props.userName}
				</Text>
			</div>
			<div className='description textAlignCenter'>
				<Text tag={'p'} className={'h5 MonRegular black'} text={'AccountActivatedPopupDescription'} />
			</div>
			<Button onClick={closePopup} className={`btn btn-black btn-max-width`} text={'Close'} />
		</AccountActivatedPopupStyle>
	)
})

export default withLanguageContext(withUIContext(AccountActivatedPopup, ['closePopup']), ['translate', 'selectedLang'])
