import { forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { Container, Text, Image, Button } from 'components/common'

//* HOC's
import { withUIContext } from 'context'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
gsap.core.globals('ScrollTrigger', ScrollTrigger)

import LastSectionStyle from './style'

const LastSection = forwardRef((props, ref) => {
	const titleRef = useRef(),
		descriptionRef = useRef(),
		btnRef = useRef(),
		wrappRef = useRef(),
		itemRef1 = useRef(),
		itemRef1in = useRef(),
		itemRef2 = useRef(),
		itemRef2in = useRef(),
		itemRef3 = useRef(),
		itemRef4 = useRef(),
		itemRef5 = useRef(),
		itemRef6 = useRef(),
		itemRef7 = useRef(),
		itemRef8 = useRef(),
		itemRef9 = useRef(),
		itemRef10 = useRef(),
		itemRef11 = useRef(),
		itemRef12 = useRef()

	useImperativeHandle(ref, () => [titleRef.current.childNodes, descriptionRef, btnRef, [
		itemRef1.current,
		itemRef1in.current[0],
		itemRef2.current,
		itemRef2in.current[0],
		itemRef3.current[0],
		itemRef4.current[0],
		itemRef5.current[0],
		itemRef6.current[0],
		[itemRef8.current[0], itemRef9.current[0], itemRef11.current[0], itemRef12.current[0]],
		[itemRef10.current[0], itemRef7.current[0]]
	]], [])


	return (
		<LastSectionStyle className={'last-section'}>
			<Container className={'last-section-block last'} ref={wrappRef}>
				<div className='content'>
					<Text tag={'stringToLetters'} className={'title h1 MonBold black'} text={props.title} ref={titleRef} />
					<Text tag={'h6'} className={'description h6 MonRegular black'} text={props.text} ref={descriptionRef} />
					<Button
						className={'btn btn-black'}
						wrappClassName={'otherElementAnimation '}
						text={props.linkText}
						link={props.url}
						ref={btnRef}
					/>
				</div>
				<div className='men-cont' ref={itemRef1}>
					<Image className='men' src={props.men} ref={itemRef1in} />
				</div>
				<div className='women-cont' ref={itemRef2}>
					<Image className='women' src={props.women} ref={itemRef2in} />
				</div>
				<Image className='filledCircle' src='/images/news/filledCircle.svg' ref={itemRef3} />
				<Image className='halfCircle' src='/images/news/halfCircle.svg' ref={itemRef4} />
				<Image className='holeCircle' src='/images/news/holeCircle.svg' ref={itemRef5} />
				<Image className='halfGreen' src='/images/news/greenHalf.svg' ref={itemRef6} />
				<Image className='holeYellowCircle' src='/images/news/holeYellowCircle.svg' ref={itemRef7} />
				<Image className='greenTriangle' src='/images/news/greenTriangle.svg' ref={itemRef8} />
				<Image className='holeCircle2' src='/images/news/holeCircle.svg' ref={itemRef9} />
				<Image className='halfYellow' src='/images/news/yellowHalf.svg' ref={itemRef10} />
				<Image className='blueTriangle' src='/images/news/blueTriangle.svg' ref={itemRef11} />
				<Image className='orangeTriangle' src='/images/news/orangeTriangle.svg' ref={itemRef12} />
			</Container>
		</LastSectionStyle>
	)
})

export default withUIContext(LastSection, ['screenSizes', 'winWidth', 'winHeight'])
