import React, { memo as Memo } from 'react'
import { Text, FormItem, Button, FormContainer } from 'components/common'
import { Checkbox } from 'antd'
import { withUIContext } from 'context'

//* Helpers
import { formatNumber } from 'helpers'

//*Component
import { BecomePartnerPopupForm } from 'components/pages'

//Style
import SinglePackageStyle from './style'

const SinglePackage = Memo((props) => {
	const PopupOpen = () => {
		props.openPopup(<BecomePartnerPopupForm val={props.val} price1={formatNumber(props.price1,'hy')} price2={formatNumber(props.price2,'hy')} />)
	}
	return (
		<SinglePackageStyle className='single-package FlexBoxColumn alignMiddle'>
			<Text tag={'h3'} className={'heading h3 MonMedium textAlignCenter'} text={props.title} />
			<div className='main-block FlexBoxColumn'>
				<div className='prices FlexBoxColumn alignMiddle textAlignCenter'>
					<div className='price FlexBox alignCenter'>
						<Text tag={'h3'} className={'h3 MonMedium'} text={formatNumber(props.price, "hy")} />
					</div>
					<Text className={'h5 MonRegular uppercase ultraDarkGrey'} text={'annualPrice'} />
				</div>
				<div className='content' dangerouslySetInnerHTML={{ __html: props.content }}></div>
				<Text tag={'p'} className={'client-text pL MonRegular ultraDarkGrey'} text={'singlePackageText1'} />
				<Text tag={'p'} className={'payment-method-text pL MonRegular darkGrey'} text={'singlePackageText2'} />
				<Button className={'btn btn-black btn-max-width capitalize'} text={'apply'} onClick={PopupOpen} />
			</div>
		</SinglePackageStyle>
	)
})

export default withUIContext(SinglePackage, ['openPopup'])
