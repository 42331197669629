import { useState, forwardRef, useRef, useImperativeHandle } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

import { Text, Translate, Container, Button } from 'components/common'

import PlanPricingStyle from './style'

const PlanPricing = forwardRef((props, ref) => {
	const [activeStateNum, setactiveStateNum] = useState(1)

	const clickHandler = (index) => {
		setactiveStateNum(index)
	}

	const titleRef = useRef()
	const descriptionRef = useRef()
	const itemsRef = useRef()

	useImperativeHandle(ref, () => [titleRef.current.childNodes, descriptionRef, itemsRef.current.childNodes], [])

	let string = props.translate('dramWord')

	return (
		<PlanPricingStyle className='plan-pricing-cont'>
			<Container className='largePadding'>
				<div className='top'>
					<Text tag={'stringToLetters'} className={'title h1 MonBold black'} text={props.title} ref={titleRef} />
					<Text tag={'h6'} className={'description h6 MonRegular black'} text={props.subtitle} ref={descriptionRef} />
				</div>
				<div className='bottom' ref={itemsRef}>
					<div className='invest-range-cont'>
						<Text tag={'p'} className={'pM MonRegular ultraDarkGrey'} text={'reqInvestRange'} />
						<div className='invest-ranges'>
							<div className={`single-invest-range ${activeStateNum === 1 ? 'active' : ''}`} onClick={() => clickHandler(1)}>
								<Text tag={'h5'} className={`h5 ${activeStateNum === 1 ? 'MonMedium' : 'MonRegular'} black`}>
									<Translate val={'lessThan'} /> {`${props.singlePriceRange1MaxValDollar}`}
								</Text>
								<Text tag={'h5'} className={`h5 ${activeStateNum === 1 ? 'MonMedium extraLightGrey' : 'MonRegular ultraDarkGrey'}`}>
									<Translate val={'lessThan'} /> {`${props.singlePriceRange1MaxValDram}`}
								</Text>
							</div>
							<div className={`single-invest-range ${activeStateNum === 2 ? 'active' : ''}`} onClick={() => clickHandler(2)}>
								<Text
									tag={'h5'}
									className={`h5 ${activeStateNum === 2 ? 'MonMedium' : 'MonRegular'} black`}
									text={`${props.singlePriceRange2MinValDollar} - ${props.singlePriceRange2MaxValDollar}`}
								/>
								<Text
									tag={'h5'}
									className={`h5 ${activeStateNum === 2 ? 'MonMedium extraLightGrey' : 'MonRegular ultraDarkGrey'}`}
									text={`${props.singlePriceRange2MinValDram} - ${props.singlePriceRange2MaxValDram}`}
								/>
							</div>
							<div className={`single-invest-range ${activeStateNum === 3 ? 'active' : ''}`} onClick={() => clickHandler(3)}>
								<Text
									tag={'h5'}
									className={`h5 ${activeStateNum === 3 ? 'MonMedium' : 'MonRegular'} black`}
									text={`${props.singlePriceRange3MinValDollar} - ${props.singlePriceRange3MaxValDollar}`}
								/>
								<Text
									tag={'h5'}
									className={`h5 ${activeStateNum === 3 ? 'MonMedium extraLightGrey' : 'MonRegular ultraDarkGrey'}`}
									text={`${props.singlePriceRange3MinValDram} - ${props.singlePriceRange3MaxValDram}`}
								/>
							</div>
							<div className={`single-invest-range ${activeStateNum === 4 ? 'active' : ''}`} onClick={() => clickHandler(4)}>
								<Text tag={'h5'} className={`h5 ${activeStateNum === 4 ? 'MonMedium' : 'MonRegular'} black`}>
									<Translate val={'moreThan'} /> {`${props.singlePriceRange4MinValDollar}`}
								</Text>
								<Text tag={'h5'} className={`h5 ${activeStateNum === 4 ? 'MonMedium extraLightGrey' : 'MonRegular ultraDarkGrey'}`}>
									<Translate val={'moreThan'} /> {`${props.singlePriceRange4MinValDram}`}
								</Text>
							</div>
						</div>
					</div>
					<div className='selected-range-price'>
						<div className={`selected-range-price-cont selected-range-price-1 `}>
							<Text tag={'h4'} className={'h4 textAlignCenter MonRegular ultraDarkGrey'} text={'submissionFee'} />
							<div className='priceWrapper relativeWrapp'>
								<Text
									tag={'stringToLetters'}
									className={`title price h1 MonBold black ${activeStateNum === 1 ? 'active' : 'hide'}`}
									text={props.selectedRangePrice1 === '0' ? 'free' : props.selectedRangePrice1 + string}
								/>
								<Text
									tag={'stringToLetters'}
									className={`title price h1 MonBold black ${activeStateNum === 2 ? 'active' : 'hide '}`}
									text={props.selectedRangePrice2 + string}
								/>

								<Text
									tag={'stringToLetters'}
									className={`title price h1 MonBold black ${activeStateNum === 3 ? 'active' : 'hide'}`}
									text={props.selectedRangePrice3 + string}
								/>
								<Text
									tag={'stringToLetters'}
									className={`title price h1 MonBold black ${activeStateNum === 4 ? 'active' : 'hide'}`}
									text={props.selectedRangePrice4 + string}
								/>
							</div>
							<div className='descriptionWrapper relativeWrapp'>
								<div
									className={`pL desc textAlignCenter MonRegular black ${activeStateNum === 1 ? 'active' : ''}`}
									dangerouslySetInnerHTML={{ __html: props.selectedRangePrice1Desc }}
								/>
								<div
									className={`pL desc textAlignCenter MonRegular black ${activeStateNum === 2 ? 'active' : ''}`}
									dangerouslySetInnerHTML={{ __html: props.selectedRangePrice2Desc }}
								/>
								<div
									className={`pL desc textAlignCenter MonRegular black ${activeStateNum === 3 ? 'active' : ''}`}
									dangerouslySetInnerHTML={{ __html: props.selectedRangePrice3Desc }}
								/>
								<div
									className={`pL desc textAlignCenter MonRegular black ${activeStateNum === 4 ? 'active' : ''}`}
									dangerouslySetInnerHTML={{ __html: props.selectedRangePrice4Desc }}
								/>
							</div>

							<Button className='btn btn-black' link={props.url} text={'importSuggest'} />
						</div>
					</div>
				</div>
			</Container>
		</PlanPricingStyle>
	)
})

export default withLanguageContext(PlanPricing, ['translate', 'selectedLang'])
