import styled from 'styled-components'

const BusinessSectionStyle = styled.div`
	.business-section {
		display: flex;
		align-items: flex-start;
		justify-content: center;

		.content {
			display: flex;
			flex-direction: column;
			max-width: var(--sp83x);

			& > .description {
				margin: var(--titleFromContent) auto var(--sp7x);
			}
		}

		.imageCont {
			margin-left: var(--sp19x);
			flex: 1;
			padding-top: 33%;
			max-width: var(--sp98x);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		.business-section {
			.content {
				& > .description {
					margin-bottom: var(--sp6x);
				}
			}

			.imageCont {
				margin-left: var(--sp4x);
				padding-top: 23.3%;
				max-width: var(--sp83x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		.business-section {
			&.inCompany {
				justify-content: space-between;

				.content {
					max-width: var(--sp83x);
				}
			}

			.content {
				max-width: var(--sp68x);

				& > .description {
					margin-bottom: var(--sp4-5x);
				}
			}

			.imageCont {
				margin-left: var(--sp4x);
				padding-top: 32.8%;
				max-width: var(--sp68x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		.business-section {
			.content {
				max-width: var(--sp57x);

				& > .description {
					margin-bottom: var(--sp4x);
				}
			}

			.imageCont {
				margin-left: var(--sp3-5x);
				padding-top: 32.8%;
				max-width: var(--sp57x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		.business-section {
			flex-direction: column-reverse;

			.content {
				max-width: unset;

				& > .description {
					margin-bottom: var(--sp4x);
				}
			}

			.imageCont {
				margin-left: 0;
				padding-top: 58.9%;
				max-width: var(--sp75x);
				margin-bottom: var(--sp2-5x);
				width: 100%;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.business-section {
			flex-direction: column-reverse;

			.content {
				max-width: unset;

				& > .description {
					margin-bottom: var(--sp3-5x);
				}
			}

			.imageCont {
				margin-left: 0;
				padding-top: 67.3%;
				max-width: unset;
				margin-bottom: var(--sp2x);
				width: 100%;
			}
		}
	}
`
export default BusinessSectionStyle
