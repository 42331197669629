import { useState, useMemo, useCallback, forwardRef, useRef, useImperativeHandle } from 'react'

//* Components
import { Container, Text, Icon, CustomLink } from 'components/common'
import { Map, CategoryBtn } from 'components/pages'
import { useRouter } from 'next/router'
import Collapse from '@material-ui/core/Collapse'

//* Helpers
import { getParamsByKey } from 'helpers'

//* Styles
import FilterStyle from './style'

const Filter = forwardRef((props, ref) => {
    //! States
    const [hgt, setHgt] = useState(true)

    //! Refs
    const titleRef1 = useRef()
    const titleRef2 = useRef()
    const titleRef3 = useRef()
    const titleRef4 = useRef()
    const itemRef1 = useRef()
    const itemRef2 = useRef()
    const itemRef3 = useRef()

    //! Imperative Handle
    useImperativeHandle(
        ref,
        () => [
            titleRef1.current.childNodes,
            titleRef2.current.childNodes,
            itemRef1.current.childNodes,
            titleRef3.current.childNodes,
            itemRef2.current.childNodes,
            itemRef3.current.childNodes,
            titleRef4.current.childNodes,
        ],
        []
    )

    //! Router
    const router = useRouter()

    //! Categories Filter
    const storeCategories = useMemo(() => {
        const catParam = props.queryParamKeys[0] //? "cat_ids"
        const activeItems = getParamsByKey(router, catParam)

        return props.categories.map((i, k) => (
            <span key={k}>
                <CategoryBtn
                    active={activeItems?.includes(i.id.toString())}
                    onChange={() => props.onCategoryChange(catParam, i.id)}
                    id={i.id}
                    slug={i.slug}
                    text={i.name}
                    inFilter={true}
                    state={false}
                    bgColor={activeItems?.includes(i.id.toString()) && i.color}
                />
            </span>
        ))
    }, [props.categories, router.query])

    //! Active States
    const storeStatesActiveArr = useMemo(() => {
        const stateParam = props.queryParamKeys[1] //? "loc_ids"
        return getParamsByKey(router, stateParam)
    }, [router.query])

    //! States Filter
    const storeStates = useMemo(() => {
        const stateParam = props.queryParamKeys[1] //? "loc_ids"

        return props.states.map((i, k) => (
            <span key={k}>
                <CategoryBtn
                    active={storeStatesActiveArr?.includes(i.id.toString())}
                    onChange={() => props.onCategoryChange(stateParam, i.id)}
                    id={i.id}
                    slug={i.slug}
                    text={i.name}
                    inFilter={true}
                    state={true}
                />
            </span>
        ))
    }, [props.states, router.query])

    //! Toggle Height
    const clickHandler = useCallback(() => {
        setHgt(!hgt)

        props.popState(hgt === 0 ? true : false)
    }, [hgt])

    //! Map Number Of News
    const numberOfNews = useMemo(() => {
        const arr = []
        for (let i = 1; i < 6; i++) {
            arr.push(
                <div className='single-row' key={i}>
                    <span></span>
                    <Text tag={'p'} className={'pS MonBold black'} text={i * (i < 3 ? 5 : 10)} />
                </div>
            )
        }
        return arr
    }, [])

    return (
        <FilterStyle className='filter'>
            <Container className='filter-section full'>
                <Container className='filter-section-block'>
                    <div className='top'>
                        <Text tag={'stringToLetters'} className={'h2 MonBold'} text={'newsPageTitle'} ref={titleRef1} />
                        <div onClick={() => clickHandler()} className={`close-filter ${hgt === 0 ? 'inActive' : ''}`}>
                            <Text tag={'p'} className={'pL MonMedium black'} text={'closeSection'} />
                            <Icon className='icon-arrow-down' />
                        </div>
                    </div>
                    <Collapse in={hgt} timeout='auto' className='map'>
                        <div className='left'>
                            <Text tag={'stringToLetters'} className={'title pM darkGrey MonMedium'} text={'filterByCat'} ref={titleRef2} />
                            <div className='filters cats' ref={itemRef1}>
                                {storeCategories}
                            </div>
                            <Text tag={'stringToLetters'} className={'title sec-title pM darkGrey MonMedium'} text={'filterByCat'} ref={titleRef3} />
                            <div className='filters states' ref={itemRef2}>
                                {storeStates}
                            </div>
                            <CustomLink
                                url='/news'
                                className={'resetBtn pL black'}
                                content={<Text tag={'stringToLetters'} className={'pL black MonMedium'} text={'resetFilters'} ref={titleRef3} />}
                            />
                        </div>
                        <div className='right'>
                            <div className='scale' ref={itemRef3}>
                                {numberOfNews}

                                <Text
                                    tag={'stringToLetters'}
                                    className={'numOfNews pS MonRegular ultraDarkGrey'}
                                    text={'numberOfNews'}
                                    ref={titleRef4}
                                />
                            </div>
                            <Map activeArr={storeStatesActiveArr} onCategoryChange={props.onCategoryChange} locations={props.states} />
                        </div>
                    </Collapse>
                </Container>
            </Container>
        </FilterStyle>
    )
})

export default Filter
