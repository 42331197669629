import { memo as Memo, useState, useCallback, useEffect, useMemo } from 'react'
import { gsap } from 'gsap'

//* HOC's
import { withLanguageContext, withAccountContext } from 'context'

//* Components
import { Text, FormItem, FormContainer, SuccessMessage, FormInput } from 'components/common'
import { Button, Checkbox, Input } from 'antd'

//* Styles
import LoginRegisterStyle from 'styles/pages/LoginRegister'
import FormStyle from 'styles/pages/FormStyle'

const InvestmentProjectsSinglePopup = Memo(
	({ isProject, isProjectsCompany, isProductsCompany, submitProjectContact, submitProductContact, id, ...props }) => {
		//! States
		const [success, setSuccess] = useState(false)

		//! On Form Submit
		const onFinish = useCallback((values) => {
			return eval((isProject || isProjectsCompany) ? 'submitProjectContact' : 'submitProductContact')(values).then(() => {
				setSuccess(true)
			})
		}, [])

		useEffect(() => {
			gsap.to('.animationItem', {
				y: 0,
				stagger: 0.05,
				ease: 'power4.out',
			})
		}, [])

		const hiddenFormItemName = useMemo(
			() => (isProjectsCompany ? `projects_company_id` : isProductsCompany ? `products_company_id` : isProject ? `project_id` : `product_id`),
			[isProjectsCompany, isProductsCompany, isProject]
		)

		return (
			<LoginRegisterStyle>
				{!success ? (
					<>
						<Text tag={'div'} className={' Title h4 MonMedium black defoltAnimationState animationItem'} text={'ContactBusiness'} />

						<div className='subitleBlock defoltAnimationState animationItem'>
							<Text tag={'span'} className={'pM MonRegular black'} text={'From'} />
							&nbsp;
							<Text tag={'span'} className={'pM MonMedium black'} text={props.messageTo} />
						</div>

						<div className='SignInFormWrapp'>
							<FormStyle>
								<FormContainer
									initialValues={{ [hiddenFormItemName]: id }}
									layout={'vertical'}
									onFinish={onFinish}
									className={'FormRow FlexBoxContainer spaceBetween'}
								>
									<FormItem name={hiddenFormItemName} options={{ noStyle: true }}>
										<FormInput type='text' type='hidden' />
									</FormItem>

									<div className='FormCol animationItem'>
										<FormItem name={'topic'} required={true}>
											<FormInput placeholder={props.translate('Topic') + '*'} />
										</FormItem>
									</div>

									{props.countryAndQuantity ? (
										<>
											<div className='FormCol FormCol-50 animationItem'>
												<FormItem name={'country'} required={true}>
													<FormInput placeholder={props.translate('Country') + '*'} />
												</FormItem>
											</div>
											<div className='FormCol FormCol-50 animationItem'>
												<FormItem name={'order_quantity'} required={true}>
													<FormInput placeholder={props.translate('OrderQuantity') + '*'} />
												</FormItem>
											</div>
										</>
									) : (
										''
									)}

									<div className='FormCol animationItem'>
										<FormItem name={'company_name'} required={true}>
											<FormInput placeholder={props.translate('EnterNameCompany') + '*'} />
										</FormItem>
									</div>
									<div className='FormCol FormCol-50 animationItem'>
										<FormItem name={'first_name'} required={true}>
											<FormInput placeholder={props.translate('FirstName') + '*'} />
										</FormItem>
									</div>
									<div className='FormCol FormCol-50 animationItem'>
										<FormItem name={'last_name'} required={true}>
											<FormInput placeholder={props.translate('LastName') + '*'} />
										</FormItem>
									</div>
									<div className='FormCol FormCol-50 animationItem'>
										<FormItem name={'email'} type='email' validationType='email' required={true}>
											<FormInput placeholder={props.translate('Email') + '*'} />
										</FormItem>
									</div>
									<div className='FormCol FormCol-50 animationItem'>
										<FormItem name={'phone'} validationType='whatsapp_phone' required={true}>
											<FormInput placeholder={props.translate('WhatsappPhoneNumber') + '*'} />
										</FormItem>
									</div>

									<div className='FormCol animationItem'>
										<FormItem name={'message'} required={true}>
											<Input.TextArea placeholder={props.translate('Message') + '*'} />
										</FormItem>
									</div>

									{isProject && (
										<div className='FormCol animationItem'>
											<FormItem name='business_plan' valuePropName='checked'>
												<Checkbox.Group>
													<Checkbox value='1'>
														<Text tag={'p'} className={'pL MonMedium black '} text={'BusinessPlan'} />
													</Checkbox>
												</Checkbox.Group>
											</FormItem>
										</div>
									)}

									<div className='formBtnWrapp animationItem'>
										<Button htmlType={'Submit'} className={'btn btn-black btn-max-width'}>
											{props.translate('Send')}
										</Button>
									</div>
								</FormContainer>
							</FormStyle>
						</div>
					</>
				) : (
					<SuccessMessage title={'ThankYouForUsingOurPlatform'} text={'YourEmailHasBeenSuccessfully'} />
				)}
			</LoginRegisterStyle>
		)
	}
)

export default withLanguageContext(withAccountContext(InvestmentProjectsSinglePopup, ['submitProjectContact', 'submitProductContact']), ['translate'])
