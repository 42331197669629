import styled from 'styled-components'

const FormForGuestStyle = styled.div.attrs((props) => ({ className: props.className }))`
	padding-top: var(--containerMarginBottom);
	position: relative;

	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, rgba(255, 255, 255, 1) 85%, rgb(255 255 255 / 0%) 100%);
		z-index: 1;
	}

	.form {
		max-width: var(--sp98x);
		width: 100%;
		z-index: 2;
		& > * {
			transform: translateY(30vh);
		}

		.text {
			margin: var(--sp2x) 0 var(--sp7x);
		}

		.login {
			& > p {
				margin-right: var(--spSize);
			}
		}
		.SignIn {
			margin: 0;
			.SignInWrap {
				text-decoration: underline;
			}
		}

		.or {
			margin: var(--sp3x) 0;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: var(--extraLightGrey);
			}

			&-text {
				padding: 0 var(--sp2x);
				background-color: var(--white);
				z-index: 1;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		&:before {
			height: 100%;
		}

		.form {
			max-width: var(--sp83x);

			.text {
				margin: var(--sp2x) 0 var(--sp6x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		.form {
			.text {
				margin: var(--sp2x) 0 var(--sp5x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		.form {
			max-width: var(--sp88x);

			.text {
				margin: var(--sp2x) 0 var(--sp4x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		.form {
			max-width: var(--sp64x);

			.text {
				margin: var(--sp2x) 0 var(--sp4x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.form {
			max-width: unset;

			.text {
				margin: var(--sp2x) 0 var(--sp3-5x);
			}
		}
	}
`
export default FormForGuestStyle
