import React, { memo as Memo, useCallback, useState, useRef, useEffect } from 'react'

//* HOC's
import { withLanguageContext, withAccountContext, withUIContext } from 'context'

//* Components
import { Text, FormItem, FormContainer, FormInput } from 'components/common'
import { Button, Input } from 'antd'
import Collapse from '@material-ui/core/Collapse'
import { AccountActivatedPopup, VerifyAccountPopup } from 'components/pages'

//* Helpers
import Cookie from 'js-cookie'

//* Styles
import FormStyle from 'styles/pages/FormStyle'

const ProfileForm = Memo(({ user, updateProfile, updatePassword, openPopup, ...props }) => {
    //! States
    const [height, setHeight] = useState(false)
    const [passBtnIsActive, setPassBtnIsActive] = useState(false)
    const [profileSuccessActive, setProfileSuccessActive] = useState(false)

    //! Refs
    const passFormRef = useRef()

    //! Update Profile
    const updateProfileSubmit = useCallback((values) => {
        return updateProfile(values).then(() => {
            setProfileSuccessActive(true)
            setTimeout(() => {
                setProfileSuccessActive(false)
            }, 3000)
        })
    }, [])

    //! Change Password
    const updatePasswordSubmit = useCallback((values) => {
        return updatePassword(values).then(() => {
            passFormRef.current.resetFields()
            setPassBtnIsActive(false)
            setHeight(true)
            setTimeout(() => {
                setHeight(false)
            }, 3000)
        })
    }, [])

    //! Opening Popup When User Activate Account
    useEffect(() => {
        const activationStatus = Cookie.get('activation_status')

        //? If Account Activated
        if (user.verified && activationStatus === 'valid') {
            Cookie.remove('activation_status')
            openPopup(<AccountActivatedPopup userName={user.name} />)
        }
        //? If Account Activated URL Expired
        else if (!user.verified && activationStatus === 'invalid') {
            Cookie.remove('activation_status')
            openPopup(<VerifyAccountPopup type={'verify'} />)
        }
        //? Account Activated Popup In ProtectRoute Component
    }, [])

    return (
        <FormStyle>
            <div className='FormColGroup '>
                <FormContainer
                    layout={'vertical'}
                    onFinish={updateProfileSubmit}
                    initialValues={user}
                    className={'FormRow FlexBoxContainer spaceBetween'}
                >
                    {profileSuccessActive ?
                        <Text tag={'p'} className={'pM MonRegular black SuccessfullProfile '} text={'SuccessfullProfile'} />
                        :
                        ""
                    }
                    <Collapse in={height} timeout='auto' unmountOnExit>
                        <div className='FormColTitle'>
                            <Text tag={'p'} className={'pM MonRegular black'} text={'SuccessfullCreatedNewPassword'} />
                        </div>
                    </Collapse>

                    <div className='FormColTitle'>
                        <Text tag={'p'} className={'pL MonMedium black uppercase '} text={'AccountInformation'} />
                    </div>

                    <div className='FormCol FormCol-50'>
                        <FormItem name={'first_name'} required={true}>
                            <FormInput placeholder={props.translate('FirstName') + '*'} />
                        </FormItem>
                    </div>
                    <div className='FormCol FormCol-50'>
                        <FormItem name={'last_name'} required={true}>
                            <FormInput placeholder={props.translate('LastName') + '*'} />
                        </FormItem>
                    </div>
                    <div className='FormCol FormCol-50'>
                        <FormItem name={'email'} type='email' validationType='email' required={true}>
                            <FormInput placeholder={props.translate('Email') + '*'} />
                        </FormItem>
                    </div>
                    <div className='FormCol FormCol-50'>
                        <FormItem name={'phone'} validationType='phone' required={true}>
                            <FormInput placeholder={props.translate('PhoneNumber') + '*'} />
                        </FormItem>
                    </div>
                    <div className='FormCol FormCol-50' />

                    <div className='formBtnWrapp FlexBox justifyEnd'>
                        <div className='FormCol FormCol-50'>
                            <Button htmlType={'Submit'} className={'btn btn-black btn-max-width'}>
                                {props.translate('Save')}
                            </Button>
                        </div>
                    </div>
                </FormContainer>
            </div>

            <div className='FormColGroup '>
                <FormContainer
                    layout={'vertical'}
                    ref={passFormRef}
                    onFinish={updatePasswordSubmit}
                    className={'FormRow FlexBoxContainer spaceBetween'}
                >
                    <div className='FormColTitle'>
                        <Text tag={'p'} className={'pL MonMedium black uppercase '} text={'Password'} />
                    </div>

                    <div className='FormCol FormCol-50'>
                        <FormItem
                            name='password'
                            required={true}
                            validationType='password'
                            validateWith={'password_confirmation'}
                            onError={() => setPassBtnIsActive(false)}
                            onSuccess={() => setPassBtnIsActive(true)}
                        >
                            <Input.Password placeholder={props.translate('NewPassword')} />
                        </FormItem>
                    </div>
                    <div className='FormCol FormCol-50'>
                        <FormItem
                            name='password_confirmation'
                            required={true}
                            validationType='c_password'
                            onError={() => setPassBtnIsActive(false)}
                            onSuccess={() => setPassBtnIsActive(true)}
                        >
                            <Input.Password placeholder={props.translate('RepeatPassword')} />
                        </FormItem>
                    </div>
                    <div className='FormCol FormCol-50' />
                    <div className='formBtnWrapp FlexBox justifyEnd'>
                        <div className='FormCol FormCol-50'>
                            <Button htmlType={'Submit'} className={`btn btn-black btn-max-width ${!passBtnIsActive && 'disabled'}`}>
                                {props.translate('Save')}
                            </Button>
                        </div>
                    </div>
                </FormContainer>
            </div>
        </FormStyle>
    )
})

export default withLanguageContext(withUIContext(withAccountContext(ProfileForm, ['user', 'updateProfile', 'updatePassword']), ['openPopup']), [
    'translate',
    'selectedLang',
])
