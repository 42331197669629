import { memo as Memo, useState, useMemo, useCallback, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'

//* HOC's
import { withUIContext } from 'context'

//* Components
import { Text, Icon, Button, SingleCatalog, SearchField } from 'components/common'
import Pagination from 'react-js-pagination'
import Collapse from '@material-ui/core/Collapse'
import { useRouter } from 'next/router'

//* Helpers
import { config, getParamsByKey } from 'helpers'

//* Styles
import CatalogsStyle from './style'

const Catalogs = forwardRef(({ onPageChange, total, itemsCountPerPage, queryParamKeys, winWidth, ...props }, ref) => {
    //! States
    const [hgt, setHgt] = useState(false)
    const [activeClassFilter, setActiveClassFilter] = useState('')
    const [activeSortOption, setActiveSortOption] = useState({ text: 'sortByFeatured', id: 1 })

    //! Refs
    const sortRef = useRef()
    const catalogsRef = useRef()

    //! Router
    const router = useRouter()

    //! Imperative Handle
    useImperativeHandle(ref, () => catalogsRef.current && [sortRef, catalogsRef], [props.sorts, winWidth, hgt])

    //! Active Page
    const activePage = useMemo(() => router.query?.[queryParamKeys[2]] || 1, [router.query])

    //! Project List Itmes
    const storeCatalogs = useMemo(() => {
        return props.catalogsArr?.map((i, k) => {
            return (
                <SingleCatalog
                    key={k}
                    photo={i.feature_image}
                    categorySlug={'/' + config.routes.investmentProjects.slug + '/' + i.category?.slug}
                    categoryName={i.category?.name}
                    title={i?.name}
                    url={'/' + config.routes.investmentProjects.slug + '/' + i.slug}
                    location={i.province.name}
                    description={i.short_description}
                    priceInDollar={`${i.required_investment.USD}`}
                    priceInAMD={`${i.required_investment.AMD}`}
                    profitPercent={`${i.margin_profitability}`}
                    companyName={`${i?.company?.name} ${i?.company?.type}`}
                    companyURL={config.routes.productsCompanySingle.path.replace(':slug', i?.company?.slug)}
                    bgColor={i.category?.color}
                />
            )
        })
    }, [props.catalogsArr])

    //! Pagination Change
    const onPaginationChange = useCallback(
        (p) => {
            const paginationParam = queryParamKeys[2] //? "page"
            onPageChange(paginationParam, p, false)
        },
        [queryParamKeys, onPageChange]
    )

    //! Search Active Param
    const searchActiveParam = useMemo(() => {
        const catParam = queryParamKeys[7] //? "search"
        const activeItems = getParamsByKey(router, catParam)

        return activeItems?.[0]
    }, [router, queryParamKeys])

    //! Search
    const onSearch = useCallback(
        (text) => {
            const paginationParam = queryParamKeys[7] //? "search"

            if (searchActiveParam != text) {
                onPageChange(paginationParam, text, false)
            }
        },
        [queryParamKeys]
    )

    //! Sort By Active Param
    const sortByActiveParam = useMemo(() => {
        const catParam = queryParamKeys[6] //? "order_by"
        const activeItems = getParamsByKey(router, catParam)

        return activeItems?.[0]
    }, [router, queryParamKeys])

    //! Component Did Mount
    useEffect(() => {
        const param = sortByActiveParam
        const text = Object.keys(props.sorts).find((key) => props.sorts[key] == param) || 'sortByFeatured'
        const id = props.sorts[text]

        setActiveSortOption({ text, id })
    }, [])

    //! Sort By Change
    const sortByChange = useCallback(
        (text, id) => {
            setHgt(!hgt)
            if (text) {
                const paginationParam = queryParamKeys[6] //? "order_by"
                const newID = id == 1 ? sortByActiveParam : id

                if (id != sortByActiveParam) {
                    setActiveSortOption({ text, id })
                    onPageChange(paginationParam, newID, false)
                }
            }
        },
        [hgt, queryParamKeys]
    )

    //! Sort By Mobile Action
    const sortByMobileAction = useCallback(
        (open, cancel) => {
            if (open) {
                setActiveClassFilter('active')
                document.body.classList.add('hidden')
            } else if (cancel) {
                const param = sortByActiveParam
                const text = Object.keys(props.sorts).find((key) => props.sorts[key] == param) || 'sortByFeatured'
                const id = props.sorts[text]
                setActiveClassFilter('')
                setActiveSortOption({ text, id })
                document.body.classList.remove('hidden')
            } else {
                setActiveClassFilter('')
                sortByChange(activeSortOption.text, activeSortOption.id)
                document.body.classList.remove('hidden')
            }
        },
        [activeSortOption, sortByActiveParam, props.sorts]
    )

    //! Sort By List
    const sortByList = useMemo(() => {
        if (props.sorts) {
            const keys = Object.keys(props.sorts)

            return keys?.map((i, k) => (
                <Text
                    key={k}
                    tag={'p'}
                    onClick={() => (winWidth > 1023 ? sortByChange(i, props.sorts[i]) : setActiveSortOption({ text: i, id: props.sorts[i] }))}
                    className={`option pM MonRegular black ${winWidth <= 1023 && activeSortOption.text === i ? 'MonMedium' : 'MonRegular'}`}
                    text={props.projects ? `${i}Project` : i}
                />
            ))
        }
    }, [props.sorts, winWidth, activeSortOption, sortByChange])

    return (
        <CatalogsStyle>
            <div className='list-head'>
                <SearchField onSearch={onSearch} initialValue={searchActiveParam} />
                {props.catalogsArr?.length ? (
                    winWidth > 1023 ? (
                        <div className='sort-by-cont'>
                            <div className='sort-by' ref={sortRef}>
                                <Text tag={'p'} className={'title pS MonRegular uppercase darkGrey'} text={'sortBy'} />
                                <div className='sort-block'>
                                    <div className={`selected-option ${hgt && 'active'}`} onClick={() => sortByChange()}>
                                        <Text
                                            tag={'p'}
                                            className={'pM MonRegular black'}
                                            text={props.projects ? `${activeSortOption.text}Project` : activeSortOption.text}
                                        />
                                        <Icon className={'icon-arrow-down'} />
                                    </div>
                                    <Collapse in={hgt} timeout='auto' className='sort-options'>
                                        {sortByList}
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <Button onClick={() => sortByMobileAction(true)} className='sort-btn' ariaLabel={'sort'}>
                                <Text tag={'p'} className={'pM black capitalize MonMedium'} text={'sort'} />
                                <Icon className='icon-sort' />
                            </Button>
                            <div className={`sort-by ${activeClassFilter}`}>
                                <Text tag={'p'} className={'head-title pS MonRegular uppercase darkGrey'} text={'sortBy'} />
                                <div className='sort-options'>{sortByList}</div>
                                <div className='btns'>
                                    <Button onClick={() => sortByMobileAction(false, true)} className='btn btn-white capitalize' text={'reset'} />
                                    <Button onClick={() => sortByMobileAction(false)} className='btn btn-black capitalize' text={'confirm'} />
                                </div>
                            </div>
                        </>
                    )
                ) : (
                    ''
                )}
            </div>
            {props.catalogsArr?.length ? (
                <>
                    <div className='catalogs-block' ref={catalogsRef}>
                        {storeCatalogs}
                    </div>

                    {total > itemsCountPerPage && (
                        <Pagination
                            activePage={Number(activePage)}
                            totalItemsCount={total}
                            pageRangeDisplayed={4}
                            itemsCountPerPage={itemsCountPerPage}
                            linkClassLast={null}
                            linkClassFirst={null}
                            nextPageText={<Icon className='icon-arrow-right' />}
                            prevPageText={<Icon className='icon-arrow-left' />}
                            linkClassPrev={'prev'}
                            linkClassNext={'next'}
                            onChange={onPaginationChange}
                        />
                    )}
                </>
            ) : (
                props.children
            )}
        </CatalogsStyle>
    )
})

export default withUIContext(Catalogs, ['winWidth'])
