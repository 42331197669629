import React, { forwardRef, useRef, useImperativeHandle, useCallback } from 'react'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'

//* Components
import { CategoryBtn, InvestmentProjectsSinglePopup } from 'components/pages'
import { Icon, Text, Button, CustomLink } from 'components/common'
import { Tooltip } from 'antd'

//* Styles
import ProjectInfoStyle from './style'

const ProjectInfo = forwardRef((props, ref) => {
    //! Refs
    const CategoryRef = useRef()
    const titleRef = useRef()
    const locationRef = useRef()
    const investRef = useRef()
    const projectOptionsRef = useRef()
    const btnRef = useRef()

    //! Imperative Handle
    useImperativeHandle(ref, () => [CategoryRef, titleRef, locationRef, investRef, projectOptionsRef, props.loggedIn && btnRef], [])

    //! Open Popup
    const PopupOpen = useCallback(() => {
        props.openPopup(<InvestmentProjectsSinglePopup isProject id={props.id} messageTo={`${props.companyName} ${props.companyType}`} />)
    }, [props.id, props.companyName, props.companyType])

    return (
        <ProjectInfoStyle className='project-info'>
            <CategoryBtn inFilter={false} slug={props.categorySlug} text={props.categoryName} ref={CategoryRef} />
            <Text tag={'h2'} className={'project-title h2 MonBold'} text={props.title} ref={titleRef} />
            <div className='location-company' ref={locationRef}>
                <div className='location'>
                    <Icon className='icon-pin'>
                        <span className='path1' />
                        <span className='path2' />
                    </Icon>
                    <Text tag={'p'} className={'pS MonMedium ultraDarkGrey'} text={props.location} />
                </div>
                <CustomLink url={props.companyURL} className='company-name'>
                    <Text tag={'p'} className={'pM MonRegular'} text={props.companyName} />
                    <Text tag={'p'} className={'pM MonRegular'} text={props.companyType} />
                </CustomLink>
            </div>
            <div className='req-invest' ref={investRef}>
                <Text tag={'p'} className={'pL MonRegular uppercase ultraDarkGrey'} text={'requiredInvestTitle'} />
                <div className='prices'>
                    <Text tag={'h4'} className={'h4 MonBold'} text={props.priceDollar} />
                    <Text tag={'h4'} className={'h4 MonRegular'} text={props.priceDram} />
                </div>
            </div>
            <div className='project-options' ref={projectOptionsRef}>
                <div className='project-full-value option'>
                    <Text tag={'p'} className={'option-title pM MonRegular ultraDarkGrey'} text={'filterCostProject'} />
                    <div className='prices child'>
                        <Text tag={'h6'} className={'h6 MonBold'} text={props.priceDollarFull} />
                        <Text tag={'h6'} className={'h6 MonRegular'} text={props.priceDramFull} />
                    </div>
                </div>
                <div className='profit-percent option'>
                    <div className='option-title'>
                        <Text tag={'p'} className={'title pM MonRegular ultraDarkGrey'} text={'EBIDAMarginProfitability'} />
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: props.projectPriceTooltip }} />}>
                            <div className='HelperDesc'>
                                <Icon className='icon-quastion'>
                                    <span className='path1' />
                                    <span className='path2' />
                                </Icon>
                            </div>
                        </Tooltip>
                    </div>
                    <Text tag={'h6'} className={'h6 MonBold child'} text={props.profitPercent} />
                </div>
                <div className='yearly-sale option'>
                    <Text tag={'p'} className={'option-title pM MonRegular ultraDarkGrey'} text={'yearlySale'} />
                    <div className='prices child'>
                        <Text tag={'h6'} className={'h6 MonBold'} text={props.yearlySaleDollar} />
                        <Text tag={'h6'} className={'h6 MonRegular'} text={props.yearlySaleDram} />
                    </div>
                </div>
                <div className='auction-percent option'>
                    <Text tag={'p'} className={'option-title pM MonRegular ultraDarkGrey'} text={'ShareTheCompanyIfOneInvestsTheRequiredMoney'} />
                    <Text tag={'p'} className={'h6 MonBold child'} text={props.auctionsPercent} />
                </div>
                <div className='employers-number option'>
                    <Text tag={'h6'} className={'option-title pM MonRegular ultraDarkGrey'} text={'NumberOfEmployees'} />
                    <Text tag={'h6'} className={'h6 MonBold child'} text={props.employersNumber} />
                </div>
                <div className='payback-period option'>
                    <div className='option-title'>
                        <Text tag={'p'} className={'title pM MonRegular ultraDarkGrey'} text={'PaybackPeriod'} />
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: props.translate('PaybackPeriodHelper') }} />}>
                            <div className='HelperDesc'>
                                <Icon className='icon-quastion'>
                                    <span className='path1' />
                                    <span className='path2' />
                                </Icon>
                            </div>
                        </Tooltip>
                    </div>
                    <div className='FlexBox'>
                        <Text tag={'h6'} className={'h6 MonBold child'} text={props.paybackPeriod} />
                        &nbsp;
                        <Text tag={'span'} className={'pM MonMedium black uppercase'} text={'year'} />
                    </div>
                </div>

                <div className='employers-number option full'>
                    {props.details && (
                        <>
                            <Text tag={'h6'} className={'option-title pM MonRegular ultraDarkGrey'} text={'details'} />
                            <Text tag={'h6'} className={'h6 child'} text={props.details} />
                        </>
                    )}
                </div>
            </div>
            {props.loggedIn && <Button ref={btnRef} className='btn btn-black' text={'contactWithCompany'} onClick={PopupOpen} />}
        </ProjectInfoStyle>
    )
})

export default withUIContext(withLanguageContext(ProjectInfo, ['translate']), ['openPopup'])
