import styled from 'styled-components'

const SignInStyle = styled.div`
	&.SignIn {
		--SignInMargRight: var(--sp6x);
		--iconSize: var(--sp7x);

		.SignInWrap {
			margin-right: var(--SignInMargRight);
			.avatarImg,
			.icon-login {
				width: var(--iconSize);
				height: var(--iconSize);
			}
			.icon-login {
				display: block;
			}
			i {
				font-size: var(--iconSize);
				display: inline-block;
				.icon-login {
					position: relative;
					display: block;
					border-radius: var(--borderLg);
					border: 1px solid var(--black);
					overflow: hidden;
				}
				.path1:before,
				.path2:before {
					position: absolute;
					top: 0;
					left: 0;
					margin: 0;
				}
			}

            @media (hover: hover) {
                > div {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
			--SignInMargRight: var(--sp4x);
			--iconSize: var(--sp6x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
			--SignInMargRight: var(--sp2x);
			--iconSize: var(--sp5x);
		}
	}
`

export default SignInStyle
