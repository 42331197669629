import React, { memo as Memo, useMemo, useState, useCallback } from 'react'

//* HOCs
import { withLanguageContext, withDataContext, withUIContext } from 'context'

//* Components
import { Text, Menu, SocIcon, LanguageSwitcher, Translate, Container, CustomLink, Button } from 'components/common'

//* Helpers
import { config } from 'helpers'

//* Styles
import Footer from './style'

const AppFooter = Memo(({ subscribeNewsletter, openSubscribePopup, ...props }) => {
    //! States
    const [subscribeSuccess, setSubscribeSuccess] = useState(false)

    //! Routes from Config
    const routes = useMemo(() => config.routes, [])

    //! Menu Object
    const menuObj = useMemo(
        () => ({
            contClass: 'menu-items-cont FlexBoxContainer',
            itemClass: 'menu-item pL white',
            items: [
                {
                    name: routes.investmentProjects.name,
                    path: routes.investmentProjects.path,
                },
                {
                    name: routes.exportFromArmenia.name,
                    path: routes.exportFromArmenia.path,
                },
                {
                    name: routes.investmentsForBusiness.name,
                    path: routes.investmentsForBusiness.path,
                },
                {
                    name: routes.whatIsaProductSubmission.name2,
                    path: routes.whatIsaProductSubmission.path,
                },
                {
                    name: routes.news.name,
                    path: routes.news.path,
                },
                {
                    name: routes.businessInArmenia.name,
                    path: routes.businessInArmenia.path,
                },
                {
                    name: routes.becomePartner.name,
                    path: routes.becomePartner.path,
                },
                {
                    name: routes.ourServices.name,
                    path: routes.ourServices.path,
                },
                {
                    name: routes.contactUs.name,
                    path: routes.contactUs.path,
                },
            ],
        }),
        [routes]
    )

    //! Form Submit
    const onFinish = useCallback(
        (values) => {
            return !subscribeSuccess && subscribeNewsletter(values).then((res) => setSubscribeSuccess(true))
        },
        [subscribeSuccess]
    )

    //! Get Year
    const year = useMemo(() => new Date().getFullYear(), [])

    return (
        <Footer>
            <div className='footerTopCont'>
                <Container className='footerTop FlexBoxContainer spaceBetween alignMiddle'>
                    <div className='footerText'>
                        <Text tag={'div'} className={'h3 Title black MonBold'} text={'StayInformed'} />
                        <Text tag={'div'} className={'h3 Title black MonRegular'} text={'SubscribeNewsletter'} />
                    </div>

                    <div className='footerSubscribe'>
                        <Button text={'subscribeBtnText'} className="btn-black" onClick={openSubscribePopup} />
                    </div>
                </Container>
            </div>
            <Container className='footerCont'>
                <div className='footerMenuCont FlexBoxContainer spaceBetween'>
                    <div className='footerMenu'>
                        <Menu checked={props.opened} type={'nav'} onClick={props.onClick} {...menuObj} />
                    </div>

                    <div className='footerContactInfo FlexBox'>
                        <div className='footerInfo'>
                            <CustomLink className={'pL white MonRegular'} url={'/privacy-policy'}>
                                <Translate val={'termsConditions'} />
                            </CustomLink>
                        </div>

                        <LanguageSwitcher color={'white'} className='footerLanguageSwitcher' />
                    </div>
                </div>

                <div className='footerBottom FlexBoxContainer spaceBetween'>
                    <div className='AllRightsReserved'>
                        <Text
                            tag={'div'}
                            className={'pM darkGrey MonRegular'}
                            children={
                                <>
                                    {'©' + year}
                                    <Translate val={'AllRightsReserved'} />
                                </>
                            }
                        />
                        <div
                            tag={'div'}
                            className={'pM darkGrey MonRegular'}
                            dangerouslySetInnerHTML={{ __html: props.translate('madeByConceptStudio') }}
                        />
                    </div>

                    <SocIcon color={'white'} />
                </div>
            </Container>
        </Footer>
    )
})

export default withUIContext(withLanguageContext(withDataContext(AppFooter, ['subscribeNewsletter']), ['translate', 'selectedLang']), ['openSubscribePopup'])
