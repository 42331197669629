import React, { useImperativeHandle, useState, useCallback, forwardRef, useRef, useEffect } from 'react'

//* Components
import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Text, Icon } from 'components/common'

const FormContainer = forwardRef(({ layout, className, ...props }, ref) => {
	//! Form
	const [form] = useForm()

	//! Send Form as Ref
	useImperativeHandle(ref, () => {
		return form
	})

	//! States
	const [error, setError] = useState()

	//! On Value Change
	const onValuesChange = (newVal, vals) => {
		props.onChange && props.onChange(newVal, vals, form)
	}

	//! On Finish
	const onFinish = useCallback(
		(values) => {
			form.setFields([])
			setError('')

			props
				.onFinish(values)
				.then((res) => {
					Promise.resolve(res)
				})
				.catch((err) => {
					console.error(err)
					if (err?.data) {
						const formVals = []

						if (typeof err.data.message === 'string') {
							setError(err.data.message)
						}

						for (let key in values) {
							formVals.push({
								name: key,
								value: values[key],
								errors: [err.data.errors?.[key]]?.[0],
							})
						}
						form.setFields(formVals)
					}

					Promise.reject(err?.data)
				})
		},
		[error, props.onFinish]
	)

	const formRef = useRef()

	return (
		<div ref={formRef}>
			{error && (
				<div className='globalErrorWrap FlexBoxContainer spaceBetween'>
					<div className='globalErrorIconWrap'>
						<Icon className='icon-attention FlexBox alignMiddle alignCenter'>
							<span className='path1' />
							<span className='path2' />
							<span className='path3' />
						</Icon>
					</div>

					<div className='globalErrorInfoWrap'>
						<Text tag={'h6'} className={'MonBold black'} text={error} />
						<Text tag={'p'} className={'MonRegular black'} text={'errorStaticText'} />
					</div>
				</div>
			)}
			<Form
				layout={layout || ''}
				initialValues={props.initialValues}
				onFinish={onFinish}
				onValuesChange={onValuesChange}
				form={form}
				className={className || ''}
			>
				{props.children}
			</Form>
		</div>
	)
})

export default FormContainer
