import React, { memo as Memo } from 'react'

//* HOC's
import { useRouter } from 'next/router'

//* Components
import { Text, CustomLink, Translate, Image } from 'components/common'

const SuccessMessage = Memo(({ mail }) => {
    //! Router
    const router = useRouter()

    return (
        <>
            <Text tag={'div'} className={' Title h4 MonMedium black'} text={'ForgotPassword'} />

            <div className='subTitle'>
                <Text tag={'span'} className={'pL MonRegular black'} text={'recoveryLinkText'} />
                <Text tag={'span'} className={'pL MonRegular black'} text={` ${mail}`} />
            </div>

            <div className='successIcon FlexBox alignCenter'>
                <Image src={'/images/svg/sentRecoveryLink.svg'} alt='sent Recovery Link' />
            </div>

            <div className='linkWrap FlexBox spaceBetween'>
                <CustomLink
                    url={{ pathname: router.pathname, query: { c: 'login' } }}
                    className={'black underline'}
                    content={<Translate val='ReturnToSignIn' />}
                />

                {/* <CustomLink url={"/"} className={"black underline"} content={<Translate val="ResendRecoveryLink" />} /> */}
            </div>
        </>
    )
})

export default SuccessMessage
