import { forwardRef } from 'react'
import { withLanguageContext } from 'context'
import Link from 'next/link'

const CustomLink = forwardRef(({ selectedLang, options, ...props }, ref) => {
	return (
		<Link
			onMouseOver={props.onMouseOver}
			onMouseEnter={props.onMouseEnter}
			onMouseOut={props.onMouseOut}
			scroll={props.scroll}
			shallow={props.shallow}
			href={props.url}
		>
			<a ref={ref} onClick={props.onClick} className={props.className} {...options}>
				{props.content || props.children}
			</a>
		</Link>
	)
})

export default withLanguageContext(CustomLink, ['selectedLang'])
