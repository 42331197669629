import styled from 'styled-components'

const BottomBlockStyle = styled.div`
	display: flex;
	justify-content: space-between;

	.last {
		.block {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			border: 1px solid var(--lightGrey);
			border-radius: 8px;
			padding: var(--sp4x);
			max-width: calc(50% - var(--sp2x));
			transform: translateY(20vh);

			.title {
				margin-bottom: var(--titleFromContent);
			}
			.btnLottieWrapper {
				position: relative;
				.btnWrapperClass {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					.btn-black {
						margin: var(--sp3x) 0 var(--sp4x);
						width: auto;
					}
				}
				&:hover {
					.LottieWrapp {
						transform: translateY(100%);
					}
				}
			}

			.LottieWrapp {
				width: 100%;
				padding-top: 53.3%;
				background-color: var(--white);
				transition: all var(--trTime2);
				z-index: 2;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		.last .block {
			.title {
				margin-bottom: var(--sp6x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		.last .block {
			width: calc(50% - var(--sp1-75x));

			.title {
				margin-bottom: var(--sp6x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
			.last {
				flex-direction: column;

				.block {
					width: 100% !important;
					max-width: unset;

					&:last-child {
						margin-top: var(--sp4x);
					}
				}
			}
		}

		.last .block {
			width: calc(50% - var(--sp1-75x));
			padding: var(--sp3x);

			.btnLottieWrapper {
				.btnWrapperClass {
					position: static;
				}
				&:hover {
					.LottieWrapp {
						transform: translateY(0%);
					}
				}
			}

			.title {
				margin-bottom: var(--sp4x);
				flex: 1;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
		.last {
			flex-direction: column;

			.block {
				width: 100%;
				max-width: unset;
				padding: var(--sp2x);

				.btnLottieWrapper {
					.btnWrapperClass {
						position: static;
					}
					&:hover {
						.LottieWrapp {
							transform: translateY(0%);
						}
					}
				}

				&:last-child {
					margin-top: var(--sp4x);
				}

				.btn-black {
					margin: var(--sp2x) 0 var(--sp3x);
					width: auto;
				}

				.title {
					margin-bottom: var(--sp3x);
					flex: 1;
				}
			}
		}
	}
`
export default BottomBlockStyle
