import { memo as Memo } from 'react'
import { Text, Image, CustomLink } from 'components/common'
import { CategoryBtn } from 'components/pages'
import SingleNewsStyle from './style'
import Moment from 'react-moment'

const SingleNews = Memo((props) => {
    return (
        <SingleNewsStyle className='single-news'>
            <CustomLink
                url={props.url}
                className={'single-news-block'}
                content={
                    <>
                        <Image src={props.photo} />
                        <div className='info'>
                            <CategoryBtn bgColor={props.bgColor} inFilter={false} slug={props.categorySlug} text={props.categoryName} />
                            <Text tag={'h3'} className={'news-name h6 MonMedium black'} text={props.newsName} />
                            <div className='bottom'>
                                <Text tag={'p'} className={'pS MonRegular uppercase black'} text={props.state} />
                                <div className={'pS MonRegular uppercase black'}>
                                    <Text tag={'p'} className={'pS MonRegular uppercase black'} text={props.date} />
                                </div>
                            </div>
                        </div>
                    </>
                }
            />
        </SingleNewsStyle>
    )
})

export default SingleNews
