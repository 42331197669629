import React, { forwardRef } from 'react';
//* HOC's
import { withLanguageContext } from 'context'

import Translate from '../Translate';

//* Component
import stringToLetters from '../../../helpers/stringToLetters';

const Text = forwardRef((props, ref) => {
    let string = <Translate val={props.text} />

    switch (props.tag) {
        case 'h1':
            string = <h1 {...props.attr} style={props.style} ref={ref} onMouseEnter={props.onMouseEnter}
                onClick={props.onClick}
                className={`comp-title ${props.className || 'h1'} ${props.uppercase ? 'text-upper' : ''}`}>{props.children || string}</h1>
            break;
        case 'h2':
            string = <h2 {...props.attr} style={props.style} ref={ref} onMouseEnter={props.onMouseEnter}
                onClick={props.onClick}
                className={`comp-title ${props.className || 'h2'} ${props.uppercase ? 'text-upper' : ''}`}>{props.children || string}</h2>
            break;
        case 'h3':
            string = <h3 {...props.attr} style={props.style} ref={ref} onMouseEnter={props.onMouseEnter}
                onClick={props.onClick}
                className={`comp-title ${props.className || 'h3'} ${props.uppercase ? 'text-upper' : ''}`}>{props.children || string}</h3>
            break;
        case 'h4':
            string = <h4 {...props.attr} style={props.style} ref={ref} onMouseEnter={props.onMouseEnter}
                onClick={props.onClick}
                className={`comp-title ${props.className || 'h4'} ${props.uppercase ? 'text-upper' : ''}`}>{props.children || string}</h4>
            break;
        case 'h5':
            string = <h5 {...props.attr} style={props.style} ref={ref} onMouseEnter={props.onMouseEnter}
                onClick={props.onClick}
                className={`comp-title ${props.className || 'h5'} ${props.uppercase ? 'text-upper' : ''}`}>{props.children || string}</h5>
            break;
        case 'h6':
            string = <h6 {...props.attr} style={props.style} ref={ref} onMouseEnter={props.onMouseEnter}
                onClick={props.onClick}
                className={`comp-title ${props.className || 'h6'} ${props.uppercase ? 'text-upper' : ''}`}>{props.children || string}</h6>
            break;
        case 'label':
            string = <label {...props.attr} style={props.style} ref={ref}
                className={`comp-title ${props.className || ''} ${props.uppercase ? 'text-upper' : ''}`}
                onClick={props.onClick || null}>{props.children || string}</label>
            break;
        case 'span':
            string = <span {...props.attr} style={props.style} ref={ref}
                className={`comp-title ${props.className || ''} ${props.uppercase ? 'text-upper' : ''}`}
                onClick={props.onClick || null}>{props.children || string}</span>
            break;
        case 'div':
            string = <div {...props.attr} style={props.style} ref={ref}
                className={`comp-title ${props.className || ''} ${props.uppercase ? 'text-upper' : ''}`}
                onClick={props.onClick || null}>{props.children || string}</div>
            break;
        case 'stringToLetters':
            string = <h2 {...props.attr} style={props.style} ref={ref}
                className={`comp-title ${props.className || ''} ${props.uppercase ? 'text-upper' : ''}`}
                onClick={props.onClick || null}>{stringToLetters(props.translate(props.text))}</h2>
            break;
        case 'stringToLettersH1':
            string = <h1 {...props.attr} style={props.style} ref={ref}
                className={`comp-title ${props.className || ''} ${props.uppercase ? 'text-upper' : ''}`}
                onClick={props.onClick || null}>{stringToLetters(props.translate(props.text))}</h1>
            break;
        default:
            string = <p {...props.attr} style={props.style} ref={ref} onMouseEnter={props.onMouseEnter}
                onClick={props.onClick}
                className={`comp-title ${props.className || 'p'} ${props.uppercase ? 'text-upper' : ''}`}>{props.children || string}</p>
    }

    return string
})

export default withLanguageContext(Text, ['translate', 'selectedLang'])
