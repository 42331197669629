import styled from 'styled-components'

const NewsBlockStyle = styled.div.attrs((props) => ({ className: props.className }))`
	.news-block {
		&-section {
			display: flex;
			flex-direction: column;

			.news-container {
				display: flex;
				flex-wrap: wrap;

				.single-news {
					transform: translateY(100%);
				}

				.no-result {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin: 0 auto;

					.imageCont {
						width: var(--sp18x);
						padding-top: var(--sp18x);
					}

					.title {
						margin: var(--sp5x) 0 var(--sp3x);
					}
				}
			}

			.pagination {
				margin-top: var(--sp3x);
				display: flex;
				align-self: flex-end;
				align-items: center;

				li {
					margin-left: var(--sp4x);

					&.active {
						a {
							text-decoration: underline;
						}
					}

					&.disabled {
						a {
							i {
								color: var(--darkGrey);
							}
						}
					}

					&:first-child,
					&:last-child {
						display: none;
					}

					a {
						display: flex;
						justify-content: center;
						align-items: center;
						font-family: MonRegular, MonRegularRu;
						font-size: var(--pL);

						&.prev,
						&.next {
							width: var(--sp6x);
							height: var(--sp6x);
							background-color: var(--white);
							border-radius: 50%;
							box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);

							i {
								font-size: var(--sp1-5x);
							}
						}

						&.prev {
							i {
								transform: translateX(-2px);
							}
						}

						&.next {
							i {
								transform: translateX(2px);
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		.news-block {
			&-section {
				.pagination {
					margin-top: var(--sp2x);

					li {
						a {
							&.prev,
							&.next {
								width: var(--sp5-5x);
								height: var(--sp5-5x);
							}

							&.prev {
								i {
									transform: translateX(0);
								}
							}

							&.next {
								i {
									transform: translateX(0);
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		.news-block {
			&-section {
				.news-container {
					flex-direction: column;
				}

				.pagination {
					margin-top: var(--spSize);

					li {
						a {
							&.prev,
							&.next {
								width: var(--sp5-5x);
								height: var(--sp5-5x);
							}

							&.prev {
								i {
									transform: translateX(0);
								}
							}

							&.next {
								i {
									transform: translateX(0);
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.news-block {
			&-section {
				.news-container {
					flex-direction: column;
				}

				.pagination {
					margin-top: 0;

					li {
						a {
							&.prev,
							&.next {
								width: var(--sp5-5x);
								height: var(--sp5-5x);
							}

							&.prev {
								i {
									transform: translateX(0);
								}
							}

							&.next {
								i {
									transform: translateX(0);
								}
							}
						}
					}
				}
			}
		}
	}
`
export default NewsBlockStyle
