import { memo as Memo } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Button, Checkbox } from 'antd'
import { Text, CustomLink } from 'components/common'

//* Helpers
import { formatNumber } from 'helpers'

const NewsSummary = Memo((props) => {
	return (
		<div className='totlaSection FormRow FlexBoxContainer spaceBetween'>
			<div className='FormColumnsTitle'>
				<Text tag={'h4'} className={' MonMedium black'} text={'Summary'} />
			</div>

			<div className='FormColumnsWrapp FlexBoxContainer spaceBetween'>
				<div className='priceWrapp'>
					<div className='priceItem FlexBoxContainer spaceBetween'>
						<div className='priceItemLeft'>
							<Text tag={'p'} className={'h6 MonRegular black'} text={'SubmitNewsTotal'} />
						</div>
						<div className='priceItemRight'>
							<Text tag={'p'} className={'h6 MonRegular black'} text={`${formatNumber(props.price.amd)} ֏`} />
							<Text tag={'p'} className={'h6 MonRegular black'} text={`${formatNumber(props.price.usd)} $`} />
						</div>
					</div>
					<div className='totalPrice FlexBoxContainer spaceBetween'>
						<div className='priceItemLeft'>
							<Text tag={'p'} className={'h6 MonRegular black'} text={'Total'} />
						</div>
						<div className='priceItemRight'>
							<Text tag={'p'} className={'h6 MonBold black'} text={`${formatNumber(props.price.amd)} ֏`} />
						</div>
					</div>
				</div>
				<div>
					<Checkbox onChange={props.acceptTermsChange}>
						<Text tag={'p'} className={'pM MonRegular black '}>
							{props.translate('SubmitNewsPostAgree')}
							&nbsp;
							<CustomLink url={'/privacy-policy'} className={'black underline MonMedium'} options={{ target: '_blank' }}>
								{props.translate('SubmitNewsPostAgreeLink')}
							</CustomLink>
						</Text>
					</Checkbox>
				</div>

				<div className='formBtnWrapp'>
					<Button htmlType={'Submit'} className={`btn btn-black btn-max-width ${!props.passBtnIsActive && 'disabled'}`}>
						{props.translate('SubmitNewsPost')}
					</Button>
				</div>
			</div>
		</div>
	)
})

export default withLanguageContext(NewsSummary, ['translate', 'selectedLang'])
