import { memo as Memo } from 'react'

//* Components
import { Text, FormItem, FormInput } from 'components/common'

const ContactInformation = Memo((props) => {
	return (
		<>
			<div className='FormColumnsTitle'>
				<Text tag={'h4'} className={' MonMedium black'} text={'Contacts'} />
			</div>

			<div className='FormColumnsWrapp FlexBoxContainer spaceBetween'>
				<div className='FormCol'>
					<FormItem label={'ContactPersonNameAndSurname'} name={'full_name'} required={true}>
						<FormInput />
					</FormItem>
				</div>

				<div className='FormCol FormCol-50'>
					<FormItem label={'PhoneNumber'} name={'phone'} required={true} validationType='phone'>
						<FormInput />
					</FormItem>
				</div>
				<div className='FormCol FormCol-50'>
					<FormItem label={'Email'} name={'email'} required={true} validationType='email'>
						<FormInput />
					</FormItem>
				</div>
			</div>
		</>
	)
})

export default ContactInformation
