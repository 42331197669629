import { useState, useMemo, useCallback, forwardRef, useRef, useImperativeHandle } from 'react'
import { Text, MapComponent } from 'components/common'
import Collapse from '@material-ui/core/Collapse'
import MapInCatalogueStyle from './style'

const MapInCatalogue = forwardRef(({ projects, ...props }, ref) => {
	const [hgt, setHgt] = useState(true)

	//! Toggle Height
	const clickHandler = useCallback(() => {
		setHgt(!hgt)
	}, [hgt])

	const mapProjects = useMemo(() => {
		return projects?.map((i) => ({
			id: i.id,
			pos: { lat: Number(i.latitude), lng: Number(i.longitude) },
			title: i.name,
			description: i.short_description,
			price: i.required_investment.USD,
			img: i.feature_image,
		}))
	}, [projects])

	const itemsRef = useRef()
	useImperativeHandle(ref, () => [itemsRef], [])

	return (
		<MapInCatalogueStyle>
			<div ref={itemsRef}>
				<Collapse in={hgt} timeout='auto' className='map'>
					<MapComponent className='mapSm' pinsData={mapProjects} popInfo={true} markerDrag={false} />
				</Collapse>
				<div onClick={() => clickHandler()} className={`close-map underline capitalize pL black`}>
					<Text tag={'p'} className={' pL black MonMedium cursorPointer'} text={hgt ? 'closeMap' : 'openMap'} />
				</div>
			</div>
		</MapInCatalogueStyle>
	)
})

export default MapInCatalogue
