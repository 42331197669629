import { memo as Memo } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Button, Checkbox, Radio } from 'antd'
import { Text, FormItem } from 'components/common'

//* Helpers
import { formatNumber } from 'helpers'

const Summary = Memo((props) => {
	return (
		<div className='totlaSection FormRow FlexBoxContainer spaceBetween'>
			<div className='FormColumnsTitle'>
				<Text className={'h4 MonMedium black'} text={'Summary'} />
			</div>

			<div className='FormColumnsWrapp FlexBoxContainer spaceBetween'>
				<div className='priceWrapp'>
					{props.type === 2 && (
						<div className='priceItem'>
							<Text className={' ant-form-item-label uppercase'} text={'SubmissionPlan'} />
							<div className='FormCol'>
								<FormItem name={'plan_id'} required={true}>
									<Radio.Group>
										<div className='FlexBoxContainer spaceBetween'>
											{props.priceList &&
												props.priceList.map((p, k) => (
													<div key={k} className='FormCol FormCol-50'>
														<Radio.Button value={p.id}>
															<Text tag={'p'} className={' h6 MonMedium black'} text={p.title} />
															<Text tag={'div'} className={' pL MonMedium ultraDarkGrey'}>
																{`${p.price} ${props.translate('dramWord')}`}
															</Text>
															<Text tag={'div'} className={' pM MonMedium darkGrey'}>
																{`~ ${p.price_per_month} ${props.translate('dramMonth')}`}
															</Text>
														</Radio.Button>
													</div>
												))}
										</div>
									</Radio.Group>
								</FormItem>
							</div>
						</div>
					)}
					<div className='priceItem FlexBoxContainer spaceBetween'>
						<div className='priceItemLeft'>
							{props.productQuantity ? (
								<>
									<Text tag={'p'} className={'h6 black MonMedium'}>
										{props.categoryQuantity}&nbsp;
										{props.translate('XCategory')}
									</Text>
									<Text tag={'p'} className={'h6 MonRegular black'}>
										{props.productQuantity}&nbsp;
										{props.translate('XProduct')}
									</Text>
								</>
							) : (
								<Text
									tag={'p'}
									className={`h6 black ${props.type === 2 ? 'MonMedium' : 'MonRegular'}`}
									text={'InvestmentOpportunitySubmission'}
								/>
							)}
						</div>
						<div className='priceItemRight'>
							<Text tag={'p'} className={'h6 MonRegular black'} text={`${formatNumber(props.total, 'hy')}`} />
						</div>
					</div>
					<div className='totalPrice FlexBoxContainer spaceBetween'>
						<div className='priceItemLeft'>
							<Text tag={'p'} className={`h6 MonRegular black ${props.type === 2 ? 'uppercase' : ''}`} text={'Total'} />
						</div>
						<div className='priceItemRight'>
							<Text tag={'p'} className={'h6 MonBold black'} text={`${formatNumber(props.total, 'hy')}`} />
						</div>
					</div>
				</div>
				<div>
					<Checkbox onChange={props.acceptTermsChange}>
						<Text
							tag={'p'}
							className={'pM MonRegular black '}
							text={`${props.type === 2 ? 'BySubmittingExportProductAgree' : 'BySubmittingInvestmentOpportunity'}`}
						/>
					</Checkbox>
				</div>

				<div className='formBtnWrapp'>
					<Button htmlType={'submit'} className={`btn btn-black btn-max-width ${!props.passBtnIsActive && 'disabled'}`}>
						{props.translate('CompleteSubmission')}
					</Button>
				</div>
			</div>
		</div>
	)
})

export default withLanguageContext(Summary, ['translate', 'selectedLang'])
