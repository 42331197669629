import styled from 'styled-components'

const ProjectApprovedStyle = styled.div.attrs((props) => ({ className: props.className }))`
	width: 100%;
	text-align: center;
	margin-top: var(--sp12x);
	margin-bottom: var(--sp22x);
	background-color: var(--ultraLightGrey);
	padding: var(--sp15x) 0;

	.block {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: var(--sp108x);
		&>*{
			transform: translateY(30vh);
		}

		.text {
			margin: var(--sp6x) 0 var(--sp4-5x);
		}

		.imageCont {
			width: var(--sp11x);
			padding-top: var(--sp15x);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		margin-top: var(--sp22x);
		padding: var(--sp12x) 0;

		.block {
			max-width: var(--sp83x);

			.text {
				margin: var(--sp4x) 0 var(--sp4x);
			}

			.imageCont {
				width: var(--sp8x);
				padding-top: var(--sp11x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		margin-top: var(--sp20x);
		margin-bottom: var(--sp20x);
		padding: var(--sp10x) 0;

		.block {
			max-width: var(--sp83x);

			.text {
				margin: var(--sp3-5x) 0 var(--sp3-5x);
			}

			.imageCont {
				width: var(--sp8x);
				padding-top: var(--sp11x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		margin-top: var(--sp16x);
		margin-bottom: var(--sp16x);
		padding: var(--sp7-5x) 0;

		.block {
			max-width: var(--sp77x);

			.text {
				margin: var(--sp2-5x) 0 var(--sp2-5x);
			}

			.imageCont {
				width: var(--sp8x);
				padding-top: var(--sp11x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		margin-top: var(--sp16x);
		margin-bottom: var(--sp16x);
		padding: var(--sp7-5x) 0;

		.block {
			max-width: var(--sp64x);

			.text {
				margin: var(--sp2-5x) 0 var(--sp2-5x);
			}

			.imageCont {
				width: var(--sp8x);
				padding-top: var(--sp11x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		margin-top: var(--sp12x);
		margin-bottom: var(--sp12x);
		padding: var(--sp7-5x) 0;

		.block {
			max-width: unset;

			.text {
				margin: var(--sp2x) 0;
			}

			.imageCont {
				width: var(--sp5-5x);
				padding-top: var(--sp8x);
			}
		}
	}
`
export default ProjectApprovedStyle
