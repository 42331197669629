import React, { memo as Memo, useEffect, useMemo, useState, forwardRef, useRef, useImperativeHandle } from 'react'
import { withUIContext } from 'context'
import { Image, Icon } from 'components/common'
import SliderStyle from './style'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Thumbs, Navigation, Pagination } from 'swiper/core'
SwiperCore.use([Thumbs, Navigation, Pagination])

function SampleNextArrow(props) {
    const { onClick } = props
    return <Icon className={`icon-arrow-right swiper-btn next`} onClick={onClick} />
}

function SamplePrevArrow(props) {
    const { onClick } = props
    return <Icon className={`icon-arrow-left swiper-btn prev`} onClick={onClick} />
}

const Slider = forwardRef(({ winWidth, ...props }, ref) => {
    const sl1Ref = useRef()
    const sl2Ref = useRef()

    const [thumbsSwiper, setThumbsSwiper] = useState(null)

    const storePhotos = useMemo(() => {
        return props.photos.map((el, ind) => {
            return (
                <SwiperSlide onClick={() => onClick(ind + 1)} key={ind}>
                    <Image src={el} alt='slider photo' />
                </SwiperSlide>
            )
        })
    }, [props.photos])

    const onChange = (e) => {
        sl1Ref.current.swiper.slideToLoop(e.realIndex)
    }

    useImperativeHandle(ref, () => [sl1Ref, sl2Ref], [])

    const slidePrev = () => {
        sl1Ref.current.swiper.slidePrev()
        sl2Ref.current.swiper.slidePrev()
    }

    const slideNext = () => {
        sl1Ref.current.swiper.slideNext()
        sl2Ref.current.swiper.slideNext()
    }

    const onClick = (index) => {
        sl1Ref.current.swiper.slideTo(index)
    }

    return (
        <SliderStyle className='sliders'>
            <Swiper
                ref={sl1Ref}
                className='sl-1'
                slidesPerView={1}
                thumbs={winWidth > 1439 && thumbsSwiper && { swiper: thumbsSwiper }}
                spaceBetween={0}
                loop={true}
                simulateTouch={false}
                pagination={{
                    el: winWidth < 1440 && '.swiper-pagination',
                    clickable: true,
                }}
            >
                {storePhotos}
                {winWidth < 1440 && <div className='swiper-pagination' />}
            </Swiper>
            {winWidth > 1439 && (
                <div className='sliderBottWrapp relativeWrapp'>
                    <Swiper
                        onSlideChange={onChange}
                        ref={sl2Ref}
                        className='sl-2'
                        onSwiper={setThumbsSwiper}
                        watchSlidesVisibility={true}
                        watchSlidesProgress={true}
                        loop={props.photos.length > 4}
                        spaceBetween={16}
                        slidesPerView={4}
                        simulateTouch={true}
                        slideToClickedSlide={true}
                        enabled={`${winWidth > 1439}`}
                    >
                        {storePhotos}
                    </Swiper>
                    <SampleNextArrow onClick={slideNext} />
                    <SamplePrevArrow onClick={slidePrev} />
                </div>
            )}
        </SliderStyle>
    )
})

export default withUIContext(Slider, ['winWidth', 'screenSizes'])
