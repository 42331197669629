import React, { memo as Memo, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
gsap.core.globals('ScrollTrigger', ScrollTrigger)

//* HOC's
import { withUIContext } from 'context'

import AnimationWrappStyle from './style'

const AnimationWrapp = Memo(({ onDelay, start, update = [], ...props }) => {
    useEffect(() => {
        ScrollTrigger.refresh(true)
        if (props?.state?.length > 0 && props.state !== null) {
            props.state.forEach((el, index) => {
                if (el !== null && el !== undefined) {
                    ScrollTrigger.create({
                        trigger: el,
                        start: start || 'top-=50px bottom',
                        onEnter: () => {
                            el &&
                                gsap.to(el, {
                                    y: 0,
                                    force3D: false,
                                    opacity: 1,
                                    delay: typeof onDelay === 'number' && onDelay >= index ? 0.5 : 0,
                                    stagger: 0.05,
                                    ease: 'power4.out',
                                })
                        },
                    })
                }
            })
        }

        if (props?.formItems?.length > 0 && props.formItems !== null) {
            props.formItems.forEach((el, index) => {
                if (el !== null && el !== undefined) {
                    ScrollTrigger.create({
                        trigger: el?.[0] ? el[0].parentElement : props.formItems[index + 1][0].parentElement,
                        start: 'top bottom',
                        onEnter: () => {
                            gsap.to(el, {
                                y: 0,
                                force3D: false,
                                stagger: 0.05,
                                ease: 'power4.out',
                            })
                        },
                    })
                }
            })
        }

        if (props?.minItemScrub?.length > 0 && props.minItemScrub !== null) {
            gsap.to(props.minItemScrub, 1.2, {
                y: 0,
                force3D: false,
                rotation: 0,
                ease: 'power4.out',
                scrollTrigger: {
                    trigger: props.minItemScrub[0],
                    start: 'top bottom',
                },
                onComplete: () => {
                    props.minItemScrub.forEach((el, index) => {
                        gsap.from(el, {
                            rotate: props.afterComplete[index].rotate ? props.afterComplete[index].rotate : 0,
                            y: props.afterComplete[index].y ? props.afterComplete[index].y : 0,
                            duration: props.afterComplete[index].duration,
                            ease: 'power4.out',
                            scrollTrigger: {
                                trigger: el[0] || el,
                                start: 'top bottom',
                                scrub: true,
                            },
                        })
                    })
                },
            })
        }

        if (props?.otherItem?.length > 0 && props.otherItem !== null) {
            props.otherItem.forEach((el, index) => {
                gsap.to(el, {
                    scale: props.afterComplete[index].scale ? props.afterComplete[index].scale : 1,
                    x: props.afterComplete[index].x ? props.afterComplete[index].x : 0,
                    duration: props.afterComplete[index].duration,
                    delay: props.afterComplete[index].delay ? props.afterComplete[index].delay : 0,
                    ease: 'power4.out',
                    scrollTrigger: {
                        trigger: el[0] || el,
                        start: 'top bottom',
                    },
                })
            })
        }

        if (props?.ourServicesAnimation?.length > 0 && props.ourServicesAnimation !== null) {
            props.ourServicesAnimation[1].forEach((el, index) => {
                ScrollTrigger.create({
                    trigger: props.ourServicesAnimation[0][index][3],
                    start: 'top-=50% bottom',
                    onEnter: () => {
                        gsap.to(props.ourServicesAnimation[0][index], {
                            y: 0,
                            force3D: false,
                            stagger: 0.05,
                            ease: 'power1.out',
                        })
                        gsap.to([el[1], el[2], el[3]], 0.6, {
                            y: 0,
                            force3D: false,
                            stagger: 0.05,
                            ease: 'power1.out',
                        })
                        gsap.to(el[2].firstChild.childNodes[1], 1, {
                            scale: 1,
                            ease: 'power1.out',
                            delay: 1,
                        })
                        if (el[3]) {
                            gsap.to(el[3].firstChild.firstChild, 0.5, {
                                rotation: index % 2 == 0 > 0 ? 180 : -180,
                                transformOrigin: index % 2 == 0 > 0 ? '55% 55%' : '45% 45%',
                                ease: 'power1.out',
                            })
                        }
                    },
                })
            })
        }

        if (props?.countAnimation?.length > 0 && props?.countAnimation !== null) {
            ScrollTrigger.batch(props.countAnimation[1], {
                start: 'top+=50% bottom',
                marker: true,
                onEnter: () => {
                    props.countAnimation[0].forEach((element, i) => {
                        gsap.to(element.current, {
                            duration: 1,
                            textContent: `+=${props.titlesItem[i]}`,
                            roundProps: 'textContent',
                        })
                    })
                },
            })
            gsap.fromTo(
                props.countAnimation[2][0],
                {
                    y: '-30%',
                },
                {
                    y: '25%',
                    scrollTrigger: {
                        trigger: props.countAnimation[2][0],
                        scrub: true,
                        start: 'top+=50% bottom-=20%',
                        end: 'bottom top',
                    },
                }
            )
        }

        return () => ScrollTrigger.getAll().forEach((t) => t.kill())
    }, [props.state, props.winWidth, props.formItems, ...update])

    return <AnimationWrappStyle>{props.children}</AnimationWrappStyle>
})

export default withUIContext(AnimationWrapp, ['screenSizes', 'winWidth'])
