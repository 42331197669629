import styled from 'styled-components'

const CooperationPackagesStyle = styled.div.attrs((props) => ({ className: props.className }))`
	background-color: var(--ultraLightGrey);
	padding: var(--sp12x) 0;

	.block {
		& > .heading {
			max-width: var(--sp142x);
			width: 100%;
			text-align: center;
			margin-bottom: var(--sp12x);

			.title {
				margin-bottom: var(--titleFromContent);
			}
		}

		.packages {
			.single-package {
				width: calc(50% - var(--sp2x));
				transform: translateY(15vh);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		padding: var(--sp10x) 0;

		.block {
			& > .heading {
				max-width: var(--sp132x);
				margin-bottom: var(--sp10x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		padding: var(--sp7-5x) 0;

		.block {
			& > .heading {
				max-width: var(--sp112x);
				margin-bottom: var(--sp7-5x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		padding: var(--sp5x) 0;

		.block {
			& > .heading {
				max-width: var(--sp112x);
				margin-bottom: var(--sp5x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		padding: var(--sp5x) 0;

		.block {
			& > .heading {
				max-width: var(--sp112x);
				margin-bottom: var(--sp5x);
			}

			.packages {
				flex-direction: column;

				.single-package {
					width: 100%;
					max-width: var(--sp70x);

					&:last-child {
						margin-top: var(--sp5x);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		padding: var(--sp5x) 0;

		.block {
			padding: 0;

			& > .heading {
				max-width: var(--sp112x);
				margin-bottom: var(--sp5x);
			}

			.packages {
				flex-direction: column;

				.single-package {
					width: 100%;

					&:last-child {
						margin-top: var(--sp5x);
					}
				}
			}
		}
	}
`
export default CooperationPackagesStyle
