import styled from 'styled-components'

const CooperationStyle = styled.div.attrs({})`
   
    --accountCooperationPaddTop:var(--sp3x);
    --accountCooperationPaddLeft:var(--sp2x);

    &.accountCooperationCont{
        .accountCooperation{

            background-color:var(--ultraLightGrey);
            border-radius: var(--borderSm);
            padding: var(--accountCooperationPaddTop) var(--accountCooperationPaddLeft);

            .Title{
                padding:0  var(--sp1-5x);
                margin-bottom: var(--sp3x);
                text-align:center;
            }
            .CooperationItem{
                margin-bottom: var(--sp4x);
                >div{
                    padding-left:var(--spSize);
                    width:calc(100% - var(--sp2x));
                }

                .iconCont{
                    margin-top:var(--spSize);
                    width:var(--sp2x);
                    height:var(--sp2x);
                    border-radius:var(--borderLg);
                    background-color:var(--ultraDarkGrey);
                    i{
                        font-size: calc(var(--spSize) - 2px);
                    }
                }
            }
        }
    }

    @media screen and (max-width:${props => props.theme.mediaQuery.DesktopSizeSMin}){
    }

    @media screen and (max-width:${props => props.theme.mediaQuery.LaptopSizeMin}) and (min-width:${props => props.theme.mediaQuery.MobileSize}) {
    }

    @media screen and (min-width:${props => props.theme.mediaQuery.TabletSize}) {
    }
    
    @media screen and (max-width:${props => props.theme.mediaQuery.TabletSizeMin}){
    }
`
export default CooperationStyle