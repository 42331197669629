import styled from 'styled-components'

const DoneWorksStyle = styled.div`
	.full {
		padding-top: var(--sp24x);
		padding-bottom: var(--sp24x);
		position: relative;
		.backImg {
			position: absolute;
			top: -30%;
			left: 0;
			width: 100%;
			height: 150%;
			img {
				object-fit: cover;
			}
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: var(--black);
			opacity: 0.37;
		}

		.block {
			position: relative;
			z-index: 1;

			.single-work {
				width: calc(33% - var(--sp4x));

				.title {
					margin-bottom: var(--titleFromContent);
				}

				h4 {
					flex: 1;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		.full {
			padding-top: var(--sp21x);
			padding-bottom: var(--sp21x);
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		.full {
			padding: var(--sp13x) 0;

			.block {
				.single-work {
					width: calc(33% - var(--sp3x));
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.full {
			padding: var(--sp12x) var(--sp5-5x);

			.block {
				flex-direction: column;

				.single-work {
					width: 100%;

					&:not(:last-child) {
						margin-bottom: var(--sp7-5x);
					}
				}
			}
		}
	}
`
export default DoneWorksStyle
