import styled from 'styled-components'

const SubscribeStyle = styled.div.attrs({})`
    --successIconMarg: var(--sp7x);
	--successIconSize: var(--sp20x);

    display: flex;
    flex-direction: column;
    align-items: center;

    &.loading {
        opacity: 0.7;
        pointer-events: none;
    }

    .Title {
        margin-bottom: var(--sp6x);
    }

    .ant-checkbox-group {
        display: flex;
        flex-wrap: wrap;

        .ant-checkbox-wrapper {
            width: 50%;
            margin: 0 0 var(--sp3x);
        }
    }

    .successIcon{
		margin: var(--successIconMarg) 0 ;
		.LottieWrapp,.imageCont  {
			width: var(--successIconSize);
			height: var(--successIconSize);
			svg,img {
				width: 400% !important;
				height: 400% !important;
				left: -150%;
				top: -105%;
			}
		}

        ~ * {
            text-align: center;
        }
	}

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.MobileSizeMin}) {
        .ant-checkbox-group {

            .ant-checkbox-wrapper {
                width: 100%;
            }
        }
    }
`

export default SubscribeStyle