import React, { memo as Memo } from 'react';
import { CustomLink , Text } from 'components/common'

const Nav = Memo(props => {
    return (
        <>
            <Text tag={'span'} className={'MonMedium dubleTitle'} children={props.children} />
            <CustomLink className={"Link"} url={props.path} content={props.children} nav={true} data={props.data}/>
        </>
    )
})

export default Nav
