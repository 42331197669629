import styled from 'styled-components'

const ButtonStyle = styled.div.attrs((props) => ({}))`
	.btn {
		--maxWidthBntMid: 100%;
		--iconBtnSize: var(--sp3x);
		--socIconMarg: var(--sp2x);

		-webkit-appearance: none;
		cursor: pointer;
		display: inline-block;
		color: var(--white);
		line-height: var(--lineHeightS);
		position: relative;
		z-index: 1;
		padding: var(--sp2x) var(--sp4x);
		border-radius: var(--borderSm);
		border: 0;
		font-size: var(--pL);
		overflow: hidden;
		background-color: transparent;

		&.btn-max-width {
			width: 100%;
			text-align: center;
		}

		&.btn-black {
			color: var(--white);
			width: 100%;
			text-align: center;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 205%;
				height: 100%;
				transform: translateX(0%);
				transition: var(--trTime);
				z-index: -1;
				background: linear-gradient(90deg, var(--black) 50%, var(--yellow) 50%);
			}

            @media (hover: hover) {
                &:hover {
                    color: var(--black);
                    &:before {
                        transform: translateX(-50%);
                    }
                }
            }
		}

		&.btn-white {
			color: var(--black);
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border: 1px solid var(--black);
				border-radius: var(--borderSm);
				pointer-events: none;
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 200%;
				height: 100%;
				transform: translateX(0%);
				transition: var(--trTime);
				z-index: -1;
				background: linear-gradient(90deg, var(--white) 50%, var(--black) 50%);
			}

            @media (hover: hover) {
                &:hover {
                    color: var(--white);
                    &:before {
                        transform: translateX(-50%);
                    }
                }
            }
		}

		&.disabled {
			background-color: var(--lightGrey);
			color: var(--darkGrey);
		}

		&.disabled-opacity {
			opacity: 0.5;
			cursor: not-allowed;
			pointer-events: none;
		}

		&.greyBtn {
			background-color: var(--lightGrey);
		}

		&.yellowIcon {
			display: inline-flex;
			align-items: center;
			font-family: MonRegular, MonRegularRu;
			background-color: transparent;
			position: relative;
			transition: var(--trTime);
			padding: 0;
			border-radius: 0;
			border: none;

			.text {
				margin-right: var(--spSize);
			}

			i {
				overflow: hidden;
				position: relative;
				font-size: var(--sp3-5x);
				.path2 {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					transition: all var(--trTime2);
					transform: translateX(0%);
					&.pathHover {
						transform: translateX(-100%);
					}
					&:before {
						margin-left: 0;
					}
				}
			}

            @media (hover: hover) {
                &:hover {
                    i {
                        .path2 {
                            &.pathHover {
                                transform: translateX(0%);
                            }
                            transform: translateX(100%);
                        }
                    }
                }
            }
		}

		&.iconBtn {
			display: flex;
			width: 100%;
			.imageCont {
				margin-right: var(--socIconMarg);
				width: var(--iconBtnSize);
				height: var(--iconBtnSize);
                
				/* .image {
					object-fit: contain;
				} */
			}
		}

		&.iconBtn2 {
			i {
				margin-right: var(--socIconMarg);
				font-size: 13px;
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSizeMin}) {
			font-size: var(--pM);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
			font-size: var(--pS);

			&.iconBtn2 {
				width: 100%;
			}
		}
	}
`

export default ButtonStyle
