import styled from 'styled-components'

function createCSS() {
    let styles = ''

    for (let i = 0; i < 20; i += 1) {
        styles += `
		.single-cat,.single-location ,.title  {
			&:nth-child(${i}){
				transition-delay: ${i / 15}s;
			}
		}
     `
    }

    return `${styles}`
}

const FilterInExportStyle = styled.div.attrs((props) => ({ className: 'filter-in-export-cont' }))`
	max-width: var(--sp47x);
	width: 100%;

	.filters {
		display: flex;
		flex-direction: column;
		margin-top: var(--sp3x);

		&.up {
			.back-to,
			.clear-filters,
			.single-location,
			.single-invest-range,
			.h5,
			.pM.darkGrey.capitalize,
			.selected,
			.selected-cat p,
			.title,
			.ant-slider,
			.single-cat,
			.search,
			.single-prod-type {
				transform: translateY(0vh);
			}
		}

		.single-filter {
			display: flex;
			flex-direction: column;
			margin-bottom: var(--sp7x);

			.MuiCollapse-wrapperInner {
				position: relative;
			}

			.selected-cat {
				padding-bottom: var(--sp2x);
				margin-bottom: var(--sp5x);
				border-bottom: 1px solid var(--extraLightGrey);

				.back-to {
					display: flex;
					align-items: center;
					margin-bottom: var(--sp2x);

					i {
						margin-right: 12px;
					}
				}
			}

			&.active {
				.title {
					margin-bottom: var(--sp4x);
					&:after {
						transform: translate(50%, -50%) rotate(-270deg);
					}
				}
			}

			& > .title {
				padding-right: var(--sp8x);
				position: relative;
				cursor: pointer;
				margin-bottom: 0;
				transition: all var(--trTime) ease;

				&:before {
					content: '';
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 0;
					width: var(--sp3x);
					height: 3px;
					background-color: var(--black);
					transition: var(--trTime2) ease-out;
					border-radius: 4px;
				}

				&:after {
					content: '';
					position: absolute;
					top: 50%;
					transform: translate(50%, -50%);
					right: calc((var(--sp3x) / 2));
					height: var(--sp3x);
					width: 3px;
					background-color: var(--black);
					transition: var(--trTime2) ease-out;
					border-radius: 4px;
				}
			}

			.search {
				i {
					position: absolute;
					top: 20px;
					right: 16px;
					font-size: var(--sp2x);
				}
			}

			.selected-prod-types,
			.selected-locations {
				margin-bottom: var(--sp2-5x);
				cursor: pointer;
				.single-sel-prod-type,
				.single-sel-location {
					padding: var(--sp2x);
					background-color: var(--ultraLightGrey);

					&:not(:last-child) {
						margin-bottom: var(--spSize);
					}

					i {
						font-size: var(--sp1-25x);
					}
				}
			}

			.prod-types {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				pointer-events: none;
				overflow: auto;
				overflow-x: hidden;
				z-index: -1;
				background-color: var(--white);
				&.active {
					z-index: 1;
					opacity: 1;
					pointer-events: all;
				}

				&::-webkit-scrollbar {
					width: var(--spSize);
				}

				&::-webkit-scrollbar-track {
					background: var(--lightGrey);
					border-radius: 10px;
				}

				&::-webkit-scrollbar-thumb {
					border: 1px var(--ultraDarkGrey) solid;
					border-radius: 10px;
					background-color: var(--ultraDarkGrey);
					width: var(--spSize);
				}

				.single-prod-type {
					&:not(:last-child) {
						margin-bottom: var(--sp4x);
					}
				}
			}

			.cat-block,
			.locations {
				position: relative;
				overflow: auto;
				overflow-x: hidden;
				max-height: var(--sp47x);
				padding-right: var(--sp2x);

				&.hidden {
					overflow: hidden;
				}

				&::-webkit-scrollbar {
					width: var(--spSize);
				}

				&::-webkit-scrollbar-track {
					background: var(--lightGrey);
					border-radius: 10px;
				}

				&::-webkit-scrollbar-thumb {
					border: 1px var(--ultraDarkGrey) solid;
					border-radius: 10px;
					background-color: var(--ultraDarkGrey);
					width: var(--spSize);
				}

				.single-location {
					padding-left: var(--sp2x);
				}

				.single-cat,
				.single-location {
					&:not(:last-child) {
						margin-bottom: var(--sp4x);
					}
				}
			}

			.selected {
				display: flex;
				justify-content: flex-start;
				align-items: center;

				p:first-child {
					margin-right: var(--sp1-5x);
				}
			}

			.ant-slider {
				margin: var(--sp10x) auto var(--sp2-5x);
				width: calc(100% - var(--sp6x));
				position: relative;
				cursor: pointer;
				touch-action: none;

				.ant-slider-rail,
				.ant-slider-step {
					opacity: 0;
				}

				.ant-slider-track {
					position: absolute;
					width: 100%;
					height: 1px;
					background-color: var(--ultraDarkGrey);
				}

				.ant-slider-handle {
					position: absolute;
					width: var(--sp5-5x);
					height: var(--sp5-5x);
					background-color: var(--white);
					box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
					border-radius: 50%;
					border: none;
					margin: 0;
					transform: translate(-50%, -50%) !important;
					cursor: pointer;
					transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
				}

				.ant-slider-mark {
					&-text {
						top: calc(-1 * var(--sp7x));
						color: var(--ultraDarkGrey) !important;
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		max-width: var(--sp40x);

		.filters {
			.single-filter {
				.selected-cat {
					margin-bottom: var(--sp4x);
				}

				&.active {
					.title {
						margin-bottom: var(--sp3x);
					}
				}

				.selected-prod-types,
				.selected-locations {
					margin-bottom: var(--sp2x);

					.single-sel-prod-type,
					.single-sel-location {
						padding: var(--sp1-5x);
					}
				}

				.cat-block,
				.locations {
					max-height: var(--sp42x);

					.single-location {
						padding-left: var(--sp1-5x);
					}

					.single-cat,
					.single-location {
						&:not(:last-child) {
							margin-bottom: var(--sp3x);
						}

						.prod-types {
							.single-prod-type {
								&:not(:last-child) {
									margin-bottom: var(--sp3x);
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.LaptopSize}) {
		max-width: var(--sp37x);

		.filters {
			.single-filter {
				.selected-cat {
					margin-bottom: var(--sp4x);
				}

				&.active {
					.title {
						margin-bottom: var(--sp3x);
					}
				}

				.selected-prod-types,
				.selected-locations {
					margin-bottom: var(--sp2x);

					.single-sel-prod-type,
					.single-sel-location {
						padding: var(--sp1-5x);
					}
				}

				.cat-block,
				.locations {
					max-height: var(--sp42x);

					.single-location {
						padding-left: var(--sp1-5x);
					}

					.single-cat,
					.single-location {
						&:not(:last-child) {
							margin-bottom: var(--sp3x);
						}

						.prod-types {
							.single-prod-type {
								&:not(:last-child) {
									margin-bottom: var(--sp3x);
								}
							}
						}
					}
				}
			}
		}
	}

	@media screen and (min-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
		.clear-filters,
		.single-prod-type,
		.single-location,
		.single-invest-range,
		.h5,
		.pM.darkGrey.capitalize,
		.selected,
		.back-to,
		.selected-cat p,
		.title,
		.ant-slider,
		.single-cat,
		.search {
			transform: translateY(40vh);
		}

		.single-cat,
		.single-prod-type,
		.single-location,
		.h5,
		.selected,
		.back-to,
		.selected-cat p,
		.ant-slider,
		.search {
			transition: all var(--trTime) ease-in-out;
		}

		${createCSS()};
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		max-width: unset;

		.btn-action {
			width: calc(50% - var(--sp1-5x));
			margin-bottom: var(--sp2-5x);
			align-self: flex-end;

			.filter-btn {
				width: 100%;
				padding: var(--sp2x) var(--sp6x);
				background-color: var(--ultraLightGrey);
				border: 1px solid var(--darkGrey);
				border-radius: 4px;

				span {
					display: flex;
					justify-content: space-between;
					align-items: center;

					i {
						font-size: var(--sp1-5x);
						color: var(--darkGrey);
					}
				}
			}
		}

		.filters {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100vw;
			height: 100vh;
			overflow-x: hidden;
			overflow-y: auto;
			z-index: -1;
			display: none;
			pointer-events: none;
			background-color: var(--white);
			padding-left: var(--containerPadding);
			padding-right: var(--containerPadding);
			padding-bottom: var(--sp25x);
			margin-top: 0;

			&::-webkit-scrollbar {
				width: var(--spSize);
			}

			&::-webkit-scrollbar-track {
				background: var(--lightGrey);
				border-radius: 10px;
			}

			&::-webkit-scrollbar-thumb {
				border: 1px var(--ultraDarkGrey) solid;
				border-radius: 10px;
				background-color: var(--ultraDarkGrey);
				width: var(--spSize);
			}

			&.active {
				pointer-events: all;
				display: block;
				z-index: 100;
			}

			.head-title {
				padding: var(--sp3x) 0;
				position: relative;
				text-align: center;
				margin-bottom: var(--sp3x);

				&:before {
					content: '';
					position: absolute;
					bottom: 0;
					left: calc(-1 * var(--containerPadding));
					width: calc(100% + (2 * var(--containerPadding)));
					height: 1px;
					background-color: var(--darkGrey);
				}
			}

			.single-filter {
				margin-bottom: var(--sp2x);

				& > .title {
					margin-bottom: var(--sp3x);

					&.tablet {
						display: flex;
						justify-content: space-between;
						padding-right: 0;
						align-items: center;

						&:before,
						&:after {
							display: none;
						}

						i {
							font-size: var(--sp2x);
						}
					}
				}

				.filter-in-tablet-mode {
					height: 0;
					position: fixed;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					width: 100vw;
					overflow-x: hidden;
					overflow-y: auto;
					z-index: -1;
					display: none;
					pointer-events: none;
					background-color: var(--white);
					padding-left: var(--containerPadding);
					padding-right: var(--containerPadding);
					padding-bottom: var(--sp25x);

					&.active {
						display: flex;
						flex-direction: column;
						pointer-events: all;
						height: 100vh;
						z-index: 105;
					}

					.head-title {
						padding: var(--sp3x) 0;
						justify-content: center;
						position: relative;
						text-align: center;
						margin-bottom: var(--sp3x);

						&:before {
							content: '';
							position: absolute;
							bottom: 0;
							left: calc(-1 * var(--containerPadding));
							width: calc(100% + (2 * var(--containerPadding)));
							height: 1px;
							background-color: var(--darkGrey);
						}

						i {
							position: absolute;
							top: 50%;
							left: 0;
							font-size: var(--sp2x);
							transform: translateY(-50%);
						}
					}

					.locations {
						max-height: unset;
						padding-left: var(--sp2x);
					}

					.cat-block {
						max-height: unset;
						position: fixed;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						width: 100vw;
						height: 100vh;
						overflow-x: hidden;
						overflow-y: auto;
						background-color: var(--white);
						padding-left: var(--containerPadding);
						padding-right: var(--containerPadding);
						padding-bottom: var(--sp25x);
						display: flex;
						flex-direction: column;
						z-index: 115;

						.single-cat {
							padding-left: var(--sp2x);

							&.active {
								position: fixed;
								top: 0;
								left: 0;
								bottom: 0;
								right: 0;
								width: 100vw;
								height: 100vh;
								overflow-x: hidden;
								overflow-y: auto;
								background-color: var(--white);
								padding-left: var(--containerPadding);
								padding-right: var(--containerPadding);
								padding-bottom: var(--sp25x);
								display: flex;
								flex-direction: column;
								z-index: 125;
							}

							.prod-types {
								.head-title i {
									left: var(--containerPadding);
								}
								.selected-prods {
									padding-left: var(--containerPadding);
									padding-right: var(--containerPadding);

									margin-bottom: var(--sp2-5x);

									.single-sel-prod-type {
										padding: var(--sp2x);
										background-color: var(--ultraLightGrey);

										&:not(:last-child) {
											margin-bottom: var(--spSize);
										}

										i {
											font-size: var(--sp1-25x);
										}
									}
								}

								.single-prod-type {
									padding-left: var(--sp7x);
									padding-right: var(--containerPadding);
								}
							}
						}
					}
				}
			}

			.btns {
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: var(--sp18x);
				display: flex;
				justify-content: space-between;
				padding-left: var(--containerPadding);
				padding-right: var(--containerPadding);
				background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
				z-index: 500;
				.btn {
					width: 100%;
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		max-width: unset;

		.btn-action {
			width: auto;
			margin-bottom: var(--sp2x);
			align-self: flex-end;

			.filter-btn {
				width: unset;
				padding: var(--sp1-5x) var(--sp3x);
				background-color: var(--ultraLightGrey);
				border: 1px solid var(--darkGrey);
				border-radius: 4px;

				span {
					display: flex;
					justify-content: space-between;
					align-items: center;

					i {
						font-size: var(--sp1-5x);
						color: var(--darkGrey);
						margin-left: var(--sp1-5x);
					}
				}
			}
		}

		.filters {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100vw;
			height: 100vh;
			overflow-x: hidden;
			overflow-y: auto;
			z-index: -1;
			display: none;
			pointer-events: none;
			background-color: var(--white);
			padding-left: var(--containerPadding);
			padding-right: var(--containerPadding);
			padding-bottom: var(--sp25x);
			margin-top: 0;

			&.active {
				pointer-events: all;
				display: block;
				z-index: 100;
			}

			.head-title {
				padding: var(--sp3x) 0;
				position: relative;
				text-align: center;
				margin-bottom: var(--sp3x);

				&:before {
					content: '';
					position: absolute;
					bottom: 0;
					left: calc(-1 * var(--containerPadding));
					width: calc(100% + (2 * var(--containerPadding)));
					height: 1px;
					background-color: var(--darkGrey);
				}
			}

			.single-filter {
				margin-bottom: var(--sp2x);

				& > .title {
					margin-bottom: var(--sp3x);

					&.tablet {
						display: flex;
						justify-content: space-between;
						padding-right: 0;
						align-items: center;

						&:before,
						&:after {
							display: none;
						}

						i {
							font-size: var(--sp2x);
						}
					}
				}

				.filter-in-tablet-mode {
					height: 0;
					position: fixed;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					width: 100vw;
					overflow-x: hidden;
					overflow-y: auto;
					z-index: -1;
					display: none;
					pointer-events: none;
					background-color: var(--white);
					padding-left: var(--containerPadding);
					padding-right: var(--containerPadding);
					padding-bottom: var(--sp25x);

					&.active {
						display: flex;
						flex-direction: column;
						pointer-events: all;
						height: 100vh;
						z-index: 105;
					}

					.head-title {
						padding: var(--sp3x) 0;
						justify-content: center;
						position: relative;
						text-align: center;
						margin-bottom: var(--sp3x);

						&:before {
							content: '';
							position: absolute;
							bottom: 0;
							left: calc(-1 * var(--containerPadding));
							width: calc(100% + (2 * var(--containerPadding)));
							height: 1px;
							background-color: var(--darkGrey);
						}

						i {
							position: absolute;
							top: 50%;
							left: 0;
							font-size: var(--sp2x);
							transform: translateY(-50%);
						}
					}

					.locations {
						max-height: unset;
					}

					.cat-block {
						max-height: unset;
						position: fixed;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						width: 100vw;
						height: 100vh;
						overflow-x: hidden;
						overflow-y: auto;
						background-color: var(--white);
						padding-left: var(--containerPadding);
						padding-right: var(--containerPadding);
						padding-bottom: var(--sp25x);
						display: flex;
						flex-direction: column;
						z-index: 115;

						.single-cat {
							&.active {
								position: fixed;
								top: 0;
								left: 0;
								bottom: 0;
								right: 0;
								width: 100vw;
								height: 100vh;
								overflow-x: hidden;
								overflow-y: auto;
								background-color: var(--white);
								padding-left: var(--containerPadding);
								padding-right: var(--containerPadding);
								padding-bottom: var(--sp25x);
								display: flex;
								flex-direction: column;
								z-index: 125;
							}

							.prod-types {
								.head-title i {
									left: var(--containerPadding);
								}
								.selected-prods {
									margin-bottom: var(--sp2-5x);

									.single-sel-prod-type {
										padding: var(--sp2x);
										background-color: var(--ultraLightGrey);

										&:not(:last-child) {
											margin-bottom: var(--spSize);
										}

										i {
											font-size: var(--sp1-25x);
										}
									}
								}

								.single-prod-type {
									padding-left: var(--containerPadding);
									padding-right: var(--containerPadding);
								}
							}
						}
					}
				}

				.prod-types {
					max-height: unset;
					position: fixed;
					bottom: 0;
					right: 0;
					width: 100vw;
					height: 100vh;
					overflow: auto;
					padding-left: var(--containerPadding);
					padding-right: var(--containerPadding);
					padding-bottom: var(--sp25x);
					&.active {
						z-index: 165;
					}
					.selected-prods {
						margin-bottom: var(--sp2-5x);

						.single-sel-prod-type {
							padding: var(--sp2x);
							background-color: var(--ultraLightGrey);

							&:not(:last-child) {
								margin-bottom: var(--spSize);
							}

							i {
								font-size: var(--sp1-25x);
							}
						}
					}
				}
			}

			.btns {
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: var(--sp18x);
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
				padding-left: var(--containerPadding);
				padding-right: var(--containerPadding);
				background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
				z-index: 500;

				.btn-action {
					width: calc(50% - var(--sp0-75x));

					.btn {
						width: 100%;
					}
				}
			}
		}
	}
`
export default FilterInExportStyle
