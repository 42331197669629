import styled from 'styled-components'

const QuoteStyle = styled.div`
	.quote {
		background-color: var(--ultraLightGrey);
		padding-top: var(--pageFromHeaderMargin);
		padding-bottom: var(--pageFromHeaderMargin);
		text-align: center;

		.main-cont {
			max-width: var(--sp98x);
			width: 100%;
			&>*{
				transform: translateY(30vh);
			}

			.marks {
				width: var(--sp8x);
				padding-top: var(--sp6x);
				align-self: flex-start;

				img {
					object-fit: contain;
				}
			}

			.text {
				margin-top: var(--sp4x);
				padding-bottom: var(--sp4x);
				border-bottom: 1px solid var(--lightGrey);
			}

			.author {
				margin: var(--sp4x) 0 var(--sp0-75x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		.quote {
			.main-cont {
				max-width: var(--sp83x);

				.marks {
					width: var(--sp6x);
					padding-top: var(--sp5x);
				}

				.text {
					margin-top: var(--sp3x);
					padding-bottom: var(--sp3x);
				}

				.author {
					margin: var(--sp3x) 0 var(--sp0-75x);
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		.quote {
			.main-cont {
				max-width: var(--sp64x);

				.marks {
					width: var(--sp6x);
					padding-top: var(--sp5x);
				}

				.text {
					margin-top: var(--sp3x);
					padding-bottom: var(--sp3x);
				}

				.author {
					margin: var(--sp3x) 0 var(--sp0-75x);
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.quote {
			.main-cont {
				max-width: unset;

				.marks {
					width: var(--sp5x);
					padding-top: var(--sp4x);
				}

				.text {
					margin-top: var(--sp2x);
					padding-bottom: var(--sp2x);
				}

				.author {
					margin: var(--sp2x) 0 var(--sp0-75x);
				}
			}
		}
	}
`
export default QuoteStyle
