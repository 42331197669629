import React, { forwardRef, useRef, useImperativeHandle } from 'react'

//* Components
import { Container, LottieAnimation, Text, Button } from 'components/common'

//* Helpers
import { config } from 'helpers'

//* Animations
import animationJSON from 'lottieAnimation/services/servicesAnime.json'
import animationJSON2 from 'lottieAnimation/services/servicesAnime2.json'

//* Styles
import ContactSectionStyle from './style'

const ContactSection = forwardRef((props, ref) => {
	const titleRef = useRef()
	const descriptionRef = useRef()
	const btnRef = useRef()

	useImperativeHandle(ref, () => [titleRef.current.childNodes, descriptionRef, btnRef], [])

	return (
		<ContactSectionStyle>
			<Container className={`contact-section full ${props.last ? 'last' : ''}`}>
				<Container className='contact-section-block last largePadding'>
					<div className='content'>
						<Text tag={'stringToLetters'} className={'title h1 MonBold black'} ref={titleRef} text={props.title} />
						<Text tag={'h6'} className={'description h6 MonRegular black'} ref={descriptionRef} text={props.text} />
						<Button
							link={props.url || config.routes.whatIsaProductSubmission.path}
							wrappClassName={'otherElementAnimation'}
							className={'btn btn-black capitalize'}
							ref={btnRef}
							text={props.linkText}
						/>
					</div>
					<LottieAnimation loop={true} isStopped={false} animationJSON={props.singlePage ? animationJSON2 : animationJSON} />
				</Container>
			</Container>
		</ContactSectionStyle>
	)
})

export default ContactSection
