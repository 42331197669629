import { forwardRef, useRef, useImperativeHandle } from 'react'
import { Container, Text, Image } from 'components/common'
import QuotePlansStyle from './style'

const QuotePlans = forwardRef((props, ref) => {

	const itemRef = useRef()

	useImperativeHandle(ref, () => [itemRef], [])

	return (
		<QuotePlansStyle>
			<Container className='quote full FlexBox alignCenter'>
				<div className='main-cont FlexBoxColumn alignMiddle' ref={itemRef}>
					<Image className='marks' src='/images/marks.svg' alt='marksSvg' />
					<Text tag={'h5'} className={'text h5 MonRegular'} text={props.quote} />
					<Text tag={'p'} className={'author pL MonRegular uppercase'} text={props.author} />
					<Text tag={'p'} className={'pM MonRegular ultraDarkGrey'} text={props.position} />
				</div>
			</Container>
		</QuotePlansStyle>
	)
})

export default QuotePlans
