import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import { Image, Text, Container } from 'components/common'
import InvestFieldsStyle from './style'

const WhyArmenia = forwardRef((props, ref) => {

    const titleRef = useRef()
    const itemRef = useRef()


    useImperativeHandle(ref, () => [titleRef.current.childNodes, itemRef.current.childNodes], [])

    return (
        <InvestFieldsStyle className='invest-fields'>
            <Container className='FlexBoxColumn'>
                <Text tag={'stringToLetters'} className={'title h1 MonBold'} text={props.title} ref={titleRef} />
                <div className='fields FlexBox spaceBetween' ref={itemRef}>
                    <div className='single-field FlexBoxColumn spaceBetween IT'>
                        <Image src='/images/home/IT.svg' alt={props.field1} />
                        <Text tag={'h3'} className={'text h5 uppercase MonBold white'} text={props.field1} />
                    </div>
                    <div className='single-field FlexBoxColumn spaceBetween energy'>
                        <Image src='/images/home/energy.svg' alt={props.field2} />
                        <Text tag={'h3'} className={'text h5 uppercase MonBold'} text={props.field2} />
                    </div>
                    <div className='single-field FlexBoxColumn spaceBetween jewellery'>
                        <Image src='/images/home/pharmacy.svg' alt={props.field3} />
                        <Text tag={'h3'} className={'text h5 uppercase MonBold white'} text={props.field3} />
                    </div>
                    <div className='single-field FlexBoxColumn spaceBetween agro'>
                        <Image src='/images/home/agro.svg' alt={props.field4} />
                        <Text tag={'h3'} className={'text h5 uppercase MonBold white'} text={props.field4} />
                    </div>
                    <div className='single-field FlexBoxColumn spaceBetween textile'>
                        <Image src='/images/home/textile.svg' alt={props.field5} />
                        <Text tag={'h3'} className={'text h5 uppercase MonBold white'} text={props.field5} />
                    </div>
                    <div className='single-field FlexBoxColumn spaceBetween pharmacy'>
                        <Image src='/images/home/jewellery.svg' alt={props.field6} />
                        <Text tag={'h3'} className={'text h5 uppercase MonBold'} text={props.field6} />
                    </div>
                </div>
            </Container>
        </InvestFieldsStyle>
    )
})

export default WhyArmenia
