import { forwardRef, useRef, useImperativeHandle } from 'react'

//* Components
import { Container, Text, Image, Button } from 'components/common'

//* Helpers
import { config } from 'helpers'

//* Styles
import BusinessSectionStyle from './style'

const BusinessSection = forwardRef((props, ref) => {
	const titleRef = useRef()
	const descriptionRef = useRef()
	const btnRef = useRef()

	useImperativeHandle(ref, () => [titleRef.current.childNodes, descriptionRef, btnRef], [])

	return (
		<BusinessSectionStyle>
			<Container className={`business-section last ${props.inCompany ? 'inCompany' : 'largePadding'}`}>
				<div className='content'>
					<Text tag={'stringToLetters'} className={'title h1 MonBold black'} text={props.title} ref={titleRef} />
					<Text tag={'h6'} className={'description h6 MonRegular black'} text={props.text} ref={descriptionRef} />
					<Button
						link={config.routes.investmentsForBusiness.path}
						yellowIcon={true}
						text={'learnMore'}
						wrappClassName={'otherElementAnimation'}
						ref={btnRef}
					/>
				</div>
				<Image src={props.photo} alt={props.title} />
			</Container>
		</BusinessSectionStyle>
	)
})

export default BusinessSection
