import styled from 'styled-components'

const SearchFieldStyle = styled.div.attrs({ className: 'search-field-cont' })`
    position: relative;
    margin-bottom: var(--sp3x);

	.search {
        i {
            position: absolute;
            top: 20px;
            right: 16px;
            font-size: var(--sp2x);
        }
    }

    @media screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
        width: 50%;
    }

    @media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
        width: 100%;
    }
`
export default SearchFieldStyle
