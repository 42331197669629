import React, { memo as Memo, useEffect, useState, useRef } from 'react'

import animation from 'lottieAnimation/message/anime.json'

//* Components
import { Text, LottieAnimation, AnimationWrapp } from 'components/common'

const SuccessMessage = Memo((props) => {
    //! State
    const [secObj, setSecObj] = useState()

    const titleRef = useRef()
    const descriptionRef = useRef()

    const arrayGen = (x) => {
        let arr = []
        x.forEach((el) => {
            el.childNodes.forEach((el2) => {
                arr.push(el2)
            })
        })
        return arr
    }

    useEffect(() => {
        if (titleRef?.current && descriptionRef?.current) {
            setSecObj([arrayGen(titleRef.current.childNodes[0].childNodes), descriptionRef.current])
        }
    }, [titleRef.current, descriptionRef.current])

    return (
        <AnimationWrapp state={secObj}>
            <div className='successIcon FlexBox alignCenter'>
                <LottieAnimation animationJSON={animation} loop={false} isStopped={false} />
            </div>
            <Text tag={'stringToLetters'} className={'title Title h3 MonBold black'} text={props.title} ref={titleRef} />

            <Text tag={'div'} className={'description subTitle h6 MonRegular black'} text={props.text} ref={descriptionRef} />
        </AnimationWrapp>
    )
})

export default SuccessMessage
