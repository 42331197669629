import styled from 'styled-components'

const SingleCatalogStyle = styled.div.attrs((props) => ({ className: props.className }))`
	display: flex;

	.single-catalog-block {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;
		border-radius: 4px;
		border: 1px solid var(--lightGrey);

		.imageCont {
			width: 100%;
			padding-top: var(--sp30x);
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			overflow: hidden;
		}

		.single-cat {
			position: absolute;
			top: var(--sp2x);
			right: var(--sp2x);
			z-index: 1;
		}

		.info {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: var(--sp2x);
			flex: 1;

			.title {
				flex: 1;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;

				&.inExport {
					margin-bottom: var(--spSize);
				}
			}

			.location {
				display: flex;
				justify-content: flex-start;
				align-self: flex-start;
				align-items: center;
				margin: var(--sp2-5x) 0 var(--sp2x);

				i {
					margin-right: var(--spSize);
				}
			}

			.int-countries {
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}

			.desc {
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}

			.subtitle {
				margin: var(--sp3x) 0 var(--sp1-25x);
				padding: var(--sp0-75x);
				background-color: var(--ultraLightGrey);
			}

			.amd-price {
				margin-top: var(--spSize);
			}

			.company {
				margin-top: var(--sp2x);
				align-self: flex-end;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		.single-catalog-block .info {
			.location {
				margin: var(--sp2x) 0;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		.single-catalog-block .info {
			.location {
				margin: var(--sp2x) 0;
			}
		}
	}
`
export default SingleCatalogStyle
