import styled from 'styled-components'

const SubmissionPlansStyle = styled.div`
	.submit-prod {
		background-color: var(--ultraLightGrey);
		padding-top: var(--pageFromHeaderMargin);
		padding-bottom: var(--pageFromHeaderMargin);

		.title {
			margin-bottom: var(--pageFromHeaderMargin);
		}

		.plans {
			justify-content: center;
			margin-bottom: var(--sp7x);

			.single-plan {
				border: 1px solid var(--darkGrey);
				border-radius: var(--sp0-5x);
				background-color: var(--white);
				padding: var(--sp10x) var(--sp2x);
				width: var(--sp64x);
				text-align: center;
				position: relative;
				transform: translateY(30vh);

				.best-price {
					position: absolute;
					top: var(--sp2x);
					right: var(--sp2x);
					padding: var(--spSize);
					background-color: var(--yellow);
					border-radius: var(--sp0-5x);
				}

				&:nth-child(1) {
					margin-right: var(--sp2x);
				}

				&:nth-child(2) {
					margin-left: var(--sp2x);
				}

				.desc {
					margin: var(--sp4x) 0 var(--sp7x);
					flex: 1;
				}

				.cost {
					margin-bottom: var(--sp4x);

					.price {
						margin-right: var(--sp1-5x);
					}
				}

				.per-month {
					.price {
						margin-right: var(--sp0-5x);
					}
				}
			}
		}

		.btn-cont {
			transform: translateY(30vh);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		.submit-prod {
			.plans {
				margin-bottom: var(--sp6x);

				.single-plan {
					padding: var(--sp9x) var(--sp2x);
					width: var(--sp54x);

					.desc {
						margin: var(--sp3x) 0 var(--sp6x);
					}

					.cost {
						margin-bottom: var(--sp3x);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		.submit-prod {
			.plans {
				margin-bottom: var(--sp5x);

				.single-plan {
					padding: var(--sp9x) var(--sp2x);
					width: var(--sp54x);

					.desc {
						margin: var(--sp3x) 0 var(--sp5x);
					}

					.cost {
						margin-bottom: var(--sp3x);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		.submit-prod {
			.plans {
				margin-bottom: var(--sp4x);
				width: 100%;

				.single-plan {
					padding: var(--sp9x) var(--sp2x);
					width: calc(50% - var(--sp1-5x));

					&:nth-child(1) {
						margin-right: var(--sp1-5x);
					}

					&:nth-child(2) {
						margin-left: var(--sp1-5x);
					}

					.desc {
						margin: var(--sp3x) 0 var(--sp4x);
					}

					.cost {
						margin-bottom: var(--sp3x);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.submit-prod {
			.plans {
				width: 100%;
				flex-direction: column;
				margin-bottom: var(--sp3-5x);

				.single-plan {
					padding: var(--sp8x) var(--sp2x);
					width: 100%;

					&:nth-child(1) {
						margin-right: 0;
						margin-bottom: var(--sp5x);
					}

					&:nth-child(2) {
						margin-left: 0;
					}

					.desc {
						margin: var(--sp2x) 0 var(--sp3-5x);
					}

					.cost {
						margin-bottom: var(--sp2x);

						.price {
							margin-right: var(--sp0-5x);
						}
					}
				}
			}
		}
	}
`
export default SubmissionPlansStyle
