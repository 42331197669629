import { forwardRef, useRef, useImperativeHandle, useMemo, useCallback } from 'react'

//* Components
import { Text } from 'components/common'

//* Helpers
import { formatNumber } from 'helpers'

const BusinessInArmeniaFormMid = forwardRef(({ result, currency, ...props }, ref) => {
	//! Refs
	const titleRef = useRef()
	const itemRef = useRef()

	//! Imperative Handle
	useImperativeHandle(ref, () => [titleRef, itemRef], [])

	//! Create Result
	const createResult = useCallback(
		(resultObj) => {
			return (
				resultObj &&
				Object.keys(resultObj).map(
					(name, k) =>
						name !== 'Text' && (
							<div key={k} className='priceItem animationItemRemoved FlexBoxContainer spaceBetween'>
								<div className='priceItemLeft'>
									<Text tag={'p'} className={'h6 MonRegular black'} text={name} />
								</div>

								<div className='priceItemRight'>
									<Text
										tag={'p'}
										className={'h6 MonRegular black'}
										text={`${formatNumber(resultObj[name], currency === 'USD' ? 'en' : currency === 'AMD' ? 'hy' : '')}`}
									/>
								</div>
							</div>
						)
				)
			)
		},
		[currency, result]
	)

	//! Result
	const resultHtml = useMemo(() => createResult(result.first), [result.first])

	//! Second Result
	const secondResultHtml = useMemo(() => createResult(result.second), [result.second])

	return (
		<div className='totlaSection FormRow FlexBoxContainer spaceBetween'>
			<div className='FormColumnsTitle'>
				<Text tag={'p'} className={'description pL MonMedium black'} text={'CalculationResult'} ref={titleRef} />
			</div>

			<div className='FormColumnsWrapp FlexBoxContainer spaceBetween'>
				<div className='priceWrapp' ref={itemRef}>
					{secondResultHtml && (
						<div className=' animationItemRemoved'>
							<Text tag={'p'} className={'pM MonRegular ultraDarkGrey'} text={`vatIncluded`} />
						</div>
					)}

					{resultHtml}

					<div className='totalPrice animationItemRemoved'>
						<Text tag={'p'} className={'pM MonRegular ultraDarkGrey'} text={result.first.Text} />
					</div>

					{secondResultHtml && (
						<div className=' animationItemRemoved'>
							<Text tag={'p'} className={'pM MonRegular ultraDarkGrey'} text={`withoutVat`} />
						</div>
					)}

					{secondResultHtml}

					{secondResultHtml && (
						<div className='totalPrice animationItemRemoved'>
							<Text tag={'p'} className={'pM MonRegular ultraDarkGrey'} text={result.second.Text} />
						</div>
					)}
				</div>
			</div>
		</div>
	)
})

export default BusinessInArmeniaFormMid
