import React, { useMemo, useRef, forwardRef, useImperativeHandle } from 'react'
import { CustomLink, Icon, Text, Image } from 'components/common'

//* HOCs
import { withLanguageContext } from 'context'
import withUIContext from 'context/consumerHOC/UIConsumer'

//style
import ButtonStyle from './style'

const Button = forwardRef((props, ref) => {
    const buttonRef = useRef()

    //! Button Type
    const btn = useMemo(() => {
        if (props.icon) {
            return (
                <ButtonStyle ref={ref} className={props.wrappClassName || ''}>
                    <button
                        ref={buttonRef}
                        title={props.title || ''}
                        type={props.type || 'button'}
                        aria-label={props.translate(props.text) ? props.translate(props.text) : props.ariaLabel}
                        className={`alignCenter alignMiddle MonMedium btn iconBtn ${props.className || ''}`}
                        onClick={props.onClick}
                    >
                        <Image objectFit="contain" src={props.icon} alt={props.translate(props.text)} />
                        <span className=' pL MonRegular'>{props.children || props.translate(props.text)}</span>
                    </button>
                </ButtonStyle>
            )
        } else if (props.link && !props.yellowIcon && !props.icon2) {
            return (
                <ButtonStyle className={`btn-cont ${props.wrappClassName || ''}`} ref={ref}>
                    <CustomLink url={props.link} className={`btn MonMedium ${props.className || ''}`}>
                        {props.children || props.translate(props.text)}
                    </CustomLink>
                </ButtonStyle>
            )
        } else if (props.yellowIcon) {
            return (
                <ButtonStyle ref={ref} className={props.wrappClassName || ''}>
                    <CustomLink url={props.link} className={`btn yellowIcon MonRegular ${props.className || ''}`}>
                        <Text tag={'p'} className={'text h5 MonRegular ultraDarkGrey uppercase'} text={props.text} />
                        <Icon className='icon-circle-arrow-left'>
                            <span className='path2 pathHover'></span>
                            <span className='path1'></span>
                            <span className='path2'></span>
                        </Icon>
                    </CustomLink>
                </ButtonStyle>
            )
        } else if (props.icon2) {
            return (
                <ButtonStyle ref={ref} className={props.wrappClassName || ''}>
                    <CustomLink url={props.link} className={`alignCenter alignMiddle MonMedium btn iconBtn2 ${props.className || ''}`}>
                        <Icon className={props.icon2} />
                        <span className=' pL MonRegular'>{props.children || props.translate(props.text)}</span>
                    </CustomLink>
                </ButtonStyle>
            )
        } else {
            return (
                <ButtonStyle className={`btn-action ${props.wrappClassName || ''}`}>
                    <button
                        type={props.type || 'button'}
                        title={props.title || ''}
                        className={`btn MonMedium ${props.className || ''}`}
                        ref={ref}
                        aria-label={props.translate(props.text) ? props.translate(props.text) : props.ariaLabel}
                        onClick={props.onClick}
                    >
                        <span>{props.children || props.translate(props.text)}</span>
                    </button>
                </ButtonStyle>
            )
        }
    }, [props])

    return btn
})

export default withUIContext(withLanguageContext(Button, ['translate', 'selectedLang']))
