import { useState, forwardRef, useRef, useCallback, useImperativeHandle, useEffect } from 'react'

//* HOC's
import { withUIContext, withLanguageContext } from 'context'

//* Components
import { Text, Icon, Button } from 'components/common'
import { useRouter } from 'next/router'
import CategoryFilterSection from './CategoryFilter'
import LocationFilter from './LocationFilter'
import PriceFilter from './PriceFilter'

//* Styles
import 'antd/dist/antd.css'
import FilterInExportStyle from './style'

const FilterInExport = forwardRef(
    ({ queryParamKeys, onCategoryChange, categories, types, countries, unitPrices, winWidth, translate, ...props }, ref) => {

        //! Router
        const router = useRouter()

        //! States
        const [activeClassFilter, setActiveClassFilter] = useState('')
        const [addClassName, setClassName] = useState('')

        //! Refs
        const titleRef = useRef()
        const itemsRef = useRef()
        const titleRef2 = useRef()
        const priceContRef = useRef()

        //! Reset Filters
        const resetFilter = useCallback(() => {
            priceContRef.current.setUnitPricesState([unitPrices[0], unitPrices[1]])
            router.push('/export-from-armenia/')
        }, [router, priceContRef, unitPrices])

        const filterBtnClickHandler = (open, reset = false) => {
            if (open) {
                setActiveClassFilter('active')
                document.body.classList.add('hidden')
            } else {
                setActiveClassFilter('')
                document.body.classList.remove('hidden')
                reset && resetFilter()
            }
        }

        //! Imperative Handle
        useImperativeHandle(ref, () => [titleRef, titleRef2], [router])

        useEffect(() => {
            if (categories && types && countries && unitPrices && itemsRef.current) {
                winWidth >= 1024 &&
                    setTimeout(() => {
                        setClassName('up')
                    }, 10)
            }
        }, [categories, types, countries, unitPrices, itemsRef.current, winWidth])

        return (
            <FilterInExportStyle >
                {winWidth > 1023 ? (
                    <Text tag={'p'} className={'pM black MonRegular darkGrey capitalize'} text={'filterName'} ref={titleRef} />
                ) : (
                    <Button className='filter-btn' onClick={() => filterBtnClickHandler(true)} ariaLabel={'filterName'}>
                        <Text tag={'p'} className={'pM black MonMedium capitalize'} text={'filterName'} />
                        <Icon className='icon-filter' />
                    </Button>
                )}

                <div className={`filters ${addClassName} ${activeClassFilter}`} ref={itemsRef}>
                    {winWidth < 1024 && <Text tag={'p'} className={'head-title pM MonRegular uppercase darkGrey'} text={'filterName'} />}

                    {/* Category filter */}
                    <CategoryFilterSection
                        categories={categories}
                        queryParamKeys={queryParamKeys}
                        onCategoryChange={onCategoryChange}
                        types={types}
                    />

                    {/* Locations Filter */}
                    <LocationFilter queryParamKeys={queryParamKeys} countries={countries} onCategoryChange={onCategoryChange} />

                    {/* Price Filter */}
                    <PriceFilter ref={priceContRef} queryParamKeys={queryParamKeys} unitPrices={unitPrices} onCategoryChange={onCategoryChange} />

                    {winWidth < 1024 && (
                        <div className='btns'>
                            <Button onClick={() => filterBtnClickHandler(false, true)} className='btn btn-white capitalize' text={'reset'} />
                            <Button onClick={() => filterBtnClickHandler(false)} className='btn btn-black capitalize' text={'Save'} />
                        </div>
                    )}
                </div>
                {winWidth > 1023 && (
                    <div onClick={resetFilter} className={'clear-filters underline capitalize pL black'} ref={titleRef2}>
                        <Text tag={'span'} className={'cursorPointer pL black MonMedium'} text={'resetFilters'} />
                    </div>
                )}
            </FilterInExportStyle>
        )
    }
)

export default withLanguageContext(withUIContext(FilterInExport, ['winWidth']), ['translate'])
