import React, { useMemo, forwardRef, useRef, useImperativeHandle } from 'react'
import { Container, Button, Text } from 'components/common'
import { SingleNews } from 'components/pages'
import NewsInHomeStyle from './style'

const NewsInHome = forwardRef((props, ref) => {
    const storeNews = useMemo(() => {
        return props.news.map((i, k) => {
            return (
                <SingleNews
                    photo={i.feature_image}
                    categoryName={i?.category?.name}
                    categorySlug={i?.category?.slug}
                    newsName={i.title}
                    state={i?.location?.name}
                    date={i.date}
                    url={`/news/${i.slug}`}
                    key={k}
                    bgColor={i?.category?.color}
                />
            )
        })
    }, [props.news])

    const titleRef = useRef()
    const descriptionRef = useRef()
    const btnRef = useRef()
    const itemRef = useRef()

    useImperativeHandle(ref, () => [titleRef.current.childNodes, descriptionRef, btnRef, itemRef.current.childNodes], [])

    return (
        <NewsInHomeStyle>
            <Container className='read-more FlexBox'>
                <div className='read-more-cont FlexBoxColumn'>
                    <Text tag={'stringToLetters'} className={'title h1 MonBold black'} text={props.title} ref={titleRef} />
                    <Text tag={'h3'} className={'description h6 MonRegular'} text={props.description} ref={descriptionRef} />
                    <Button link={'/news'} yellowIcon={true} text={'moreNews'} wrappClassName={'otherElementAnimation'} ref={btnRef} />
                </div>
                <div className='news FlexBoxColumn' ref={itemRef}>
                    {storeNews}
                </div>
            </Container>
        </NewsInHomeStyle>
    )
})

export default NewsInHome
