import styled from 'styled-components'

const AnimationWrappStyle = styled.section.attrs({})`
	.word {
		overflow: hidden;
		display: inline-block;
		margin-right: calc(var(--h2) / 2.5);
		white-space: nowrap;
		line-height: 1.2;
		.letter {
			display: inline-block;
			transform: translateY(11vh);
		}
		&:last-child {
			margin-right: 0;
		}
	}

	.description,
	.otherElementAnimation {
		transform: translateY(30vh);
	}
`

export default AnimationWrappStyle
