import styled from 'styled-components'

const AccountActivatedPopupStyle = styled.div.attrs((props) => ({ className: props.className }))`
	--titleMarginBottom:var(--sp4x);
	--btnMarginTop:var(--sp6x);
	--imageSize:var(--sp30x);

	.imageCont{
		width:var(--imageSize);
		height:var(--imageSize);
		border-radius:var(--borderLg);
		margin-bottom:var(--btnMarginTop)
	}

	.title{
		margin-bottom:var(--titleMarginBottom)
	}
	.btn{
		margin-top:var(--btnMarginTop)
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {

		--btnMarginTop:var(--sp5x);
		--imageSize:var(--sp25x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
			--titleMarginBottom:var(--sp3-5x);
			--btnMarginTop:var(--sp4x);
			--imageSize:var(--sp25x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
			--titleMarginBottom:var(--sp3x);
			--btnMarginTop:var(--sp4x);
			--imageSize:var(--sp22x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		--titleMarginBottom:var(--sp2-5x);
			--btnMarginTop:var(--sp4x);
			--imageSize:var(--sp22x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
			--titleMarginBottom:var(--sp2x);
			--btnMarginTop:var(--sp3x);
			--imageSize:var(--sp20x);
	}
`
export default AccountActivatedPopupStyle
