import { memo as Memo, useCallback } from 'react'

//* HOC's
import { withUIContext, withLanguageContext } from 'context'

//* Components
import { Text, Icon, CustomLink } from 'components/common'
import { Tooltip } from 'antd'

const ProductRow = Memo(({ products, catId, ...props }) => {
	//! Product Name
	const productName = useCallback(
		(status, id, name) => {
			return status === 'pending' ? (
				<CustomLink url={`/products/${catId}/${id}`}>
					<Text tag={'p'} className={'pM MonMedium black underline'} text={name} />
				</CustomLink>
			) : (
				<Text tag={'p'} className={'pM MonMedium black'} text={name} />
			)
		},
		[catId]
	)

	//! Product Status
	const productStatus = useCallback((status, id) => {
		return status === 'pending' ? (
			<CustomLink url={`/products/${catId}/${id}`}>
				<Text tag={'p'} className={'pM MonMedium black'} text={`${status}Status`} />
			</CustomLink>
		) : (
			<Text tag={'p'} className={'pM MonMedium black'} text={`${status}Status`} />
		)
	}, [])

	//! Tooltip
	const tooltip = useCallback((status) => {
		return (
			<Tooltip
				title={
					<div
						dangerouslySetInnerHTML={{
							__html: props.translate(status),
						}}
					/>
				}
			>
				<div className='HelperDesc'>
					<Icon className='icon-quastion'>
						<span className='path1'></span>
						<span className='path2'></span>
					</Icon>
				</div>
			</Tooltip>
		)
	}, [])

	return props.winWidth >= 768 ? (
		<div className='SubmittedProductsBottomItem'>
			{products.length > 0 && (
				<div className='SubmittedProductsBottomTitles tableTitlesWrap FlexBoxContainer spaceBetween'>
					<div className='tableItemTitles'>
						<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'ProducName'} />
					</div>
					<div className='tableItemTitles'>
						<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'prodType'} />
					</div>
					<div className='tableItemTitles'>
						<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'Status'} />
					</div>
				</div>
			)}
			{products.map((item, key) => {
				return (
					<div key={key} className='SubmittedProductsBottom FlexBoxContainer spaceBetween'>
						<div className='tableItem'>
							<div className='FlexBoxContainer'>{productName(item.status, item.id, item.name)}</div>
						</div>

						<div className='tableItem'>
							<Text tag={'p'} className={'pM MonMedium black '} text={item.category} />
						</div>

						<div className='tableItem'>
							<div className='FlexBoxContainer'>
								{tooltip(item.status)}

								{productStatus(item.status, item.id)}
							</div>
						</div>
					</div>
				)
			})}
		</div>
	) : (
		<div className='SubmittedProductsBottomItem'>
			{products.map((item, key) => {
				return (
					<div key={key} className='SubmittedProductsBottom FlexBoxContainer spaceBetween'>
						<div className='tableItem'>
							<div className='tableItemTitles'>
								<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'ProducName'} />
							</div>
							{productName(item.status, item.id, item.name)}
						</div>
						<div className='tableItem'>
							<div className='tableItemTitles'>
								<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'prodType'} />
							</div>

							<Text tag={'p'} className={'pM MonMedium black'} text={item.category} />
						</div>
						<div className='tableItem'>
							<div className='tableItemTitles'>
								<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'Status'} />
							</div>
							<div className='FlexBoxContainer'>
								{tooltip(item.status)}

								{productStatus(item.status, item.id)}
							</div>
						</div>
					</div>
				)
			})}
		</div>
	)
})

export default withUIContext(withLanguageContext(ProductRow, ['translate']), ['screenSizes', 'winWidth'])
