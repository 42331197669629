import { memo as Memo } from 'react'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'

//* Components
import { Text, Button } from 'components/common'

//* Style
import WarningPopupStyle from './style'

const WarningPopup = Memo(({ closePopup, submit, ...props }) => {
	return (
		<WarningPopupStyle>
			<div className='title textAlignCenter'>
				<Text tag={'p'} className={'h4 MonRegular black'} text={'ExportPotentialFullCalculator'} />
			</div>
			<div className='description'>
				<Text
					tag={'div'}
					className={'h6 MonRegular black'}
					children={<div dangerouslySetInnerHTML={{ __html: props.translate('ExportPotentialFullCalculatorDesc') }} />}
				/>
			</div>

			<div className='priceWrapp'>
				<div className='priceItem FlexBoxContainer spaceBetween'>
					<Text tag={'p'} className={'h6 MonRegular black'} text={'ExportPotentialCalculator'} />
					<Text tag={'p'} className={'h6 MonRegular black'} text={'25,000 AMD'} />
				</div>
				<div className='priceItem FlexBoxContainer spaceBetween'>
					<Text tag={'p'} className={'h6 MonRegular black'} text={'Total'} />
					<Text tag={'p'} className={'h6 MonBold black'} text={'25,000 AMD'} />
				</div>
			</div>

			<Button onClick={props.onSubmit} className={`btn btn-black btn-max-width`} text={'ExportPotentialCalculatorBtn'} />
		</WarningPopupStyle>
	)
})

export default withLanguageContext(withUIContext(WarningPopup, ['closePopup']), ['translate', 'selectedLang'])
