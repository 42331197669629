import React, { memo as Memo, useEffect, useState } from 'react'

//* HOCs
import { withUIContext } from 'context'
import { useRouter } from 'next/router'

//* Config
import { config } from 'helpers'

//*Component
import Logo from './HeaderComponent/Logo'
import SignIn from './HeaderComponent/SignIn'
import Burger from './HeaderComponent/Burger'
import MenuComponent from './HeaderComponent/MenuComponent'
import { Button, LanguageSwitcher } from 'components/common'

import Header from './style'

const AppHeader = Memo(({ ...props }) => {
	//! States
	const [checked, setChecked] = useState(false)
	const [opened, setOpened] = useState(false)

	const router = useRouter()

	useEffect(() => {
		if (checked && !opened) {
			setOpened(true)
			document.body.classList.add('modal-open')
		} else if (!checked && opened) {
			setOpened(false)
			document.body.classList.remove('modal-open')
		}
	}, [checked, opened, router, router.asPath, props.winWidth])

	useEffect(() => {
		setChecked(false)
	}, [router])

	const handleClick = () => {
		setChecked(!checked)
	}

	return (
		<>
			<Header className={props.preloader ? 'active' : ''}>
				<div className='headerTop FlexBox alignMiddle spaceBetween'>
					<div className='FlexBox alignMiddle'>
						<Logo className={'headerLogo'} />

						{props.winWidth >= 768 && router.asPath.split("/")[1] != "thank-you-message" ? <LanguageSwitcher color={'black'} /> : ''}
					</div>

					<div className='FlexBox alignMiddle'>
						<SignIn />
						{(props.preloader && props.winWidth) >= 1440 ? (
							<div className='btnWrap FlexBox alignMiddle'>
								<Button className={'btn-white'} text={'submitExportProduct'} link={config.routes.whatIsaProductSubmission.path} />

								<Button className={'btn-black'} text={'SubmitInvestmentProject'} link={config.routes.investmentsForBusiness.path} />
							</div>
						) : (
							<Burger checked={opened} onClick={handleClick} />
						)}
					</div>
				</div>

				<MenuComponent opened={opened} />
			</Header>
		</>
	)
})

export default withUIContext(AppHeader, ['screenSizes', 'winWidth'])
