import styled from 'styled-components'

const MapPopInfoStyle = styled.div.attrs({})`
	border-radius: var(--borderSm);
	overflow: hidden;
	background-color: var(--white);
	box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
	width: var(--sp50x);
	min-height: var(--sp12x);
	.imageCont {
		width: var(--sp12x);
	}
	.infoWrap {
		width: calc(100% - var(--sp12x));
		padding: var(--spSize) var(--sp2x);
		> div {
			margin-bottom: var(--sp1-5x);
			&.title {
				max-width: 90%;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSizeMin}) {
		max-width: var(--sp45x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		max-width: var(--sp31x);
		.imageCont {
			width: 100%;
			height: var(--sp12x);
		}
		.infoWrap {
			width: 100%;
		}
	}
`

export default MapPopInfoStyle
