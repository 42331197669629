import styled from 'styled-components'

const CalculatorStyle = styled.div.attrs({})`
	--titleMargBott: var(--sp6x);
	--titleStrongMargBott: var(--sp3x);
	--CalculatorTopInfoLeftMaxWidth: 49%;
	--CalculatorTopInfoRightMaxWidth: 42%;

	--calculatorResulItemtWidth: calc(17% - var(--sp3x));
	--calculatorResulItemtWidth2: calc(13% - var(--sp3x));
	--calculatorResulItemtOtherWidth: calc(23% - var(--sp3x));
	--calculatorResulItemtOtherWidth2: calc(17% - var(--sp3x));
	--calculatorResulItemPadd: var(--sp4x);
	--calculatorFormResultWidth: 1180px;
	--calculatorFormResultWidth2: 2000px;

	--flagSizeWidth: var(--sp4x);
	--flagSizeHeight: var(--sp3x);

	.CalculatorTopInfoWrap {
		.title {
			margin-bottom: var(--titleMargBott);
			max-width: var(--CalculatorTopInfoLeftMaxWidth);
		}

		.CalculatorTopInfo {
			.CalculatorTopInfoLeft {
				max-width: var(--CalculatorTopInfoLeftMaxWidth);
				> div {
					> p {
						font-size: var(--h5);
						line-height: var(--lineHeight);
						transform: translateY(30vh);
					}
				}
			}
			.CalculatorTopInfoRight {
				max-width: var(--CalculatorTopInfoRightMaxWidth);
				strong {
					display: block;
					margin-bottom: var(--titleStrongMargBott);
					font-size: var(--h4);
					font-family: MonBold, MonBoldRu;
					line-height: var(--lineHeightS);
				}
				> div {
					> p {
						transform: translateY(30vh);
					}
				}
			}
		}
	}

	.CalculatorWrap {
		margin-bottom: 0;

		.calculatorFormResult,
		.calculatorFormResultBottom >*,
		.TopCountries >*  {
			transform: translateY(30vh);
		}
		.calculatorFormResultSection2 {
			min-width: var(--calculatorFormResultWidth2);
		}
		.calculatorFormResultTitleWrap2 {
			.calculatorFormResultTitle2 {
				padding: 0 var(--sp2x) var(--calculatorResulItemPadd);
				width: var(--calculatorResulItemtOtherWidth2);
				&:nth-child(2),
				:nth-child(3),
				:nth-child(4) {
					width: var(--calculatorResulItemtWidth2);
				}
			}
		}

		.calculatorFormResultItemWrap2 {
			border-bottom: 1px solid var(--extraLightGrey);
			.calculatorFormResultItem2 {
				padding: var(--calculatorResulItemPadd) var(--sp2x);
				width: var(--calculatorResulItemtOtherWidth2);
				&:nth-child(2),
				:nth-child(3),
				:nth-child(4) {
					width: var(--calculatorResulItemtWidth2);
				}
				&:first-child {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 4; /* number of lines to show */
					-webkit-box-orient: vertical;
				}
			}
			&:first-child {
				border-top: 1px solid var(--extraLightGrey);
			}
		}

		.calculatorFormResult {
			overflow: auto;
			&::-webkit-scrollbar {
				height: 6px;
			}

			&::-webkit-scrollbar-track {
				background: var(--lightGrey);
				border-radius: var(--spSize);
			}

			&::-webkit-scrollbar-thumb {
				background: var(--ultraDarkGrey);
				border-radius: var(--spSize);
			}

			&::-webkit-scrollbar-thumb:hover {
				background: var(--black);
			}

			.calculatorFormResultSection {
				min-width: var(--calculatorFormResultWidth);
			}
			.calculatorFormResultTitle {
				padding: 0 var(--sp2x) var(--calculatorResulItemPadd);
				width: var(--calculatorResulItemtWidth);
				&:first-child,
				&:last-child {
					width: var(--calculatorResulItemtOtherWidth);
				}
			}

			.calculatorFormResultItemWrap {
				border-bottom: 1px solid var(--extraLightGrey);
				.calculatorFormResultItem {
					padding: var(--calculatorResulItemPadd) var(--sp2x);
					width: var(--calculatorResulItemtWidth);
					&:first-child,
					&:last-child {
						width: var(--calculatorResulItemtOtherWidth);
					}
				}

				.imageCont {
					width: var(--flagSizeWidth);
					height: var(--flagSizeHeight);
					margin-right: var(--sp2x);
					box-shadow: 0px 0px 5px 0px rgb(136 136 136 / 42%);
				}
				&:first-child {
					border-top: 1px solid var(--extraLightGrey);
				}
			}
		}

		.calculatorFormResultBottom {
			margin-bottom: calc(var(--titleStrongMargBott) * 2);
			> div {
				padding: var(--calculatorResulItemPadd) 0;
			}
			a {
				display: inline-block;
			}
		}

		.TopCountries {
			text-align: center;
			.comp-title {
				margin-bottom: calc(var(--titleStrongMargBott) * 2);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		--titleMargBott: var(--sp5x);
		--titleStrongMargBott: var(--sp2-5x);
		--calculatorResulItemPadd: var(--sp3x);

		--flagSizeWidth: 28px;
		--flagSizeHeight: var(--sp2x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		--titleMargBott: var(--sp5x);
		--titleStrongMargBott: var(--sp2-5x);
		--calculatorResulItemPadd: var(--sp3x);

		--flagSizeWidth: 28px;
		--flagSizeHeight: var(--sp2x);

		.CalculatorWrap {
			.calculatorFormResult {
				padding-bottom: var(--calculatorResulItemPadd);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		--titleMargBott: var(--sp3x);
		--titleStrongMargBott: var(--sp2-5x);
		--calculatorResulItemPadd: var(--sp3x);

		--flagSizeWidth: 28px;
		--flagSizeHeight: var(--sp2x);

		--calculatorFormResultWidth: 1110px;

		.CalculatorWrap {
			.calculatorFormResult {
				padding-bottom: var(--calculatorResulItemPadd);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--titleMargBott: var(--sp2-5x);
		--titleStrongMargBott: var(--sp2-5x);
		--CalculatorTopInfoLeftMaxWidth: 100%;
		--CalculatorTopInfoRightMaxWidth: 100%;

		--calculatorResulItemtWidth: calc(17% - var(--sp3x));
		--calculatorResulItemtOtherWidth: calc(23% - var(--sp3x));

		--calculatorResulItemPadd: var(--sp3x);

		--flagSizeWidth: 28px;
		--flagSizeHeight: var(--sp2x);
		--calculatorFormResultWidth: 1050px;

		.CalculatorTopInfoWrap {
			.CalculatorTopInfo {
				.CalculatorTopInfoLeft {
					margin-bottom: var(--sp5x);
				}
			}
		}

		.CalculatorWrap {
			.calculatorFormResult {
				padding-bottom: var(--calculatorResulItemPadd);
			}
		}
	}
`
export default CalculatorStyle
