import React, { memo as Memo } from 'react';
import { CustomLink } from 'components/common'

const Default = Memo(props => {
    return (
        <CustomLink url={props.path + "dsfdf"} content={props.text}/>
    )
})

export default Default
