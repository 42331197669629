import { memo as Memo, useState, useMemo, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'

//* HOC's
import { withUIContext, withLanguageContext } from 'context'

//* Components
import { SingleCatalog, Icon, SortBy, SearchField } from 'components/common'
import Pagination from 'react-js-pagination'
import { useRouter } from 'next/router'

//* Helpers
import { config, getParamsByKey } from 'helpers'

//* Styles
import ExportCatalogStyle from './style'

const ExportCatalog = forwardRef(({ onPageChange, total, itemsCountPerPage, queryParamKeys, currency, winWidth, ...props }, ref) => {
    //! Refs
    const catalogsRef = useRef()

    //! Router
    const router = useRouter()

    //! Active Page
    const activePage = useMemo(() => router.query?.[queryParamKeys[2]] || 1, [router.query])

    //! Search Active Param
    const searchActiveParam = useMemo(() => {
        const catParam = queryParamKeys[6] //? "search"
        const activeItems = getParamsByKey(router, catParam)

        return activeItems?.[0]
    }, [router, queryParamKeys])

    //! Search
    const onSearch = useCallback(
        (text) => {

            const paginationParam = queryParamKeys[6] //? "search"

            if (searchActiveParam != text) {
                onPageChange(paginationParam, text, false)
            }

        },
        [queryParamKeys]
    )

    //! Product List Itmes
    const storeCatalogs = useMemo(() => {
        return props.catalogsArr?.map((i, k) => {
            return (
                <SingleCatalog
                    key={k}
                    photo={i.feature_image}
                    export={true}
                    categorySlug={'/' + config.routes.exportFromArmenia.slug + '/' + i.category?.slug}
                    categoryName={i.category.name}
                    title={i.name}
                    url={'/' + config.routes.exportFromArmenia.slug + '/' + i.slug}
                    description={i?.company?.short_description}
                    priceInDollar={i.unit_price.USD}
                    priceInAMD={i.unit_price.AMD}
                    companyName={`${i?.company?.name} ${i?.company?.type}`}
                    companyURL={config.routes.productsCompanySingle.path.replace(':slug', i?.company?.slug)}
                    bgColor={i?.category?.color}
                    interestedCountries={i?.company?.interestCountries.map(
                        (country, key) => `${country.name}${key < i.company?.interestCountries.length - 1 ? ', ' : ''}`
                    )}
                />
            )
        })
    }, [props.catalogsArr])

    //! Pagination Change
    const onPaginationChange = useCallback(
        (p) => {
            const paginationParam = queryParamKeys[2] //? "page"
            onPageChange(paginationParam, p, false)
        },
        [queryParamKeys, onPageChange]
    )

    //! Imperative Handle
    useImperativeHandle(ref, () => catalogsRef?.current && [catalogsRef?.current.childNodes], [props.catalogsArr, router, winWidth])

    return (
        <ExportCatalogStyle>
            <div className="list-head">
                <SearchField onSearch={onSearch} initialValue={searchActiveParam} />
                {props.catalogsArr?.length ? <SortBy sortList={props.sorts} onCategoryChange={onPageChange} sortByParamKey={queryParamKeys[3]} /> : ''}
            </div>

            {
                props.catalogsArr?.length ? (
                    <>
                        <div className='catalogs-block' ref={catalogsRef}>
                            {storeCatalogs}
                        </div>
                        {total > itemsCountPerPage && (
                            <Pagination
                                activePage={Number(activePage)}
                                totalItemsCount={total}
                                pageRangeDisplayed={4}
                                itemsCountPerPage={itemsCountPerPage}
                                linkClassLast={null}
                                linkClassFirst={null}
                                nextPageText={<Icon className='icon-arrow-right' />}
                                prevPageText={<Icon className='icon-arrow-left' />}
                                linkClassPrev={'prev'}
                                linkClassNext={'next'}
                                onChange={onPaginationChange}
                            />
                        )}
                    </>
                ) : props.children
            }

        </ExportCatalogStyle>
    )
})

export default withLanguageContext(withUIContext(ExportCatalog, ['winWidth']), ['translate'])
