export const objectToFormData = (data, formData, parentKey) => {
	const newFormData = formData || new FormData()
	if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
		Object.keys(data).forEach((key) => {
			objectToFormData(data[key], newFormData, parentKey ? `${parentKey}[${key}]` : key)
		})
	} else {
		const value = data == null ? '' : data

		newFormData.append(parentKey, value)
	}

	return newFormData
}
