import React, { memo as Memo, useMemo, useRef, useEffect, useState } from 'react'
import Modal from '@material-ui/core/Modal'
import Fade from '@material-ui/core/Fade'

//* HOCs
import { withUIContext } from 'context'

import { useRouter } from 'next/router'

//* Components
import { Icon, Login, Register, ForgotPassword } from 'components/common'

//* Style
import PopupStyle from './style'
import { Subscribe } from 'components/common'

const Popup = Memo(({ closePopup, popupIsOpen, popupComponent, ...props }) => {
    const router = useRouter()

    const oldScroll = useRef()

    const [martgTop, setMartgTop] = useState('40px')

    const components = useMemo(
        () => ({
            login: Login,
            register: Register,
            'forgot-password': ForgotPassword,
            'subscribe': Subscribe
        }),
        []
    )

    const Child = useMemo(() => typeof popupComponent === 'string' && components[popupComponent] && components[popupComponent], [popupComponent])

    const contRef = useRef()

    useEffect(() => {
        if (popupIsOpen) {
            let height = props.winHeight / 2 - contRef?.current?.clientHeight / 2
            if (height > 25) {
                setMartgTop(height)
            }
        }
    }, [popupIsOpen, closePopup, props.screenSizes, router])

    useEffect(() => {
        if (popupIsOpen) {
            oldScroll.current = window.scrollY
            document.querySelector('html').classList.add('hidden')
        }
        else {
            document.querySelector('html').classList.remove('hidden')
            oldScroll.current && window.scrollTo({ top: oldScroll.current })
            oldScroll.current = null
        }
    }, [popupIsOpen])

    return (
        <Modal open={popupIsOpen} onClose={closePopup} closeAfterTransition>
            <Fade in={popupIsOpen}>
                <PopupStyle>
                    <div className='popup-container FlexBox alignCenter'>
                        <div className='popup-content-container'>
                            <div className='popup-content' style={{ paddingTop: martgTop }}>
                                <div className='popup-background' onClick={closePopup}></div>
                                <div className='popup-content-wrapp  relativeWrapp' ref={contRef}>
                                    <div className='close FlexBox alignMiddle alignCenter cursorPointer' onClick={closePopup}>
                                        <Icon className='icon-close black' />
                                    </div>
                                    {typeof popupComponent === 'string' ? <Child /> : popupComponent}
                                </div>
                            </div>
                        </div>
                    </div>
                </PopupStyle>
            </Fade>
        </Modal>
    )
})

export default withUIContext(Popup, ['closePopup', 'popupIsOpen', 'popupComponent', 'winHeight', 'screenSizes'])
