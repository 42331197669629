import styled from 'styled-components'

const TopSectionStyle = styled.div`
    .top-section {
        display:flex;
        align-items:flex-start;
        justify-content: space-between;
        
        .title {
            max-width:var(--sp81x);    
        }

        .description {
            max-width:var(--sp115x);
            width:100%;
            margin-left:var(--sp4x);
        }
    }

    @media screen and (max-width:${props => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width:${props => props.theme.mediaQuery.DesktopSizeM}) {
        .top-section {
            .title {
                max-width:var(--sp68x);    
            }

            .description {
                max-width:var(--sp98x);
            }
        }
    }

    @media screen and (max-width:${props => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width:${props => props.theme.mediaQuery.DesktopSizeS}) {
        .top-section {
            .title {
                max-width:var(--sp56x);    
            }

            .description {
                max-width:var(--sp83x);
                margin-left:var(--sp3-5x);
            }
        }
    }

    @media screen and (max-width:${props => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width:${props => props.theme.mediaQuery.TabletSize}) {
        .top-section {
            flex-direction:column;

            .title {
                max-width:unset;    
            }

            .description { 
                max-width:unset;
                margin-left:0;
                margin-top:var(--sp2-5x);
            }
        }
    }

    @media screen and (max-width:${props => props.theme.mediaQuery.TabletSizeMin}){
        .top-section {
            flex-direction:column;

            .title {
                max-width:unset;    
            }
            
            .description {
                max-width:unset;
                margin-left:0;
                margin-top:var(--sp2x);
            }
        }
    }
`
export default TopSectionStyle