import { memo as Memo, useState, useCallback, useEffect } from 'react'
import { gsap } from 'gsap'

//* HOC's
import { withLanguageContext, withDataContext } from 'context'

//* Components
import { Text, FormItem, FormContainer, Icon, CustomLink, SuccessMessage, FormInput } from 'components/common'
import { Button, Checkbox, Select, Radio } from 'antd'

//* Styles
import LoginRegisterStyle from 'styles/pages/LoginRegister'
import FormStyle from 'styles/pages/FormStyle'

const BecomePartnerPopupForm = Memo(({ becomePartner, ...props }) => {
    //! States
    const [success, setSuccess] = useState(false)
    const [successLoader, setSuccessLoader] = useState(false)
    const [passBtnIsActive, setPassBtnIsActive] = useState(false)

    //! On Form Submit
    const onFinish = useCallback((values) => {
        setSuccessLoader(true)
        return becomePartner(values).then(() => {
            setSuccessLoader(false)
            setSuccess(true)
        })
    }, [])

    //! Terms Toggle Function
    const acceptTermsChange = useCallback((e) => {
        setPassBtnIsActive(e.target.checked)
    }, [])

    useEffect(() => {
        gsap.to('.animationItem', {
            y: 0,
            stagger: 0.05,
            ease: 'power4.out',
        })
    }, [])

    return (
        <LoginRegisterStyle>
            <div className={`successLoader ${successLoader ? 'active' : ''}`} />
            {!success ? (
                <>
                    <Text tag={'div'} className={'animationItem Title h4 MonMedium black'} text={'BecomePartnerPopupForm'} />

                    <div className='Title animationItem'>
                        <Text tag={'span'} className={' pL MonRegular black'} text={'TellMoreAboutYourBusiness'} />
                    </div>

                    <div className='SignInFormWrapp'>
                        <FormStyle>
                            <FormContainer
                                layout={'vertical'}
                                initialValues={{ plan: props.val }}
                                onFinish={onFinish}
                                className={'FormRow FlexBoxContainer spaceBetween'}
                            >
                                <div className='FormCol FormCol-50 animationItem'>
                                    <FormItem name={'company_name'} required={true}>
                                        <FormInput placeholder={props.translate('NameOfTheCompany2') + '*'} />
                                    </FormItem>
                                </div>
                                <div className='FormCol FormCol-50 animationItem'>
                                    <FormItem name={'url'} required={true}>
                                        <FormInput placeholder={props.translate('WebpageOrFBPage') + '*'} />
                                    </FormItem>
                                </div>
                                <div className='FormCol animationItem select-item'>
                                    <FormItem name={'company_type'} required={true}>
                                        <Select
                                            placeholder={props.translate('LegalType') + '*'}
                                            suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />}
                                            getPopupContainer={trigger => trigger.parentNode}
                                            virtual={false}
                                            allowClear
                                        >
                                            <Select.Option value={'LLC'}>{props.translate('LimitedLiabilityCompany')}</Select.Option>
                                            <Select.Option value={'CJSC'}>{props.translate('ClosedJointStockCompany')}</Select.Option>
                                            <Select.Option value={'ОJSC'}>{props.translate('OpenJointStockCompany')}</Select.Option>
                                            <Select.Option value={'IE'}>{props.translate('Individualentrepreneur')}</Select.Option>
                                            <Select.Option value={'Other'}>{props.translate('Other')}</Select.Option>
                                        </Select>
                                    </FormItem>
                                </div>
                                <div className='FormCol FormCol-50 animationItem'>
                                    <FormItem name={'first_name'} required={true}>
                                        <FormInput placeholder={props.translate('FirstName') + '*'} />
                                    </FormItem>
                                </div>
                                <div className='FormCol FormCol-50 animationItem'>
                                    <FormItem name={'last_name'} required={true}>
                                        <FormInput placeholder={props.translate('LastName') + '*'} />
                                    </FormItem>
                                </div>
                                <div className='FormCol FormCol-50 animationItem'>
                                    <FormItem name={'email'} type='email' validationType='email' required={true}>
                                        <FormInput placeholder={props.translate('Email') + '*'} />
                                    </FormItem>
                                </div>
                                <div className='FormCol FormCol-50 animationItem'>
                                    <FormItem name={'phone'} validationType='phone' required={true}>
                                        <FormInput placeholder={props.translate('PhoneNumber') + '*'} />
                                    </FormItem>
                                </div>

                                <div className='FormCol animationItem'>
                                    <FormItem name={'plan'} required={true}>
                                        <Radio.Group value={props.val}>
                                            <div className='FlexBoxContainer spaceBetween'>
                                                <div className='FormCol FormCol-50'>
                                                    <Radio.Button value={'main_plan_price'}>
                                                        {props.translate('basicPlan')}
                                                        <Text tag={'div'} className={' h5 MonMedium '}>
                                                            {props.price1}
                                                        </Text>
                                                    </Radio.Button>
                                                </div>

                                                <div className='FormCol FormCol-50'>
                                                    <Radio.Button value={'premium_plan_price'}>
                                                        {props.translate('premiumPlan')}
                                                        <Text tag={'div'} className={' h5 MonMedium '}>
                                                            {props.price2}
                                                        </Text>
                                                    </Radio.Button>
                                                </div>
                                            </div>
                                        </Radio.Group>
                                    </FormItem>

                                    <div className='formItemExtraMargMinus'>
                                        <div className='ant-form-item-extra'>{props.translate('BecomePartnerSelectWarning')}</div>
                                    </div>
                                </div>

                                <div className='FormCol animationItem'>
                                    <FormItem name='business_plan' valuePropName='checked'>
                                        <Checkbox onChange={acceptTermsChange}>
                                            {props.translate('BecomePartnerArgeement')}
                                            &nbsp;
                                            <CustomLink
                                                url={'/privacy-policy'}
                                                className={'black underline MonMedium'}
                                                options={{ target: '_blank' }}
                                            >
                                                {props.translate('PrivacyPolicyTermsService')}
                                            </CustomLink>
                                        </Checkbox>
                                    </FormItem>
                                </div>

                                <div className='formBtnWrapp animationItem'>
                                    <Button htmlType={'Submit'} className={`btn btn-black btn-max-width ${!passBtnIsActive && 'disabled'}`}>
                                        {props.translate('Send')}
                                    </Button>
                                </div>
                            </FormContainer>
                        </FormStyle>
                    </div>
                </>
            ) : (
                <SuccessMessage title='BecomePartnerPopupFormThenkMessTitle' text='BecomePartnerPopupFormThenkMessText' />
            )}
        </LoginRegisterStyle>
    )
})

export default withLanguageContext(withDataContext(BecomePartnerPopupForm, ['becomePartner']), ['translate'])
