import { memo as Memo, useCallback, useState, useEffect } from 'react'

//* HOC's
import { withLanguageContext, withDataContext } from 'context'

//* Components
import { Select, Tooltip, Upload, Button, Input } from 'antd'
import { Text, FormItem, Icon, MapComponent, FormInput } from 'components/common'

const AboutProjectFields = Memo(({ markerChange, provinces, cities, address, coordinates, edit, ...props }) => {
    //! States
    const [isLogoActive, setLogoActive] = useState(false)

    //! Uploader Fake Request
    const fakeRequest = useCallback(({ onSuccess }) => {
        onSuccess('ok')
    }, [])

    //! On Scroll
    const onScroll = useCallback(() => {
        setLogoActive(window.pageYOffset > 600)
    }, [])

    //! Component Did Mount
    useEffect(() => {
        window.addEventListener('scroll', onScroll)

        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [props.winWidth])

    //! Handle Category Change
    const upClick = useCallback(() => {
        if (typeof window !== undefined) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }
    }, [])

    return (
        <div className='relativeWrapp FlexBoxContainer spaceBetween'>
            <div className='FormColumnsTitle'>
                <Text tag={'h4'} className={'MonMedium black'} text={'AboutProject'} />
            </div>

            <div className='FormColumnsWrapp FlexBoxContainer spaceBetween'>
                <div className='FormCol'>
                    <FormItem label={'InvestmentProjectName'} name={'name'} required={true}>
                        <FormInput />
                    </FormItem>
                </div>

                <div className='FormCol FormCol-50'>
                    <FormItem label={'ProjectImplementationLocation'} name={'province_id'} required={true}>
                        <Select placeholder={props.translate('Select')} suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />} allowClear>
                            {provinces.map((el, k) => (
                                <Select.Option key={k} value={el.id}>
                                    {el.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                </div>
                <div className='FormCol FormCol-50' />

                <div className='FormCol FormCol-50'>
                    <FormItem label={'CityOrVillage'} name={'city_id'} required={true}>
                        <Select
                            disabled={!cities.length}
                            placeholder={props.translate('Select')}
                            suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />}
                            allowClear
                        >
                            {cities.map((el, k) => (
                                <Select.Option key={k} value={el.id}>
                                    {el.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                </div>

                <div className='FormColGroup FlexBoxContainer spaceBetween'>
                    <div className='FormCol FormCol-50 relativeWrapp'>
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: props.translate('LatitudeHelper') }} />}>
                            <div className='HelperDesc'>
                                <Icon className='icon-quastion'>
                                    <span className='path1' />
                                    <span className='path2' />
                                </Icon>
                            </div>
                        </Tooltip>
                        <FormItem label={'Latitude'} name={'latitude'} required={true}>
                            <FormInput />
                        </FormItem>
                    </div>
                    <div className='FormCol FormCol-50 relativeWrapp'>
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: props.translate('LongitudeHelper') }} />}>
                            <div className='HelperDesc'>
                                <Icon className='icon-quastion'>
                                    <span className='path1' />
                                    <span className='path2' />
                                </Icon>
                            </div>
                        </Tooltip>
                        <FormItem label={'Longitude'} name={'longitude'} required={true}>
                            <FormInput />
                        </FormItem>
                    </div>
                </div>

                <div className='FormCol'>
                    <MapComponent
                        className='mapMd'
                        coordinates={coordinates}
                        address={address}
                        popInfo={false}
                        markerDrag={true}
                        markerChange={markerChange}
                    />
                    <div className='ant-form-item-extra'>{props.translate('YouCanDragPing')}</div>
                </div>

                <div className='FormCol relativeWrapp'>
                    <Tooltip title={<div dangerouslySetInnerHTML={{ __html: props.translate('CompanyDescriptionCompletedInvestmentHelp') }} />}>
                        <div className='HelperDesc'>
                            <Icon className='icon-quastion'>
                                <span className='path1' />
                                <span className='path2' />
                            </Icon>
                        </div>
                    </Tooltip>
                    <FormItem label={'ShortDescription'} name={'short_description'} required={true}>
                        <Input.TextArea placeholder={props.translate('DescribeYourBusiness')} maxLength={2500} />
                    </FormItem>
                </div>

                <div className='FormCol FormCol-50'>
                    <FormItem
                        label={'RequiredInvestments'}
                        name={'required_investment'}
                        extra={props.translate('TotalCostProjectExtra', {
                            USD: props.projectPrices?.[0].range.usd?.min?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0,
                            AMD: props.projectPrices?.[0].range.amd?.min?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0,
                        })}
                        required={true}
                        validationType='number'
                        min={props.projectPrices?.[0].range[props.currency === 'USD' ? 'usd' : 'amd'].min}
                    >
                        <FormInput
                            type='number'
                            min={0}
                            addonBefore={
                                <FormItem name='required_investment_currency'>
                                    <Select suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />} disabled={edit} className='select-before'>
                                        <Select.Option value='USD'>$</Select.Option>
                                        <Select.Option value='AMD'>֏</Select.Option>
                                    </Select>
                                </FormItem>
                            }
                            disabled={edit}
                        />
                    </FormItem>
                </div>

                <div className='FormCol FormCol-50'>
                    <FormItem label={'TotalCostProject'} name={'total_cost'} required={true}>
                        <FormInput type='number' min={0} addonBefore={props.currency === 'USD' ? '$' : '֏'} />
                    </FormItem>
                </div>

                <div className='FormColGroup FlexBoxContainer spaceBetween'>
                    <div className='FormCol FormCol-50 suffix suffixLarge'>
                        <FormItem
                            label={'ShareTheCompanyIfOneInvestsTheRequiredMoney'}
                            name={'share_company'}
                            required={true}
                            validationType='number'
                            min={1}
                            max={99}
                        >
                            <FormInput type={'number'} min={0} suffix={'%'} />
                        </FormItem>
                    </div>
                </div>

                <div className='FormColGroup FlexBoxContainer spaceBetween'>
                    <div className='FormCol FormCol-50 suffix suffixLarge relativeWrapp'>
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: props.translate('EBIDAMarginProfitabilityHelper') }} />}>
                            <div className='HelperDesc'>
                                <Icon className='icon-quastion'>
                                    <span className='path1' />
                                    <span className='path2' />
                                </Icon>
                            </div>
                        </Tooltip>
                        <FormItem
                            label={'EBIDAMarginProfitability'}
                            name={'margin_profitability'}
                            required={true}
                            validationType='number'
                            min={1}
                            max={99}
                        >
                            <FormInput type={'number'} min={0} suffix={'%'} />
                        </FormItem>
                    </div>
                </div>

                <div className='FormColGroup FlexBoxContainer spaceBetween'>
                    <div className='FormCol FormCol-50 suffix'>
                        <div className='relativeWrapp'>
                            <Tooltip title={<div dangerouslySetInnerHTML={{ __html: props.translate('PaybackPeriodHelper') }} />}>
                                <div className='HelperDesc'>
                                    <Icon className='icon-quastion'>
                                        <span className='path1' />
                                        <span className='path2' />
                                    </Icon>
                                </div>
                            </Tooltip>
                            <FormItem label={'PaybackPeriod'} name={'payback_period'} required={true}>
                                <FormInput type={'number'} min={0} suffix={props.translate('year')} step="0.1" />
                            </FormItem>
                        </div>
                    </div>
                </div>

                <div className='FormCol'>
                    <FormItem label={'UploadCoverPhotoForInvestmentProject'} name={'feature_image'} validationType='uploader' required={true}>
                        <Upload
                            accept='image/png, image/jpeg'
                            listType='picture-card'
                            multiple={false}
                            fileList={props.feature_image}
                            customRequest={fakeRequest}
                        >
                            {props.feature_image.length < 1 && (
                                <Text tag={'span'} className={'pS MonMedium darkGrey'}>
                                    <Icon className='icon-addFile'>
                                        <span className='path1' />
                                        <span className='path2' />
                                    </Icon>
                                    <br />
                                    <span>{props.translate('UploadPhotos')}</span>
                                </Text>
                            )}
                        </Upload>
                    </FormItem>
                </div>

                <div className='FormCol'>
                    <FormItem
                        label={'UploadProjectPhotos'}
                        name={'photos'}
                        required={true}
                        extra={'PhotosShouldRepresentHowYourInvestmentProjectWillLookLike'}
                        validationType='uploader'
                    >
                        <Upload
                            accept='image/png, image/jpeg'
                            listType='picture-card'
                            fileList={props.photos}
                            maxCount={5}
                            multiple={true}
                            customRequest={fakeRequest}
                        >
                            {props.photos.length < 6 && (
                                <Text tag={'span'} className={'pS MonMedium darkGrey'}>
                                    <Icon className='icon-addFile'>
                                        <span className='path1' />
                                        <span className='path2' />
                                    </Icon>
                                    <br />
                                    <span>{props.translate('UploadPhotos')}</span>
                                </Text>
                            )}
                        </Upload>
                    </FormItem>
                </div>

                <div className='FormCol'>
                    <FormItem label={'UploadProjectFile'} name={'bussines_plan'} extra='UploadProjectFileExtra' multiple={false}>
                        <Upload
                            accept='application/msword, application/vnd.ms-excel, application/pdf'
                            fileList={props.business_plan}
                            listType='picture-card'
                            customRequest={fakeRequest}
                        >
                            <Text tag={'span'} className={'pS MonMedium darkGrey'}>
                                <Icon className='icon-addFile'>
                                    <span className='path1' />
                                    <span className='path2' />
                                </Icon>
                                <br />
                                <span>{props.translate('UploadFile')}</span>
                            </Text>
                        </Upload>
                    </FormItem>
                </div>

                <div className='FormCol'>
                    <div className='relativeWrapp'>
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: props.translate('submitInvestmentProjectDetailsTooltip') }} />}>
                            <div className='HelperDesc'>
                                <Icon className='icon-quastion'>
                                    <span className='path1' />
                                    <span className='path2' />
                                </Icon>
                            </div>
                        </Tooltip>
                        <FormItem label={'details'} name={'details'} required={true} >
                            <Input.TextArea placeholder={props.translate('additionalFeatures')} />
                        </FormItem>
                    </div>
                </div>

                {edit && (
                    <div className='formBtnWrapp'>
                        <Button htmlType={'Submit'} className={`btn btn-black btn-max-width ${!props.passBtnIsActive && 'disabled'}`}>
                            {props.translate('SaveChanges')}
                        </Button>
                    </div>
                )}
            </div>

            <div className={'UpBtnWrap'}>
                <div className={`UpBtn FlexBox alignCenter alignMiddle ${isLogoActive ? 'active' : ''} `} onClick={upClick}>
                    <Icon className={'icon-arrow-up'} />
                </div>
            </div>
        </div>
    )
})

export default withLanguageContext(withDataContext(AboutProjectFields, ['global']), ['translate', 'selectedLang'])
