import styled from 'styled-components'

const SortByStyle = styled.div.attrs({ className: 'sort-by-cont' })`
	/* align-self: flex-end;

	.sort-by {
		justify-content: flex-end;
		display: flex;
		align-items: center;
		margin-bottom: var(--sp3x);
		position: relative;
		z-index: 1;

		.title {
			margin-right: var(--sp2x);
			display: flex;
			align-items: center;
		}

		.sort-block {
			position: relative;
			.selected-option {
				display: flex;
				align-items: center;
				cursor: pointer;

				&.active {
					i {
						transform: rotate(180deg);
					}
				}

				i {
					margin-left: var(--sp3x);
					font-size: var(--sp2x);
					color: var(--darkGrey);
					transition: var(--trTime2);
				}
			}

			.sort-options {
				position: absolute;
				top: calc(100% + var(--spSize));
				right: 0;
				width: max-content;
				background-color: var(--white);
				z-index: 2;
				border-radius: 4px;
				box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.16);

				.option {
					padding: var(--sp2x);
					transition: var(--trTime);
					cursor: pointer;
					border-radius: 4px;

					&:hover {
						background-color: var(--ultraLightGrey);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		margin-left: var(--sp3x);

		.sort-by {
			margin-bottom: var(--sp3x);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		margin-left: 0;

		.btn-action {
			width: calc(50% - var(--sp1-5x));
			margin-bottom: var(--sp2-5x);
			align-self: flex-end;

			.sort-btn {
				width: 100%;
				padding: var(--sp2x) var(--sp6x);
				background-color: var(--ultraLightGrey);
				border: 1px solid var(--darkGrey);
				border-radius: 4px;

				span {
					display: flex;
					justify-content: space-between;
					align-items: center;

					i {
						font-size: var(--sp2x);
						color: var(--darkGrey);
					}
				}
			}
		}

		.sort-by {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100vw;
			height: 100vh;
			overflow-x: hidden;
			overflow-y: auto;
			z-index: -1;
			display: none;
			pointer-events: none;
			background-color: var(--white);
			padding-left: var(--containerPadding);
			padding-right: var(--containerPadding);
			padding-bottom: var(--sp25x);
			margin-top: 0;
			margin-bottom: 0;

			&.active {
				pointer-events: all;
				display: block;
				z-index: 100;
			}

			.head-title {
				padding: var(--sp3x) 0;
				position: relative;
				text-align: center;
				margin-bottom: var(--sp3x);

				&:before {
					content: '';
					position: absolute;
					bottom: 0;
					left: calc(-1 * var(--containerPadding));
					width: calc(100% + (2 * var(--containerPadding)));
					height: 1px;
					background-color: var(--darkGrey);
				}
			}

			.sort-options {
				display: flex;
				flex-direction: column;

				.option {
					margin-bottom: var(--sp3x);
				}
			}

			.btns {
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: var(--sp18x);
				display: flex;
				justify-content: space-between;
				padding-left: var(--containerPadding);
				padding-right: var(--containerPadding);
				background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);

				.btn {
					width: 100%;
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		margin-left: 0;

		.btn-action {
			width: auto;
			margin-bottom: var(--sp2x);
			align-self: flex-end;

			.sort-btn {
				width: unset;
				padding: var(--sp1-5x) var(--sp3x);
				background-color: var(--ultraLightGrey);
				border: 1px solid var(--darkGrey);
				border-radius: 4px;

				span {
					display: flex;
					justify-content: space-between;
					align-items: center;

					i {
						font-size: var(--sp2x);
						color: var(--darkGrey);
						margin-left: var(--sp1-5x);
					}
				}
			}
		}

		.sort-by {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100vw;
			height: 100vh;
			overflow-x: hidden;
			overflow-y: auto;
			z-index: -1;
			display: none;
			pointer-events: none;
			background-color: var(--white);
			padding-left: var(--containerPadding);
			padding-right: var(--containerPadding);
			padding-bottom: var(--sp25x);
			margin-top: 0;
			margin-bottom: 0;

			&.active {
				pointer-events: all;
				display: block;
				z-index: 100;
			}

			.head-title {
				padding: var(--sp3x) 0;
				position: relative;
				text-align: center;
				margin-bottom: var(--sp3x);

				&:before {
					content: '';
					position: absolute;
					bottom: 0;
					left: calc(-1 * var(--containerPadding));
					width: calc(100% + (2 * var(--containerPadding)));
					height: 1px;
					background-color: var(--darkGrey);
				}
			}

			.sort-options {
				display: flex;
				flex-direction: column;

				.option {
					margin-bottom: var(--sp3x);
				}
			}

			.btns {
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: var(--sp18x);
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
				padding-left: var(--containerPadding);
				padding-right: var(--containerPadding);
				background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);

				.btn-action {
					width: calc(50% - var(--sp0-75x));

					.btn {
						width: 100%;
					}
				}
			}
		}
	} */
`
export default SortByStyle
