import { createGlobalStyle } from 'styled-components'

const HelperClass = createGlobalStyle`

    html.hidden {
        &, 
        body {
            max-height:100vh;
            overflow:hidden;
            touch-action:none;
            overscroll-behavior: contain;
        }
    }
    
    body {
        width: 100%;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        color: var(--black);
        font-family: MonRegular,MonRegularRu;
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        position: relative;
        background-color:var(--white);

        main.page {
            min-height: 100vh;
            z-index: 1;
            position: relative;
        }

        #root {
            overflow-x: hidden;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &.hidden {
            max-height:100vh;
            overflow:hidden;
            touch-action:none;
            overscroll-behavior: contain;
        }
    }

    .modal-open {
        overflow-y: hidden;
    }

    ul,
    fieldset {
        margin: 0;
        padding: 0;
        list-style: none;
        border: none;
    }

    .minHeightCont{
        padding: 0.1px 0;
        min-height:90vh;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    // flex box

    .FlexBoxContainer {
        display: flex;
        flex-wrap: wrap;
    }

    .FlexBoxColumn {
        display: flex;
        flex-direction: column;
    }

    .FlexBox {
        display: flex;
    }

    .alignMiddle {
        align-items: center;
    }

    .alignCenter {
        justify-content: center;
    }

    .justifyEnd {
        justify-content: flex-end;
    }
    
    .AlignEnd{
        align-items: flex-end;
    }

    .spaceBetween {
        justify-content: space-between;
    }


    .cursorPointer{
        cursor:pointer;
    }

    .textAlignCenter {
        text-align:center;
    }

    //!Colors
    // Primary
    .black {
        color: var(--black);
    }

    .white {
        color: var(--white);
    }
    
    .ultraDarkGrey {
        color: var(--ultraDarkGrey);
    }

    .darkGrey {
        color: var(--darkGrey);
    }

    .ultraLightGrey {
        color: var(--ultraLightGrey);
    }

    .extraLightGrey {
        color: var(--extraLightGrey);
    }


    .lightGrey {
        color: var(--lightGrey);
    }

    .blue {
        color: var(--blue);
    }

    .lightBlue {
        color: var(--lightBlue);
    }

    .yellow {
        color: var(--yellow);
    }

    .orange {
        color: var(--orange);
    }

    .coral {
        color: var(--coral);
    }

    .green {
        color: var(--green);
    }
    
    .pink {
        color: var(--pink);
    }

    //secondary
    .secBlue {
        color: var(--secBlue);
    }

    .secLightBlue {
        color: var(--secLightBlue);
    }

    .secLightBlue {
        color: var(--secLightBlue);
    }

    .secLightGreen {
        color: var(--secLightGreen);
    }

    .secOrange {
        color: var(--secOrange);
    }

    .secYellow {
        color: var(--secYellow);
    }

    .secLightYellow {
        color: var(--secLightYellow);
    }


    //errColor
    .error {
        color:var(--errColor);
    }

    //relaative

    .relativeWrapp{
        position: relative;
    }

    .overflowHidden{
        overflow:hidden;
    }
`

export default HelperClass
