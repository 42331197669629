import { memo as Memo, useMemo } from 'react'

//* HOC's
import { withLanguageContext, withAccountContext } from 'context'

//* Components
import { Radio, Select, Button, Tooltip, Input } from 'antd'
import { FormItem, Icon, FormInput } from 'components/common'

const ProjectCompanySection = Memo(({ provinces, cities, countries, companyTypeOther, user, ...props }) => {
	const countriesHTML = useMemo(() => {
		return countries.map((el, k) => (
			<Select.Option key={k} title={el.name} value={el.id}>
				{el.name}
			</Select.Option>
		))
	}, [countries])
	return (
		<div className='FormColumnsWrapp FlexBoxContainer spaceBetween '>
			<div className='FormCol'>
				<FormItem label={'NameOfTheCompany'} name={'name'} extra={'EnterTheNameOfTheCompanyExtra'} required={true}>
					<FormInput />
				</FormItem>
			</div>

			<div className='FormCol'>
				<FormItem label={'LegalTypeOfTheCompany'} validationType='radio' name={'type'} required={true}>
					<Radio.Group checked='LLC'>
						<div className='FlexBoxContainer spaceBetween'>
							<div className='FormCol'>
								<Radio.Button value='LLC'>{props.translate('LimitedLiabilityCompany')}</Radio.Button>
							</div>
							<div className='FormCol'>
								<Radio.Button value='CJSC'>{props.translate('ClosedJointStockCompany')}</Radio.Button>
							</div>
							<div className='FormCol'>
								<Radio.Button value='OJSC'>{props.translate('OpenJointStockCompany')}</Radio.Button>
							</div>
							<div className='FormCol'>
								<Radio.Button value='IE'>{props.translate('Individualentrepreneur')}</Radio.Button>
							</div>
							<div className='FormCol'>
								<Radio.Button value='other'>{props.translate('Other')}</Radio.Button>
							</div>
						</div>
					</Radio.Group>
				</FormItem>
				{companyTypeOther && (
					<>
						<div className='mutableFormItem'>
							<FormItem name={'type_other'} required={true}>
								<FormInput placeholder={props.translate('specify')} />
							</FormItem>
						</div>
						<div className='ant-form-item-extra'>{props.translate('LegalTypeOfTheCompanyExtra')}</div>
					</>
				)}
			</div>

			<div className='FormCol FormCol-50 extraMaxWidth'>
				<FormItem label={'CompanysRegistrationNumber'} name={'registration_number'} extra={'CompanysTaxNumberExtra'}>
					<FormInput />
				</FormItem>
			</div>

			<div className='FormCol FormCol-50'>
				<FormItem label={'CompanysTaxNumber'} name={'tax_number'}>
					<FormInput />
				</FormItem>
			</div>

			<div className='FormCol FormCol-50'>
				<FormItem label={'ProjectImplementationLocation'} name={'province_id'} required={true}>
					<Select placeholder={props.translate('Select')} suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />} allowClear>
						{provinces.map((el, k) => (
							<Select.Option key={k} value={el.id}>
								{el.name}
							</Select.Option>
						))}
					</Select>
				</FormItem>
			</div>

			<div className='FormCol FormCol-50'></div>

			<div className='FormCol FormCol-50'>
				<FormItem label={'CityOrVillage'} name={'city_id'} required={true}>
					<Select
						disabled={!cities.length}
						placeholder={props.translate('Select')}
						suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />}
						allowClear
					>
						{cities.map((el, k) => (
							<Select.Option key={k} value={el.id}>
								{el.name}
							</Select.Option>
						))}
					</Select>
				</FormItem>
			</div>

			<div className='FormCol relativeWrapp'>
				<Tooltip title={<div dangerouslySetInnerHTML={{ __html: props.translate('CompanyDescriptionCompletedExportHelp') }} />}>
					<div className='HelperDesc'>
						<Icon className='icon-quastion'>
							<span className='path1'></span>
							<span className='path2'></span>
						</Icon>
					</div>
				</Tooltip>
				<FormItem label={'ShortDescriptionOfTheCompany'} name={'short_description'} required={true}>
					<Input.TextArea placeholder={props.translate('ShortDescriptionOfTheCompany')} />
				</FormItem>
			</div>

			<div className='FormCol FormCol-90'>
				<FormItem label={'CompanysWebpageOfficialFacebookPage'} name={['socials', 'url']}>
					<FormInput addonBefore={'URL'} />
				</FormItem>
				<FormItem name={['socials', 'facebook']}>
					<FormInput addonBefore={'facebook.com/'} />
				</FormItem>
				<FormItem name={['socials', 'instagram']}>
					<FormInput addonBefore={'instagram.com/'} />
				</FormItem>
			</div>

			<div className='FormCol FormCol-50'>
				<FormItem label={'YearsOfExperienceInExportingTheProduct'} name={'export_period'} required={true}>
					<FormInput step='0.1' type={'number'} min={1} suffix={props.translate('year')} />
				</FormItem>
			</div>

			<div className='FormCol'>
				<FormItem label={'CountriesTheProductIsExportedNow'} name={'export_countries'} required={true}>
					<Select
						mode='multiple'
						placeholder={props.translate('Select')}
						suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />}
						allowClear
						filterOption={(searchVal, option) => option.title.toLowerCase().includes(searchVal.toLowerCase())}
					>
						{countriesHTML}
					</Select>
				</FormItem>
			</div>

			<div className='FormCol'>
				<FormItem label={'CountriesYouAreInterestedToExport'} name={'interest_countries'} required={true}>
					<Select
						placeholder={props.translate('Select')}
						suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />}
						mode='multiple'
						allowClear
						filterOption={(searchVal, option) => option.title.toLowerCase().includes(searchVal.toLowerCase())}
					>
						{countriesHTML}
					</Select>
				</FormItem>
			</div>

			<div className='FormCol'>
				<FormItem label={'ContactPerson'} name={'contact_name'} required={true}>
					<FormInput />
				</FormItem>
			</div>

			<div className='FormCol FormCol-50'>
				<FormItem label={'MobileNumber'} name={'contact_phone'} required={true} validationType='phone'>
					<FormInput />
				</FormItem>
			</div>
			<div className='FormCol FormCol-50'>
				<FormItem label={'Email'} name={'contact_email'} required={true} validationType='email'>
					<FormInput />
				</FormItem>
			</div>

			<div className='formBtnWrapp FlexBox justifyEnd'>
				<div className='FormCol FormCol-50'>
					<Button htmlType={'Submit'} className={`btn btn-black btn-max-width ${!props.disabled ? '' : 'disabled'}`}>
						{props.translate('Save')}
					</Button>
				</div>
			</div>
		</div>
	)
})

export default withLanguageContext(withAccountContext(ProjectCompanySection, ['user']), ['translate', 'selectedLang'])
