import styled from 'styled-components'

const Header = styled.header`
	--btnItemMarg: var(--sp3x);

	position: relative;
	z-index: 12;
	transform: translateY(-100%);

	&.active {
		transition: all var(--trTime3) ease-in-out;
		transform: translateY(0);
	}
	.headerTop {
		padding: var(--headerPaddingTopBot) var(--headerPaddingLeftRigth);
	}

	.btnWrap {
		> div {
			margin-right: var(--btnItemMarg);
			&:last-child {
				margin-right: 0;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		--btnItemMarg: var(--sp4x);
		--btnWrapMargBot: var(--sp9x);

		.btnWrap {
			margin-bottom: var(--btnWrapMargBot);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		.btnWrap {
			justify-content: flex-end;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) {
		--btnWrapMargBot: var(--sp15x);

		.btnWrap {
			flex-direction: column;
			align-items: flex-end;
			> div {
				margin-right: 0;
				margin-bottom: var(--btnItemMarg);
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--btnItemMarg: var(--sp2x);
		--btnWrapMargBot: var(--sp3x);
	}
`

export default Header
