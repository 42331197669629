import styled from 'styled-components'

const InvestFieldsStyle = styled.div`
	section {
		.fields {
			margin-top: var(--sp12x);
			flex-wrap: wrap;

			.single-field {
				width: calc(33% - var(--sp3x));
				height: var(--sp27x);
				padding: var(--sp4x) var(--sp2-5x) var(--sp4x);
				border-radius: var(--spSize);
				transform:translateY(20vh);

				&:nth-child(4),
				&:nth-child(5),
				&:nth-child(6) {
					margin-top: var(--sp4-5x);
				}

				.imageCont img {
					object-fit: contain!important;
				}

				&.IT {
					background-color: var(--blue);

					.imageCont {
						width: var(--sp11x);
						padding-top: var(--sp13x);
					}
				}

				&.energy {
					background-color: var(--yellow);

					.imageCont {
						width: var(--sp16x);
						padding-top: var(--sp12x);
					}
				}

				&.jewellery {
					background-color: var(--coral);

					.imageCont {
						width: var(--sp8x);
						padding-top: var(--sp11x);
					}
				}

				&.agro {
					background-color: var(--green);

					.imageCont {
						width: var(--sp12x);
						padding-top: var(--sp12x);
					}
				}

				&.textile {
					background-color: var(--orange);

					.imageCont {
						width: var(--sp14x);
						padding-top: var(--sp9x);
					}
				}

				&.pharmacy {
					background-color: var(--lightBlue);

					.imageCont {
						width: var(--sp11x);
						padding-top: var(--sp10x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		section {
			.fields {
				margin-top: var(--sp10x);
				@media screen and (max-width: DesktopSizeS) {
					margin-top: var(--sp7-5x);
				}

				.single-field {
					width: calc(33% - (var(--sp8x) / 3));
					height: var(--sp23x);
					padding: var(--sp3x);

					&:nth-child(4),
					&:nth-child(5),
					&:nth-child(6) {
						margin-top: var(--sp4x);
					}

					&.IT {
						.imageCont {
							width: var(--sp9-5x);
							padding-top: var(--sp11x);
						}
					}

					&.energy {
						.imageCont {
							width: var(--sp13x);
							padding-top: var(--sp10x);
						}
					}

					&.jewellery {
						.imageCont {
							width: var(--sp7x);
							padding-top: var(--sp9x);
						}
					}

					&.agro {
						.imageCont {
							width: var(--sp10x);
							padding-top: var(--sp10x);
						}
					}

					&.textile {
						.imageCont {
							width: var(--sp11x);
							padding-top: var(--sp7-5x);
						}
					}

					&.pharmacy {
						.imageCont {
							width: var(--sp10x);
							padding-top: var(--sp9x);
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		section {
			.fields {
				margin-top: var(--sp5x);

				.single-field {
					width: calc(50% - var(--sp1-5x));
					height: var(--sp20x);
					padding: var(--sp2x);

					&:nth-child(3),
					&:nth-child(4),
					&:nth-child(5),
					&:nth-child(6) {
						margin-top: var(--sp3x);
					}

					&.IT {
						.imageCont {
							width: var(--sp9-5x);
							padding-top: var(--sp11x);
						}
					}

					&.energy {
						.imageCont {
							width: var(--sp13x);
							padding-top: var(--sp10x);
						}
					}

					&.jewellery {
						.imageCont {
							width: var(--sp7x);
							padding-top: var(--sp9x);
						}
					}

					&.agro {
						.imageCont {
							width: var(--sp10x);
							padding-top: var(--sp10x);
						}
					}

					&.textile {
						.imageCont {
							width: var(--sp11x);
							padding-top: var(--sp7-5x);
						}
					}

					&.pharmacy {
						.imageCont {
							width: var(--sp10x);
							padding-top: var(--sp9x);
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		section {
			.fields {
				margin-top: var(--sp5x);
				flex-direction: column;

				.single-field {
					width: 100%;
					height: var(--sp17x);
					padding: var(--sp2x);

					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4),
					&:nth-child(5),
					&:nth-child(6) {
						margin-top: var(--sp2-5x);
					}

					&.IT {
						.imageCont {
							width: var(--sp6-5x);
							padding-top: var(--sp7-5x);
						}
					}

					&.energy {
						.imageCont {
							width: var(--sp9-5x);
							padding-top: var(--sp7x);
						}
					}

					&.jewellery {
						.imageCont {
							width: var(--sp5x);
							padding-top: var(--sp6x);
						}
					}

					&.agro {
						.imageCont {
							width: var(--sp7x);
							padding-top: var(--sp7x);
						}
					}

					&.textile {
						.imageCont {
							width: var(--sp8x);
							padding-top: var(--sp5x);
						}
					}

					&.pharmacy {
						.imageCont {
							width: var(--sp7x);
							padding-top: var(--sp6x);
						}
					}
				}
			}
		}
	}
`
export default InvestFieldsStyle
