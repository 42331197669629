import styled from 'styled-components'

const LastSectionStyle = styled.div.attrs((props) => ({ className: props.className }))`
	position: relative;
	margin-top: calc(2 * var(--containerMarginBottom));

	.last-section-block {
		display: flex;

		.content {
			display: flex;
			flex-direction: column;
			max-width: var(--sp98x);
			width: 100%;

			.title {
				margin-bottom: var(--titleFromContent);
			}

			.description {
				margin-bottom: var(--sp7x);
			}

			.btn {
				width: auto;
			}
		}

		.imageCont {
			position: absolute;
			z-index: 1;
		}

		.men-cont {
			bottom: calc((var(--pageFromFooterMargin) + 15%) * -1);
			left: -4%;
			width: 16.3%;
			padding-top: 16.3%;
			clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
			background-color: var(--blue);
			position: absolute;
			z-index: 1;
			transform: translateY(50%) rotate(-22deg);

			& > * {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transform: translateY(100%) rotate(22deg);
			}
		}

		.women-cont {
			top: -25%;
			right: 27%;
			width: 10.1%;
			padding-top: 10.1%;
			clip-path: circle(50% at 50% 50%);
			background-color: var(--yellow);
			overflow: hidden;
			position: absolute;
			z-index: -1;
			transform: translateY(40vh);

			& > * {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 150%;
				transform: translateY(-40%) rotate(-9deg);
			}
		}

		.filledCircle {
			width: 9.7%;
			padding-top: 9.7%;
			right: 9%;
			bottom: -35%;
			transform: translateY(100%) rotate(-40deg);
		}

		.halfCircle {
			width: 3.6%;
			padding-top: 3.4%;
			left: 5%;
			top: 35%;
			transform: translateY(14vh) rotate(-40deg);
		}

		.halfYellow {
			width: 2.6%;
			padding-top: 2.2%;
			right: 5%;
			top: 0%;
			transform: translateY(70vh) rotate(110deg);
		}

		.halfGreen {
			width: 4%;
			padding-top: 3.4%;
			left: 52%;
			top: 80%;
			transform: translateY(20vh) rotate(45deg);
		}

		.holeCircle {
			width: 3.3%;
			padding-top: 3.2%;
			right: 16%;
			bottom: 44%;
			transform: translateY(10vh);
		}

		.holeCircle2 {
			width: 2%;
			padding-top: 2%;
			left: 23%;
			bottom: -46%;
			transform: translateY(30vh);
		}

		.holeYellowCircle {
			width: 1.9%;
			padding-top: 1.8%;
			left: 29%;
			top: -38%;
			transform: translateY(70vh);
		}

		.blueTriangle {
			width: 2.4%;
			padding-top: 2.3%;
			right: 18%;
			top: -35%;
			transform: translateY(20vh) rotate(-45deg);
		}

		.greenTriangle {
			width: 2.4%;
			padding-top: 2.3%;
			left: 3%;
			top: -21%;
			transform: translateY(16vh) rotate(35deg);
		}

		.orangeTriangle {
			width: 2.4%;
			padding-top: 2.3%;
			right: 30%;
			bottom: 0;
			transform: translateY(8vh) rotate(-25deg);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		.last-section-block {
			.content {
				max-width: var(--sp83x);

				.description {
					margin-bottom: var(--sp6x);
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		.last-section-block {
			.content {
				max-width: var(--sp83x);

				.description {
					margin-bottom: var(--sp5x);
				}
			}

			.men-cont {
				left: 36%;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.LaptopSize}) {
		.last-section-block {
			.content {
				max-width: var(--sp72x);

				.description {
					margin-bottom: var(--sp4x);
				}
			}

			.men-cont {
				bottom: -52%;
				left: 33%;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		.last-section-block {
			.content {
				max-width: var(--sp64x);

				.description {
					margin-bottom: var(--sp4x);
				}
			}

			.men-cont {
				bottom: -50%;
				left: 44%;
				width: 18.1%;
				height: 18.1%;
			}

			.women-cont {
				top: 10%;
				right: 13%;
				width: 11.2%;
				padding-top: 11.2%;
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.last-section-block {
			.content {
				max-width: unset;

				.description {
					margin-bottom: var(--sp3-5x);
				}

				.btn {
					width: 100%;
				}
			}

			.men-cont {
				bottom: -43%;
				left: -9%;
				width: 29.2%;
				padding-top: 29.2%;
			}

			.women-cont {
				display: none;
			}

			.filledCircle {
				width: 19.2%;
				padding-top: 19.2%;
				right: -9.6%;
				bottom: -44%;
			}

			.halfCircle {
				width: 8.6%;
				padding-top: 8.4%;
				left: 28%;
				bottom: -24%;
				top: unset;
			}

			.halfYellow {
				width: 5.6%;
				padding-top: 5.2%;
				right: 35%;
				top: -10%;
			}

			.halfGreen {
				width: 11%;
				padding-top: 9.4%;
				right: 12%;
				top: 69%;
				left: unset;
			}

			.holeCircle {
				width: 7.3%;
				padding-top: 7.2%;
				right: 0px;
				bottom: unset;
				top: -5%;
				transform: translateX(50%);
			}

			.holeCircle2 {
				width: 4%;
				padding-top: 4%;
				left: 50%;
				bottom: -12%;
			}

			.holeYellowCircle {
				width: 3.9%;
				padding-top: 3.8%;
				left: 0;
				top: 10%;
				transform: translateX(-50%);
			}

			.blueTriangle {
				width: 5.4%;
				padding-top: 5.3%;
				right: 18%;
				top: 0%;
			}

			.greenTriangle {
				width: 4.4%;
				padding-top: 4.5%;
				left: 0;
				top: 49%;
			}

			.orangeTriangle {
				width: 5.4%;
				padding-top: 5.3%;
				right: 39%;
				bottom: 17%;
			}
		}
	}
`

export default LastSectionStyle
