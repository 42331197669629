import styled from 'styled-components'

const PopupStyle = styled.div.attrs((props) => ({ className: props.className }))`
	--popupPaddingTop: var(--sp8x);
	--popupPaddingLeft: var(--sp8x);
	--popupContentOut: var(--sp4x);
	--closeSize: var(--sp6x);
	--closeSizePos: var(--sp2x);
	--popupContentMaxWidth: var(--sp115x);
	--popUpBorderRad: var(--spSize);

	/* background: rgb(0 0 0 / 30%); */
	pointer-events: none;

	.popup-container {
		height: 100vh;
		width: 100vw;
        
		.popup-background {
			background: rgb(0 0 0 / 30%);
			pointer-events: all;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		.popup-content-container {
			max-width: var(--popupContentMaxWidth);
			width: 100%;
			height: 100%;
			pointer-events: all;
			overflow: auto;
			overflow: -moz-scrollbars-none;
			-ms-overflow-style: none;
			.popup-content {
				padding: var(--popupContentOut) 0 calc(var(--popupContentOut) * 2);
				width: 100%;
				&::-webkit-scrollbar {
					display: none;
				}
				.popup-content-wrapp {
					width: 100%;
					background-color: var(--white);
					border-radius: var(--popUpBorderRad);
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
					padding: var(--popupPaddingTop) var(--popupPaddingLeft);
					overflow: hidden;
				}
			}
			&::-webkit-scrollbar {
				width: 0; /* Remove scrollbar space */
				background: transparent; /* Optional: just make scrollbar invisible */
			}
			/* Optional: show position indicator in red */
			&::-webkit-scrollbar-thumb {
				background: #ff0000;
			}
		}
		.close {
			position: absolute;
			top: var(--closeSizePos);
			right: var(--closeSizePos);
			width: var(--closeSize);
			height: var(--closeSize);
			border-radius: var(--borderLg);
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
		}
	}

    .SignInFormWrapp {
        max-width: 100%;
    }

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		--popupContentMaxWidth: var(--sp99x);
		--closeSize: var(--sp5x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.LaptopSize}) {
		--closeSize: var(--sp5x);
		--popupContentMaxWidth: var(--sp103x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		--closeSize: var(--sp5x);
		--popupContentMaxWidth: var(--sp78x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--popupPaddingLeft: var(--sp2x);
		--closeSize: var(--sp5x);
		--popupContentMaxWidth: 100%;
		--popUpBorderRad: 0;

        .popup-container {
        
            .popup-background {
                background: var(--white);
            }

            .popup-content-container {
                .popup-content {
                    .popup-content-wrapp {
                        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
                    }
                }
            }
        }
	}
`
export default PopupStyle
