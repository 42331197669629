//service
export { default as TopSection } from './Services/TopSection'
export { default as MainSection } from './Services/MainSection'
export { default as ContactSection } from './Services/ContactSection'
export { default as BusinessSection } from './Services/BusinessSection'

//news
export { default as Filter } from './News/Filter'
export { default as Map } from './News/Map'
export { default as NewsBlock } from './News/NewsBlock'
export { default as SingleNews } from './News/SingleNews'
export { default as LastSection } from './News/LastSection'
export { default as CategoryBtn } from './News/CategoryBtn'

//acaunt
export { default as AccountMain } from './Account/main'
export { default as Cooperation } from './Account/otherPage/Cooperation'
export { default as AccountActivatedPopup } from './Account/otherPage/AccountActivatedPopup'
export { default as VerifyAccountPopup } from './Account/otherPage/VerifyAccountPopup'
export { default as ProjectCompany } from './Account/otherPage/ProjectCompany'
export { default as ProductsCompany } from './Account/otherPage/ProductsCompany'
export { default as ProfileForm } from './Account/otherPage/Profile'
export { default as Submissions } from './Account/otherPage/Submissions'
export { default as PurchaseHistory } from './Account/otherPage/PurchaseHistory'
export { default as SubmittedProducts } from './Account/otherPage/SubmittedProducts'
export { default as ProductCatalogRow } from './Account/otherPage/SubmittedProducts/ProductCatalogRow'
export { default as ProductRow } from './Account/otherPage/SubmittedProducts/ProductRow'

//Reset Password
export { default as ResetPasswordForm } from './ResetPassword/ResetPasswordForm'
export { default as SuccessMessage } from './ResetPassword/SuccessMessage'

//invest in your business
export { default as BusinessStep } from './InvestingInBusiness/BusinessStep'
export { default as PlanPricing } from './InvestingInBusiness/PlanPricing'
export { default as RegisterPlan } from './InvestingInBusiness/RegisterPlan'
export { default as CatalogView } from './InvestingInBusiness/CatalogView'

//investment projects
export { default as FilterInCatalogue } from './InvestmentProjects/FilterInCatalogue'
export { default as MapInCatalogue } from './InvestmentProjects/MapInCatalogue'
export { default as Catalogs } from './InvestmentProjects/Catalogs'
export { default as InvestmentProjectsSinglePopup } from './InvestmentProjects/InvestmentProjectsSinglePopup'

//investment project single
export { default as Slider } from './SingleInvestmentProject/Slider'
export { default as ProjectInfo } from './SingleInvestmentProject/ProjectInfo'
export { default as ProjectContent } from './SingleInvestmentProject/ProjectContent'
export { default as MapInSingle } from './SingleInvestmentProject/Map'
export { default as ProjectApproved } from './SingleInvestmentProject/ProjectApproved'
export { default as FormForGuest } from './SingleInvestmentProject/FormForGuest'

//submit investment project
export { default as Summary } from './SubmitInvestmentProjectComp/Summary'
export { default as ProjectWarningPopup } from './SubmitInvestmentProjectComp/WarningPopup'

//become a partner
export { default as CooperationWithInvest } from './BecomePartner/CooperationWithInvest'
export { default as CooperationPackages } from './BecomePartner/CooperationPackages'
export { default as SinglePackage } from './BecomePartner/SinglePackage'
export { default as PartnersSlider } from './BecomePartner/PartnersSlider'
export { default as BecomePartnerPopupForm } from './BecomePartner/BecomePartnerPopupForm'

//news
export { default as NewsContent } from './NewsSubmissionForm/NewsContent'
export { default as ContactInformation } from './NewsSubmissionForm/ContactInformation'
export { default as NewsSummary } from './NewsSubmissionForm/NewsSummary'

//single product
export { default as ProductInfo } from './SingleProduct/ProductInfo'
export { default as ProductContent } from './SingleProduct/ProductContent'

//export from armenia
export { default as ExportCatalog } from './ExportFromArmenia/ExportCatalog'
export { default as FilterInExport } from './ExportFromArmenia/FilterInExport'
export { default as Calculator } from './ExportFromArmenia/Calculator'
export { default as CalculatorForm } from './ExportFromArmenia/CalculatorForm'
export { default as ExportWarningPopup } from './ExportFromArmenia/WarningPopup'

//export from armenia
export { default as BusinessInArmeniaTop } from './BusinessInArmenia/BusinessInArmeniaTop'
export { default as BusinessInArmeniaForm } from './BusinessInArmenia/BusinessInArmeniaForm'
export { default as BusinessInArmeniaFormTop } from './BusinessInArmenia/BusinessInArmeniaFormTop'
export { default as BusinessInArmeniaFormMid } from './BusinessInArmenia/BusinessInArmeniaFormMid'
export { default as BusinessInArmeniaFormBottom } from './BusinessInArmenia/BusinessInArmeniaFormBottom'
export { default as BusinessInArmeniaVideoSlider } from './BusinessInArmenia/BusinessInArmeniaVideoSlider'

//what is a product submission
export { default as Steps } from './WhatIsaProductSubmission/Steps'
export { default as SubmissionPlans } from './WhatIsaProductSubmission/SubmissionPlans'
export { default as Quote } from './WhatIsaProductSubmission/Quote'
export { default as Terms } from './WhatIsaProductSubmission/Terms'

//home
export { default as AboutInvest } from './Home/AboutInvest'
export { default as WhyArmenia } from './Home/WhyArmenia'
export { default as InvestFields } from './Home/InvestFields'
export { default as DoneWorks } from './Home/DoneWorks'
export { default as NewsInHome } from './Home/NewsInHome'
export { default as OurServices } from './Home/OurServices'
export { default as BottomBlock } from './Home/BottomBlock'
export { default as Testimonials } from './Home/Testimonials'

//submit export product
export { default as SubmitExportProductSection } from './SubmitExportProduct/SubmitExportProductSection'
export { default as SubmitExportProductItem } from './SubmitExportProduct/SubmitExportProductItem'
export { default as ProductWarningPopup } from './SubmitExportProduct/WarningPopup'

//company
export { default as CompanyInfo } from './Company/CompanyInfo'

//UpdateSubmittedProduct
export { default as UpdateSubmittedProduct } from './UpdateSubmittedProduct'
