import styled from 'styled-components'

const WhyArmeniaStyle = styled.div`
	section {
		.title {
			margin-bottom: var(--titleFromContent);
		}

		.fields {
			margin-top: var(--sp12x);

			.single-field {
				width: calc(25% - (var(--sp18x) / 3));
				transform:translateY(20vh);
				.LottieWrapp {
					width: var(--sp16x);
					padding-top: var(--sp16x);
					margin-bottom: var(--sp3x);
				}
			}
		}
	}
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		section {
			.fields {
				margin-top: var(--sp10x);

				.single-field {
					width: calc(25% - (var(--sp12x) / 3));

					.LottieWrapp {
						width: var(--sp15x);
						padding-top: var(--sp15x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		section {
			.fields {
				margin-top: var(--sp7-5x);

				.single-field {
					width: calc(25% - (var(--sp12x) / 3));

					.LottieWrapp {
						width: var(--sp15x);
						padding-top: var(--sp15x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		section {
			.fields {
				margin-top: var(--sp5x);

				.single-field {
					width: calc(25% - var(--sp3x));

					.LottieWrapp {
						width: var(--sp15x);
						padding-top: var(--sp15x);
						margin-bottom: var(--sp2-5x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		section {
			.fields {
				margin-top: var(--sp5x);
				flex-wrap: wrap;

				.single-field {
					width: calc(50% - var(--sp2-5x));

					&:nth-child(3),
					&:nth-child(4) {
						margin-top: var(--sp5x);
					}

					.LottieWrapp {
						width: var(--sp15x);
						padding-top: var(--sp15x);
						margin-bottom: var(--sp2-5x);
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		section {
			.fields {
				margin-top: var(--sp5x);
				flex-direction: column;

				.single-field {
					width: 100%;
					flex-direction: row;
					align-items: center;

					&:not(:last-child) {
						margin-bottom: var(--sp7-5x);
					}

					.LottieWrapp {
						width: var(--sp12x);
						padding-top: var(--sp12x);
						margin-bottom: 0;
						margin-right: var(--sp2x);
					}

					.text {
						flex: 1;
					}
				}
			}
		}
	}
`
export default WhyArmeniaStyle
