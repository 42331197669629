import React, { forwardRef, useMemo, useState, useCallback, useEffect, useRef } from 'react'

//* HOC's
import { withUIContext } from 'context'

//* Components
import { Text, Icon, Button } from 'components/common'
import { useRouter } from 'next/router'

//* Helpers
import { getParamsByKey } from 'helpers'
import Collapse from '@material-ui/core/Collapse'
//* Styles
import SortByStyle from './style'

const Sortby = forwardRef(({ sortList, onCategoryChange, sortByParamKey, winWidth, ...props }, sortRef) => {
    //! States
    const [hgt, setHgt] = useState(false)
    const [activeClassFilter, setActiveClassFilter] = useState('')
    const [activeSortOption, setActiveSortOption] = useState({ text: 'sortByFeatured', id: 1 })

    //! Router
    const router = useRouter()

    //! Sort By Active Param
    const sortByActiveParam = useMemo(() => {
        const sortByParam = sortByParamKey //? "order_by"
        const activeItems = getParamsByKey(router, sortByParam)

        return activeItems?.[0]
    }, [router, sortByParamKey])

    //! Component Did Mount
    useEffect(() => {
        const param = sortByActiveParam
        const text = Object.keys(sortList).find((key) => sortList[key] == param) || 'sortByFeatured'
        const id = sortList[text]

        setActiveSortOption({ text, id })
    }, [])

    //! Sort By Change
    const sortByChange = useCallback(
        (text, id) => {
            setHgt(!hgt)
            if (text) {
                const sortByParam = sortByParamKey //? "order_by"
                const newID = id == 1 ? sortByActiveParam : id

                if (id != sortByActiveParam) {
                    setActiveSortOption({ text, id })
                    onCategoryChange(sortByParam, newID, false)
                }
            }
        },
        [hgt, sortByParamKey]
    )

    //! Sort By Mobile Action
    const sortByMobileAction = useCallback(
        (open, cancel) => {
            if (open) {
                setActiveClassFilter('active')
                document.body.classList.add('hidden')
            } else if (cancel) {
                const param = sortByActiveParam
                const text = Object.keys(sortList).find((key) => sortList[key] == param) || 'sortByFeatured'
                const id = sortList[text]
                setActiveClassFilter('')
                setActiveSortOption({ text, id })
                document.body.classList.remove('hidden')
            } else {
                setActiveClassFilter('')
                sortByChange(activeSortOption.text, activeSortOption.id)
                document.body.classList.remove('hidden')
            }
        },
        [activeSortOption, sortByActiveParam, sortList]
    )

    //! Sort By List
    const sortByList = useMemo(() => {
        if (sortList) {
            const keys = Object.keys(sortList)

            return keys?.map((i, k) => (
                <Text
                    key={k}
                    tag={'p'}
                    onClick={() => (winWidth > 1023 ? sortByChange(i, sortList[i]) : setActiveSortOption({ text: i, id: sortList[i] }))}
                    className={`option pM MonRegular black ${winWidth <= 1023 && activeSortOption.text === i ? 'MonMedium' : 'MonRegular'}`}
                    text={i}
                />
            ))
        }
    }, [sortList, winWidth, activeSortOption, sortByChange])

    return (
        <SortByStyle>
            {winWidth > 1023 ? (
                <div className='sort-by' ref={sortRef}>
                    <Text tag={'p'} className={'title pS MonRegular uppercase darkGrey'} text={'sortBy'} />
                    <div className='sort-block'>
                        <div className={`selected-option ${hgt && 'active'}`} onClick={() => sortByChange()}>
                            <Text tag={'p'} className={'pM MonRegular black'} text={activeSortOption.text} />
                            <Icon className={'icon-arrow-down'} />
                        </div>
                        <Collapse in={hgt} timeout='auto' className='sort-options'>
                            {sortByList}
                        </Collapse>
                    </div>
                </div>
            ) : (
                <>
                    <Button onClick={() => sortByMobileAction(true)} className='sort-btn' ariaLabel={'sort'}>
                        <Text tag={'p'} className={'pM black capitalize MonMedium'} text={'sort'} />
                        <Icon className='icon-sort' />
                    </Button>
                    <div className={`sort-by ${activeClassFilter}`}>
                        <Text tag={'p'} className={'head-title pS MonRegular uppercase darkGrey'} text={'sortBy'} />
                        <div className='sort-options'>{sortByList}</div>
                        <div className='btns'>
                            <Button onClick={() => sortByMobileAction(false, true)} className='btn btn-white capitalize' text={'reset'} />
                            <Button onClick={() => sortByMobileAction(false)} className='btn btn-black capitalize' text={'confirm'} />
                        </div>
                    </div>
                </>
            )}
        </SortByStyle>
    )
})

export default withUIContext(Sortby, ['winWidth'])
