import React, { memo as Memo, useMemo } from 'react'

//* HOCs
import { withDataContext, withLanguageContext } from 'context'

//*component
import { Icon } from 'components/common'

//style
import ShareStyle from './style'

const SocIcon = Memo(({ global, selectedLang, ...props }) => {
    const data = useMemo(() => global?.[selectedLang], [global, selectedLang])

    return data ? (
        <ShareStyle className='socIconWrapp FlexBox alignMiddle'>
            {props.color === 'white' ? (
                <>
                    <a href={data.telegram} className='socIconItem FlexBox alignMiddle white' target='_blank' rel='noopener noreferrer' aria-label='telegram'>
                        <Icon className={'icon-telegram'} />
                    </a>
                    <a href={data.linkedin} className='socIconItem FlexBox alignMiddle white' target='_blank' rel='noopener noreferrer' aria-label='linkedin'>
                        <Icon className={'icon-linkedin'} />
                    </a>
                    <a href={data.facebook} className='socIconItem FlexBox alignMiddle white' target='_blank' rel='noopener noreferrer' aria-label='facebook'>
                        <Icon className={'icon-facebook'} />
                    </a>
                </>
            ) : (
                <>
                    <a href={data.facebook} className='socIconItem FlexBox alignMiddle black' target='_blank' rel='noopener noreferrer' aria-label='facebook'>
                        <Icon className={'icon-facebook'} />
                    </a>
                    <a href={data.linkedin} className='socIconItem FlexBox alignMiddle black' target='_blank' rel='noopener noreferrer' aria-label='linkedin'>
                        <Icon className={'icon-linkedin'} />
                    </a>
                    <a href={data.twitter} className='socIconItem FlexBox alignMiddle black' target='_blank' rel='noopener noreferrer' aria-label='twitter'>
                        <Icon className={'icon-twitter'} />
                    </a>
                    <a href={data.telegram} className='socIconItem FlexBox alignMiddle black' target='_blank' rel='noopener noreferrer' aria-label='telegram'>
                        <Icon className={'icon-telegram'} />
                    </a>
                </>
            )}
        </ShareStyle>
    ) : null
})

export default withLanguageContext(withDataContext(SocIcon, ['global']), ['selectedLang'])
