import { memo as Memo, useMemo, useState, useCallback, useEffect } from 'react'

//* Components
import { Slider } from 'antd'
import { Text } from 'components/common'
import { useRouter } from 'next/router'
import Collapse from '@material-ui/core/Collapse'

//* Helpers
import { getParamsByKey } from 'helpers'

const ProfitFilter = Memo(({ queryParamKeys, onCategoryChange }) => {
	//! Router
	const router = useRouter()

	//! States
	const [opened, setOpened] = useState(true)
	const [profitPercent, setProfitPercent] = useState([0, 100])

	//! Marks Object
	const marks = useMemo(() => ({ 0: '0%', 100: '100%' }), [])

	//! Get Category Param and Active Items
	const getParamWidthActiveItems = useMemo(() => {
		const catParam = queryParamKeys[4] //? "'margin_profitability'"
		const activeItems = getParamsByKey(router, catParam)

		return { catParam, activeItems }
	}, [queryParamKeys, router.query])

	//! Margin Profitability Filter
	const marginProfitabilityChange = useCallback(
		(value) => {
			const { catParam, activeItems } = getParamWidthActiveItems
			const newParam = value[0] === 0 && value[1] === 100 ? router.query[catParam] : `${value[0]},${value[1]}`

			if (!activeItems || (activeItems && (activeItems[0] != value[0] || activeItems[1] != value[1]))) {
				onCategoryChange(catParam, newParam, false)
			}
		},
		[queryParamKeys, profitPercent, router.query]
	)

	useEffect(() => {
		setProfitPercent(getParamWidthActiveItems.activeItems ? getParamWidthActiveItems.activeItems : [0, 100])
	}, [router.query])

	return (
		<div className={`single-filter overflowHidden ${opened ? 'active' : ''} profit`}>
			<Text tag={'h5'} onClick={() => setOpened(!opened)} className={'title h5 MonMedium black'} text={'filterProfit'} />
			<Collapse in={opened} timeout='auto'>
				<div className='selected'>
					<Text tag={'p'} className={'pM MonRegular ultraDarkGrey capitalize'} text={'selected'} />
					<Text tag={'p'} className={'pL MonMedium'} text={`${profitPercent[0]}% - ${profitPercent[1]}%`} />
				</div>
				<Slider
					range
					marks={marks}
					value={[profitPercent[0], profitPercent[1]]}
					onChange={(value) => setProfitPercent([value[0], value[1]])}
					onAfterChange={(value) => marginProfitabilityChange(value)}
				/>
			</Collapse>
		</div>
	)
})

export default ProfitFilter
