import styled from 'styled-components'

const BusinessStepStyle = styled.div.attrs(props => ({ className: props.className }))`
    section {
        .big-cont {
            display: flex;
            flex-direction:column;

            .top {
                display: flex;
                justify-content:space-between;

                .text {
                    display: flex;
                    width:calc(50% - var(--sp2x));
                }
            }

            .steps {
                margin-top:var(--sp12x);
                display: flex;
                flex-wrap:wrap;
                justify-content:space-between;

                .single-step {
                    width:calc(50% - var(--sp2x));
                    display: flex;
                    flex-direction:column;
                    padding:var(--sp5x);
                    background-color:var(--ultraLightGrey);
                    border-radius:var(--spSize);
                    transform: translateY(20vh);

                    &:nth-child(3),
                    &:nth-child(4){
                        margin-top:var(--sp4x);
                    }

                    .subtitle {
                        margin:var(--titleFromContent) 0 var(--sp10x);
                        flex:1;
                    }

                    .LottieWrapp {
                        padding-top:52%;
                    }
                }
            }
        }
    }

    @media screen and (max-width:${props => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width:${props => props.theme.mediaQuery.DesktopSizeS}){
        section {
            .big-cont {
                .steps {
                    margin-top:var(--sp10x);
                    
                    @media screen and (max-width:DesktopSizeS){
                        margin-top:var(--sp9x);
                    }

                    .single-step {
                        padding:var(--sp4x);

                        .subtitle {
                            margin:var(--titleFromContent) 0 var(--sp8x);
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width:${props => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width:${props => props.theme.mediaQuery.TabletSize}){
        section {
            .big-cont {
                .top {
                    flex-direction:column;

                    .text {
                        width:100%;

                        &:first-child {
                            margin-bottom:var(--sp2-5x);
                        }
                    }
                }

                .steps {
                    margin-top:var(--sp5x);


                    .single-step {
                        padding:var(--sp3x);
                        width:calc(50% - var(--sp1-5x));

                        &:nth-child(3),
                        &:nth-child(4){
                            margin-top:var(--sp3x);
                        }
                        
                        .subtitle {
                            margin:var(--titleFromContent) 0 var(--sp4x);
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width:${props => props.theme.mediaQuery.TabletSizeMin}){
        section {
            .big-cont {
                .top {
                    flex-direction:column;

                    .text {
                        width:100%;

                        &:first-child {
                            margin-bottom:var(--sp2x);
                        }
                    }
                }

                .steps {
                    margin-top:var(--sp5x);
                    flex-direction:column;

                    .single-step {
                        padding:var(--sp3x);
                        width:100%;

                        &:not(:first-child){
                            margin-top:var(--sp3x);
                        }
                        
                        .subtitle {
                            margin:var(--titleFromContent) 0 var(--sp3x);
                        }
                    }
                }
            }
        }
    }
`
export default BusinessStepStyle;