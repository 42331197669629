import React, { memo as Memo, useMemo } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Form } from 'antd'
import { Translate } from 'components/common'

//* Helpers
import formValidation from '../../../helpers/_formValidation'

const FormItem = Memo(
    ({ label, name, type, validationType, validateWith, required, min, max, message, autocomplete, extra, extraChild, rules = [], options = {}, onError, onSuccess, ...props }) => {
        const newRules = useMemo(() => {
            let r = [...rules]

            if (type == 'email') {
                r.push({ transform: (value) => value.trim() })
            }

            const typeObj = type ? { type } : ''
            r[0] = { ...r?.[0], message: '', required, ...typeObj }

            const params = r[0]


            if (required || validationType) {
                r[1] = ({ getFieldValue, setFields, ...aaa }) => ({
                    validator(_, value) {
                        const validType = validationType || params.type || 'string'
                        let validate

                        let settings
                        settings = min || typeof min === 'number' ? { ...settings, min: min } : settings
                        settings = max ? { ...settings, max: max } : settings

                        if (name === 'password_confirmation') {
                            validate = formValidation(Translate, { pass: getFieldValue('password'), c_pass: value }, 'c_password')

                            validate === true && setFields([{
                                name: 'password',
                                errors: [],
                            }])
                        }
                        else if (name === 'password' && validateWith === 'password_confirmation') {
                            validate = formValidation(Translate, { c_pass: getFieldValue('password_confirmation'), pass: value }, 'c_password')

                            validate === true && setFields([{
                                name: 'password_confirmation',
                                errors: [],
                            }])
                        }
                        else {
                            validate = formValidation(Translate, value, validType, message, settings)
                        }

                        if (validate === true) {
                            onSuccess && onSuccess()
                            return Promise.resolve()
                        }

                        onError && onError()
                        return Promise.reject(validate)
                    },
                })
            }

            return r
        })

        return (
            <Form.Item
                onChange={props.onChange}
                label={label && props.translate(label) + (required || validationType ? '*' : '')}
                hasFeedback
                extra={extra && (extraChild || props.translate(extra))}
                name={name}
                rules={newRules}
                {...options}
            >
                {React.cloneElement(props.children, { autoComplete: autocomplete || 'newpassword' })}
            </Form.Item>
        )
    }
)

export default withLanguageContext(FormItem, ['translate', 'selectedLang'])
