import styled from 'styled-components'

const PartnersSliderStyle = styled.div.attrs((props) => ({ className: props.className }))`
	margin-top: var(--containerMarginBottom);

	section {
		display: flex;
		flex-direction: column;

		&.inHome {
			& > .comp-title {
				margin-bottom: var(--titleFromContent);
			}

			.btn-cont {
				margin-top: var(--titleFromContent);
				align-self: center;
			}
		}

		.slider {
			position: relative;
			margin-top: var(--sp12x);
			.swiper-btn {
				width: var(--sp6x);
				height: var(--sp6x);
				background-color: var(--white);
				border-radius: 50%;
				box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
				font-size: var(--sp1-5x);
				display: inline-flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				top: calc(-1 * var(--titleFromContent));
				transform: translateY(-100%);
				z-index: 10;
				cursor: pointer;
				color: var(--black);
				transition: var(--trTime);

				&.swiper-button-disabled {
					color: var(--darkGrey);
				}

				&.prev {
					right: var(--sp8-5x);
				}

				&.next {
					right: var(--sp0-5x);
				}
			}
			.swiper-container {
				/* overflow: visible; */
				margin-left: calc(-1 * var(--sp4x));

				.swiper-wrapper {
					.swiper-slide a {
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 0 var(--sp4x);

						.imageCont {
							width: calc(100% - var(--sp6x));
							padding-top: calc(100% - var(--sp6x));

							img {
								object-fit: contain;
							}
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		section {
			.slider {
				margin-top: var(--sp10x);

				.swiper-container {
					.swiper-wrapper {
						.swiper-slide a {
							.imageCont {
								width: calc(100% - var(--sp5x));
								padding-top: calc(100% - var(--sp5x));
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		section {
			.slider {
				margin-top: var(--sp7-5x);

				.swiper-container {
					.swiper-wrapper {
						.swiper-slide a {
							.imageCont {
								width: calc(100% - var(--sp5x));
								padding-top: calc(100% - var(--sp5x));
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		section {
			.slider {
				margin-top: var(--sp5x);

				.swiper-container {
					.swiper-wrapper {
						.swiper-slide a {
							.imageCont {
								width: calc(100% - var(--sp5x));
								padding-top: calc(100% - var(--sp5x));
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		overflow: hidden;

		section {
			.slider {
				margin-top: var(--sp9x);

				.swiper-container {
					margin-left: calc(-1 * var(--sp2-5x));
					.swiper-wrapper {
						.swiper-slide a {
							padding: 0 var(--sp2-5x);
							.imageCont {
								width: calc(100% - var(--sp3x));
								padding-top: calc(100% - var(--sp3x));
							}
						}
					}

					.swiper-btn {
						width: var(--sp5x);
						height: var(--sp5x);

						&.prev {
							right: var(--sp7-5x);
						}

						&.next {
							right: var(--sp0-5x);
						}
					}
				}
			}
		}
	}
`
export default PartnersSliderStyle
