import styled from 'styled-components'

const MapInCatalogueStyle = styled.div.attrs({ className: 'map-block' })`
	margin-bottom: var(--sp7x);
	overflow: hidden;

	.map {
		width: 100%;
		margin-bottom: var(--sp2x);
		display: flex;
		flex-direction: column;
		transform: translateY(30vh);
		& > div {
			padding-top: var(--sp10x);
		}
	}
	.close-map {
		transform: translateY(30vh);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		.map {
			& > div {
				padding-top: var(--sp6x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		.map {
			& > div {
				padding-top: var(--sp7-5x);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		margin-bottom: var(--sp5x);

		.map {
			& > div {
				padding-top: var(--sp5x);
			}
		}
	}
`
export default MapInCatalogueStyle
