export default {
    colors: {
        //primary colors

        black: "#1f1f1f",
        white: "#fff",
        ultraDarkGrey: "#6c6c6c",
        darkGrey: "#AFAFAF",
        ultraLightGrey: "#f7f7f7",
        lightGrey: "#e6e6e6",
        extraLightGrey: "#EBEBEB",
        blue: "#0066FF",
        lightBlue: "#66CCFF",
        yellow: "#FDD301",
        orange: "#FA943D",
        coral: "#FA5D5E",
        green: "#009966",
        pink: "#FFABAB",

        //secondary colors

        secBlue: "#64A2FF",
        secLightBlue: "#9cd9f8",
        secGreen: "#A3DE83",
        secLightGreen: "#95E7CF",
        secOrange: "#ffbf8c",
        secYellow: "#fde467",
        secLightYellow: "#F5F991",

        //error color
        errColor: "#DE0101"
    },

    mediaQuery: {
        DesktopSizeL: "2560px",
        DesktopSizeM: "1920px",
        DesktopSizeS: "1440px",
        LaptopSize: "1024px",
        TabletSize: "768px",
        MobileSize: "601px",

        DesktopSizeLMin: "2559px",
        DesktopSizeMMin: "1919px",
        DesktopSizeSMin: "1439px",
        LaptopSizeMin: "1023px",
        TabletSizeMin: "767px",
        MobileSizeMin: "600px",
    }
}