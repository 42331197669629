import React, { useMemo, forwardRef, useRef, useImperativeHandle, useEffect, useState } from 'react'
import { withUIContext, withLanguageContext } from 'context'
import { Image, Text, Button, Container, LottieAnimation } from 'components/common'
import AboutInvestStyle from './style'

import homeDesctop from 'lottieAnimation/home/top/homeDesctop.json'
import homeMobile from 'lottieAnimation/home/top/homeMobile.json'

import { gsap, TimelineLite, TweenMax } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
gsap.core.globals('ScrollTrigger', ScrollTrigger)

const AboutInvest = forwardRef((props, ref) => {
    const [playAnime, setPlayAnime] = useState(false)

    const titleTopRef = useRef()
    const titleRef = useRef()
    const descriptionRef = useRef()
    const btnRef = useRef()

    const WomenTopRef = useRef()
    const ManLeftRef = useRef()
    const ManRightRef = useRef()

    const tl = useMemo(() => new TimelineLite({ repeat: false, paused: true }), []);

    useEffect(() => {
        setPlayAnime(false)
        return tl.progress(0).clear()
    }, [])

    useEffect(() => {
        TweenMax.to(
            titleTopRef.current,
            {
                scrollTrigger: {
                    trigger: titleTopRef.current,
                    start: 'top center',
                    onEnter: () => {
                        setPlayAnime(true)
                    },
                },
            }
        )
    }, [props.preloader])

    useEffect(() => {
        tl.to(
            [WomenTopRef.current[0].firstChild, WomenTopRef.current[0].firstChild.firstChild],
            {
                scale: 1,
                duration: 0.9,
                ease: 'power4.out',
            },
            2
        ).to(
            ManRightRef.current[0].firstChild,
            {
                y: 0,
                duration: 0.9,
                ease: 'power4.out',
            },
            2.5
        ).to(
            ManLeftRef.current[0].firstChild,
            {
                x: 0,
                duration: 1,
                ease: 'power4.out',
            },
            2.5
        )

        if (playAnime) {
            tl.play();
        }
    }, [playAnime])


    useImperativeHandle(ref, () => [titleTopRef.current.childNodes, titleRef.current.childNodes, descriptionRef, btnRef], [])

    return (
        <AboutInvestStyle className='about-invest overflowHidden'>
            <Container className='full FlexBox alignMiddle'>
                <div className='content FlexBoxColumn'>
                    <Text
                        tag={'stringToLetters'}
                        className={` title titleTop h1L MonBold uppercase ${playAnime ? 'active' : ''}`}
                        text={'INVEST IN AM'}
                        ref={titleTopRef}
                    />
                    <div className="overflowHidden">
                        <Text tag={'stringToLettersH1'} className={'title titleBig h1L MonRegular uppercase'} text={props.title} ref={titleRef} />
                        <Text tag={'h2'} className={'description h4 MonRegular'} text={props.description} ref={descriptionRef} />
                        <Button link={props.url} yellowIcon={true} text={'ourServices'} ref={btnRef} wrappClassName={'otherElementAnimation'} />
                    </div>
                </div>

                <div className='LottieAnimationSection'>
                    {
                        (props.winWidth > 1279 || props.winWidth < 768) &&
                        <LottieAnimation animationJSON={homeDesctop} loop={false} isStopped={!playAnime} />
                    }

                    {
                        (props.winWidth <= 1279 && props.winWidth > 767) &&
                        <LottieAnimation animationJSON={homeMobile} loop={false} isStopped={!playAnime} />
                    }

                    <Image className='imgTop' src={props.imgTop} alt={props.title} ref={WomenTopRef} />
                    <Image className='imgLeft' src={props.imgLeft} alt={props.title} ref={ManLeftRef} />
                    <Image className='imgRight' src={props.imgRight} alt={props.title} ref={ManRightRef} />
                </div>
            </Container>
        </AboutInvestStyle>
    )
})

export default withUIContext(AboutInvest, ['winWidth', 'preloader'])
