import { memo as Memo, useMemo, useState, useCallback } from 'react'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'

//* Components
import { Checkbox, Select, Button, Upload, Tooltip } from 'antd'
import { FormItem, Icon, Text, FormInput } from 'components/common'

const SubmitExportProductItem = Memo(({ form, field, categories, subcategories, length, saveProduct, remove, ...props }) => {
    //! States
    const [selectedCat, setSelectedCat] = useState()
    const [saved, setSaved] = useState(false)
    const [images, setImages] = useState([])
    const [certification, setCertification] = useState(0)

    //! Subcategory Items List
    const subcategoryItems = useMemo(() => {
        return subcategories?.[selectedCat]?.map((i, k) => (
            <Select.Option key={k} value={i.id}>
                {i.name}
            </Select.Option>
        ))
    }, [subcategories, selectedCat])

    //! Validate and Save Fields Group
    const saveFieldsGroup = useCallback(() => {
        form.current
            .validateFields([
                ['products', field.name, 'parent_category'],
                ['products', field.name, 'category_id'],
                ['products', field.name, 'name'],
                ['products', field.name, 'unit_price'],
                ['products', field.name, 'unit_price_currency'],
                ['products', field.name, 'minimum_order'],
                ['products', field.name, 'photos'],
            ])
            .then(() => {
                setSaved(true)
                saveProduct()
            })
    }, [form, field, saveProduct])

    //! Category On Change
    const categoryOnChange = useCallback((catId) => setSelectedCat(catId), [])

    //! Uploader Fake Request
    const fakeRequest = useCallback(({ onSuccess }) => {
        onSuccess('ok')
    }, [])

    //! Uploader On Change
    const imagesOnChange = useCallback(
        ({ fileList }) => {
            const { products } = form.current.getFieldsValue()
            setImages(fileList)

            Object.assign(products[field.name], { photos: fileList.map((f) => f.originFileObj) })
            form.current.setFieldsValue({ products })
        },
        [form, field]
    )

    //! Checkbox On Change
    const checkbocOnChange = useCallback(
        (e) => {
            const { products } = form.current.getFieldsValue()
            setCertification(e.target.checked)

            Object.assign(products[field.name], { certification: e.target.checked ? 1 : 0 })
            form.current.setFieldsValue({ products })
        },
        [form, field]
    )

    return (
        <div className={`SubmitExportProductItem ${props.winWidth < 1440 ? 'FlexBoxContainer spaceBetween' : ''} ${saved ? 'disabled-product' : ''}`}>
            <div className='closeWrapp FlexBox justifyEnd'>
                <div
                    className='close FlexBox alignMiddle alignCenter cursorPointer'
                    style={length === 1 || saved ? { opacity: 0, pointerEvents: 'none' } : {}}
                    onClick={() => length !== 1 && remove(field.name)}
                >
                    <Icon className='icon-close black' />
                </div>
            </div>

            <div className={`FormCol ${props.winWidth < 1440 ? 'FormCol-50' : ''}`}>
                <FormItem required label={'ProductCategory'} name={[field.name, 'parent_category']} fieldKey={[field.fieldKey, 'parent_category']}>
                    <Select onChange={categoryOnChange} suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />} allowClear>
                        {categories?.map((i, k) => (
                            <Select.Option key={k} value={i.id}>
                                {i.name}
                            </Select.Option>
                        ))}
                    </Select>
                </FormItem>
            </div>

            <div className={`FormCol ${props.winWidth < 1440 ? 'FormCol-50' : ''}`}>
                <FormItem required label={'ExportProductType'} name={[field.name, 'category_id']} fieldKey={[field.fieldKey, 'category_id']}>
                    <Select suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />} disabled={!selectedCat} allowClear>
                        {subcategoryItems}
                    </Select>
                </FormItem>
            </div>

            <div className={`FormCol ${props.winWidth < 1440 ? 'FormCol-50' : ''}`}>
                <FormItem required label={'ProductName'} name={[field.name, 'name']} fieldKey={[field.fieldKey, 'name']}>
                    <FormInput />
                </FormItem>
            </div>

            <div className={`FormCol ${props.winWidth < 1440 ? 'FormCol-50' : ''}`}>
                <FormItem
                    required
                    label={'StartingPricePerUnit'}
                    name={[field.name, 'unit_price']}
                    fieldKey={[field.fieldKey, 'unit_price']}
                    validationType='number'
                    min={1}
                >
                    <FormInput
                        type='number'
                        addonBefore={
                            <FormItem name={[field.name, 'unit_price_currency']}>
                                <Select suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />} className='select-before'>
                                    <Select.Option value='USD'>$</Select.Option>
                                    <Select.Option value='AMD'>֏</Select.Option>
                                </Select>
                            </FormItem>
                        }
                    />
                </FormItem>
            </div>

            <div className={`FormCol ${props.winWidth < 1440 ? 'FormCol-50' : ''}`}>
                <FormItem required label={'MinimumOrder'} name={[field.name, 'minimum_order']} fieldKey={[field.fieldKey, 'minimum_order']}>
                    <FormInput />
                </FormItem>
            </div>

            <div className={`FormCol ${props.winWidth < 1440 ? 'FormCol-50' : ''}`}>
                <FormItem label={'Certifications'} name={[field.name, 'certification']} fieldKey={[field.fieldKey, 'certification']}>
                    {/* <Checkbox.Group> */}
                    <Checkbox checked={certification} onChange={checkbocOnChange}>
                        <Text tag={'p'} className={'pL MonRegular ultraDarkGrey '} text={'Available'} />
                    </Checkbox>
                    {/* </Checkbox.Group> */}
                </FormItem>
            </div>

            <div className='FormCol uploader-col'>
                <FormItem
                    label={'UploadImagesProduct'}
                    name={[field.name, 'photos']}
                    fieldKey={[field.fieldKey, 'photos']}
                    required={true}
                    validationType='uploader'
                >
                    <Upload
                        accept='image/png, image/jpeg'
                        listType='picture-card'
                        fileList={images}
                        maxCount={5}
                        multiple={true}
                        customRequest={fakeRequest}
                        onChange={imagesOnChange}
                    >
                        <Icon className='icon-addFile'>
                            <span className='path1' />
                            <span className='path2' />
                        </Icon>
                    </Upload>
                </FormItem>
            </div>

            <div className={`FormCol ${props.winWidth < 1440 ? 'FormCol-50' : ''}`}>
                <div className='relativeWrapp'>
                    <Tooltip title={<div dangerouslySetInnerHTML={{ __html: props.translate('submitExportProductDetailsTooltip') }} />}>
                        <div className='HelperDesc'>
                            <Icon className='icon-quastion'>
                                <span className='path1' />
                                <span className='path2' />
                            </Icon>
                        </div>
                    </Tooltip>
                    <FormItem required={true} label={'details'} name={[field.name, 'details']} fieldKey={[field.fieldKey, 'details']}>
                        <FormInput htmlType="textarea" />
                    </FormItem>
                </div>
            </div>

            <div className={'formBtnWrapp FlexBoxContainer'}>
                <div className='btnInline'>
                    <Button className={`btn btn-black ${saved ? 'disabled' : ''}`} onClick={!saved && saveFieldsGroup}>
                        {props.translate(saved ? 'saved' : 'Save')}
                    </Button>
                </div>
                {saved && length !== 1 && (
                    <span className='FlexBox alignMiddle'>
                        <span className='pM MonMedium coral underline cursorPointer' onClick={() => remove(field.name)}>
                            {props.translate('delete')}
                        </span>
                    </span>
                )}
            </div>
        </div>
    )
})

export default withUIContext(withLanguageContext(SubmitExportProductItem, ['translate', 'selectedLang']), ['screenSizes', 'winWidth'])
