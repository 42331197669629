import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle, useCallback } from 'react'
import { Text, Button, Container, LottieAnimation } from 'components/common'
import WhyArmeniaStyle from './style'

import animationJSON1 from 'lottieAnimation/home/whyArmenia/anime1.json'
import animationJSON2 from 'lottieAnimation/home/whyArmenia/anime2.json'
import animationJSON3 from 'lottieAnimation/home/whyArmenia/anime3.json'
import animationJSON4 from 'lottieAnimation/home/whyArmenia/anime4.json'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
gsap.core.globals('ScrollTrigger', ScrollTrigger)

const WhyArmenia = forwardRef((props, ref) => {
    const titleRef = useRef()
    const btnRef = useRef()
    const imgRef = useRef()

    useImperativeHandle(ref, () => [titleRef.current.childNodes, btnRef, imgRef.current.childNodes], [])

    const [playAnime, setPlayAnime] = useState({
        sec1: true,
        sec2: true,
        sec3: true,
        sec4: true,
    })

    const sectionAnime = useCallback(() => {

        let newObj = playAnime
        const elTop = imgRef?.current?.getBoundingClientRect()
        const pointStart = window.innerHeight

        if (elTop?.top < pointStart) {
            Object.values(playAnime).forEach((element, i) => {
                newObj = { ...newObj, [`sec${i + 1}`]: false }
            })
            setPlayAnime(newObj)
        }

    }, [playAnime])

    useEffect(() => {
        window.addEventListener('scroll', sectionAnime)

        return () => {
            window.removeEventListener('scroll', sectionAnime)
        }
    }, [sectionAnime])

    return (
        <WhyArmeniaStyle className='why-armenia'>
            <Container>
                <Text tag={'stringToLetters'} className={'title h1 MonBold'} text={props.title} ref={titleRef} />
                <Button link={props.url} wrappClassName={'otherElementAnimation'} yellowIcon={true} text={'moreAboutArmenia'} ref={btnRef} />
                <div className='fields FlexBox spaceBetween' ref={imgRef}>
                    <div className='single-field FlexBoxColumn'>
                        <LottieAnimation animationJSON={animationJSON1} loop={false} isStopped={playAnime.sec1} />
                        <Text tag={'h3'} className={'text h5 MonRegular'} text={props.whyArm1} />
                    </div>
                    <div className='single-field FlexBoxColumn '>
                        <LottieAnimation animationJSON={animationJSON2} loop={false} isStopped={playAnime.sec2} />
                        <Text tag={'h3'} className={'text h5 MonRegular'} text={props.whyArm2} />
                    </div>
                    <div className='single-field FlexBoxColumn '>
                        <LottieAnimation animationJSON={animationJSON3} loop={false} isStopped={playAnime.sec3} />
                        <Text tag={'h3'} className={'text h5 MonRegular'} text={props.whyArm3} />
                    </div>
                    <div className='single-field FlexBoxColumn '>
                        <LottieAnimation animationJSON={animationJSON4} loop={false} isStopped={playAnime.sec4} />
                        <Text tag={'h3'} className={'text h5 MonRegular'} text={props.whyArm4} />
                    </div>
                </div>
            </Container>
        </WhyArmeniaStyle>
    )
})

export default WhyArmenia
