import styled from 'styled-components'

const SingleServiceStyle = styled.div.attrs((props) => ({ className: props.className }))`
	display: flex;
	justify-content: center;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: var(--containerMarginBottom);
	}

	&:nth-child(even) {
		.img-block .img {
			.backColor {
				background-color: var(--lightBlue);
				left: var(--sp4x);
				top: var(--sp4x);
			}
		}
	}

	&:nth-child(3) {
		.img-block .img {
			.backColor {
				background-color: var(--green);
			}
		}
	}

	&:nth-child(4) {
		.img-block .img {
			.backColor {
				background-color: var(--coral);
			}
		}
	}

	&:nth-child(5) {
		.img-block .img {
			.backColor {
				background-color: var(--lightBlue);
			}
		}
	}

	&:nth-child(6) {
		.img-block .img {
			.backColor {
				background-color: var(--green);
			}
		}
	}

	&:nth-child(7) {
		.img-block .img {
			.backColor {
				background-color: var(--blue);
			}
		}
	}

	&:nth-child(even) {
		flex-direction: row-reverse;

		.img-block {
			margin-left: unset;
			margin-right: var(--sp20-5x);
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		max-width: var(--sp98x);

		& > .description {
			margin: var(--titleFromContent) auto var(--sp7x);
		}
		.icon-circle-arrow-left {
			transform: scale(0);
		}
	}

	.img-block {
		position: relative;
		max-width: var(--sp81-5x);
		width: 100%;
		margin-left: var(--sp20-5x);
		transform: translateY(20vh);

		.img {
			width: 100%;
			padding-top: 100%;
			position: relative;

			.backColor {
				content: '';
				position: absolute;
				top: calc(-1 * var(--sp4x));
				left: calc(-1 * var(--sp4x));
				width: 95.3%;
				padding-top: 95.3%;
				background-color: var(--yellow);
				border-radius: 50%;
				transition: all 2s;
			}

			.imageCont {
				position: absolute;
				width: 95.3%;
				padding-top: 95.3%;
				top: 0;
				left: 0;

				img {
					border-radius: 50%;
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		&:nth-child(even) {
			.img-block .img {
				.backColor {
					top: var(--sp3x);
					left: var(--sp3x);
				}
			}
		}

		&:nth-child(even) {
			.img-block {
				margin-right: var(--sp18-5x);
			}
		}

		.content {
			max-width: var(--sp83x);

			& > .description {
				margin-bottom: var(--sp6x);
			}
		}

		.img-block {
			margin-left: var(--sp18-5x);
			max-width: var(--sp68-5x);

			.img {
				.backColor {
					left: calc(-1 * var(--sp3x));
					top: calc(-1 * var(--sp3x));
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		&:nth-child(even) {
			.img-block .img {
				.backColor {
					left: var(--sp3x);
					top: var(--sp2x);
				}
			}
		}

		&:nth-child(even) {
			.img-block {
				margin-right: var(--sp4x);
			}
		}

		.content {
			max-width: var(--sp68-5x);

			& > .description {
				margin-bottom: var(--sp4-5x);
			}
		}

		.img-block {
			margin-left: var(--sp4x);
			max-width: var(--sp68-5x);

			.img {
				.backColor {
					left: calc(-1 * var(--sp3x));
					top: calc(-1 * var(--sp3x));
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		&:nth-child(even) {
			.img-block .img {
				.backColor {
					left: var(--sp2x);
					top: var(--sp2-5x);
				}
			}
		}

		&:nth-child(even) {
			.img-block {
				margin-right: var(--sp1-5x);
			}
		}

		.content {
			max-width: var(--sp57-5x);

			& > .description {
				margin-bottom: var(--sp4x);
			}
		}

		.img-block {
			margin-left: var(--sp1-5x);
			max-width: var(--sp59x);

			.img {
				.backColor {
					left: calc(-1 * var(--sp2-5x));
					top: calc(-1 * var(--sp2-5x));
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		flex-direction: column-reverse;

		&:nth-child(even) {
			flex-direction: column-reverse;
		}

		&:nth-child(even) {
			.img-block .img {
				.backColor {
					left: var(--sp2x);
					top: var(--sp2-5x);
				}
			}
		}
		&:nth-child(even) {
			.img-block {
				margin-right: 0;
			}
		}

		.content {
			max-width: unset;

			& > .description {
				margin-bottom: var(--sp4x);
			}
		}

		.img-block {
			margin-left: 0;
			max-width: var(--sp41-5x);
			margin-bottom: var(--sp4x);
			align-self: flex-start;

			.img {
				.backColor {
					top: calc(-1 * var(--sp2-5x));
					left: calc(-1 * var(--sp2-5x));
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		flex-direction: column-reverse;

		&:nth-child(even) {
			flex-direction: column-reverse;
		}

		&:nth-child(even) {
			.img-block .img {
				.backColor {
					left: var(--sp0-75x);
					top: var(--spSize);
				}
			}
		}

		&:nth-child(even) {
			.img-block {
				margin-right: 0;
			}
		}

		.content {
			max-width: unset;

			& > .description {
				margin-bottom: var(--sp3-5x);
			}
		}

		.img-block {
			margin-left: 0;
			max-width: 48.4%;
			margin-bottom: var(--sp3-5x);
			align-self: flex-start;

			.img {
				.backColor {
					left: calc(-1 * var(--spSize));
					top: calc(-1 * var(--spSize));
				}
			}
		}
	}
`
export default SingleServiceStyle
