import { useState, useMemo, useCallback, forwardRef, useRef, useImperativeHandle } from 'react'

//* HOC's
import { withUIContext, withLanguageContext, withDataContext } from 'context'

//* Components
import { Text, FormContainer, FormItem, Icon, Image, Button } from 'components/common'
import { ExportWarningPopup } from 'components/pages'
import { Select, Radio } from 'antd'

//* Helpers
import { formatNumber } from 'helpers'

//* Styles
import CalculatorFormStyle from './style'
import FormStyle from 'styles/pages/FormStyle'

const CalculatorForm = forwardRef(({ calcData, winWidth, potentialCalculatorCalculate, potentialCalculatorGetFile, openPopup, ...props }, ref) => {
	let data1 = [
		{
			title: 'CountryExport',
		},
		{
			title: 'ExportPotential',
		},
		{
			title: 'ActualExports',
		},
		{
			title: 'UntappedPotential',
		},
		{
			title: 'AppliedTariffArmenia',
		},
	]
	let data2 = [
		{
			title: 'NameTheProduct',
		},
		{
			title: 'ExportPotential',
		},
		{
			title: 'ActualExports',
		},
		{
			title: 'UntappedPotential',
		},
		{
			title: 'AppliedTariffForTheGiven',
		},
		{
			title: 'CountryOverallImport',
		},
		{
			title: 'ArmeniaExportOfAllCountries',
		},
	]

	//! Initaial Value
	const initialValue = useMemo(
		() => ({
			type: null,
			parent: null,
			child: null,
		}),
		[]
	)

	//! States
	const [state, setState] = useState(initialValue)
	const [data, setData] = useState()

	//! Ref's
	const form = useRef()
	const formRef = useRef()
	const topRef = useRef()
	const botRef1 = useRef()
	const botRef2 = useRef()

	//! Imperative Handle for Forward Refs
	useImperativeHandle(
		ref,
		() => [formRef.current.childNodes[0].childNodes[0], topRef.current, botRef1.current.childNodes, botRef2.current.childNodes],
		[state, form]
	)

	//! Parent Select Options
	const parentOptions = useMemo(() => {
		return calcData?.[state.type]?.parents.map((i, k) => (
			<Select.Option key={k} value={i.key}>
				{i.name}
			</Select.Option>
		))
	}, [calcData, state])

	//! Child Select Options
	const childOptions = useMemo(() => {
		return calcData?.[state.type]?.childs?.[state.parent]?.map((i, k) => (
			<Select.Option key={k} value={i.key}>
				{i.name}
			</Select.Option>
		))
	}, [calcData, state])

	//! Form on Change
	const onChange = useCallback(
		(val) => {
			let newState = { ...state }
			const name = Object.keys(val)[0]
			if (name === 'type') {
				newState = { ...initialValue, type: val[name] }
				setData('')
				form.current.setFieldsValue({ parent: '', child: '' })
			} else if (name === 'parent') {
				newState = { ...newState, parent: val[name], child: null }
				form.current.setFieldsValue({ child: '' })
			} else if (name === 'child') {
				setData('loading')
				newState = { ...newState, child: val[name] }

				potentialCalculatorCalculate({
					type: state.type,
					key: val[name],
				}).then((res) => {
					setData(res)
				})
			}
			setState(newState)
		},
		[state, form]
	)

	//! On Submit
	const onSubmit = useCallback(() => {
		state.child &&
			potentialCalculatorGetFile({
				type: state.type,
				key: state.child,
			}).then((res) => {
				window.location.href = `${res.redirect_url}`
			})
	}, [state])

	return (
		<CalculatorFormStyle className={`${data === 'loading' ? 'loading' : ''}`}>
			<FormStyle className={`calculatorFormSection `}>
				<div className='formCont ' ref={formRef}>
					<FormContainer ref={form} layout={'vertical'} className={'FormRow FlexBoxContainer spaceBetween'} onChange={onChange}>
						<div className='FormCol animationItem'>
							<FormItem label={props.translate('CalculateBy')} name={'type'}>
								<Radio.Group>
									<Radio.Button className='btn btn-grey' value='product'>
										{props.translate('prodType')}
									</Radio.Button>
									<Radio.Button className='btn btn-grey' value='country'>
										{props.translate('Country')}
									</Radio.Button>
								</Radio.Group>
							</FormItem>
						</div>
						<div className='FormCol FormCol-40 animationItem'>
							<FormItem label={props.translate(`${state.type || 'product'}CalculatorLabelParnet`)} name={'parent'}>
								<Select suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />} allowClear disabled={!state.type}>
									{parentOptions}
								</Select>
							</FormItem>
						</div>

						<div className='FormCol FormCol-60 animationItem'>
							<FormItem label={props.translate(`${state.type || 'product'}CalculatorLabelChild`)} name={'child'}>
								<Select suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />} allowClear disabled={!state.parent}>
									{childOptions}
								</Select>
							</FormItem>
						</div>
					</FormContainer>
				</div>
			</FormStyle>
			<div className='calculatorFormResult' ref={topRef}>
				{data?.filter &&
					(state.type == 'product' ? (
						<div className={'calculatorFormResultSection'}>
							<div className='calculatorFormResultTitleWrap FlexBoxContainer spaceBetween'>
								{data1.map((item, key) => {
									return (
										<Text
											key={key}
											tag={'div'}
											className={'calculatorFormResultTitle pL MonMedium black'}
											text={props.translate(item.title)}
										/>
									)
								})}
							</div>
							<div className='calculatorFormResultWrap'>
								{data?.filter?.map((item, key) => {
									return (
										<div key={key} className='calculatorFormResultItemWrap FlexBoxContainer spaceBetween'>
											<Text tag={'div'} className={'calculatorFormResultItem FlexBoxContainer alignMiddle MonBold pM black'}>
												<Image src={item.flag} alt={item.name} />
												{props.translate(item.name)}
											</Text>
											<Text
												tag={'div'}
												className={'calculatorFormResultItem MonBold pM black'}
												text={`${formatNumber(item.export_potential, 'en')}`}
											/>
											<Text
												tag={'div'}
												className={'calculatorFormResultItem MonBold pM black'}
												text={`${formatNumber(item.actual_exports, 'en')}`}
											/>
											<Text
												tag={'div'}
												className={'calculatorFormResultItem pL MonRegular black'}
												text={`${formatNumber(item.untapped_potential, 'en')}`}
											/>
											<Text
												tag={'div'}
												className={'calculatorFormResultItem pL MonRegular black'}
												text={`${item.applied_tariff}`}
											/>
										</div>
									)
								})}
							</div>
						</div>
					) : (
						<div className={'calculatorFormResultSection2'}>
							<div className='calculatorFormResultTitleWrap2 FlexBoxContainer spaceBetween'>
								{data2.map((item, key) => {
									return (
										<Text
											key={key}
											tag={'div'}
											className={'calculatorFormResultTitle2 pL MonMedium black'}
											text={props.translate(item.title)}
										/>
									)
								})}
							</div>
							<div className='calculatorFormResultWrap'>
								{data?.filter?.map((item, key) => {
									return (
										<div key={key} className='calculatorFormResultItemWrap2 FlexBoxContainer spaceBetween'>
											<Text
												tag={'div'}
												className={'calculatorFormResultItem2 MonBold pM black'}
												text={props.translate(item.name)}
											/>
											<Text
												tag={'div'}
												className={'calculatorFormResultItem2 MonBold pM black'}
												text={`${formatNumber(item.export_potential, 'en')}`}
											/>
											<Text
												tag={'div'}
												className={'calculatorFormResultItem2 MonBold pM black'}
												text={`${formatNumber(item.actual_exports, 'en')}`}
											/>
											<Text
												tag={'div'}
												className={'calculatorFormResultItem2 pL MonRegular black'}
												text={`${formatNumber(item.untapped_potential, 'en')}`}
											/>
											<Text
												tag={'div'}
												className={'calculatorFormResultItem2 pL MonRegular black'}
												text={`${item.applied_tariff}`}
											/>
											<Text
												tag={'div'}
												className={'calculatorFormResultItem2 pL MonRegular black'}
												text={`${formatNumber(item.overall_import, 'en')}`}
											/>
											<Text
												tag={'div'}
												className={'calculatorFormResultItem2 pL MonRegular black'}
												text={`${formatNumber(item.armenias_export, 'en')}`}
											/>
										</div>
									)
								})}
							</div>
						</div>
					))}
			</div>
			<div className='calculatorFormResultBottom' ref={botRef1}>
				<div>
					<Text className={'pM MonRegular black'} text={'CalculationResultBottomExtra'} />
				</div>
				<a href={'/downloadPDF/Methodology.pdf'} className='pL MonMedium black underline' download>
					{props.translate('Methodology')}
				</a>
			</div>
			<div className='TopCountries' ref={botRef2}>
				<Text className={'h3 MonRegular black'} text={'TopCountries'} />
				<Button
					onClick={() => openPopup(<ExportWarningPopup onSubmit={onSubmit} />)}
					className={`btn-white ${!state.child && 'disabled-opacity'}`}
					text={'UnlockFullCalculator'}
				/>
			</div>
		</CalculatorFormStyle>
	)
})

export default withLanguageContext(
	withUIContext(withDataContext(CalculatorForm, ['potentialCalculatorCalculate', 'potentialCalculatorGetFile']), ['openPopup']),
	['translate', 'selectedLang']
)
