import React, { forwardRef, useRef, useImperativeHandle, useCallback } from 'react'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'

//* Components
import { Text, Button, CustomLink, Icon } from 'components/common'
import { CategoryBtn, InvestmentProjectsSinglePopup } from 'components/pages'

//* Styles
import ProductInfoStyle from './style'
import { Tooltip } from 'antd'

const ProductInfo = forwardRef((props, ref) => {
    //! Refs
    const CategoryRef = useRef()
    const titleRef = useRef()
    const locationRef = useRef()
    const investRef = useRef()
    const projectOptionsRef = useRef()
    const btnRef = useRef()

    //! Imperative Handle
    useImperativeHandle(ref, () => [CategoryRef, titleRef, locationRef, investRef, projectOptionsRef, props.loggedIn && btnRef], [])

    //! Open Popup
    const PopupOpen = useCallback(() => {
        props.openPopup(
            <InvestmentProjectsSinglePopup countryAndQuantity={true} id={props.id} messageTo={`${props.companyName} ${props.companyType}`} />
        )
    }, [props.id, props.companyName, props.companyType])

    return (
        <ProductInfoStyle className='project-info'>
            <CategoryBtn inFilter={false} slug={props.categorySlug} bgColor={props.bgColor} text={props.categoryName} ref={CategoryRef} />
            <Text tag={'h2'} className={'project-title h2 MonBold'} text={props.title} ref={titleRef} />
            <div className='prod-company' ref={locationRef}>
                <Text tag={'p'} className={'prod-type pS MonMedium ultraDarkGrey'} text={props.productType} />
                <CustomLink url={props.companyURL} className='company-name'>
                    <Text tag={'p'} className={'pM MonRegular'} text={props.companyName} />
                    <Text tag={'p'} className={'pM MonRegular'} text={props.companyType} />
                </CustomLink>
            </div>
            <div className='start-price' ref={investRef}>
                <div className='option-title no-margin'>
                    <Text tag={'p'} className={'pL MonRegular uppercase ultraDarkGrey'} text={'startingPricePerUnit'} />
                    {props.productPriceTooltip &&
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: props.productPriceTooltip }} />}>
                            <div className='HelperDesc'>
                                <Icon className='icon-quastion'>
                                    <span className='path1' />
                                    <span className='path2' />
                                </Icon>
                            </div>
                        </Tooltip>
                    }
                </div>
                <div className='prices'>
                    <Text tag={'h4'} className={'h4 MonBold'} text={props.priceDollar} />
                    <Text tag={'h4'} className={'h4 MonRegular'} text={props.priceDram} />
                </div>
            </div>
            <div className='product-options' ref={projectOptionsRef}>
                <div className='product-interest option'>
                    <Text tag={'p'} className={'option-title pM MonRegular ultraDarkGrey'} text={'interestedToExport'} />
                    <Text tag={'p'} className={'option-text pM MonRegular black'} text={props.interestingToExport} />
                </div>
                <div className='product-market option'>
                    <Text tag={'p'} className={'option-title pM MonRegular ultraDarkGrey'} text={'alreadyExporting'} />
                    <Text tag={'p'} className={'option-text pM MonRegular black'} text={props.exportingCountries} />
                </div>
                <div className='product-export-years option'>
                    <Text tag={'p'} className={'option-title pM MonRegular ultraDarkGrey'} text={'exportExperience'} />
                    <div className='FlexBox'>
                        <Text tag={'p'} className={'option-text pM MonRegular black'} text={props.exportExperience} />
                        &nbsp;
                        <Text tag={'span'} className={'pM MonMedium black uppercase'} text={'year'} />
                    </div>
                </div>
                <div className='product-minimal-order option'>
                    <Text tag={'p'} className={'option-title pM MonRegular ultraDarkGrey'} text={'minimumOrder'} />
                    <Text tag={'p'} className={'option-text pM MonRegular black'} text={props.minimumOrder} />
                </div>

                <div className='product-export-details option full'>
                    {props.details ? (
                        <>
                            <Text tag={'p'} className={'option-title pM MonRegular ultraDarkGrey'} text={'details'} />
                            <div className='FlexBox'>
                                <Text tag={'p'} className={'option-text pM MonRegular black'} text={props.details} />
                            </div>
                        </>
                    ) : ''}
                </div>
            </div>
            {props.loggedIn && <Button ref={btnRef} className='btn btn-black' text={'contactWithCompany'} onClick={PopupOpen} />}
        </ProductInfoStyle>
    )
})

export default withUIContext(withLanguageContext(ProductInfo, ['translate']), ['openPopup'])
