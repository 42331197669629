import styled, { keyframes } from 'styled-components'

const hideAnime = keyframes`
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-100%);
  }
`

function createCSS() {
	let styles = ''

	for (let i = 0; i < 20; i += 1) {
		styles += `
	   	&:nth-child(${i}) {
			transition-delay: ${i / 20}s;
			animation-delay: ${i / 20}s;
		}
	   `
	}

	return `${styles}`
}

const PlanPricingStyle = styled.div.attrs((props) => ({ className: props.className }))`
	section {
		display: flex;
		flex-direction: column;

		.top {
			display: flex;
			justify-content: space-between;
			align-items: start;

			& > * {
				width: calc(50% - var(--sp2x));
			}
		}

		.bottom {
			display: flex;
			justify-content: space-between;
			margin-top: var(--sp12x);

			.invest-range-cont {
				width: calc(50% - var(--sp2x));
				background-color: var(--ultraLightGrey);
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				border-radius: 4px;
				padding: var(--sp5x);
				transform: translateY(20vh);

				.invest-ranges {
					width: 100%;
					display: flex;
					flex-direction: column;
					margin-top: var(--sp3x);

					.single-invest-range {
						background-color: var(--ultraLightGrey);
						border: 1px solid var(--lightGrey);
						padding: var(--sp4x);
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						transition: var(--trTime);
						width: 100%;
						border-radius: 4px;
						cursor: pointer;

						&:not(:first-child) {
							margin-top: var(--sp4x);
						}

						&.active {
							background-color: var(--lightGrey);

							* {
								color: var(--black);
							}
						}
					}
				}
			}

			.selected-range-price {
				width: calc(50% - var(--sp2x));
				background-color: var(--ultraLightGrey);
				position: relative;
				border-radius: 4px;
				transform: translateY(20vh);

				&-cont {
					display: flex;
					flex-direction: column;
					align-items: stretch;
					background-color: var(--ultraLightGrey);
					padding: var(--sp21x) var(--sp17x) 0;

					.priceWrapper {
						margin: var(--titleFromContent) 0 var(--sp5x);
					}

					.price {
						.wordWrap {
							text-align: center;
							line-height: 0;
							.letter {
								transform: translateY(100%);
								transition: all 0.3s ease-in-out;
								${createCSS()}
								line-height: var(--lineHeightXS);
							}
						}
						&:not(:first-child) {
							position: absolute;
							top: 0;
							left: 0;
							bottom: 0;
							right: 0;
						}
						&.hide {
							.letter {
								animation: ${hideAnime} 0.3s linear;
							}
						}
						&.active {
							.letter {
								transform: translateY(0%);
							}
						}
					}

					.desc {
						margin-bottom: var(--sp7x);
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						opacity: 0;

						* {
							font-family: MonRegular, MonRegularRu;
							font-size: var(--pL);
						}

						&.active {
							position: static;
							opacity: 1;
						}
					}
				}
			}
		}
	}

	@keyframes hideAnime {
		0% {
			transform: translateY(0vh);
		}
		100% {
			transform: translateY(-10vh);
		}
	}

	@keyframes activeAnime {
		0% {
			transform: translateY(10vh);
		}
		100% {
			transform: translateY(0vh);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		section {
			.bottom {
				margin-top: var(--sp10x);

				.invest-range-cont {
					padding: var(--sp4x);

					.invest-ranges {
						.single-invest-range {
							padding: var(--sp3x);

							&:not(:first-child) {
								margin-top: var(--sp3x);
							}
						}
					}
				}

				.selected-range-price {
					&-cont {
						padding: var(--sp12x) var(--sp10x) 0;

						.priceWrapper {
							margin: var(--titleFromContent) 0 var(--sp4x);
						}

						.desc {
							margin-bottom: var(--sp6x);
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		section {
			.bottom {
				margin-top: var(--sp7-5x);

				.invest-range-cont {
					padding: var(--sp4x);

					.invest-ranges {
						.single-invest-range {
							padding: var(--sp3x);

							&:not(:first-child) {
								margin-top: var(--sp3x);
							}
						}
					}
				}

				.selected-range-price {
					&-cont {
						padding: var(--sp12x) var(--sp7-5x) 0;

						.priceWrapper {
							margin: var(--titleFromContent) 0 var(--sp3-5x);
						}

						.desc {
							margin-bottom: var(--sp5x);
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.LaptopSize}) {
		section {
			.top {
				& > * {
					width: calc(50% + var(--sp1-5x));
				}
			}

			.bottom {
				margin-top: var(--sp5x);

				.invest-range-cont {
					padding: var(--sp3x);
					width: calc(50% - var(--sp1-5x));

					.invest-ranges {
						.single-invest-range {
							padding: var(--sp3x);

							&:not(:first-child) {
								margin-top: var(--sp3x);
							}
						}
					}
				}

				.selected-range-price {
					width: calc(50% - var(--sp1-5x));

					&-cont {
						padding: var(--sp11x) var(--sp5x) 0;

						.priceWrapper {
							margin: var(--titleFromContent) 0 var(--sp2-5x);
						}

						.desc {
							margin-bottom: var(--sp4x);
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.TabletSize}) {
		section {
			.top {
				flex-direction: column;

				& > :last-child {
					max-width: var(--sp64x);
					margin-top: var(--sp2-5x);
				}

				& > * {
					width: 100%;
				}
			}

			.bottom {
				margin-top: var(--sp5x);
				flex-direction: column;

				.invest-range-cont {
					padding: var(--sp3x);
					width: 100%;

					.invest-ranges {
						flex-flow: row wrap;
						justify-content: space-between;

						.single-invest-range {
							padding: var(--sp2-5x);
							width: calc(50% - var(--sp1-5x));

							&:nth-child(2) {
								margin-top: 0;
							}

							&:nth-child(3),
							&:nth-child(4) {
								margin-top: var(--sp3x);
							}
						}
					}
				}

				.selected-range-price {
					width: 100%;
					margin-top: var(--sp3x);

					&-cont {
						padding: var(--sp7-5x);

						align-items: center;

						.priceWrapper {
							margin: var(--titleFromContent) 0 var(--sp2-5x);
						}

						.desc {
							margin-bottom: var(--sp4x);
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		section {
			padding-right: 0;
			padding-left: 0;

			.top {
				flex-direction: column;
				padding: 0 var(--sp2x);

				& > :last-child {
					margin-top: var(--sp2x);
				}

				& > * {
					width: 100%;
				}
			}

			.bottom {
				margin-top: var(--sp2x);
				flex-direction: column;

				.invest-range-cont {
					padding: var(--sp2x);
					width: 100%;

					.invest-ranges {
						flex-flow: column;

						.single-invest-range {
							padding: var(--sp2x);
							width: 100%;

							&:not(:first-child) {
								margin-top: var(--sp2x);
							}
						}
					}
				}

				.selected-range-price {
					width: 100%;
					margin-top: var(--sp3x);

					&-cont {
						padding: var(--sp5x) var(--sp2x);

						.priceWrapper {
							margin: var(--titleFromContent) 0 var(--sp2x);
						}

						.desc {
							margin-bottom: var(--sp3-5x);
						}
					}
				}
			}
		}
	}
`
export default PlanPricingStyle
