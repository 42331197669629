import styled from 'styled-components'


const LanguageSwitcherStyle = styled.div`
    --langMarg:var(--sp5x);
    a{
        margin-right:var(--langMarg);
        &:last-child{
            margin-right: 0;
        }
    }

    @media only screen and (max-width: ${props => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${props => props.theme.mediaQuery.DesktopSizeM}) {
        --langMarg:var(--sp4x);
    }

    @media only screen and (max-width: ${props => props.theme.mediaQuery.DesktopSizeMMin}) {
        --langMarg:var(--sp3x);
    }

    @media only screen and (max-width: ${props => props.theme.mediaQuery.TabletSizeMin}) {
        margin-bottom:var(--sp3x);
    }

`

export default LanguageSwitcherStyle