import { createGlobalStyle } from 'styled-components'

const Typography = createGlobalStyle`

    @font-face {
        font-family: MonRegular;
        src: url("/fonts/Montserratarm-Regular.woff2") format("woff2"),
            url("/fonts/Montserratarm-Regular.woff") format("woff"),
            url('/fonts/Montserratarm-Regular.otf') format('opentype');
        font-display: swap;
        font-style: normal;
        font-weight: unset;
    }

    @font-face {
        font-family: MonRegularRu;
        src: url("/fonts/Montserrat-Regular.woff2") format("woff2"),
            url("/fonts/Montserrat-Regular.woff") format("woff"),
            url('/fonts/Montserrat-Regular.ttf') format('truetype');
        font-display: swap;
        font-style: normal;
        font-weight: unset;
    }

    @font-face {
        font-family: MonMedium;
        src: url("/fonts/Montserratarm-Medium.woff2") format("woff2"),
            url("/fonts/Montserratarm-Medium.woff") format("woff"),
            url('/fonts/Montserratarm-Medium.otf') format('opentype');
        font-display: swap;
        font-style: normal;
        font-weight: unset;
    }

    @font-face {
        font-family: MonMediumRu;
        src: url("/fonts/Montserrat-Medium.woff2") format("woff2"),
            url("/fonts/Montserrat-Medium.woff") format("woff"),
            url('/fonts/Montserrat-Medium.ttf') format('truetype');
        font-display: swap;
        font-style: normal;
        font-weight: unset;
    }

    @font-face {
        font-family: MonBold;
        src: url("/fonts/Montserratarm-Bold.woff2") format("woff2"),
            url("/fonts/Montserratarm-Bold.woff") format("woff"),
            url('/fonts/Montserratarm-Bold.otf') format('opentype');
        font-display: swap;
        font-style: normal;
        font-weight: unset;
    }

    @font-face {
        font-family: MonBoldRu;
        src: url("/fonts/Montserrat-Bold.woff2") format("woff2"),
            url("/fonts/Montserrat-Bold.woff") format("woff"),
            url('/fonts/Montserrat-Bold.ttf') format('truetype');
        font-display: swap;
        font-style: normal;
        font-weight: unset;
    }

    /* textarea,
    input[type="text"],
    input[type="button"],
    input[type="submit"] {
        -webkit-appearance: none;
        line-height: var(--lineHeightS) !important;
    } */


    /* Typography */

    * {
        box-sizing: border-box;
        -webkit-appearance: none;
        outline: none;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -webkit-font-smoothing: antialiased;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }

    input:-internal-autofill-selected {
        background-color: transparent !important;
        box-shadow: inset 1000px 1000px #fff;
    }

    input:focus::placeholder {
        color: transparent;
    }

    input {
        outline: none;
    }

    input:-webkit-autofill {
        -webkit-box-shadow: inset 0 0 0 50px #fff !important;
    }

    /* Change autocomplete styles in WebKit */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 2px solid var(--black);
        -webkit-text-fill-color: var(--black);
        color: var(--white);
        background-color: transparent;
        -webkit-box-shadow: 0 0 0 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    /* Change the white to any color ;) */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    }

    @-webkit-keyframes autofill {
        to {
            background: transparent;
        }
    }

    a {
        text-decoration: none;
        color: inherit;
        &:hover{
            color: initial;
        }
    }

    .no-margin {
        margin: 0 !important;
    }

    div[role=presentation]{
        z-index: 158 !important;
        &+div{
            z-index:111111;
        }
    }
    .ant-select-dropdown{
        z-index:111111;
    }

    .ant-form-item-has-feedback > .ant-select .ant-select-arrow, .ant-form-item-has-feedback > .ant-select .ant-select-clear, .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow, .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
        right: var(--sp2x);
    }

    .ant-tooltip {
        box-sizing: border-box;
        margin: 0;
        max-width: var(--sp50x);
    }
    .ant-tooltip-arrow{
        display:none;
    }
    .ant-tooltip-inner{
        padding: var(--sp2x) var(--sp1-3x);
        background-color:var(--white);
        color: var(--black);
        font-size: var(--pM);
        box-shadow: 0 0px 8px 2px rgb(0 0 0 / 16%);
        border-radius:var(--borderSm);  
        font-family: MonRegular,MonRegularRu;
    }

    //Text capitalize

    .uppercase {
        text-transform: uppercase;
    }

    .lowercase {
        text-transform: lowercase;
    }

    .capitalize {
        text-transform: capitalize;
    }

    .underline {
        text-decoration: underline;
    }

    .lineThrough{
        text-decoration: line-through;
    }

    .textAlignCenter {
        text-align:center;
    }
    .inlineBlock {
        display:inline-block;
    }

    //!Fonts

    .MonRegular {
        font-family: MonRegular,MonRegularRu;
    }

    .MonMedium {
        font-family: MonMedium,MonMediumRu;
    }

    .MonBold {
        font-family: MonBold,MonBoldRu;
    }

    //!Font Sizes

    .h1L {
        font-size: var(--h1L);
        margin: 0;
        line-height: var(--lineHeightS);
        font-weight: unset;
    }

    .h1,
    h1 {
        font-size: var(--h1);
        margin: 0;
        line-height: var(--lineHeightS);
        font-weight: unset;
        
    }

    .h2,
    h2 {
        font-size: var(--h2);
        margin: 0;
        line-height: var(--lineHeightS);
        font-weight: unset;
    }

    .h3,
    h3 {
        font-size: var(--h3);
        margin: 0;
        line-height: var(--lineHeightS);
        font-weight: unset;
    }

    .h4,
    h4 {
        font-size: var(--h4);
        margin: 0;
        line-height: var(--lineHeightS);
        font-weight: unset;
    }

    .h5,
    h5 {
        font-size: var(--h5);
        margin: 0;
        line-height: var(--lineHeight);
        font-weight: unset;
    }

    .h6,
    h6 {
        font-size: var(--h6);
        margin: 0;
        line-height: var(--lineHeight);
        font-weight: unset;
    }

    .pL,
    p {
        font-size: var(--pL);
        margin: 0;
        line-height: var(--lineHeight);
        font-weight: unset;
    }

    .pM {
        font-size: var(--pM);
        margin: 0;
        line-height: var(--lineHeight);
        font-weight: unset;
    }

    .pS {
        font-size: var(--pS);
        margin: 0;
        line-height: var(--lineHeight);
        font-weight: unset;
    }
`

export default Typography
