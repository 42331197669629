import React, { memo as Memo, useMemo } from 'react'
import Link from 'next/link'

import { withLanguageContext } from 'context'
import { withRouter } from 'next/router'

import LanguageSwitcherStyle from './style'

const LanguageSwitcher = Memo(({ selectLanguage, languages, ...props }) => {
	let primColor = props.color === 'white' ? 'darkGrey' : 'ultraDarkGrey'
	let primColorActive = props.color === 'white' ? 'white' : 'black'

	return (
		<LanguageSwitcherStyle className={` ${props.className ? props.className : ''} FlexBox alignMiddle`}>
			{Object.keys(languages).map((lang) => {
				return (
					<Link key={lang} href={props.router.asPath} locale={lang}>
						<a
							className={`${props.router.locale === lang ? primColorActive : primColor} ${lang === 'ru' ? 'MonMedium' : 'MonRegular'
								} pS uppercase`}
						>
							{languages[lang].params.name}
						</a>
					</Link>
				)
			})}
		</LanguageSwitcherStyle>
	)
})

export default withRouter(withLanguageContext(LanguageSwitcher, ['selectLanguage', 'languages']))
