import React, { memo as Memo } from 'react'
import { Text, Icon } from 'components/common'

import GlobalErrorStyle from './style.js'

const GlobalError = Memo((props) => {
	return (
		<GlobalErrorStyle className='globalErrorWrap FlexBoxContainer spaceBetween'>
			<div className='globalErrorIconWrap'>
				<Icon className='icon-attention FlexBox alignMiddle alignCenter'>
					<span className='path1' />
					<span className='path2' />
					<span className='path3' />
				</Icon>
			</div>
			<div className='globalErrorInfoWrap'>
				<Text tag={'h6'} className={'MonBold black'} text={props.title ? props.title : 'businessPlan'} />
				<Text tag={'p'} className={'MonRegular black'} text={props.text ? props.text : 'YourEmailHasBeenSuccessfully'} />
			</div>
		</GlobalErrorStyle>
	)
})

export default GlobalError
