import { memo as Memo, useCallback } from 'react'

//* HOC's
import { withAccountContext } from 'context'

//* Components
import { Text, Button } from 'components/common'

const ProductCatalogRow = Memo(({ catalog, ...props }) => {
	const renewGroup = useCallback(() => {
		// catalog.status === 'expired' &&
		props.renewExportProductsGroup({ group_id: catalog.id }).then((res) => {
			window.location.href = res.redirect_url
		})
	}, [])
	return (
		<div className='SubmittedProductsTopItem'>
			<div className='SubmittedProductsTopItemTablet tableItemWrapp FlexBoxContainer spaceBetween'>
				<div className='tableItem'>
					<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'SubmissionID'} />
					<Text tag={'p'} className={'pS MonRegular black '} text={catalog.submission_number} />
				</div>

				<div className='tableItem'>
					<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'SubmissionDate'} />
					<Text tag={'p'} className={'pS MonRegular black '} text={catalog.date} />
				</div>

				<div className='tableItem'>
					<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'SubmissionPlan2'} />
					<Text tag={'p'} className={'pS MonRegular black '} text={catalog.plan} />
				</div>

				<div className='tableItem'>
					<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'ProductCategory'} />
					<Text tag={'p'} className={'pS MonRegular black '} text={catalog.category} />
				</div>

				<div className='tableItem  FlexBoxContainer spaceBetween'>
					<div>
						<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'Status'} />
						<Text tag={'p'} className={'pS MonMedium green '} text={catalog.status} />
					</div>

					<Button className={`btn btn-black ${catalog.status !== 'expired' ? 'disabled' : ''}`} text={'Update'} onClick={renewGroup} />
				</div>
			</div>
		</div>
	)
})

export default withAccountContext(ProductCatalogRow, ['renewExportProductsGroup'])
