import React, { useEffect } from 'react'

//* HOC's
import { withUIContext, withAccountContext } from 'context'

//* Components
import { VerifyAccountPopup } from 'components/pages'

const ProtectRoute = ({ children, user, activateAccountWarning, /* isLoggedIn, actionType, */ openPopup }) => {
	//! Opening Popup When User Activate Account
	useEffect(() => {
		if (!user.verified) {
			openPopup(<VerifyAccountPopup warning />)
		}
	}, [])

	useEffect(() => {
		activateAccountWarning && openPopup(<VerifyAccountPopup warning />)
	}, [activateAccountWarning])

	// useEffect( () => {
	//     if ( !isLoggedIn && actionType !== 'logout' ) {
	//         return router.push( '/?c=login' );
	//     }
	// }, [ isLoggedIn ] )

	return children
}

export default withUIContext(withAccountContext(ProtectRoute, ['user', 'activateAccountWarning' /*'isLoggedIn', 'actionType'*/]), ['openPopup'])
