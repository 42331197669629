import React from 'react';

const stringToLetters = (text) => {
    let wordLength = text.split(" ");
    let arr = [];

    for (let i = 0; i < wordLength.length; i++) {
        arr.push(wordLength[i].split(""));
    }

    return (
        <span className='wordWrap'>
            {arr.map((i, k) => {
                return (
                    <span className='word' key={k}>
                        {i.map((item, key) => {
                            return (
                                <span className='letter' key={key}>
                                    {item}
                                </span>
                            )
                        })}
                    </span>
                )
            })}
        </span>
    )
}

export default stringToLetters;