import React, { Component, createContext } from 'react'

//* Hocs
import { withRouter } from 'next/router'

//* Helpers
import { routerToPathAndQuery } from 'helpers'

//* Global Variables
const errorsInitialState = {
    formError: {
        error: false,
        names: [],
        msg: {},
    },
    systemError: {
        error: false,
        msg: '',
    },
}

const UIContext = createContext(null)

class UIProvider extends Component {
    //! States
    state = {
        popupIsOpen: false,
        popupComponent: null,
        loading: false,
        winWidth: 1920,
        winHeight: 1080,
        screenSizes: {
            screen3XL: '',
            screen2XL: '',
            screenXL: '',
            screenL: '',
            screenM: '',
            screenS: '',
            screenXS: '',
        },
        colors: {},
        ...errorsInitialState,
    }

    //! Methods
    methods = {
        openPopup: this.openPopup.bind(this),
        openLoginPopup: this.openLoginPopup.bind(this),
        openRegisterPopup: this.openRegisterPopup.bind(this),
        openForgotPopup: this.openForgotPopup.bind(this),
        openSubscribePopup: this.openSubscribePopup.bind(this),
        closePopup: this.closePopup.bind(this),
        toggleLoading: this.toggleLoading.bind(this),
        addErrorMsg: this.addErrorMsg.bind(this),
        removeErrorMsg: this.removeErrorMsg.bind(this),
    }

    popups = ['login', 'register', 'forgot-password', 'subscribe']

    //! Screen Resize
    screenResize = () => {
        this.setState({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }

    //! Gett Media Screen Sizes from Css Variables
    getScreenSizes() {
        const root = getComputedStyle(document.documentElement)

        const screenSizes = {
            screen3XL: parseInt(root.getPropertyValue('--screen3XL')),
            screen2XL: parseInt(root.getPropertyValue('--screen2XL')),
            screenXL: parseInt(root.getPropertyValue('--screenXL')),
            screenL: parseInt(root.getPropertyValue('--screenL')),
            screenM: parseInt(root.getPropertyValue('--screenM')),
            screenS: parseInt(root.getPropertyValue('--screenS')),
            screenXS: parseInt(root.getPropertyValue('--screenXS')),
        }

        const colors = {
            black: root.getPropertyValue('--black').trim(),
            white: root.getPropertyValue('--white').trim(),
            color1: root.getPropertyValue('--color1').trim(),
            color2: root.getPropertyValue('--color2').trim(),
        }

        this.setState({ screenSizes, colors })
    }

    //! Open Popup
    openPopup(popupComponent = null) {
        if (typeof popupComponent === 'string' && this.popups.includes(popupComponent) && this.props.router.query?.c !== popupComponent) {
            const { pathname, query } = routerToPathAndQuery(this.props.router, 'c', popupComponent)
            this.props.router.push({ pathname, query }, undefined, { shallow: true })
        }


        this.setState({
            popupIsOpen: true,
            popupComponent,
        })
    }

    //! Toggle Loading
    toggleLoading(x) {
        this.setState({
            loading: x,
        })
    }

    //! Open Login Popup
    openLoginPopup() {
        this.openPopup(this.popups[0])
    }

    //! Open Register Popup
    openRegisterPopup() {
        this.openPopup(this.popups[1])
    }

    //! Open Forgot Password Popup
    openForgotPopup() {
        this.openPopup(this.popups[2])
    }

    //! Open Forgot Password Popup
    openSubscribePopup() {
        this.openPopup(this.popups[3])
    }

    //! Close Popup
    closePopup(scroll = false) {
        const { pathname, query } = routerToPathAndQuery(this.props.router, 'c')
        this.props.router.push({ pathname, query }, undefined, { scroll: false })

        this.setState({
            popupIsOpen: false,
            popupComponent: null,
        })
    }

    //! Query Popups Checking and Opening
    queryPopupsCheckAndOpen() {
        const popupQuery = this.props.router.query.c

        if (popupQuery && this.popups.includes(popupQuery) && this.state.popupComponent !== popupQuery) {
            this.openPopup(popupQuery)
        }
    }

    //! Query Popups Checking and Closing
    queryPopupsCheckAndClose(prevProps) {
        const popupQuery = this.props.router.query?.c

        if (!popupQuery && this.state.popupIsOpen && this.popups.includes(this.state.popupComponent)) {
            this.setState({
                popupIsOpen: false,
                popupComponent: null,
            })
        }
    }

    //! Add Error Message
    addErrorMsg(type, data) {
        if (type === 'form') {
            const errors = Object.fromEntries(Object.entries(data).map(([key, value]) => [key, value[0]]))

            this.setState({
                formError: {
                    error: true,
                    names: Object.keys(errors),
                    msg: errors,
                },
            })
        } else {
            this.setState({
                systemError: {
                    error: true,
                    msg,
                },
            })
        }
    }

    //! Remove Error Message
    removeErrorMsg(type) {
        type === 'form'
            ? this.setState({
                formError: {
                    error: false,
                    names: [],
                    msg: {},
                },
            })
            : this.setState({
                systemError: {
                    error: false,
                    msg: '',
                },
            })
    }

    //! Component Did Mount
    componentDidMount() {
        window.addEventListener('resize', this.screenResize)

        this.getScreenSizes()
        this.screenResize()

        this.queryPopupsCheckAndOpen()

        this.props.router.events.on('routeChangeComplete', this.queryPopupsCheckAndClose.bind(this))
    }

    //! Component Did Update
    componentDidUpdate(prevProps, prevState) {
        this.queryPopupsCheckAndOpen(prevProps)
    }

    //! Component Will Unmount
    componentWillUnmount() {
        window.addEventListener('resize', this.screenResize)
        document.removeEventListener('keydown', this.escKeydown, false)
        this.props.router.events.off('routeChangeComplete', this.queryPopupsCheckAndClose.bind(this))
    }

    render() {
        return <UIContext.Provider value={{ ...this.state, ...this.methods }}>{this.props.children}</UIContext.Provider>
    }
}

export default withRouter(UIProvider)
export const UIConsumer = UIContext.Consumer
