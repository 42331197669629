import styled from 'styled-components'


const BurgerStyle = styled.div`
    
    padding:var(--spSize) 0 var(--spSize) var(--spSize);

    .Burger{
        --burgerSize:var(--sp3-5x);
        --burgerheightSize:23px;

        cursor: pointer;
        width: var(--burgerSize);
        height: var(--burgerheightSize);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span{
            display:block;
            height:1px;
            width:100%;
            background-color:var(--black);
            transition:all var(--trTime3);
            
            &:first-child{
                transform-origin: 0% 0%;
            }
            &:last-child{
                transform-origin: 0% 0%;
            }
        }

        &.active{
            span{
                width: 124%;
                &:first-child{
                    transform:rotate(39deg);
                }
                &:nth-child(2){
                    opacity:0;
                    width: 100%;
                }
                &:last-child{
                    transform: rotate(-39deg);
                }
            }
        }
    }

`

export default BurgerStyle