import styled from 'styled-components'

const AboutInvestStyle = styled.div`
	section {
		padding-left: var(--containerPadding) !important;

		.content {
			max-width: var(--sp117x);
			width: 100%;
			margin-right: var(--sp18x);

			.titleTop {
				.word:nth-child(2) {
					position: relative;
					padding: 0 var(--spSize);
					transition-duration: var(--trTime);
					transition-delay: 1s;
					&:after{
						content:'';
						position:absolute;
						top:0;
						left:0;
						width:120%;
						height:100%;
						transform:translateX(-122%);
						transition-duration: var(--trTime);
						transition-delay: 1s;
						z-index:-1;
						background-color:var(--yellow) ;
					}
				}
				&.active{
					.word:nth-child(2) {
						color:var(--white);
						&:after{
							transform:translateX(0%);
						}
					}
				}
			}

			.titleBig {
				margin: var(--sp1-75x) 0 var(--titleFromContent);
			}

			.description {
				margin-bottom: var(--sp9x);
				max-width: var(--sp99x);
			}
		}

		.LottieAnimationSection{
			flex: 1;
			position:relative;
			.imgTop,.imgLeft,.imgRight{
				position:absolute;
				overflow:hidden;
				z-index: 1;
			}
			.imgTop{
			    top: 0;
				left: 0;
				padding: 21.25%;
				> * {
					border-radius: 110%;
					transform: scale(0);
					background-color:#67A4FC;
					transform-origin: center bottom;
				}
			}
			.imgLeft{
				bottom: 0%;
				left: 0%;
				padding: 21.28%;
				> * {
					transform: translateX(-100%);
				}
			}
			.imgRight{
				bottom: 18.5%;
				right: 0;
				padding: 21.25% 20.9%;
				> * {
					transform: translateY(100%);
				}
			}
			
			.LottieWrapp {
				height: auto;
				>div{
					height: auto;
					>div{
						display: flex;
					}
					svg {
    					position: static;
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		section {
			.content {
				max-width: var(--sp88x);

				.titleBig {
					margin-top: var(--spSize);
				}

				.description {
					margin-bottom: var(--sp8x);
					max-width: var(--sp75x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		section {
			.content {
				max-width: var(--sp77x);
				margin-right: var(--sp12x);

				.titleBig {
					margin-top: var(--spSize);
				}

				.description {
					margin-bottom: var(--sp7x);
					max-width: var(--sp68x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.LaptopSize}) {
		section {
			padding-right: 0 !important;
			.content {
				max-width: var(--sp64x);
				margin-right: var(--sp14x);

				.titleBig {
					margin-top: var(--sp2x);
				}

				.description {
					margin-bottom: var(--sp6x);
					max-width: var(--sp58x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		section {
			padding-right: 0 !important;
			flex-direction: column;

			.content {
				align-self: flex-start;
				max-width: var(--sp64x);
				margin-right: 0;

				.titleBig {
					margin-top: var(--sp2x);
				}

				.description {
					margin-bottom: var(--sp6x);
					max-width: var(--sp58x);
				}
			}

			.LottieAnimationSection {
				width: 52%;
				align-self: flex-end;
				margin-top: -36%;
			}
		}
	}


	@media only screen and (max-width: 1279px) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		section {
			.LottieAnimationSection {
				.imgTop{
					left:42%;	
					padding: 21%;
				}
				.imgRight{
					bottom: 13.55%;
    				padding: 21.05% 21.11%;
				}
				.imgLeft{
					bottom: 0;
					left: 0;
					padding: 21.05%;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		section {
			padding-right: 0 !important;
			padding-left: 0 !important;
			flex-direction: column;

			.content {
				align-self: flex-start;
				max-width: unset;
				margin-right: 0;
				padding: 0 var(--containerPadding);

				.titleBig {
					margin-top: var(--sp2x);
				}

				.description {
					margin-bottom: var(--sp4x);
					max-width: unset;
				}
			}

			.LottieAnimationSection {
				width: 100%;
				margin-top: var(--sp4x);

			}
		}
	}
`
export default AboutInvestStyle
