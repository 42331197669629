import styled from 'styled-components'


const ShareStyle = styled.div.attrs(props => ({ className: props.className }))`

    --shareLinkkSize:var(--sp6x);
    --shareLinkkRight:var(--sp3x);

    .socIconItem{
        font-size:var(--shareLinkkSize);
        margin-right:var(--shareLinkkRight);
        &:last-child{
            margin-right:0;
        }
    }

    

`

export default ShareStyle