import { useMemo, forwardRef, useRef, useImperativeHandle } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Text } from 'components/common'



const BusinessInArmeniaBottom = forwardRef((props, ref) => {
    //! Refs
    const titleRef = useRef()
    const itemRef = useRef()
    const wrapRef = useRef()

    useImperativeHandle(ref, () => [titleRef, itemRef, wrapRef], [])

    const pdf = useMemo(() => ({
        en: [
            "/downloadPDF/pdf/MicroBusinessTaxRegimeForIndividualCompanies/MicroBusinessTaxENG.pdf",
            "/downloadPDF/pdf/BorderlineAreaSpecialTaxRegime/BorderlineAreaSpecialTaxRegimeENG.pdf",
            "/downloadPDF/pdf/SpecialTaxRegimeForIT/SpecialTaxRegimeForITStartupsENG.pdf",
            "/downloadPDF/pdf/MicroBusinessTaxRegimeForPhysicalPersons/MicroBusinessTaxRegimeForPhysicalPersonsENG.pdf",
            "/downloadPDF/pdf/TurnoverSalesTaxRegime/TurnoverSalesTaxRegimeENG.pdf",
        ],
        ru: [
            "/downloadPDF/pdf/MicroBusinessTaxRegimeForIndividualCompanies/MicroBusinessTaxRUS.pdf",
            "/downloadPDF/pdf/BorderlineAreaSpecialTaxRegime/BorderlineAreaSpecialTaxRegimeRUS.pdf",
            "/downloadPDF/pdf/SpecialTaxRegimeForIT/SpecialTaxRegimeForITStartupsRUS.pdf",
            "/downloadPDF/pdf/MicroBusinessTaxRegimeForPhysicalPersons/MicroBusinessTaxRegimeForPhysicalPersonsRUS.pdf",
            "/downloadPDF/pdf/TurnoverSalesTaxRegime/TurnoverSalesTaxRegimeRUS.pdf",
        ],
        hy: [
            "/downloadPDF/pdf/MicroBusinessTaxRegimeForIndividualCompanies/MicroBusinessTaxARM.pdf",
            "/downloadPDF/pdf/BorderlineAreaSpecialTaxRegime/BorderlineAreaSpecialTaxRegimeARM.pdf",
            "/downloadPDF/pdf/SpecialTaxRegimeForIT/SpecialTaxRegimeForITStartupsARM.pdf",
            "/downloadPDF/pdf/MicroBusinessTaxRegimeForPhysicalPersons/MicroBusinessTaxRegimeForPhysicalPersonsARM.pdf",
            "/downloadPDF/pdf/TurnoverSalesTaxRegime/TurnoverSalesTaxRegimeARM.pdf",
        ]
    }), [])


    const arrayGen = useMemo(() => {
        let arrayOfStrings = props.rightText.split('</li>');
        let arr = []
        arrayOfStrings.forEach((el, ind) => {
            if (ind !== arrayOfStrings.length - 1) {
                arr.push(el + `<a href='${pdf[props.selectedLang][ind]}' className='h5 MonRegular FlexBox alignMiddle uppercase' download><svg  width="100%" height="100%" viewBox="0 0 27 36"><path d="M12.79,18.007c-.352-1.125-.345-3.3-.141-3.3C13.24,14.709,13.184,17.3,12.79,18.007Zm-.12,3.319a32.444,32.444,0,0,1-2,4.409,25.891,25.891,0,0,1,4.423-1.54A9.108,9.108,0,0,1,12.67,21.326ZM6.054,30.1c0,.056.928-.38,2.454-2.827A9.718,9.718,0,0,0,6.054,30.1ZM17.438,11.25H27V34.313A1.683,1.683,0,0,1,25.313,36H1.688A1.683,1.683,0,0,1,0,34.313V1.688A1.683,1.683,0,0,1,1.688,0H15.75V9.563A1.692,1.692,0,0,0,17.438,11.25Zm-.562,12.08a7.057,7.057,0,0,1-3-3.783c.316-1.3.816-3.277.436-4.514a1.761,1.761,0,0,0-3.361-.478c-.352,1.287-.028,3.1.57,5.414A66.025,66.025,0,0,1,8.648,26c-.007,0-.007.007-.014.007-1.905.977-5.175,3.129-3.832,4.781a2.185,2.185,0,0,0,1.512.7c1.259,0,2.51-1.266,4.3-4.345a40.079,40.079,0,0,1,5.555-1.631,10.656,10.656,0,0,0,4.5,1.371,1.82,1.82,0,0,0,1.385-3.052c-.977-.956-3.818-.682-5.175-.506ZM26.508,7.383,19.617.492A1.686,1.686,0,0,0,18.422,0H18V9h9V8.571A1.682,1.682,0,0,0,26.508,7.383ZM21.3,25.334c.288-.19-.176-.837-3.009-.633C20.9,25.812,21.3,25.334,21.3,25.334Z"/></svg></a>` + "</li>")
            }
        })
        return arr.join('')
    }, [props.rightText, pdf, props.selectedLang])

    return (
        <div className='formInfoBlock FlexBoxContainer spaceBetween' ref={wrapRef}>
            <div className='formInfoBlockLeft'>
                <Text tag={'div'} className={' MonRegular black BusinessInArmeniaHTML'} ref={titleRef}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: props.leftText,
                        }}
                    />
                </Text>
            </div>

            <div className='formInfoBlockRight FlexBoxContainer spaceBetween' >
                <Text tag={'div'} className={' MonRegular black BusinessInArmeniaList'}>
                    <div
                        ref={itemRef}
                        dangerouslySetInnerHTML={{
                            __html: arrayGen,
                        }}
                    />
                </Text>
            </div>
        </div>
    )
})

export default withLanguageContext(BusinessInArmeniaBottom, ['translate', 'selectedLang'])
