import { memo as Memo } from 'react'


//* Components
import { Text, Image, CustomLink, Translate } from 'components/common'

//* Styles
import LoginRegisterStyle from 'styles/pages/LoginRegister'

const SuccessMessage = Memo((props) => {
    return (
        <LoginRegisterStyle>
            <Text tag={'div'} className={' Title h4 MonMedium black'} text={"PasswordChanged"} />

            <div className="subTitle">
                <Text tag={'span'} className={'pL MonRegular black'} text={"YourPasswordBeenChanged"} />
            </div>

            <div className="successIcon FlexBox alignCenter">
                <Image src={'/images/svg/unlock.svg'} alt="sent Recovery Link" />
            </div>

            <div className="linkWrap FlexBox alignCenter">
                <CustomLink url={"/"} className={"black underline"} content={<Translate val="ReturnToHomePage" />} />
            </div>
        </LoginRegisterStyle>
    )
})

export default SuccessMessage
