import { memo as Memo, useCallback } from 'react'

//* HOC's
import { withLanguageContext, withUIContext, withAccountContext } from 'context'

//* Config
import { config } from 'helpers'

//* Components
import { Text, Button, Image } from 'components/common'

//* Style
import AccountActivatedPopupStyle from './style'

const AccountActivatedPopup = Memo(({ closePopup, resendVerficationMail, onClosePopup, warning, ...props }) => {
	const onClick = useCallback(() => {
		onClosePopup && onClosePopup()
		closePopup()
		resendVerficationMail()
	}, [])

	return (
		<AccountActivatedPopupStyle>
			<div className='FlexBox alignMiddle alignCenter'>
				<Image src='/images/svg/verifyAccount.svg' alt={props.translate('AccountActivatedTitle')} />
			</div>
			<div className='title textAlignCenter'>
				<Text tag={'p'} className={'h5 MonMedium black'} text={warning ? 'PleaseVerifyYourAccount' : 'SuccessfullCreatedAccount'} />
			</div>
			<div className='description '>
				<Text tag={'p'} className={'pM MonRegular black'} text={(props.type = 'verify' ? 'VerifyText' : 'YourVerificationLinkExpired.')} />
			</div>
			<div className='btn-text-wrapp'>
				<Text
					tag={'p'}
					className={'pM MonRegular darkGrey'}
					text={(props.type = 'verify' ? 'ReceiveNewConfirmationLink' : 'ClickResendConfirmationLink')}
				/>
				<Button onClick={onClick} className={`btn btn-black btn-max-width`} text={'ResendConfirmationLink'} />
			</div>
		</AccountActivatedPopupStyle>
	)
})

export default withLanguageContext(withUIContext(withAccountContext(AccountActivatedPopup, ['resendVerficationMail']), ['closePopup']), [
	'translate',
	'selectedLang',
])
