import React, { memo as Memo } from 'react'

//* HOC's
import { withAccountContext } from 'context'

//* Components
import { Text, Button } from 'components/common'

const OtherWayLoginRegistrationBtn = Memo(({ loginSocial, ...props }) => {
	//! Icons
	const socBtn = [
		{
			name: 'facebook',
			text: 'ContinueFacebook',
			icon: '/images/svg/btnSocIcon/Facebook.svg',
		},
		{
			name: 'google',
			text: 'ContinueGoogle',
			icon: '/images/svg/btnSocIcon/Google.svg',
		},
		{
			name: 'linkedin',
			text: 'ContinueLinkedin',
			icon: '/images/svg/btnSocIcon/Linkedin.svg',
		},
	]

	return (
		<div className='otherWaySignIn'>
			{socBtn.map((item, ind) => {
				return <Button key={ind} className={'btn-white iconBtn'} text={item.text} icon={item.icon} onClick={() => loginSocial(item.name)} />
			})}

			<div className={'or'}>
				<Text tag={'span'} className={' pM MonMedium darkGrey'} text={'Or'} />
			</div>
		</div>
	)
})

export default withAccountContext(OtherWayLoginRegistrationBtn, ['loginSocial'])
