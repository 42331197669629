import { forwardRef, useRef, useImperativeHandle, useMemo } from 'react'
import { Container, Text } from 'components/common'
import StepsStyle from './style'

const Steps = forwardRef((props, ref) => {
	//! Refs
	const titleRef = useRef()
	const descRef = useRef()
	const listRef = useRef()
	const itemRef = useRef()

	//! Imperative Handle
	useImperativeHandle(ref, () => [titleRef, descRef, listRef, itemRef], [])

	//! Icon HTML for Replace
	const icon = useMemo(() => '<i class="icon-check"><span class="path1"></span><span class="path2"></span></i>', [])

	return (
		<StepsStyle>
			<Container className='steps-container FlexBoxColumn '>
				<Text tag={'stringToLetters'} className={'title h1 MonBold'} text={props.title} ref={titleRef} />
				<div className='info FlexBox spaceBetween overflowHidden'>
					<div
						ref={descRef}
						className={'description pL MonRegular'}
						dangerouslySetInnerHTML={{ __html: props.description ? props.description : null }}
					/>
					<div
						className='points FlexBoxColumn '
						ref={listRef}
						dangerouslySetInnerHTML={{ __html: props.points?.replace(/<p>/g, `<p>${icon}`) }}
					></div>
				</div>
				<div className='steps FlexBox spaceBetween overflowHidden' ref={itemRef}>
					<div className='single-step FlexBoxColumn alignMiddle'>
						<Text tag={'h3'} className={'number FlexBox alignMiddle alignCenter h3 MonBold'} text={'1.'} />
						<Text tag={'h4'} className={'title h4 MonMedium'} text={'step1Title'} />
						<Text tag={'p'} className={'pL MonRegular'} text={'step1'} />
					</div>
					<div className='single-step FlexBoxColumn alignMiddle'>
						<Text tag={'h3'} className={'number FlexBox alignMiddle alignCenter h3 MonBold'} text={'2.'} />
						<Text tag={'h4'} className={'title h4 MonMedium'} text={'step2Title'} />
						<Text tag={'p'} className={'pL MonRegular'} text={'step2'} />
					</div>
					<div className='single-step FlexBoxColumn alignMiddle'>
						<Text tag={'h3'} className={'number FlexBox alignMiddle alignCenter h3 MonBold'} text={'3.'} />
						<Text tag={'h4'} className={'title h4 MonMedium'} text={'step3Title'} />
						<Text tag={'p'} className={'pL MonRegular'} text={'step3'} />
					</div>
					<div className='single-step FlexBoxColumn alignMiddle'>
						<Text tag={'h3'} className={'number FlexBox alignMiddle alignCenter h3 MonBold'} text={'4.'} />
						<Text tag={'h4'} className={'title h4 MonMedium'} text={'step4Title'} />
						<Text tag={'p'} className={'pL MonRegular'} text={'step4'} />
					</div>
				</div>
			</Container>
		</StepsStyle>
	)
})

export default Steps
