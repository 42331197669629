import React, { memo as Memo } from 'react'
import { withAccountContext, withUIContext } from 'context'
import { Text, CustomLink, Image, Icon } from 'components/common'

//style
import SignInStyle from './style'

const SignIn = Memo(({ openLoginPopup, ...props }) => {
	return (
		<SignInStyle className='SignIn'>
			{props.isLoggedIn ? (
				<CustomLink
					url={props.isLoggedIn ? '/account' : '/login'}
					className='SignInWrap FlexBox'
					content={
						props?.user?.avatar ? (
							<Image className={'avatarImg'} src={props.user.avatar} alt={props.user.first_name} />
						) : (
							<Icon>
								<span className='icon-login'>
									<span className='path1'></span>
									<span className='path2'></span>
								</span>
							</Icon>
						)
					}
				/>
			) : (
				<div className='SignInWrap FlexBox' onClick={openLoginPopup}>
					<Text
						tag={'div'}
						className={`${props.inProjectInd ? 'MonRegular' : 'MonMedium'} pL capitalize cursorPointer`}
						text={!props.inProjectInd ? 'login' : 'AlreadyHaveAccount2'}
					/>
				</div>
			)}
		</SignInStyle>
	)
})

export default withUIContext(withAccountContext(SignIn, ['isLoggedIn', 'user']), ['openLoginPopup'])
