import { memo as Memo, useRef, useState, useCallback } from 'react'

//* HOC's
import { withLanguageContext, withAccountContext } from 'context'
import { useRouter } from 'next/router'

//* Components
import { Text, FormItem, FormContainer, CustomLink, FormInput } from 'components/common'
import { SuccessMessage } from 'components/pages'
import { Button, Input } from 'antd'

//* Styles
import FormStyle from 'styles/pages/FormStyle'
import LoginRegisterStyle from 'styles/pages/LoginRegister'

const ResetPasswordForm = Memo(({ token, email, expired, resetPassword, ...props }) => {
    //! Router
    const router = useRouter()

    //! States
    const [passBtnIsActive, setPassBtnIsActive] = useState(false)
    const [success, setSuccess] = useState(false)

    //! Refs
    const formRef = useRef()

    //! Reset Password
    const resetPasswordSubmit = useCallback((values) => {
        return resetPassword(values).then((res) => {
            // router.push({
            // 	pathname: '/',
            // 	query: {
            // 		c: 'login',
            // 	},
            // })
            setSuccess(true)
        })
    }, [])

    return success ? (
        <SuccessMessage />
    ) : (
        <FormStyle>
            <LoginRegisterStyle>
                <Text tag={'div'} className={' Title h4 MonMedium black'} text={expired ? 'Your Link Has Expired' : 'ChangeYourPassword'} />
                {!expired && <Text tag={'div'} className={'subTitle pL MonRegular black'} text={'EnterYourPasswordBelow'} />}
            </LoginRegisterStyle>

            {!expired && (
                <div className='FormColGroup '>
                    <FormContainer
                        layout={'vertical'}
                        ref={formRef}
                        onFinish={resetPasswordSubmit}
                        initialValues={{ email, token }}
                        className={'FormRow FlexBoxContainer spaceBetween'}
                    >
                        <FormItem name={'email'} options={{ noStyle: true }}>
                            <FormInput type='text' type='hidden' />
                        </FormItem>
                        <FormItem name={'token'} options={{ noStyle: true }}>
                            <FormInput type='text' type='hidden' />
                        </FormItem>
                        <div className='FormCol'>
                            <FormItem
                                name='password'
                                required={true}
                                validationType='password'
                                validateWith={'password_confirmation'}
                                onError={() => setPassBtnIsActive(false)}
                                onSuccess={() => setPassBtnIsActive(true)}
                            >
                                <Input.Password placeholder={props.translate('NewPassword')} />
                            </FormItem>
                        </div>
                        <div className='FormCol'>
                            <FormItem
                                name='password_confirmation'
                                required={true}
                                validationType='c_password'
                                onError={() => setPassBtnIsActive(false)}
                                onSuccess={() => setPassBtnIsActive(true)}
                            >
                                <Input.Password placeholder={props.translate('ConfirmPassword')} />
                            </FormItem>
                        </div>

                        <div className='formBtnWrapp FlexBox justifyEnd'>
                            <div className='FormCol'>
                                <Button htmlType={'Submit'} className={`btn btn-black btn-max-width ${!passBtnIsActive && 'disabled'}`}>
                                    {props.translate('ChangeMyPassword')}
                                </Button>
                            </div>
                        </div>
                        <Text tag={'div'} className={'FormCol bottomText pM ultraDarkGrey MonRegular'}>
                            <CustomLink
                                url={'/'}
                                className={'black underline'}
                                options={{ target: '_blank' }}
                                content={props.translate('ReturnToHomePage')}
                            />
                        </Text>
                    </FormContainer>
                </div>
            )}
        </FormStyle>
    )
})

export default withLanguageContext(withAccountContext(ResetPasswordForm, ['resetPassword']), ['selectedLang', 'translate'])
