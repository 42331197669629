import React, { memo as Memo } from 'react'
import { Text, Image } from 'components/common'

//* HOC's
import { withUIContext, withLanguageContext } from 'context'

const PurchaseHistory = Memo(({ history, ...props }) => {
	return (
		<div className='tableWrap'>
			{history?.length > 0 ? (
				props.winWidth >= 768 ? (
					<>
						<div className='tableTitlesWrap FlexBoxContainer spaceBetween'>
							<div className='tableItemTitles'>
								<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'PurchaseDate'} />
							</div>
							<div className='tableItemTitles'>
								<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'PurchaseDescription'} />
							</div>
							<div className='tableItemTitles'>
								<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'PurchaseId'} />
							</div>
							<div className='tableItemTitles'>
								<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'PurchaseTotalPrice'} />
							</div>
						</div>
						{history.map((item, ind) => {
							return (
								<div className='tableItemWrapp FlexBoxContainer spaceBetween' key={ind}>
									<div className='tableItem'>
										<Text tag={'p'} className={'pL MonRegular black '} text={item.date} />
									</div>
									<div className='tableItem'>
										<Text tag={'p'} className={'pL MonRegular black '} text={item.description || '-'} />
									</div>
									<div className='tableItem'>
										<Text tag={'p'} className={'pL MonRegular black '} text={item.purchase_id || '-'} />
									</div>
									<div className='tableItem'>
										<Text tag={'p'} className={'pL MonRegular black '} text={item.total} />
									</div>
								</div>
							)
						})}
					</>
				) : (
					history.map((item, ind) => {
						return (
							<div className='tableItemWrapp FlexBoxContainer' key={ind}>
								<div className='tableItem'>
									<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'PurchaseDate'} />
								</div>
								<div className='tableItem'>
									<Text tag={'p'} className={'pL MonRegular black '} text={item.date} />
								</div>

								<div className='tableItem'>
									<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'PurchaseDescription'} />
								</div>
								<div className='tableItem'>
									<Text tag={'p'} className={'pL MonRegular black '} text={item.description || '-'} />
								</div>

								<div className='tableItem'>
									<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'PurchaseId'} />
								</div>
								<div className='tableItem'>
									<Text tag={'p'} className={'pL MonRegular black '} text={item.purchase_id || '-'} />
								</div>

								<div className='tableItem'>
									<Text tag={'p'} className={'pS MonMedium ultraDarkGrey '} text={'PurchaseTotalPrice'} />
								</div>
								<div className='tableItem'>
									<Text tag={'p'} className={'pL MonRegular black '} text={item.total} />
								</div>
							</div>
						)
					})
				)
			) : (
				<div className='FlexBox alignCenter'>
					<div className='noDataWrap FlexBoxColumn alignMiddle'>
						<Image src={'/images/svg/noPurchaseHistory.svg'} alt={props.translate('NOSubmissionsYET')} />
						<Text tag={'h5'} className={'MonMedium black '} text={'NOSubmissionsYET'} />
					</div>
				</div>
			)}
		</div>
	)
})

export default withUIContext(withLanguageContext(PurchaseHistory, ['translate']), ['screenSizes', 'winWidth'])
