import styled from 'styled-components'

const StepsStyle = styled.div`
	.steps-container {
		.title {
			max-width: var(--sp70x);
			width: 100%;
			margin-bottom: var(--titleFromContent);
		}

		.info {
			.description {
				max-width: var(--sp98x);
				width: 100%;

				p {
					&:not(:first-child) {
						margin-top: var(--sp2x);
					}
				}
			}

			.points {
				max-width: var(--sp81x);
				width: 100%;

				p {
					transform: translateY(30vh);
					font-size: var(--h6);
					font-family: MonRegular, MonRegularRu;
					padding-left: var(--sp6x);

					&:not(:last-child) {
						margin-bottom: var(--sp4x);
					}

					i {
						font-size: var(--sp4x);
						color: var(--yellow);
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
		}

		.steps {
			margin-top: var(--pageFromHeaderMargin);

			.single-step {
				max-width: var(--sp50x);
				width: 100%;
				text-align: center;
				padding:0 var(--sp3x) ;
				transform: translateY(30vh);

				.title {
					margin: var(--sp2-5x) 0 var(--sp1-5x);
				}

				.number {
					width: var(--sp24x);
					height: var(--sp24x);
					background-color: var(--ultraLightGrey);
					border-radius: 50%;
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		.steps-container {
			.title {
				max-width: var(--sp60x);
			}

			.info {
				.description {
					max-width: var(--sp84x);
				}

				.points {
					max-width: var(--sp68x);

					p {
						&:not(:last-child) {
							margin-bottom: var(--sp3x);
						}
					}
				}
			}

			.steps {
				.single-step {
					max-width: var(--sp40x);

					.number {
						width: var(--sp21x);
						height: var(--sp21x);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		.steps-container {
			.title {
				max-width: var(--sp55x);
			}

			.info {
				.description {
					max-width: var(--sp84x);
				}

				.points {
					max-width: var(--sp64x);

					p {
						&:not(:last-child) {
							margin-bottom: var(--sp3x);
						}

						i {
							margin-top: 0;
						}
					}
				}
			}

			.steps {
				.single-step {
					max-width: var(--sp45x);

					.number {
						width: var(--sp18x);
						height: var(--sp18x);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
		.steps-container {
			.title {
				max-width: var(--sp47x);
			}

			.info {
				.description {
					max-width: unset;
					width: calc(50% - var(--sp2x));
				}

				.points {
					max-width: unset;
					width: calc(50% - var(--sp2x));

					p {
						&:not(:last-child) {
							margin-bottom: var(--sp3x);
						}

						i {
							margin-top: var(--sp0-5x);
						}
					}
				}
			}

			.steps {
				.single-step {
					max-width: var(--sp27x);

					.number {
						width: var(--sp14x);
						height: var(--sp14x);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		.steps-container {
			.title {
				max-width: var(--sp47x);
			}

			.info {
				flex-direction: column;
				.description {
					max-width: unset;
					width: 100%;
				}

				.points {
					max-width: unset;
					width: 100%;
					margin-top: var(--sp5x);

					p {
						&:not(:last-child) {
							margin-bottom: var(--sp3x);
						}

						i {
							margin-top: var(--sp0-5x);
						}
					}
				}
			}

			.steps {
				flex-wrap: wrap;
				justify-content: space-between;

				.single-step {
					max-width: unset;
					width: calc(50% - var(--sp1-5x));

					&:nth-child(3),
					&:nth-child(4) {
						margin-top: var(--sp5x);
					}

					.number {
						width: var(--sp14x);
						height: var(--sp14x);
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		.steps-container {
			.title {
				max-width: unset;
			}

			.info {
				flex-direction: column;

				.description {
					max-width: unset;
					width: 100%;
				}

				.points {
					max-width: unset;
					width: 100%;
					margin-top: var(--sp5x);

					p {
						padding-left: var(--sp4x);

						&:not(:last-child) {
							margin-bottom: var(--sp2x);
						}

						i {
							margin-top: var(--sp0-5x);
							font-size: var(--sp3x);
						}
					}
				}
			}

			.steps {
				flex-direction: column;

				.single-step {
					max-width: unset;
					width: 100%;

					&:not(:last-child) {
						margin-bottom: var(--sp5x);
					}

					.number {
						width: var(--sp11x);
						height: var(--sp11x);
					}
				}
			}
		}
	}
`
export default StepsStyle
