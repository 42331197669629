import React, { memo as Memo, useCallback } from 'react'
import { withRouter } from 'next/router'

//* Componets
import MenuItem from './menuItem'

const Menu = Memo((props) => {
	const getParentClassFromActiveChild = useCallback(
		(child) => {
			return props.router.asPath === child.path + '/' ? 'MonMedium active' : 'MonRegular'
		},
		[props.router.asPath]
	)

	const menuItems = (arr, t, c = '') => {
		return arr.map((o, k) => {
			const parentClass = o ? getParentClassFromActiveChild(o) : ''
			return <MenuItem item={o} key={k} classes={c + ' ' + parentClass} type={props.type} path={o.path} navLinksclassName={o.navLinksClass} />
		})
	}

	return (
		<ul className={props.contClass || ''} onClick={props.onClick}>
			{menuItems(props.items, props.type, props.itemClass, props.navLinksClass)}
		</ul>
	)
})

export default withRouter(Menu)
