import { forwardRef, useRef, useImperativeHandle, useMemo } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Components
import { Select, Tooltip, Button } from 'antd'
import { Text, FormItem, Icon, FormInput } from 'components/common'

const BusinessInArmeniaFormTop = forwardRef((props, ref) => {
    //! Refs
    const formRef = useRef()
    const titleRef = useRef()
    const descriptionRef = useRef()

    //! Imperative Handle
    useImperativeHandle(ref, () => [titleRef, descriptionRef, formRef], [])

    //! Product Unit
    const productUnit = useMemo(() => {
        return ['Compressed'].includes(props.product)
            ? 'M³'
            : ['Tobacco', 'Cigars', 'Cigarette'].includes(props.product)
                ? 'Box'
                : ['IndustrialTobaccoSubstitutes', 'LubricatingOil'].includes(props.product)
                    ? 'Kg'
                    : ['Petroleum', 'DieselFuel', 'CrudeOil', 'PetroleumProducts'].includes(props.product)
                        ? 'Ton'
                        : 'Liter'
    }, [props.product])

    return (
        <div className='BusinessInArmeniaFormTop FlexBoxContainer spaceBetween'>
            <div className='textAlignCenter'>
                <Text tag={'stringToLetters'} className={'title h1 MonBold black'} text={'TAXCalculator'} ref={titleRef} />
                <Text tag={'h6'} className={'description MonRegular black'} text={'TellMoreAboutYourBusiness'} ref={descriptionRef} />
            </div>
            <div className={'ItemsWrapp'} ref={formRef}>
                {/* Sector */}
                <div className='FormCol '>
                    <FormItem label={props.translate('BusinessSector')} name={'BusinessSector'} required={true}>
                        <Select
                            placeholder={props.translate('ChooseBusinessSector')}
                            suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />}
                            allowClear
                        >
                            <Select.Option value={'AgriculturalProduce'}>{props.translate('AgriculturalProduce')}</Select.Option>
                            <Select.Option value={'FinancialServices'}>{props.translate('FinancialServices')}</Select.Option>
                            <Select.Option value={'ITServicesElectronic'}>{props.translate('ITServicesElectronic')}</Select.Option>
                            <Select.Option value={'Construction'}>{props.translate('Construction')}</Select.Option>
                            <Select.Option value={'Manufacturing'}>{props.translate('Manufacturing2')}</Select.Option>
                            <Select.Option value={'TradePurchase'}>{props.translate('TradePurchase')}</Select.Option>
                            <Select.Option value={'HotelsHostels'}>{props.translate('HotelsHostels')}</Select.Option>
                            <Select.Option value={'PublicCatering'}>{props.translate('PublicCatering')}</Select.Option>
                            <Select.Option value={'Other'}>{props.translate('OtherServices')}</Select.Option>
                        </Select>
                    </FormItem>
                </div>

                {/* Legal Status */}
                <div className='FormCol '>
                    <FormItem label={props.translate('LegalStatus')} name={'LegalStatus'} required={true}>
                        <Select
                            placeholder={props.translate('ChooseYourLegalStatus')}
                            suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />}
                            allowClear
                        >
                            <Select.Option value={'LLC'}>{props.translate('LimitedLiabilityCompany')}</Select.Option>
                            <Select.Option value={'CJSC'}>{props.translate('ClosedJointStockCompany')}</Select.Option>
                            <Select.Option value={'ОJSC'}>{props.translate('OpenJointStockCompany')}</Select.Option>
                            <Select.Option value={'IE'}>{props.translate('Individualentrepreneur')}</Select.Option>
                            <Select.Option value={'Other'}>{props.translate('Other')}</Select.Option>
                        </Select>
                    </FormItem>
                </div>

                {/* Number Employees */}
                <div className='FormCol '>
                    <FormItem label={'NumberEmployees'} name={'NumberEmployees'} validationType='number' min={1}>
                        <FormInput type='number' />
                    </FormItem>
                </div>

                {/* Average Monthly Salary */}
                <div className='FormCol  columnFormCol-50'>
                    <FormItem label={'AverageMonthlySalary'} name={'AverageMonthlySalary'} validationType='number' min={1}>
                        <FormInput
                            type='number'
                            addonBefore={
                                <FormItem name='currency'>
                                    <Select suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />} className='select-before'>
                                        <Select.Option value='USD'>$</Select.Option>
                                        <Select.Option value='AMD'>֏</Select.Option>
                                    </Select>
                                </FormItem>
                            }
                        />
                    </FormItem>
                </div>

                {/* Expected Average Monthly Profit */}
                <div className='FormCol  columnFormCol-50'>
                    <FormItem label={'ExpectedAverageMonthlyProfit'} name={'ExpectedAverageMonthlyProfit'} validationType='number' min={1}>
                        <FormInput type='number' addonBefore={props.currency === 'USD' ? '$' : '֏'} />
                    </FormItem>
                </div>

                {/* AnnualAales */}
                <div className='FormCol  columnFormCol-50'>
                    <FormItem label={'AnnualSales'} name={'AnnualSales'} validationType='number' min={1}>
                        <FormInput type='number' addonBefore={props.currency === 'USD' ? '$' : '֏'} />
                    </FormItem>
                </div>

                {/* Location */}
                {['PublicCatering', 'Manufacturing', 'TradePurchase'].includes(props.sector) && (
                    <div className='FormCol '>
                        <FormItem label={props.translate('Location')} name={'Location'} required={true}>
                            <Select
                                placeholder={props.translate('Location')}
                                suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />}
                                allowClear
                            >
                                <Select.Option value={'Yerevan'}>{props.translate('expLocationYerevan')}</Select.Option>
                                <Select.Option value={'NonBorderline'}>{props.translate('expLocationNonBorderline')}</Select.Option>
                                <Select.Option value={'Borderline'}>{props.translate('expLocationBorderline')}</Select.Option>
                            </Select>
                        </FormItem>

                        <div className='warningWrapp relativeWrapp'>
                            <Text tag={'span'} className={' pM MonRegular black'} text={'LocationExtra'} />

                            <Tooltip title={<div dangerouslySetInnerHTML={{ __html: props.translate('BorderlineVillages') }} />}>
                                <div className='HelperDesc'>
                                    <Icon className='icon-quastion'>
                                        <span className='path1'></span>
                                        <span className='path2'></span>
                                    </Icon>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                )}

                {/* Product */}
                {['TradePurchase', 'Manufacturing'].includes(props.sector) && (
                    <>
                        <div className='FormCol '>
                            <FormItem label={props.translate('Product')} name={'Product'} required={true}>
                                <Select
                                    placeholder={props.translate('ChooseProduct')}
                                    suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />}
                                    allowClear
                                >
                                    <Select.Option value={'EthylAlcohol'}>{props.translate('EthylAlcohol')}</Select.Option>
                                    <Select.Option value={'AlcoholicBeverages'}>{props.translate('AlcoholicBeverages')}</Select.Option>
                                    <Select.Option value={'VodkaFruitsBerries'}>{props.translate('VodkaFruitsBerries')}</Select.Option>
                                    <Select.Option value={'CognacBrandy'}>{props.translate('CognacBrandy')}</Select.Option>
                                    <Select.Option value={'Whiskey'}>{props.translate('Whiskey')}</Select.Option>
                                    <Select.Option value={'Beer'}>{props.translate('Beer')}</Select.Option>
                                    <Select.Option value={'GrapeWines'}>{props.translate('GrapeWines')}</Select.Option>
                                    <Select.Option value={'Vermouth'}>{props.translate('Vermouth')}</Select.Option>
                                    <Select.Option value={'OtherFermentedBeverages'}>{props.translate('OtherFermentedBeverages')}</Select.Option>
                                    <Select.Option value={'FruitBerryWines'}>{props.translate('FruitBerryWines')}</Select.Option>
                                    <Select.Option value={'Tobacco'}>{props.translate('Tobacco')}</Select.Option>
                                    <Select.Option value={'Cigars'}>{props.translate('Cigars')}</Select.Option>
                                    <Select.Option value={'Cigarette'}>{props.translate('Cigarette')}</Select.Option>
                                    <Select.Option value={'IndustrialTobaccoSubstitutes'}>
                                        {props.translate('IndustrialTobaccoSubstitutes')}
                                    </Select.Option>
                                    <Select.Option value={'LubricatingOil'}>{props.translate('LubricatingOil')}</Select.Option>
                                    <Select.Option value={'Petroleum'}>{props.translate('Petroleum')}</Select.Option>
                                    <Select.Option value={'DieselFuel'}>{props.translate('DieselFuel')}</Select.Option>
                                    <Select.Option value={'CrudeOil'}>{props.translate('CrudeOil')}</Select.Option>
                                    <Select.Option value={'PetroleumProducts'}>{props.translate('PetroleumProducts')}</Select.Option>
                                    <Select.Option value={'Compressed'}>{props.translate('Compressed')}</Select.Option>
                                    <Select.Option value={'None'}>{props.translate('NoneProduct')}</Select.Option>
                                </Select>
                            </FormItem>
                        </div>

                        {/* Product Params */}
                        {props.product && props.product !== 'None' && (
                            <>
                                {/* Sales Expected Units */}
                                <div className='FormCol  columnFormCol-50'>
                                    <FormItem label={'SalesExpectedUnits'} name={'SalesExpectedUnits'} required={true}>
                                        <FormInput type='number' addonBefore={props.translate(productUnit)} />
                                    </FormItem>
                                </div>

                                {/* Value Unit */}
                                {/* {[
									'EthylAlcohol',
									'AlcoholicBeverages',
									'Whiskey',
									'Beer',
									'GrapeWines',
									'Vermouth',
									'OtherFermentedBeverages',
									'FruitBerryWines',
									'Tobacco',
									'LubricatingOil',
								].includes(props.product) && (
										<div className='FormCol  columnFormCol-50'>
											<FormItem label={'ValueUnit'} name={'ValueUnit'} required={true}>
												<FormInput type='number' addonBefore={props.currency === 'USD' ? '$' : '֏'} />
											</FormItem>
										</div>
									)} */}

                                {/* Cognac Age */}
                                {['CognacBrandy'].includes(props.product) && (
                                    <div className='FormCol  columnFormCol-50'>
                                        <FormItem label={'CognacAge'} name={'Age'} validationType='number' min={1}>
                                            <FormInput addonBefore={props.translate('year')} step="0.1" />
                                        </FormItem>
                                    </div>
                                )}

                                {/* Degree Liter */}
                                {[/*'EthylAlcohol', */ 'CognacBrandy'].includes(props.product) && (
                                    <div className='FormCol  columnFormCol-50'>
                                        <FormItem label={'DegreeLiter'} name={'DegreeLiter'} validationType='number' min={0} max={100}>
                                            <FormInput addonBefore={'%'} />
                                        </FormItem>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}

                {/* Action Buttons */}
                <div className='FormCol  formBtnWrapp FlexBox justifyEnd'>
                    <Button onClick={props.resetFields} className={'btn btn-grey-black '}>
                        {props.translate('reset')}
                    </Button>

                    <Button htmlType={'Submit'} className={'btn btn-black'}>
                        {props.translate('Calculate')}
                    </Button>
                </div>
            </div>
        </div>
    )
})

export default withLanguageContext(BusinessInArmeniaFormTop, ['translate', 'selectedLang'])
