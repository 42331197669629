import styled from 'styled-components'

const CompanyInfoStyle = styled.div.attrs((props) => ({ className: props.className }))`
	section {
		position: relative;

		.back-to {
			position: absolute;
			bottom: calc(100% + var(--titleFromContent));
			left: var(--containerPadding);

			i {
				margin-right: 12px;
			}
		}

		.title {
			width: calc(50% - var(--sp2x));
		}

		.info {
			width: calc(50% - var(--sp2x));
			overflow: hidden;
			.desc {
				margin: var(--titleFromContent) 0 var(--sp7x);
			}
			>*{
				transform: translateY(60vh);
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		section {
			.info {
				.desc {
					margin: var(--titleFromContent) 0 var(--sp6x);
				}
			}
		}
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		section {
			.info {
				.desc {
					margin: var(--titleFromContent) 0 var(--sp5x);
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		section {
			flex-direction: column;

			.title {
				width: 100%;
			}

			.info {
				width: 100%;
				margin-top: var(--sp4x);

				.desc {
					margin: var(--titleFromContent) 0 var(--sp4x);
				}
			}
		}
	}
	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		section {
			flex-direction: column;

			.title {
				width: 100%;
			}

			.info {
				width: 100%;
				margin-top: var(--sp3-5x);

				.desc {
					margin: var(--titleFromContent) 0 var(--sp3-5x);
				}
			}
		}
	}
`
export default CompanyInfoStyle
