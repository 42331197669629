import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import { withUIContext } from 'context'
import { Text, Container, Image } from 'components/common'
import DoneWorksStyle from './style'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
gsap.core.globals('ScrollTrigger', ScrollTrigger)

const DoneWorks = forwardRef(({ winWidth, ...props }, ref) => {
    const imgRef = useRef()
    const itemRef = useRef()
    const numb1 = useRef()
    const numb2 = useRef()
    const numb3 = useRef()

    let arr = [numb1, numb2, numb3]

    useImperativeHandle(ref, () => [arr, itemRef, imgRef], [])


    return (
        <DoneWorksStyle className='done-works' ref={itemRef}>
            <Container className='full overflowHidden'>
                <Image className='backImg' src={props.bgImg} alt={props.bgImg} ref={imgRef} />
                <Container className='block FlexBox last largePadding spaceBetween'>
                    <div className='single-work FlexBoxColumn'>
                        <Text tag={'h2'} className={'title h1L MonBold white'}>
                            $<span ref={numb1}>0</span>
                        </Text>
                        <Text tag={'h3'} className={'h4 MonRegular uppercase white'} text={props.work1SubTitle} />
                    </div>
                    <div className='single-work FlexBoxColumn'>
                        <Text tag={'h2'} className={'title h1L MonBold white'}>
                            <span ref={numb2}>0</span>+
                        </Text>
                        <Text tag={'h3'} className={'h4 MonRegular uppercase white'} text={props.work2SubTitle} />
                    </div>
                    <div className='single-work FlexBoxColumn'>
                        <Text tag={'h2'} className={'title h1L MonBold white'}>
                            <span ref={numb3}>0</span>+
                        </Text>
                        <Text tag={'h3'} className={'h4 MonRegular uppercase white'} text={props.work3SubTitle} />
                    </div>
                </Container>
            </Container>
        </DoneWorksStyle>
    )
})

export default withUIContext(DoneWorks, ['winWidth'])
