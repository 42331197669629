import { memo as Memo } from 'react'

//* HOC's
import { withLanguageContext, withAccountContext } from 'context'

//* Components
import { Radio, Select, Button, Tooltip, Input } from 'antd'
import { FormItem, Icon, FormInput } from 'components/common'

const ProjectCompanySection = Memo(({ companyTypeOther, categoryOther, user, ...props }) => {
	return (
		<div className='FormColumnsWrapp FlexBoxContainer spaceBetween '>
			<div className='FormCol'>
				<FormItem label={'NameOfTheCompany'} name={'name'} extra={'EnterTheNameOfTheCompanyExtra'} required={true}>
					<FormInput />
				</FormItem>
			</div>

			<div className='FormCol'>
				<FormItem label={'LegalTypeOfTheCompany'} validationType='radio' name={'type'} required={true}>
					<Radio.Group checked='LLC'>
						<div className='FlexBoxContainer spaceBetween'>
							<div className='FormCol'>
								<Radio.Button value='LLC'>{props.translate('LimitedLiabilityCompany')}</Radio.Button>
							</div>
							<div className='FormCol'>
								<Radio.Button value='CJSC'>{props.translate('ClosedJointStockCompany')}</Radio.Button>
							</div>
							<div className='FormCol'>
								<Radio.Button value='OJSC'>{props.translate('OpenJointStockCompany')}</Radio.Button>
							</div>
							<div className='FormCol'>
								<Radio.Button value='IE'>{props.translate('Individualentrepreneur')}</Radio.Button>
							</div>
							<div className='FormCol'>
								<Radio.Button value='other'>{props.translate('Other')}</Radio.Button>
							</div>
						</div>
					</Radio.Group>
				</FormItem>
				{companyTypeOther && (
					<>
						<div className='mutableFormItem'>
							<FormItem name={'type_other'} required={true}>
								<FormInput placeholder={props.translate('specify')} />
							</FormItem>
						</div>
						<div className='ant-form-item-extra'>{props.translate('LegalTypeOfTheCompanyExtra')}</div>
					</>
				)}
			</div>

			<div className='FormCol FormCol-50 extraMaxWidth'>
				<FormItem label={'CompanysRegistrationNumber'} name={'registration_number'} extra={'CompanysTaxNumberExtra'}>
					<FormInput />
				</FormItem>
			</div>

			<div className='FormCol FormCol-50'>
				<FormItem label={'CompanysTaxNumber'} name={'tax_number'}>
					<FormInput />
				</FormItem>
			</div>

			<div className='FormCol FormCol-90'>
				<FormItem label={'CompanysWebpageOfficialFacebookPage'} name={['socials', 'url']}>
					<FormInput addonBefore={'URL'} />
				</FormItem>
				<FormItem name={['socials', 'facebook']}>
					<FormInput addonBefore={'facebook.com/'} />
				</FormItem>
				<FormItem name={['socials', 'instagram']}>
					<FormInput addonBefore={'instagram.com/'} />
				</FormItem>
			</div>

			<div className='FormCol'>
				<FormItem label={'BusinessCategory'} name={'category_id'} required={true}>
					<Radio.Group>
						<div className='FlexBoxContainer spaceBetween'>
							{props.categories.map((el, k) => (
								<div key={k} className='FormCol FormCol-50'>
									<Radio.Button key={k} value={el.id}>
										{el.name}
									</Radio.Button>
								</div>
							))}
						</div>
					</Radio.Group>
				</FormItem>

				{categoryOther && (
					<div className='mutableFormItem'>
						<FormItem name={'category_other'} required={true}>
							<FormInput placeholder={props.translate('specify')} />
						</FormItem>
					</div>
				)}
			</div>

			<div className='FormCol relativeWrapp'>
				<Tooltip title={<div dangerouslySetInnerHTML={{ __html: props.translate('CompanyDescriptionCompletedExportHelp') }} />}>
					<div className='HelperDesc'>
						<Icon className='icon-quastion'>
							<span className='path1'></span>
							<span className='path2'></span>
						</Icon>
					</div>
				</Tooltip>
				<FormItem label={'ShortDescriptionOfTheCompany'} name={'short_description'} required={true}>
					<Input.TextArea placeholder={props.translate('ShortDescriptionOfTheCompany')} />
				</FormItem>
			</div>

			<div className='FormColGroup FlexBoxContainer spaceBetween'>
				<div className='FormCol FormCol-50 extraMaxWidth relativeWrapp'>
					<FormItem label={'AnnualAverageSales'} name={'average_sales'} required={true}>
						<Select suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />} allowClear>
							<Select.Option value='<$55.000'> {`< $55.000`} </Select.Option>
							<Select.Option value='$55.000-$225.000'> $55.000-$225.000</Select.Option>
							<Select.Option value='$225.000-$1.000.000'> $225.000-$1.000.000</Select.Option>
							<Select.Option value='$1.000.000-$3.000.000'> $1.000.000-$3.000.000</Select.Option>
							<Select.Option value='>$3.000.000'> {`> $3.000.000`}</Select.Option>
						</Select>
					</FormItem>
				</div>

				<div className='FormCol FormCol-50'>
					<FormItem label={'NumberOfEmployees'} name={'employees_number'} required={true}>
						<Select suffixIcon={<Icon className='ultraDarkGrey icon-arrow-down' />} allowClear>
							<Select.Option value='<15'>{`< 15`}</Select.Option>
							<Select.Option value='16-50'>16-50</Select.Option>
							<Select.Option value='51-250'>51-250</Select.Option>
							<Select.Option value='>250'>{`> 250`}</Select.Option>
						</Select>
					</FormItem>
				</div>

				<div className='formItemExtraMargMinus'>
					<div className='ant-form-item-extra'>{props.translate('NumberOfEmployeesExtra')}</div>
				</div>
				<div className='FormCol' />
			</div>

			<div className='FormCol'>
				<FormItem label={'ContactPerson'} name={'contact_name'} required={true}>
					<FormInput />
				</FormItem>
			</div>

			<div className='FormCol FormCol-50'>
				<FormItem label={'MobileNumber'} name={'contact_phone'} required={true} validationType='phone'>
					<FormInput />
				</FormItem>
			</div>
			<div className='FormCol FormCol-50'>
				<FormItem label={'Email'} name={'contact_email'} required={true} validationType='email'>
					<FormInput />
				</FormItem>
			</div>

			<div className='formBtnWrapp FlexBox justifyEnd'>
				<div className='FormCol FormCol-50'>
					<Button htmlType={'Submit'} className={`btn btn-black btn-max-width ${user.verified && !props.disabled ? '' : 'disabled'}`}>
						{props.translate('Save')}
					</Button>
				</div>
			</div>
		</div>
	)
})

export default withLanguageContext(withAccountContext(ProjectCompanySection, ['user']), ['translate', 'selectedLang'])
