import { createGlobalStyle } from 'styled-components'

const Variables = createGlobalStyle`

:root {
        
    //!Screen Sizes
    --DesktopSizeL:${(props) => props.theme.mediaQuery.DesktopSize};
    --DesktopSizeS: ${(props) => props.theme.mediaQuery.DesktopSizeS};
    --LaptopSize: ${(props) => props.theme.mediaQuery.LaptopSize};
    --TabletSize: ${(props) => props.theme.mediaQuery.TabletSize};
    --TabletSizeS: ${(props) => props.theme.mediaQuery.TabletSizeS};
    --MobileSize: ${(props) => props.theme.mediaQuery.MobileSize};
    --MobileSizeS: ${(props) => props.theme.mediaQuery.MobileSizeS};

    //!Colors

    --black:${(props) => props.theme.colors.black};
    --white: ${(props) => props.theme.colors.white};
    --ultraDarkGrey: ${(props) => props.theme.colors.ultraDarkGrey};
    --darkGrey: ${(props) => props.theme.colors.darkGrey};
    --ultraLightGrey: ${(props) => props.theme.colors.ultraLightGrey};
    --extraLightGrey: ${(props) => props.theme.colors.extraLightGrey};
    --lightGrey: ${(props) => props.theme.colors.lightGrey};
    --blue: ${(props) => props.theme.colors.blue};
    --lightBlue: ${(props) => props.theme.colors.lightBlue};
    --yellow: ${(props) => props.theme.colors.yellow};
    --orange: ${(props) => props.theme.colors.orange};
    --coral: ${(props) => props.theme.colors.coral};
    --green: ${(props) => props.theme.colors.green};
    --pink: ${(props) => props.theme.colors.pink};
    
    //secondary
    --secBlue: ${(props) => props.theme.colors.secBlue};
    --secLightBlue: ${(props) => props.theme.colors.secLightBlue};
    --secGreen: ${(props) => props.theme.colors.secGreen};
    --secLightGreen: ${(props) => props.theme.colors.secLightGreen};
    --secOrange: ${(props) => props.theme.colors.secOrange};
    --secYellow: ${(props) => props.theme.colors.secYellow};
    --secLightYellow: ${(props) => props.theme.colors.secLightYellow};
    
    //error color
    --errColor: ${(props) => props.theme.colors.errColor};

    //*Spacing
    --spSize: 8px;
    
    --sp0-5x: calc(var(--spSize) * 0.5);
    --sp0-75x: calc(var(--spSize) * 0.75);
    --sp1-3x: calc(var(--spSize) * 1.3);
    --sp1-25x: calc(var(--spSize) * 1.25);
    --sp1-5x: calc(var(--spSize) * 1.5);
    --sp1-6x: calc(var(--spSize) * 1.7);
    --sp1-75x: calc(var(--spSize) * 1.75);
    --sp2x: calc(var(--spSize) * 2);
    --sp2-5x: calc(var(--spSize) * 2.5);
    --sp3x: calc(var(--spSize) * 3);
    --sp3-5x: calc(var(--spSize) * 3.5);
    --sp4x: calc(var(--spSize) * 4);
    --sp4-5x: calc(var(--spSize) * 4.5);
    --sp5x: calc(var(--spSize) * 5);
    --sp5-5x: calc(var(--spSize) * 5.5);
    --sp6x: calc(var(--spSize) * 6);
    --sp6-5x: calc(var(--spSize) * 6.5);
    --sp7x: calc(var(--spSize) * 7);
    --sp7-5x: calc(var(--spSize) * 7.5);
    --sp8x: calc(var(--spSize) * 8);
    --sp8-5x: calc(var(--spSize) * 8.5);
    --sp9x: calc(var(--spSize) * 9);
    --sp9-5x: calc(var(--spSize) * 9.5);
    --sp10x: calc(var(--spSize) * 10);
    --sp11x: calc(var(--spSize) * 11);
    --sp12x: calc(var(--spSize) * 12);
    --sp13x: calc(var(--spSize) * 13);
    --sp14x: calc(var(--spSize) * 14);
    --sp14-5x: calc(var(--spSize) * 14.5);
    --sp15x: calc(var(--spSize) * 15);
    --sp16x: calc(var(--spSize) * 16);
    --sp17x: calc(var(--spSize) * 17);
    --sp18x: calc(var(--spSize) * 18);
    --sp18-5x: calc(var(--spSize) * 18.5);
    --sp19x: calc(var(--spSize) * 19);
    --sp20x: calc(var(--spSize) * 20);
    --sp20-5x: calc(var(--spSize) * 20.5);
    --sp21x: calc(var(--spSize) * 21);
    --sp22x: calc(var(--spSize) * 22);
    --sp23x: calc(var(--spSize) * 23);
    --sp24x: calc(var(--spSize) * 24);
    --sp25x: calc(var(--spSize) * 25);
    --sp26x: calc(var(--spSize) * 26);
    --sp27x: calc(var(--spSize) * 27);
    --sp28x: calc(var(--spSize) * 28);
    --sp29x: calc(var(--spSize) * 29);
    --sp30x: calc(var(--spSize) * 30);
    --sp31x: calc(var(--spSize) * 31);
    --sp32x: calc(var(--spSize) * 32);
    --sp33x: calc(var(--spSize) * 33);
    --sp34x: calc(var(--spSize) * 34);
    --sp35x: calc(var(--spSize) * 35);
    --sp36x: calc(var(--spSize) * 36);
    --sp37x: calc(var(--spSize) * 37);
    --sp38x: calc(var(--spSize) * 38);
    --sp39x: calc(var(--spSize) * 39);
    --sp40x: calc(var(--spSize) * 40);
    --sp41x: calc(var(--spSize) * 41);
    --sp41-5x: calc(var(--spSize) * 41.5);
    --sp42x: calc(var(--spSize) * 42);
    --sp42-5x: calc(var(--spSize) * 42.5);
    --sp43x: calc(var(--spSize) * 43);
    --sp44x: calc(var(--spSize) * 44);
    --sp45x: calc(var(--spSize) * 45);
    --sp47x: calc(var(--spSize) * 47);
    --sp48x: calc(var(--spSize) * 48);
    --sp49x: calc(var(--spSize) * 49);
    --sp50x: calc(var(--spSize) * 50);
    --sp52x: calc(var(--spSize) * 52);
    --sp54x: calc(var(--spSize) * 54);
    --sp55x: calc(var(--spSize) * 55);
    --sp57x: calc(var(--spSize) * 57);
    --sp57-5x: calc(var(--spSize) * 57.5);
    --sp58x: calc(var(--spSize) * 58);
    --sp59x: calc(var(--spSize) * 59);
    --sp60x: calc(var(--spSize) * 60);
    --sp63x: calc(var(--spSize) * 63);
    --sp64x: calc(var(--spSize) * 64);
    --sp66x: calc(var(--spSize) * 66);
    --sp67x: calc(var(--spSize) * 67);
    --sp68x: calc(var(--spSize) * 68);
    --sp68-5x: calc(var(--spSize) * 68.5);
    --sp69x: calc(var(--spSize) * 69);
    --sp70x: calc(var(--spSize) * 70);
    --sp72x: calc(var(--spSize) * 72);
    --sp72-5x: calc(var(--spSize) * 72.5);
    --sp75x: calc(var(--spSize) * 73);
    --sp75x: calc(var(--spSize) * 75);
    --sp77x: calc(var(--spSize) * 77);
    --sp78x: calc(var(--spSize) * 78);
    --sp81x: calc(var(--spSize) * 81);
    --sp81-5x: calc(var(--spSize) * 81.5);
    --sp82x: calc(var(--spSize) * 82);
    --sp83x: calc(var(--spSize) * 83);
    --sp84x: calc(var(--spSize) * 84);
    --sp85x: calc(var(--spSize) * 85);
    --sp85-5x: calc(var(--spSize) * 85.5);
    --sp88x: calc(var(--spSize) * 88);
    --sp91x: calc(var(--spSize) * 91);
    --sp94x: calc(var(--spSize) * 94);
    --sp97x: calc(var(--spSize) * 97);
    --sp98x: calc(var(--spSize) * 98);
    --sp99x: calc(var(--spSize) * 99);
    --sp100x: calc(var(--spSize) * 100);
    --sp103x: calc(var(--spSize) * 103);
    --sp106x: calc(var(--spSize) * 106);
    --sp108x: calc(var(--spSize) * 108);
    --sp112x: calc(var(--spSize) * 112);
    --sp115x: calc(var(--spSize) * 115);
    --sp117x: calc(var(--spSize) * 117);
    --sp118x: calc(var(--spSize) * 118);
    --sp128x: calc(var(--spSize) * 128);
    --sp123x: calc(var(--spSize) * 123);
    --sp132x: calc(var(--spSize) * 132);
    --sp141x: calc(var(--spSize) * 141);
    --sp142x: calc(var(--spSize) * 142);
    --sp152x: calc(var(--spSize) * 152);
    --sp166x: calc(var(--spSize) * 166);

    //transition
    --trTime: 0.3s;
    --trTime2: 0.5s;
    --trTime3: 0.7s;

    --transitionTime: var(--trTime);
    --transitionTime2: var(--trTime2);
    --transitionTime3: var(--trTime3);

    //line heights
    --lineHeight: 1.7;
    --lineHeightS: 1.4;
    --lineHeightXS: 1;

    //border
    --borderSm:calc(var(--spSize)/2);
    --borderMd:var(--spSize);
    --borderLg:100px;

    --maxWidth: 100%;
}

@media only screen and  (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
    :root {
        --h1L: 86px;
        --h1: 56px;
        --h2: 46px;
        --h3: 38px;
        --h4: 28px;
        --h5: 24px;
        --h6: 22px;
        --pL:18px;
        --pM:16px;
        --pS:14px;

        //grid 
        --maxWidth: 1605px;
        --containerPadding: 280px;
        --containerMarginTop:200px;
        --containerMarginBottom:200px;

        //layouts 
        --pageFromHeaderMargin:100px;
        --pageFromFooterMargin:200px;
        
        //margins
        --titleFromContent:38px;

        //header
        --headerPaddingLeftRigth:var(--sp17x);
        --headerPaddingTopBot:var(--sp2x);
    }
}


@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {

    :root {
        --h1L: 64px;
        --h1: 48px;
        --h2: 38px;
        --h3: 32px;
        --h4: 24px;
        --h5: 20px;
        --h6: 18px;
        --pL:16px;
        --pM:14px;
        --pS:12px;

        //!grid max width
        //grid 
        --maxWidth: 1605px;
        --containerPadding: 280px;

        //container 
        --containerMarginTop:180px;
        --containerMarginBottom:180px;

        //layouts        
        --pageFromHeaderMargin:80px;
        --pageFromFooterMargin:180px;

        //margins
        --titleFromContent:32px;

        
        //header
        --headerPaddingLeftRigth:var(--sp8x);
        --headerPaddingTopBot:var(--sp2x);
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
    :root {
        --h1L: 56px;
        --h1: 42px;
        --h2: 32px;
        --h3: 28px;
        --h4: 20px;
        --h5: 18px;
        --h6: 16px;
        --pL:16px;
        --pM:14px;
        --pS:12px;

        //!grid max width
        --maxWidth: 1360px;
        --containerPadding: 40px;
        --containerPaddingLarge: 160px;
        --containerMarginTop:160px;
        --containerMarginBottom:160px;

        //layouts  
        --pageFromHeaderMargin:60px;
        --pageFromFooterMargin:160px;

        //margins
        --titleFromContent:28px;

        //header
        --headerPaddingLeftRigth:var(--sp3x);
        --headerPaddingTopBot:var(--sp1-5x);
    }
}


@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
    :root {
        --h1L: 46px;
        --h1: 36px;
        --h2: 28px;
        --h3: 24px;
        --h4: 20px;
        --h5: 16px;
        --h6: 16px;
        --pL:16px;
        --pM:14px;
        --pS:12px;

        //!grid max width
        --containerPadding: 40px;
        --containerMarginTop:140px;
        --containerMarginBottom:140px;

        //layouts  
        --pageFromHeaderMargin:40px;
        --pageFromFooterMargin:140px;

        //margins
        --titleFromContent:20px;

        //header
        --headerPaddingLeftRigth:var(--sp5x);
        --headerPaddingTopBot:var(--sp2-5x);

    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
    :root {
        --h1L: 32px;
        --h1: 28px;
        --h2: 24px;
        --h3: 20px;
        --h4: 16px;
        --h5: 16px;
        --h6: 14px;
        --pL:14px;
        --pM:14px;
        --pS:12px;
        
        //!grid max width
        --containerPadding: 16px;
        --containerMarginTop:100px;
        --containerMarginBottom:100px;

        //layouts  
        --pageFromHeaderMargin:40px;
        --pageFromFooterMargin:100px;

        //margins
        --titleFromContent:16px;

        //header
        --headerPaddingLeftRigth:var(--sp2x);
        --headerPaddingTopBot:var(--sp1-5x);
    }
}
`

export default Variables
