import React, { useCallback, useImperativeHandle, useRef, forwardRef } from 'react'

//* HOC's
import { withLanguageContext, withUIContext } from 'context'

//* Components
import { Text, Button, Container, CustomLink, Icon } from 'components/common'
import { InvestmentProjectsSinglePopup } from 'components/pages'

//*Style
import CompanyInfoStyle from './style'

const CompanyInfo = forwardRef((props, ref) => {

	const titleRef = useRef()
	const infoRef = useRef()

	useImperativeHandle(ref, () => [titleRef, infoRef], [])

	//! Open Popup
	const PopupOpen = useCallback(() => {
		props.openPopup(
			<InvestmentProjectsSinglePopup
				isProjectsCompany={props.projectsCompany}
				isProductsCompany={props.productsCompany}
				id={props.id}
				messageTo={`${props.companyName} ${props.companyType}`}
			/>
		)
	}, [props.productsCompany, props.projectsCompany, props.id, props.companyName, props.companyType])

	return (
		<CompanyInfoStyle className='company-info'>
			<Container className='comp-info FlexBox spaceBetween'>
				<Text tag={'stringToLetters'} className={'title h1 MonBold'} text={props.companyName} ref={titleRef} />
				<CustomLink className='back-to FlexBox alignMiddle' url={props.backURL}>
					<Icon className='icon-arrow-left ultraDarkGrey' />
					<Text tag={'p'} className={'pM MonRegular ultraDarkGrey'} text={props.backPage} />
				</CustomLink>
				<div className='info FlexBoxColumn' ref={infoRef}>
					<Text tag={'h4'} className={'h4 MonMedium'} text={'companyOverview'} />
					<Text tag={'p'} className={'desc pL MonRegular'} text={props.companyInfo} />
					<Button onClick={PopupOpen} text={'ContactBusiness'} className='btn btn-black capitalize' />
				</div>
			</Container>
		</CompanyInfoStyle>
	)
})

export default withUIContext(withLanguageContext(CompanyInfo, ['translate']), ['openPopup'])
