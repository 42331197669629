import React, { useEffect, useMemo, useState, forwardRef, useRef, useImperativeHandle } from 'react'
import { withUIContext } from 'context'
import { Text, Container, Image, Icon } from 'components/common'
import TestimonialsStyle from './style'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Autoplay } from 'swiper/core'
import { TimelineLite } from 'gsap'

SwiperCore.use([Navigation, Autoplay])

function SampleNextArrow(props) {
    const { onClick, disabled } = props
    return <Icon className={`icon-arrow-right swiper-btn next  ${disabled ? 'swiper-button-disabled' : ''}`} onClick={onClick} />
}

function SamplePrevArrow(props) {
    const { onClick, disabled } = props
    return <Icon className={`icon-arrow-left swiper-btn prev ${disabled ? 'swiper-button-disabled' : ''}`} onClick={onClick} />
}

const Testimonials = forwardRef((props, ref) => {
    const [sliderState, setSliderState] = useState()
    const [sliderActive, setSliderActive] = useState(true)

    const sliderRef = useRef()
    const titleRef = useRef()

    useImperativeHandle(ref, () => [titleRef.current.childNodes], [])

    const storeTestimonials = useMemo(() => {
        return props.testimonials.map((el, ind) => {
            return (
                <SwiperSlide key={ind}>
                    <div className='SwiperSlideWrap FlexBoxColumn'>
                        <Image className='quote-mark' src='/images/home/double-quotes.svg' alt='quote-shape' />
                        <Text tag={'h3'} className={'text h4 MonRegular'} text={el.text} />
                        <div className='info FlexBox alignMiddle'>
                            <Image src={el.image} alt={el.name} />
                            <div className='heading FlexBoxColumn'>
                                <Text tag={'h4'} className={'h6 MonRegular uppercase'} text={el.name} />
                                <Text tag={'h5'} className={'h6 MonRegular ultraDarkGrey'} text={el.position} />
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            )
        })
    }, [props.testimonials])

    const paddingTimeLine = useMemo(() => new TimelineLite({ ease: 'power3.out' }), [])

    const slidePrev = () => {
        if (sliderActive) {
            if (sliderState.activeIndex !== 0) {
                sliderRef.current.swiper.slidePrev()
                setSliderActive(false)

                let activeSlide = sliderState.activeIndex
                let previousSlide = 2
                let arr = []
                sliderState.slides.forEach((item, ind) => {
                    if (ind >= activeSlide && ind <= activeSlide + previousSlide) {
                        arr.push(item)
                        return arr
                    }
                })

                arr = arr.reverse()

                paddingTimeLine
                    .to(arr, 0.4, {
                        stagger: 0.04,
                        marginLeft: props.winWidth >= 1440 ? '70px' : '35px',
                        marginRight: props.winWidth >= 1440 ? '70px' : '35px',
                    })
                    .to(arr, 0.4, {
                        stagger: 0.04,
                        marginLeft: '0px',
                        marginRight: '0px',
                    })
            }

            setTimeout(() => {
                setSliderActive(true)
            }, 1000)
        }
    }

    const slideNext = () => {
        if (sliderActive) {
            if (!sliderState.isEnd) {
                sliderRef.current.swiper.slideNext()
                setSliderActive(false)

                let activeSlide = sliderState.activeIndex
                let previousSlide = 2
                let arr = []

                sliderState.slides.forEach((item, ind) => {
                    if (ind >= activeSlide - previousSlide && ind <= activeSlide + previousSlide) {
                        arr.push(item)
                        return arr
                    }
                })

                paddingTimeLine
                    .to(arr, 0.4, {
                        stagger: 0.04,
                        marginLeft: props.winWidth >= 1440 ? '70px' : '35px',
                        marginRight: props.winWidth >= 1440 ? '70px' : '35px',
                    })
                    .to(arr, 0.4, {
                        stagger: 0.04,
                        marginLeft: '0px',
                        marginRight: '0px',
                    })
            }

            setTimeout(() => {
                setSliderActive(true)
            }, 1000)
        }
    }

    return (
        <TestimonialsStyle>
            <Container className={`full overflowHidden`}>
                <Container className='last'>
                    <Text tag={'stringToLetters'} className={'title h1 MonBold'} text={'peopleAboutUs'} ref={titleRef} />
                    <div className='slider'>
                        <SampleNextArrow onClick={slideNext} disabled={!!sliderState?.isEnd} />
                        <SamplePrevArrow onClick={slidePrev} disabled={!!sliderState?.isBeginning} />

                        <Swiper
                            ref={sliderRef}
                            speed={1500}
                            slidesPerView={1.5}
                            slidesPerGroup={2}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1.2,
                                    slidesPerGroup: 1.2,
                                },
                                768: {
                                    slidesPerView: 1.2,
                                    slidesPerGroup: 2,
                                },
                                1024: {
                                    slidesPerView: 1.3,
                                    slidesPerGroup: 2,
                                },
                                1440: {
                                    slidesPerView: 1.7,
                                    slidesPerGroup: 2,
                                },
                                1920: {
                                    slidesPerView: 1.5,
                                    slidesPerGroup: 2,
                                },
                            }}
                            onSwiper={(swiper) => setSliderState(swiper)}
                        >
                            {storeTestimonials}
                        </Swiper>
                    </div>
                </Container>
            </Container>
        </TestimonialsStyle>
    )
})

export default withUIContext(Testimonials, ['winWidth'])
