import styled from 'styled-components'

const WarningPopupStyle = styled.div.attrs((props) => ({ className: props.className }))`
	--titleMarginBottom:var(--sp9x);
	--descriptionMarginBottom:var(--sp10x);
	--priceItemPadd:var(--sp3x);
	--btnMarginTop:var(--sp4x);

	.title{
		margin-bottom:var(--titleMarginBottom);
		strong{
			font-family: MonBold,MonBoldRu;
		}
	}

	.description{
		margin-bottom:var(--descriptionMarginBottom);
	}

	.priceWrapp{
		.priceItem{
			padding:var(--priceItemPadd) 0;
			border-bottom:1px solid var(--darkGrey); 
			p{
				&:first-child{
					max-width:70%;
				}
				&:last-child{
					max-width:30%;
				}
			}
			&:last-child{
				border-bottom:0px; 
			}
		}
	}

	.btn{
		margin-top:var(--btnMarginTop)
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {

		--titleMarginBottom:var(--sp8x);
		--descriptionMarginBottom:var(--sp7x);
		--btnMarginTop:var(--sp3x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {

			--titleMarginBottom:var(--sp7x);
			--descriptionMarginBottom:var(--sp6x);
			--btnMarginTop:var(--sp2x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.LaptopSize}) {
			--titleMarginBottom:var(--sp7x);
			--descriptionMarginBottom:var(--sp6x);
			--btnMarginTop:var(--sp2x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		--titleMarginBottom:var(--sp5x);
		--descriptionMarginBottom:var(--sp4x);
		--btnMarginTop:var(--sp2x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--titleMarginBottom:var(--sp4x);
		--descriptionMarginBottom:var(--sp2x);
		--btnMarginTop:var(--sp2x);
	}
`
export default WarningPopupStyle
