import React, { memo as Memo } from 'react'
import { Text, Icon, Button } from 'components/common'

const Cooperation = Memo((props) => {

    return (

        <div className="accountCooperation">

            <Text tag={'h5'} className={' Title MonRegular black'} text={"CooperationTitle"} />

            <div className="CooperationItemWrap">

                {
                    props.data.map((item, ind) => {
                        return (
                            <div className="CooperationItem FlexBox" key={ind}>
                                <span className="iconCont FlexBox alignCenter alignMiddle">
                                    <Icon className="icon-okIcon white" />
                                </span>
                                <div className={'pM MonRegular ultraDarkGrey'} dangerouslySetInnerHTML={{ __html: item.text }} />
                            </div>
                        )
                    })
                }

            </div>

            <Button
                className={"btn-black btn-max-width"}
                text={'BecomeOurPartner'}
                link={"/"}
            />
        </div>
    )
})

export default Cooperation