import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import { Container, Text } from 'components/common';
import TopSectionStyle from './style';

const TopSection = forwardRef((props, ref) => {

    const titleRef1 = useRef();
    const descriptionRef1 = useRef();

    useImperativeHandle(ref, () => (
        [titleRef1.current.childNodes, descriptionRef1]
    ), [])

    return (
        <TopSectionStyle>
            <Container className='top-section largePadding'>
                <Text tag={'stringToLetters'} className={'h1 title MonBold black'} ref={titleRef1} text={props.title} />
                <Text tag={'h4'} className={'description h4 MonRegular black'} ref={descriptionRef1} text={props.text} />
            </Container>
        </TopSectionStyle>
    )
})

export default TopSection;