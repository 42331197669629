import React, { useMemo, useState, forwardRef, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Autoplay } from 'swiper/core'
import { TimelineLite } from 'gsap'

//* HOC's
import { withUIContext, withLanguageContext } from 'context'

//* Components
import { Text, Container, Icon, Image } from 'components/common'

//* Style
import PartnersSliderStyle from './style'
SwiperCore.use([Navigation, Autoplay])

function SampleNextArrow(props) {
    const { onClick, disabled } = props
    return <Icon className={`icon-arrow-right swiper-btn next  ${disabled ? 'swiper-button-disabled' : ''}`} onClick={onClick} />
}

function SamplePrevArrow(props) {
    const { onClick, disabled } = props
    return <Icon className={`icon-arrow-left swiper-btn prev ${disabled ? 'swiper-button-disabled' : ''}`} onClick={onClick} />
}

const BusinessInArmeniaVideoSlider = forwardRef((props, ref) => {
    const [sliderState, setSliderState] = useState()
    const [sliderActive, setSliderActive] = useState(true)

    const sliderRef = useRef()

    const storeLogos = useMemo(() => {
        return props.video.map((el, ind) => {
            return (
                <SwiperSlide key={ind}>
                    <div className='videoWrap FlexBoxContainer AlignEnd'>
                        <a className='video' href={el.src} target='_blank'>
                            <Icon className='icon-play'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                            </Icon>
                            <Image src={el.img} />
                        </a>
                        <div className='videoFileWrap'>
                            <Text tag={'div'} className={'pL MonMedium'} text={el.title} />
                            <a href={el.pdf} className='h5 MonRegular FlexBox alignMiddle uppercase' download>
                                <span>{props.translate('learnMore')}</span>
                                <Icon className='icon-circle-arrow-left'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                </Icon>
                            </a>
                        </div>
                    </div>
                </SwiperSlide>
            )
        })
    }, [props.video])

    const paddingTimeLine = useMemo(() => new TimelineLite({ ease: 'power3.out' }), [])

    const slidePrev = () => {
        if (sliderActive) {
            if (sliderState.activeIndex !== 0) {
                sliderRef.current.swiper.slidePrev()
                setSliderActive(false)

                if (props.winWidth >= 1024) {
                    let activeSlide = sliderState.activeIndex
                    let previousSlide = 2
                    let arr = []
                    sliderState.slides.forEach((item, ind) => {
                        if (ind >= activeSlide && ind <= activeSlide + previousSlide) {
                            arr.push(item)
                            return arr
                        }
                    })

                    arr = arr.reverse()

                    paddingTimeLine
                        .to(arr, 0.4, {
                            stagger: 0.01,
                            marginLeft: '30px',
                            marginRight: '30px',
                            delay: -0.03,
                        })
                        .to(arr, 0.4, {
                            stagger: 0.01,
                            marginLeft: 0,
                            marginRight: 0,
                        })
                }
            }

            setTimeout(() => {
                setSliderActive(true)
            }, 1000)
        }
    }

    const slideNext = () => {
        if (sliderActive) {
            if (!sliderState.isEnd) {
                sliderRef.current.swiper.slideNext()
                setSliderActive(false)

                if (props.winWidth >= 1024) {
                    let activeSlide = sliderState.activeIndex
                    let previousSlide = 2
                    let arr = []

                    sliderState.slides.forEach((item, ind) => {
                        if (ind >= activeSlide - previousSlide && ind <= activeSlide + previousSlide) {
                            arr.push(item)
                            return arr
                        }
                    })

                    paddingTimeLine
                        .to(arr, 0.4, {
                            stagger: 0.01,
                            marginLeft: '30px',
                            marginRight: '30px',
                            delay: -0.03,
                        })
                        .to(arr, 0.4, {
                            stagger: 0.01,
                            marginLeft: '0px',
                            marginRight: '0px',
                        })
                }
            }

            setTimeout(() => {
                setSliderActive(true)
            }, 1000)
        }
    }

    return (
        <PartnersSliderStyle>
            <Container className='largePadding'>
                <Text tag={'stringToLetters'} className={'h1 title MonBold black'} text={props.title} ref={ref} />
                <div className='slider'>
                    <SamplePrevArrow onClick={slidePrev} disabled={sliderState?.isBeginning} />
                    <SampleNextArrow onClick={slideNext} disabled={sliderState?.isEnd} />
                    <Swiper
                        ref={sliderRef}
                        speed={1000}
                        slidesPerView={1.5}
                        slidesPerGroup={2}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                slidesPerGroup: 1,
                            },
                            768: {
                                slidesPerView: 2,
                                slidesPerGroup: 2,
                            },
                            1024: {
                                slidesPerView: 2,
                                slidesPerGroup: 2,
                            },
                            1900: {
                                slidesPerView: 1.5,
                                slidesPerGroup: 2,
                            },
                        }}
                        onSwiper={(swiper) => setSliderState(swiper)}
                        onSlideChange={(swiper) => setSliderState({ ...swiper })}
                    >
                        {storeLogos}
                    </Swiper>
                </div>
            </Container>
        </PartnersSliderStyle>
    )
})

export default withLanguageContext(withUIContext(BusinessInArmeniaVideoSlider, ['winWidth']), ['translate', 'selectedLang'])
