import React, { useMemo, useState, forwardRef, useRef, useImperativeHandle } from 'react'
import { withUIContext } from 'context'
import { Text, Container, Image, Icon, Button } from 'components/common'
import PartnersSliderStyle from './style'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Autoplay } from 'swiper/core'
import { TimelineLite } from 'gsap'

SwiperCore.use([Navigation, Autoplay])

function SampleNextArrow(props) {
    const { onClick, disabled } = props
    return <Icon className={`icon-arrow-right swiper-btn next ${disabled ? 'swiper-button-disabled' : ''}`} onClick={onClick} />
}

function SamplePrevArrow(props) {
    const { onClick, disabled } = props
    return <Icon className={`icon-arrow-left swiper-btn prev ${disabled ? 'swiper-button-disabled' : ''}`} onClick={onClick} />
}

const PartnersSlider = forwardRef((props, ref) => {
    const storeLogos = useMemo(() => {
        return props.logosArr.map((el, ind) => {
            return (
                <SwiperSlide key={ind}>
                    <a href={el.link} target='_blank' rel='noopener noreferrer'>
                        <Image src={el.logo} alt='slider photo' />
                    </a>
                </SwiperSlide>
            )
        })
    }, [props.logosArr])


    const sliderRef = useRef()
    const titleRef = useRef()
    const btnRef = useRef()

    const slidePrev = () => {
        sliderRef.current.swiper.slidePrev()
    }

    const slideNext = () => {
        sliderRef.current.swiper.slideNext()
    }

    useImperativeHandle(ref, () => (props.inHome ? [titleRef.current.childNodes, btnRef] : [titleRef.current.childNodes]), [])

    return (
        <PartnersSliderStyle>
            <Container className={`largePadding overflowHidden ${props.inHome ? 'inHome' : ''}`}>
                <Text tag={'stringToLetters'} className={'title h1 MonMedium'} ref={titleRef} text={'ourPartners'} />
                {props.inHome && (
                    <Button yellowIcon={true} link={props.url} text={'moreAboutPartners'} wrappClassName={'otherElementAnimation'} ref={btnRef} />
                )}
                <div className='slider'>
                    <SampleNextArrow onClick={slideNext} />
                    <SamplePrevArrow onClick={slidePrev} />

                    <Swiper
                        ref={sliderRef}
                        speed={500}
                        slidesPerView={6}
                        slidesPerGroup={1}
                        loop={true}
                        autoplay={{
                            disableOnInteraction: false,
                            delay: 3000
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 2.5,
                                slidesPerGroup: 1,
                            },
                            768: {
                                slidesPerView: 3,
                                slidesPerGroup: 1,
                            },
                            1024: {
                                slidesPerView: 4,
                                slidesPerGroup: 1,
                            },
                            1440: {
                                slidesPerView: 5,
                                slidesPerGroup: 1,
                            },
                            1920: {
                                slidesPerView: 6,
                                slidesPerGroup: 1,
                            },
                        }}
                    >
                        {storeLogos}
                    </Swiper>
                </div>
                {props.inHome && <Button link={props.url} text={'BecomeOurPartner'} className='btn btn-black' />}
            </Container>
        </PartnersSliderStyle>
    )
})

export default withUIContext(PartnersSlider, ['winWidth'])
