import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import { withAccountContext, withUIContext } from 'context'
import { Button, Text } from 'components/common'
import SignIn from '../../../common/AppHeader/HeaderComponent/SignIn'
import FormForGuestStyle from './style'

const FormForGuest = forwardRef(({ openRegisterPopup, ...props }, ref) => {

	const itemRef = useRef()

	useImperativeHandle(ref, () => [itemRef], [])

	return (

		< FormForGuestStyle className='FlexBox alignCenter alignMiddle' >
			<div className='form flexBoxColumn alignMiddle textAlignCenter' ref={itemRef}>
				<Text tag={'h4'} className={'h4 MonMedium'} text={'wantToSeeMore'} />
				<Text tag={'p'} className={'text pM MonRegular'} text={'investIndFormText'} />
				<div className='login FlexBox alignCenter'>
					<Text tag={'p'} className={'pL MonRegular darkGrey'} text={'investIndFormPlease'} />
					<SignIn inProjectInd={true} />
				</div>
				<div className='or FlexBox alignCenter'>
					<Text tag={'p'} className={'or-text pM MonRegular darkGrey'} text={'orWord'} />
				</div>
				<Button onClick={openRegisterPopup} className='btn btn-black' text={'DontHaveAccountSignUp2'} />
			</div>
		</FormForGuestStyle >
	)
})

export default withUIContext(withAccountContext(FormForGuest, ['isLoggedIn', 'user']), ['openRegisterPopup'])
