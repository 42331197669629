import styled from 'styled-components'

const MapStyle = styled.div`
	position: relative;
	&.mapMd {
		padding-top: 50%;
	}
	&.mapSm {
		padding-top: var(--sp40x);
	}

	iframe {
		& + div {
			border: 0 solid transparent !important;
		}
	}
	> div {
		position: absolute;
		overflow: hidden;
		height: 100%;
		top: 0;
		left: 0;
		width: 100%;

		a[rel='noopener'] {
			display: none !important;
		}

		.gm-svpc,
		.gm-style-mtc,
		.gm-style-cc {
			display: none !important;
		}

		.gm-style {
			.gm-style-iw-c {
				padding: 0 !important;
				max-height: inherit !important;
				border-radius: 0 !important;
				background-color: transparent !important;
				box-shadow: none !important;
				> div {
					padding: 0 !important;
					overflow: hidden !important;
					max-height: inherit !important;
				}
			}

			.gm-style-iw-t::after {
				content: none;
			}
		}

		.gm-ui-hover-effect {
			z-index: 1 !important;
			background-color:var(--white) !important;
			box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px!important;
			border-radius: var(--borderLg) !important;
			top: calc(var(--spSize) / 2) !important;
			right: calc(var(--spSize) / 2) !important;
			img {
				margin: calc(var(--spSize) / 2);
			}
		}

		.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
			bottom: var(--sp2x) !important;
			right: inherit !important;
			left: var(--sp2x) !important;
			height: var(--sp13x);
			width: var(--sp5x);
			margin: 0 !important;
		}

		.gm-control-active.gm-fullscreen-control {
			border-radius: var(--borderLg) !important;
		}

		.infoBox {
			transform: translateZ(0px) translate(17px, -130%) !important;
		}

		.popdupDescription{
			overflow: hidden;
			text-overflow: ellipsis;
			display: -moz-box;
			-moz-box-orient: vertical;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			line-clamp: 2;
			box-orient: vertical;
		}

		.gmnoprint {
			> div {
				background-color: transparent !important;
				box-shadow: none !important;
				width: var(--sp5x) !important;
				height: 100% !important;
				top: initial;
				bottom: 0 !important;
				top: 0 !important;
				button {
					width: var(--sp5x) !important;
					height: var(--sp5x) !important;
					border-radius: var(--borderLg);
					background-color: var(--white) !important;
					box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
					img {
						display: none !important;
					}
					&:first-child {
						margin-bottom: var(--sp2x) !important;
						& + div {
							display: none !important;
						}
						&:after {
							content: '+';
							font-family: MonRegular, MonRegularRu;
							font-size: var(--sp3x);
						}
					}
					&:last-child {
						&:after {
							content: '-';
							font-family: MonRegular, MonRegularRu;
							font-size: var(--sp3x);
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeM}) {
		&.mapSm {
			padding-top: var(--sp34x);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.DesktopSizeS}) {
		&.mapSm {
			padding-top: var(--sp34x);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
		props.theme.mediaQuery.TabletSize}) {
		&.mapSm {
			padding-top: var(--sp34x);
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		&.mapMd {
			padding-top: 100%;
			.map {
				.infoBox {
					transform: translateZ(0px) translate(17px, -100%) !important;
				}
			}
		}

		&.mapSm {
			padding-top: 103%;
		}
	}
`

export default MapStyle
