import React, { Component, createContext } from 'react'

//* HOC's
import withUIContext from '../consumerHOC/UIConsumer'
import { withRouter } from 'next/router'

//* Helpers
import { getData, api } from 'helpers'
import _ from 'lodash'

const DataContext = createContext(null)
class DataProvider extends Component {
    //! States
    state = { ...this.props.newData, prevRoute: '' }

    //! Methods
    methods = {
        updatePageData: this.updatePageData.bind(this),
        submitAdsPackage: this.submitAdsPackage.bind(this),
        becomePartner: this.becomePartner.bind(this),
        potentialCalculatorCalculate: this.potentialCalculatorCalculate.bind(this),
        potentialCalculatorGetFile: this.potentialCalculatorGetFile.bind(this),
        subscribeNewsletter: this.subscribeNewsletter.bind(this),
    }

    //! Component Did Mount
    componentDidUpdate(prevProps, prevState, snapshot) {
        const lang = this.props.router.locale || this.props.selectedLang

        if (prevProps.router.asPath !== this.props.router.asPath || prevProps.router.locale !== this.props.router.locale) {
            this.props.toggleLoading(true)
            getData(lang, this.state, this.props.router).then((res) => {
                this.setState({ ...res, prevRoute: prevProps.router.asPath.replace(/\/$/, '') }, () => {
                    this.props.toggleLoading(false)
                })
            })
        }
    }

    //! Update Page Data
    updatePageData() {
        const lang = this.props.router.locale || this.props.selectedLang

        return getData(lang, this.state, this.props.router, false, true).then((res) => {
            this.setState(res)
        })
    }

    //! Submit Ads Package
    submitAdsPackage(data) {
        return this.asyncFunctionDecorator(() => api.post('advertisement-submission', data, this.props.router.locale, 'multipart/form-data'))
    }

    //! Calculate Potential Calculator
    potentialCalculatorCalculate(data) {
        return this.asyncFunctionDecorator(() => api.post('potential-calculator', data, this.props.router.locale))
    }

    //! Get Potential Calculator File
    potentialCalculatorGetFile(data) {
        return this.asyncFunctionDecorator(() => api.post('potential-calculator-get-file', data, this.props.router.locale))
    }

    //! Become Partner
    becomePartner(data) {
        return this.asyncFunctionDecorator(() => api.post('become-partner', data, this.props.router.locale))
    }

    //! Subscribe To Newsletter
    subscribeNewsletter(data) {
        return this.asyncFunctionDecorator(() => api.post('subscribe', data, this.props.router.locale))
    }

    //! Async Function Catch Decorator
    asyncFunctionDecorator(asyncFunc) {
        return asyncFunc().catch((err) => {
            return Promise.reject(err.response)
        })
    }

    render() {
        return <DataContext.Provider value={{ ...this.state, ...this.methods }}>{this.props.children}</DataContext.Provider>
    }
}

export default withRouter(withUIContext(DataProvider, ['toggleLoading']))
export const DataConsumer = DataContext.Consumer
