import React, { forwardRef, useRef, useImperativeHandle } from 'react'

//* HOC's
import { withAccountContext } from 'context'

//* Components
import { Image, Text } from 'components/common'

//* Styles
import ProjectContentStyle from './style'

const ProjectContent = forwardRef(({ ...props }, ref) => {
    //! Refs
    const itemRef = useRef()

    //! Imperative Handele
    useImperativeHandle(ref, () => [itemRef], [])

    return (
        <ProjectContentStyle className='project-content' ref={itemRef}>
            <div className='about-company'>
                <Text tag={'h4'} className={'title h4 MonMedium'} text={'aboutCompany'} />
                <Text tag={'p'} className={'pL MonRegular'} text={props.aboutCompany} />
            </div>
            {props.isLoggedIn ? (
                <div className='about-invest-project'>
                    <Text tag={'h4'} className={'title h4 MonMedium'} text={'aboutInvestmentProject'} />
                    <Text tag={'p'} className={'pL MonRegular'} text={props.aboutInvestProject} />

                    {props.bussinesPlan ?
                        <div className='business-plan'>
                            <Image src='/images/singleInvest/businessPlan.svg' alt='business plan icon' />
                            <Text tag={'p'} className={'pL MonMedium'} text={'businessPlan'} />
                        </div>
                        :
                        <span className={props.details ? 'business-plan' : ''}></span>
                    }
                </div>
            ) : (
                <div />
            )}
        </ProjectContentStyle>
    )
})

export default withAccountContext(ProjectContent, ['isLoggedIn'])
