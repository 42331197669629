import React, { memo as Memo, useState, useCallback } from 'react'

//* HOC's
import { withLanguageContext, withUIContext, withAccountContext } from 'context'

//* Components
import { Text, CustomLink, SocialLogin, Translate, FormItem, FormContainer, FormInput } from 'components/common'
import { VerifyAccountPopup } from 'components/pages'
import { Button, Input } from 'antd'

//* Styles
import FormStyle from '../../../styles/pages/FormStyle'
import LoginRegisterStyle from '../../../styles/pages/LoginRegister'

const Register = Memo(({ openLoginPopup, register, ...props }) => {
	//! States
	const [success, setSuccess] = useState(false)

	//! On Form Submit
	const onFinish = useCallback((values) => {
		return register(values).then(() => {
			setSuccess(true)
		})
	}, [])

	return (
		<LoginRegisterStyle>
			{!success ? (
				<>
					<Text tag={'div'} className={' Title h4 MonMedium black'} text={'DontHaveAccount'} />

					<div className='subTitle'>
						<Text tag={'span'} className={' pL MonRegular ultraDarkGrey'} text={'AlreadyHaveAccount'} />
						&nbsp;
						<Text
							tag={'span'}
							className={' pL MonRegular black underline cursorPointer'}
							text={'AlreadyHaveAccount2'}
							onClick={openLoginPopup}
						/>
					</div>

					<SocialLogin />

					<div className='SignInFormWrapp'>
						<FormStyle>
							<FormContainer layout={'vertical'} onFinish={onFinish} className={'FormRow FlexBoxContainer spaceBetween'}>
								<div className='FormCol FormCol-50'>
									<FormItem name={'first_name'} required={true}>
										<FormInput placeholder={props.translate('FirstName') + '*'} />
									</FormItem>
								</div>
								<div className='FormCol FormCol-50'>
									<FormItem name={'last_name'} required={true}>
										<FormInput placeholder={props.translate('LastName') + '*'} />
									</FormItem>
								</div>
								<div className='FormCol FormCol-50'>
									<FormItem name={'email'} validationType='email' required={true}>
										<FormInput placeholder={props.translate('Email') + '*'} />
									</FormItem>
								</div>
								<div className='FormCol FormCol-50'>
									<FormItem name={'phone'} validationType='phone' required={true}>
										<FormInput placeholder={props.translate('PhoneNumber') + '*'} />
									</FormItem>
								</div>
								<div className='FormCol FormCol-50'>
									<FormItem name='password' required={true} validationType='password'>
										<Input.Password autoComplete='on' placeholder={props.translate('Password') + '*'} />
									</FormItem>
								</div>
								<div className='FormCol FormCol-50'>
									<FormItem
										name='password_confirmation'
										dependencies={['password']}
										hasFeedback
										required={true}
										validationType='c_password'
									>
										<Input.Password autoComplete='on' placeholder={props.translate('RepeatPassword') + '*'} />
									</FormItem>
								</div>
								<div className='formBtnWrapp'>
									<Button htmlType={'Submit'} className={'btn btn-black btn-max-width'}>
										<Translate val={'SignUp'} />
									</Button>
								</div>
								<Text tag={'div'} className={'FormCol bottomText pM ultraDarkGrey MonRegular'}>
									<Translate val='ByLoggingCreatingAccount' />
									&nbsp;
									<CustomLink url={'/privacy-policy'} className={'black underline'} options={{ target: '_blank' }}>
										<Translate val='PrivacyPolicyTermsService' />
									</CustomLink>
								</Text>
							</FormContainer>
						</FormStyle>
					</div>
				</>
			) : (
				<VerifyAccountPopup />
			)}
		</LoginRegisterStyle>
	)
})

export default withLanguageContext(withAccountContext(withUIContext(Register, ['openLoginPopup']), ['register']), ['translate'])
