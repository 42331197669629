import React, { memo as Memo } from 'react'

//* Components
import CustomLink from '../../../CustomLink'

//* Styles
import LogoStyle from './style'

const Logo = Memo((props) => {

    return (
        <LogoStyle>
            <CustomLink url={"/"} className={'Logo'} content={
                <img layout="fill" src={'/images/logo.svg'} alt="logo" width="102" height="67" />
            } />
        </LogoStyle>
    )
})


export default Logo