import { memo as Memo, useMemo, useState } from 'react'

//* Components
import { Text } from 'components/common'
import Collapse from '@material-ui/core/Collapse'
import { CategoryBtn } from 'components/pages'
import { useRouter } from 'next/router'

//* Helpers
import { getParamsByKey } from 'helpers'


const InvestmentFilter = Memo(({ queryParamKeys, onCategoryChange, categories }) => {

	//! States
	const [opened, setOpened] = useState(true)

	//! Router
	const router = useRouter()

	//! Categories Filter
	const storeCategories = useMemo(() => {
		const catParam = queryParamKeys[0] //? "cat_ids"
		const activeItems = getParamsByKey(router, catParam)

		return categories.map((i, k) => (
			<CategoryBtn
				key={k}
				active={activeItems?.includes(i.id.toString())}
				bgColor={activeItems?.includes(i.id.toString()) && i.color}
				onChange={() => onCategoryChange(catParam, i.id)}
				id={i.id}
				slug={i.slug}
				text={i.name}
				inFilter={true}
				state={false}
			/>
		))
	}, [categories, router.query])


	return (
		<div className={`single-filter overflowHidden ${opened ? 'active' : ''} sector`}>
			<Text tag={'h5'} onClick={() => setOpened(!opened)} className={'title h5 MonMedium black'} text={'filterSector'} />
			<Collapse in={opened} timeout='auto'>
				<div className='block'>{storeCategories}</div>
			</Collapse>
		</div>
	)
})

export default InvestmentFilter
