import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'

//* Components
import { MapPopInfo } from 'components/common'
import { useLoadScript, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api'

//* Styles
import MapStyle from 'styles/pages/MapStyle'

function MapComponent({ address, coordinates, pinsData, markerChange, ...props }) {
	//! States
	const [mapRef, setMapRef] = useState(null)
	const [selectedPlace, setSelectedPlace] = useState(null)
	const [markerMap, setMarkerMap] = useState({})
	const [infoOpen, setInfoOpen] = useState(false)
	const [markerMapDrag, setMarkerMapDrag] = useState()

	//! Map Is Loaded
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.googleMapKey,
	})

	//! Geocoder -> Update map form Address to coordinates
	useEffect(() => {
		if (mapRef && address) {
			const geocoder = new google.maps.Geocoder()
			geocoder.geocode({ address: address }, (results, status) => {
				if (status === 'OK' && results) {
					mapRef.setZoom(props.minZoom || 13)
					mapRef.setCenter(results[0].geometry.location)

					const lat = results[0].geometry.location.lat()
					const lng = results[0].geometry.location.lng()
					markerChange ? markerChange({ lat, lng }) : setMarkerMapDrag({ lat, lng })
				} else {
					console.warn('Geocode was not successful for the following reason: ' + status)
				}
			})
		}
	}, [mapRef, address])

	//! Pins to map bounds
	const listener = useRef()
	const fitBounds = useCallback(
		(map) => {
			const bounds = new google.maps.LatLngBounds()
			pinsData.map((place) => {
				bounds.extend(place.pos)
				return place.id
			})

			map.fitBounds(bounds)
			listener.current = google.maps.event.addListener(map, 'idle', function () {
				map.setZoom(props.minZoom || 8)
				google.maps.event.removeListener(listener.current)
			})
		},
		[pinsData]
	)

	//! Map Load Handler
	const loadHandler = useCallback(
		(map) => {
			//! Map Ref
			setMapRef(map)
			// setTimeout(() => {
			// }, 1000)

			//! Adding Pins to map bounds
			pinsData && fitBounds(map)
			if (pinsData) {
				map.setZoom(props.minZoom || 8)
			}
		},
		[pinsData]
	)

	//! Pins List if has pinsData
	const pins = useMemo(() => {
		mapRef && mapRef.setZoom(props.minZoom || 8)

		return (
			isLoaded &&
			pinsData?.map((place) => (
				<Marker
					draggable={props.markerDrag ? true : false}
					onDragEnd={props.markerDrag ? onMarkerDragEnd : ''}
					key={place.id}
					position={place.pos}
					icon={{
						url: infoOpen && selectedPlace.id === place.id ? '/images/svg/IconPinActive.svg' : '/images/svg/IconPin.svg',
						anchor: new google.maps.Point(14, 50),
					}}
					onLoad={(marker) => {
						return markerLoadHandler(marker, place)
					}}
					onClick={(event) => markerClickHandler(event, place)}
				/>
			))
		)
	}, [pinsData, isLoaded, mapRef, infoOpen, selectedPlace])

	//! Marker Load Handler for setting markers object
	const markerLoadHandler = useCallback(
		(marker, place) => {
			return setMarkerMap((prevState) => {
				mapRef?.setCenter(marker.getPosition())
				return { ...prevState, [place.id]: marker }
			})
		},
		[pins]
	)

	//! Marker Drag End Handler
	const onMarkerDragEnd = useCallback(
		(evt) => {
			const lat = evt.latLng.lat()
			const lng = evt.latLng.lng()
			markerChange && markerChange({ lat, lng })
			setMarkerMapDrag({ lat: lat, lng: lng })
		},
		[markerChange]
	)

	//! Marker Click Handler
	const markerClickHandler = useCallback(
		(event, place) => {
			setSelectedPlace(place)
			if (props.popInfo) {
				setInfoOpen(true)
			}
		},
		[props.popInfo]
	)

	useEffect(() => {
		mapRef && coordinates && mapRef.setZoom(props.minZoom || 13)
		mapRef && coordinates && mapRef.setCenter(coordinates)
	}, [mapRef, coordinates])

	const renderMap = useCallback(() => {
		return (
			<MapStyle className={props.className}>
				<GoogleMap onLoad={loadHandler} zoom={props.minZoom || 8}>
					{pins}

					{!pins && (
						<Marker
							draggable={props.markerDrag ? true : false}
							onDragEnd={props.markerDrag ? onMarkerDragEnd : ''}
							position={coordinates || markerMapDrag}
							icon={{
								url: '/images/svg/IconPin.svg',
								anchor: new google.maps.Point(14, 50),
							}}
						/>
					)}

					{props.popInfo && infoOpen && selectedPlace && (
						<InfoWindow anchor={markerMap[selectedPlace.id]} onCloseClick={() => setInfoOpen(false)}>
							<MapPopInfo
								description={selectedPlace.description}
								img={selectedPlace.img}
								price={selectedPlace.price}
								title={selectedPlace.title}
							/>
						</InfoWindow>
					)}
				</GoogleMap>
			</MapStyle>
		)
	}, [infoOpen, selectedPlace, pins, markerMap, markerMapDrag, coordinates])

	return isLoaded ? renderMap() : null
}

export default MapComponent
